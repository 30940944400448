import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: any;
  FileUpload: any;
  JSON: any;
  JSONObject: any;
  Time: any;
  /** The `Upload` scalar type represents a file upload. */
  Upload: any;
};


export type Activacion = {
  __typename?: 'Activacion';
  id: Scalars['ID'];
  nombre?: Maybe<Scalars['String']>;
  agencia?: Maybe<Scalars['Int']>;
  usuario_persona?: Maybe<Persona>;
  ts?: Maybe<Scalars['Date']>;
  boletasCount?: Maybe<Scalars['Int']>;
  montototalSum?: Maybe<Scalars['Int']>;
  montoganadoSum?: Maybe<Scalars['Int']>;
};

export type Agencia = {
  __typename?: 'Agencia';
  numero?: Maybe<Scalars['ID']>;
  nombre?: Maybe<Scalars['String']>;
};

export type Agencias = {
  __typename?: 'Agencias';
  numero?: Maybe<Scalars['Int']>;
  nombre?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['Date']>;
};

export type Ajuste = {
  __typename?: 'Ajuste';
  id?: Maybe<Scalars['ID']>;
  Corredor?: Maybe<Corredor>;
  aprobacion?: Maybe<Usuario>;
  estado?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['Date']>;
  updated_at?: Maybe<Scalars['Date']>;
  monto?: Maybe<Scalars['Int']>;
  detalle?: Maybe<Scalars['String']>;
};

export type AjusteUmn = {
  __typename?: 'AjusteUmn';
  id?: Maybe<Scalars['ID']>;
  Umn?: Maybe<Umn>;
  aprobacion?: Maybe<Usuario>;
  estado?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['Date']>;
  updated_at?: Maybe<Scalars['Date']>;
  monto?: Maybe<Scalars['Int']>;
  detalle?: Maybe<Scalars['String']>;
  tipo?: Maybe<Scalars['String']>;
};

export type Anulacion = {
  __typename?: 'Anulacion';
  id?: Maybe<Scalars['ID']>;
  nivel?: Maybe<Scalars['Int']>;
  elevacion?: Maybe<Scalars['Int']>;
  Juego?: Maybe<TicketQuiniela>;
  AnulacionMotivo?: Maybe<Motivo>;
  estado?: Maybe<Scalars['String']>;
  AprobadoPor?: Maybe<Usuario>;
  created_at?: Maybe<Scalars['Date']>;
  excedido?: Maybe<Scalars['Boolean']>;
};

export type BatchResponse = {
  __typename?: 'BatchResponse';
  caja?: Maybe<Caja>;
};

export type BoletaInput = {
  aprobado?: Maybe<Scalars['Boolean']>;
  codigo?: Maybe<Scalars['String']>;
  back_image?: Maybe<Scalars['String']>;
  codigo_verificacion?: Maybe<Scalars['String']>;
  corredor_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['String']>;
  deleted_at?: Maybe<Scalars['String']>;
  estado?: Maybe<Scalars['String']>;
  front_image?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  libro_id?: Maybe<Scalars['Int']>;
  numero?: Maybe<Scalars['String']>;
  premio?: Maybe<Scalars['Int']>;
  tipo_juego?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['String']>;
  vendido_el?: Maybe<Scalars['String']>;
};

export enum CacheControlScope {
  Public = 'PUBLIC',
  Private = 'PRIVATE'
}

export type Caja = {
  __typename?: 'Caja';
  id?: Maybe<Scalars['ID']>;
  apertura?: Maybe<Scalars['Date']>;
  cierre?: Maybe<Scalars['Date']>;
  linea?: Maybe<Scalars['Int']>;
  utilizado?: Maybe<Scalars['Int']>;
  attach_id?: Maybe<Scalars['String']>;
  terminal_id?: Maybe<Scalars['String']>;
  Terminal?: Maybe<Terminal>;
  estado?: Maybe<Scalars['String']>;
  Transacciones?: Maybe<Array<Maybe<Transaccion>>>;
  totalMonto?: Maybe<Scalars['Int']>;
  Corredor?: Maybe<Corredor>;
};

export type CajaFecha = {
  __typename?: 'CajaFecha';
  id?: Maybe<Scalars['ID']>;
  fecha?: Maybe<Scalars['Date']>;
  total?: Maybe<Scalars['Int']>;
  totalComisionA?: Maybe<Scalars['Int']>;
  totalComisionC?: Maybe<Scalars['Int']>;
  cantidad?: Maybe<Scalars['Int']>;
  apertura?: Maybe<Scalars['Date']>;
  cierre?: Maybe<Scalars['Date']>;
  linea?: Maybe<Scalars['Int']>;
  utilizado?: Maybe<Scalars['Int']>;
  attach_id?: Maybe<Scalars['String']>;
  Terminal?: Maybe<Terminal>;
  estado?: Maybe<Scalars['String']>;
  Transacciones?: Maybe<Array<Maybe<Transaccion>>>;
  totalMonto?: Maybe<Scalars['Int']>;
  Corredor?: Maybe<Corredor>;
};

export type Cedula = {
  __typename?: 'Cedula';
  nombre?: Maybe<Scalars['String']>;
  apellido?: Maybe<Scalars['String']>;
  fechanac?: Maybe<Scalars['Date']>;
  nrodoc?: Maybe<Scalars['String']>;
};

export type Cobrador = {
  __typename?: 'Cobrador';
  now?: Maybe<Scalars['Date']>;
  id?: Maybe<Scalars['ID']>;
  numero?: Maybe<Scalars['Int']>;
  nombre?: Maybe<Scalars['String']>;
  cuenta?: Maybe<Scalars['Float']>;
  linea?: Maybe<Scalars['Int']>;
  Usuario?: Maybe<Usuario>;
  Umn?: Maybe<Umn>;
};

export type Consolidacion = {
  __typename?: 'Consolidacion';
  id?: Maybe<Scalars['ID']>;
  agencia_id?: Maybe<Scalars['ID']>;
  umn_id?: Maybe<Scalars['ID']>;
  monto?: Maybe<Scalars['Int']>;
  comision?: Maybe<Scalars['Int']>;
  premio?: Maybe<Scalars['Int']>;
  reclamos?: Maybe<Scalars['Int']>;
  recuperacion?: Maybe<Scalars['Int']>;
  ocasional?: Maybe<Scalars['Int']>;
  multas?: Maybe<Scalars['Int']>;
  monto_retrasadas?: Maybe<Scalars['Int']>;
  comision_retrasadas?: Maybe<Scalars['Int']>;
  premios_retrasadas?: Maybe<Scalars['Int']>;
  cantidad_boletas?: Maybe<Scalars['Int']>;
  cantidad_corredores?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['Date']>;
  saldo_anterior?: Maybe<Scalars['Float']>;
  depositos?: Maybe<Scalars['Int']>;
  neto?: Maybe<Scalars['Float']>;
  umn?: Maybe<Scalars['Int']>;
  fecha_movimiento?: Maybe<Scalars['Date']>;
  saldo_a_depositar?: Maybe<Scalars['Float']>;
};

export type Corredor = {
  __typename?: 'Corredor';
  now?: Maybe<Scalars['Date']>;
  id?: Maybe<Scalars['ID']>;
  numero?: Maybe<Scalars['Int']>;
  nombre?: Maybe<Scalars['String']>;
  cuenta?: Maybe<Scalars['Int']>;
  linea?: Maybe<Scalars['Int']>;
  Usuario?: Maybe<Usuario>;
  Umn?: Maybe<Umn>;
  entrega_corredor?: Maybe<Scalars['Boolean']>;
  activo?: Maybe<Scalars['Boolean']>;
  Extractos?: Maybe<Array<Maybe<Extracto>>>;
  Entregas?: Maybe<Array<Maybe<Entrega>>>;
  Cajas?: Maybe<Array<Maybe<Caja>>>;
  Caja?: Maybe<Caja>;
};

export type Country = {
  __typename?: 'Country';
  id?: Maybe<Scalars['ID']>;
  iso?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  nicename?: Maybe<Scalars['String']>;
  iso3?: Maybe<Scalars['String']>;
  numcode?: Maybe<Scalars['Int']>;
  phonecode?: Maybe<Scalars['Int']>;
};

export type CreateRegimerWuInput = {
  external_id?: Maybe<Scalars['String']>;
  nombres?: Maybe<Scalars['String']>;
  documento?: Maybe<Scalars['String']>;
  dni?: Maybe<Scalars['String']>;
  nacionalidad?: Maybe<Scalars['String']>;
  fechaNacimiento?: Maybe<Scalars['String']>;
  sexo?: Maybe<Scalars['String']>;
  cityAdnCountry?: Maybe<Scalars['String']>;
  estadoCivil?: Maybe<Scalars['String']>;
  domicilio?: Maybe<Scalars['String']>;
  calle?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  departamento?: Maybe<Scalars['String']>;
  pais?: Maybe<Scalars['String']>;
  telefono?: Maybe<Scalars['String']>;
  celular?: Maybe<Scalars['String']>;
  correo?: Maybe<Scalars['String']>;
  actividadEconomica?: Maybe<Scalars['String']>;
  cargoOcupacion?: Maybe<Scalars['String']>;
  ingresos?: Maybe<Scalars['String']>;
  origenFondos?: Maybe<Scalars['String']>;
  nombresR?: Maybe<Scalars['String']>;
  apellidosR?: Maybe<Scalars['String']>;
  nacionalidadR?: Maybe<Scalars['String']>;
  paisR?: Maybe<Scalars['String']>;
  relacionR?: Maybe<Scalars['String']>;
  parentescoR?: Maybe<Scalars['String']>;
  fechaRemesa?: Maybe<Scalars['String']>;
  destinoRemesa?: Maybe<Scalars['String']>;
  cargoP?: Maybe<Scalars['String']>;
  institucionPu?: Maybe<Scalars['String']>;
  fechaInicioCargo?: Maybe<Scalars['String']>;
  fechaFinCargo?: Maybe<Scalars['String']>;
  conyuge?: Maybe<Scalars['String']>;
  asociadaP?: Maybe<Scalars['String']>;
  parentescoP?: Maybe<Array<Maybe<Scalars['String']>>>;
  nombresP?: Maybe<Array<Maybe<Scalars['String']>>>;
  tipoDocP?: Maybe<Array<Maybe<Scalars['String']>>>;
  cargoPariente?: Maybe<Array<Maybe<Scalars['String']>>>;
  institucionP?: Maybe<Array<Maybe<Scalars['String']>>>;
  accionista?: Maybe<Scalars['String']>;
  denominacion?: Maybe<Array<Maybe<Scalars['String']>>>;
  nroRuc?: Maybe<Array<Maybe<Scalars['String']>>>;
  porcentajeP?: Maybe<Array<Maybe<Scalars['String']>>>;
  nombresA?: Maybe<Array<Maybe<Scalars['String']>>>;
  tipoDocAccionaria?: Maybe<Array<Maybe<Scalars['String']>>>;
  miembroG?: Maybe<Scalars['String']>;
  denominacionPj?: Maybe<Array<Maybe<Scalars['String']>>>;
  nroRucPj?: Maybe<Array<Maybe<Scalars['String']>>>;
  reference?: Maybe<Scalars['String']>;
};

export type Cuenta = {
  __typename?: 'Cuenta';
  linea?: Maybe<Scalars['Int']>;
  cuenta?: Maybe<Scalars['Float']>;
  Transacciones?: Maybe<Array<Maybe<Transaccion>>>;
};


export type Deposito = {
  __typename?: 'Deposito';
  monto?: Maybe<Scalars['Int']>;
  detalle?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  umn_id?: Maybe<Scalars['ID']>;
  medio?: Maybe<Scalars['String']>;
  nro_recibo?: Maybe<Scalars['Int']>;
  id_externo?: Maybe<Scalars['ID']>;
  created_at?: Maybe<Scalars['Date']>;
  generado?: Maybe<Scalars['Date']>;
  anulado?: Maybe<Scalars['Boolean']>;
  Umn?: Maybe<Umn>;
  aprobado_por?: Maybe<Scalars['Int']>;
};

export type DetalleCaja = {
  __typename?: 'DetalleCaja';
  Transacciones?: Maybe<Array<Maybe<Transaccion>>>;
  Extractos?: Maybe<Array<Maybe<Extracto>>>;
  Pedidos?: Maybe<Array<Maybe<Pedido>>>;
};

export type Entrega = {
  __typename?: 'Entrega';
  id?: Maybe<Scalars['ID']>;
  Corredor?: Maybe<Corredor>;
  Cobrador?: Maybe<Cobrador>;
  aprobacion?: Maybe<Usuario>;
  estado?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['Date']>;
  updated_at?: Maybe<Scalars['Date']>;
  monto?: Maybe<Scalars['Int']>;
};

export type EstadoUmn = {
  __typename?: 'EstadoUmn';
  linea?: Maybe<Scalars['Int']>;
  deuda?: Maybe<Scalars['Int']>;
  cajasAbiertas?: Maybe<Scalars['Int']>;
  cajasCerradas?: Maybe<Scalars['Int']>;
  ventasCerradas?: Maybe<Scalars['Int']>;
  ventasAbiertas?: Maybe<Scalars['Int']>;
  venta?: Maybe<Scalars['Int']>;
  depositado?: Maybe<Scalars['Int']>;
};

export type Extracto = {
  __typename?: 'Extracto';
  id?: Maybe<Scalars['ID']>;
  detalle?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['Date']>;
  monto?: Maybe<Scalars['Int']>;
  cuenta?: Maybe<Scalars['Float']>;
  cuenta_anterior?: Maybe<Scalars['Float']>;
  fecha_referencia?: Maybe<Scalars['Date']>;
  annexable_id?: Maybe<Scalars['Int']>;
  serial?: Maybe<Scalars['String']>;
};

export type Extracto3 = {
  __typename?: 'Extracto3';
  id?: Maybe<Scalars['ID']>;
  detalle?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['Date']>;
  monto?: Maybe<Scalars['Int']>;
  cuenta?: Maybe<Scalars['Float']>;
  cuenta_anterior?: Maybe<Scalars['Float']>;
  fecha_referencia?: Maybe<Scalars['Date']>;
  annexable_id?: Maybe<Scalars['Int']>;
  Caja?: Maybe<Caja>;
};

export type ExtractoUmn = {
  __typename?: 'ExtractoUmn';
  id?: Maybe<Scalars['ID']>;
  detalle?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['Date']>;
  monto?: Maybe<Scalars['Int']>;
  cuenta?: Maybe<Scalars['Int']>;
  cuenta_anterior?: Maybe<Scalars['Int']>;
  Umn?: Maybe<Umn>;
  AjusteUmn?: Maybe<AjusteUmn>;
};


export type Grupo = {
  __typename?: 'Grupo';
  id: Scalars['ID'];
  cod?: Maybe<Scalars['String']>;
  nombre?: Maybe<Scalars['String']>;
  menu?: Maybe<Scalars['String']>;
  home?: Maybe<Scalars['String']>;
  estilo?: Maybe<Scalars['String']>;
};

export type GrupoTransacciones = {
  __typename?: 'GrupoTransacciones';
  estado?: Maybe<Scalars['String']>;
  nombre?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  producto_id?: Maybe<Scalars['String']>;
  cantidad?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
  totalComisionA?: Maybe<Scalars['Float']>;
  totalComisionC?: Maybe<Scalars['Float']>;
};

export type Heartbeat = {
  __typename?: 'Heartbeat';
  id?: Maybe<Scalars['ID']>;
  data?: Maybe<Scalars['JSON']>;
  created_at?: Maybe<Scalars['Date']>;
  updated_at?: Maybe<Scalars['Date']>;
};

export type Invite = {
  __typename?: 'Invite';
  id?: Maybe<Scalars['ID']>;
  created_at?: Maybe<Scalars['Date']>;
  type?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  agencia?: Maybe<Scalars['Int']>;
  subagencia?: Maybe<Scalars['Int']>;
  estado?: Maybe<Scalars['String']>;
  metadata?: Maybe<Scalars['JSON']>;
  status?: Maybe<Scalars['String']>;
  ci?: Maybe<Cedula>;
};



export type JugadaQuiniela = {
  __typename?: 'JugadaQuiniela';
  id?: Maybe<Scalars['ID']>;
  tipo?: Maybe<Scalars['Int']>;
  numero?: Maybe<Scalars['String']>;
  postura?: Maybe<Scalars['Int']>;
  monto_apostado?: Maybe<Scalars['Int']>;
  monto_ganado?: Maybe<Scalars['Int']>;
};

export type Me = {
  __typename?: 'Me';
  usuario?: Maybe<Usuario>;
  roles?: Maybe<Array<Maybe<Role>>>;
};

export type Motivo = {
  __typename?: 'Motivo';
  id?: Maybe<Scalars['ID']>;
  descripcion?: Maybe<Scalars['String']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  person?: Maybe<Person>;
  createCorredorExterno?: Maybe<Scalars['JSON']>;
  modificarLinea?: Maybe<Scalars['JSON']>;
  inhabilitarCorredor?: Maybe<Scalars['JSON']>;
  modificarEstadoCorredor?: Maybe<Scalars['JSON']>;
  crudTerminales?: Maybe<Scalars['JSON']>;
  login?: Maybe<Session>;
  register?: Maybe<Usuario>;
  loginPin?: Maybe<Session>;
  loginPinV2?: Maybe<Session>;
  agencia?: Maybe<Agencia>;
  addUMN?: Maybe<Umn>;
  createInvite?: Maybe<Invite>;
  deleteCorredor?: Maybe<Scalars['JSON']>;
  umnRegister?: Maybe<Invite>;
  umnInviteConfirm?: Maybe<Usuario>;
  addUser?: Maybe<Usuario>;
  setPasswordToUser?: Maybe<Usuario>;
  setPinToUser?: Maybe<Usuario>;
  addCorredorToUser?: Maybe<Usuario>;
  addCobradorToUser?: Maybe<Usuario>;
  addUmnToUser?: Maybe<Usuario>;
  changeUmnToUser?: Maybe<Usuario>;
  addPermisoToUser?: Maybe<Permiso>;
  abrirCaja?: Maybe<Caja>;
  cerrarCaja?: Maybe<Caja>;
  entrega?: Maybe<Entrega>;
  editarNumeroCorredor?: Maybe<Scalars['JSON']>;
  editarNumeroTelefonoCorredor?: Maybe<Scalars['JSON']>;
  entregaConfirm?: Maybe<Entrega>;
  generarCobro?: Maybe<Entrega>;
  generarAjuste?: Maybe<Ajuste>;
  generarAjusteUmn?: Maybe<AjusteUmn>;
  generarDeposito?: Maybe<Deposito>;
  entregaDeny?: Maybe<Entrega>;
  anularDeposito?: Maybe<Deposito>;
  changeSwitchEntregas?: Maybe<Scalars['JSON']>;
  changeSwitchEntregasCorredor?: Maybe<Scalars['JSON']>;
  deshabilitarCorredor?: Maybe<Scalars['JSON']>;
  changeDataUMN?: Maybe<Umn>;
  solicitudDeposito?: Maybe<Scalars['JSON']>;
  reconsolidar?: Maybe<Scalars['JSON']>;
  quinielaBatch?: Maybe<BatchResponse>;
  quinielaEglobal?: Maybe<Scalars['JSON']>;
  altaUsuarioTerminal?: Maybe<Scalars['JSON']>;
  createNumerosLimitados?: Maybe<NumerosLimitados>;
  solicitudAnulacion?: Maybe<Anulacion>;
  setResultadoSorteo?: Maybe<Sorteo>;
  liquidarSorteo?: Maybe<Sorteo>;
  anulacionElevacion?: Maybe<Anulacion>;
  anulacionDeny?: Maybe<Anulacion>;
  anulacionConfirm?: Maybe<Anulacion>;
  updateEntrega?: Maybe<Scalars['JSON']>;
  updateEstadoTerminal?: Maybe<Scalars['JSON']>;
  updateOneTerminal?: Maybe<Scalars['JSON']>;
  createSorteoEspecial?: Maybe<Scalars['JSON']>;
  updateRastreo?: Maybe<Scalars['JSON']>;
  acceptOrRejectSolicitudDeposito?: Maybe<Scalars['JSON']>;
  recargaPedido?: Maybe<RecargaResponse>;
  billetajeBatch?: Maybe<BatchResponse>;
  pagarPremio?: Maybe<Scalars['JSON']>;
  extorno?: Maybe<Scalars['JSON']>;
  editarCi?: Maybe<Scalars['JSON']>;
  raspaditaAsignacionCorredor?: Maybe<Scalars['JSON']>;
  raspaditaDevolucionDeBoleta?: Maybe<Scalars['JSON']>;
  revertEventoCaja?: Maybe<Scalars['JSON']>;
  recalcularExtractoDesdeCierreCaja?: Maybe<Scalars['JSON']>;
  editarEntregaDeExtracto?: Maybe<Scalars['JSON']>;
  eventoCajas?: Maybe<Scalars['JSON']>;
  createComisionByUmn?: Maybe<Scalars['JSON']>;
  borrarExtractoAnulacion?: Maybe<Scalars['JSON']>;
  solicitudAnulacionPremioPagado?: Maybe<Scalars['JSON']>;
  confirmarAnulacionPremioPagado?: Maybe<Scalars['JSON']>;
  rejectAnulacionPremioPagado?: Maybe<Scalars['JSON']>;
  createSumarioCorredor?: Maybe<Scalars['JSON']>;
  quinielaPartition?: Maybe<Scalars['JSON']>;
  planillaCobrov2?: Maybe<Scalars['JSON']>;
  promedioCorredores?: Maybe<Scalars['JSON']>;
  premiarBoletasByCodes?: Maybe<Scalars['JSON']>;
  verificarPremioMegaloto?: Maybe<Scalars['JSON']>;
  anularTransaccionMegaloto?: Maybe<Scalars['JSON']>;
  generateWUTransactionRequest?: Maybe<Scalars['JSON']>;
  verifyUser?: Maybe<Scalars['JSON']>;
  createUserWuForm?: Maybe<Scalars['JSON']>;
  generateWuTransaccion?: Maybe<Scalars['JSON']>;
  createRegimenWu?: Maybe<Scalars['JSON']>;
  getTicket?: Maybe<Scalars['JSON']>;
  pagarCuotasTeeteAsgard?: Maybe<Scalars['JSON']>;
  getComisionesExcel?: Maybe<Scalars['JSON']>;
  generarFacturaElectronica?: Maybe<Scalars['JSON']>;
};


export type MutationPersonArgs = {
  nombre?: Maybe<Scalars['String']>;
  apellido?: Maybe<Scalars['String']>;
  fechanac?: Maybe<Scalars['Date']>;
  nacionalidad?: Maybe<Scalars['Int']>;
  nrodoc?: Maybe<Scalars['Int']>;
  domicilio?: Maybe<Scalars['String']>;
  telefono?: Maybe<Scalars['String']>;
  fotoCarnet?: Maybe<Scalars['String']>;
  references?: Maybe<Array<Maybe<PersonReferenceInput>>>;
  referenciado?: Maybe<Scalars['Boolean']>;
};


export type MutationCreateCorredorExternoArgs = {
  documento: Scalars['String'];
  nombre: Scalars['String'];
  apellido: Scalars['String'];
  numero_corredor: Scalars['String'];
  celular: Scalars['String'];
  direccion: Scalars['String'];
  umn_id: Scalars['String'];
};


export type MutationModificarLineaArgs = {
  corredor_id: Scalars['Int'];
  monto: Scalars['Int'];
  soloCaja?: Maybe<Scalars['Boolean']>;
};


export type MutationInhabilitarCorredorArgs = {
  corredor_id: Scalars['Int'];
};


export type MutationModificarEstadoCorredorArgs = {
  corredor_id: Scalars['Int'];
};


export type MutationCrudTerminalesArgs = {
  id?: Maybe<Array<Maybe<Scalars['ID']>>>;
  seriales?: Maybe<Array<Maybe<Scalars['String']>>>;
  plataforma?: Maybe<Scalars['String']>;
  estado?: Maybe<Scalars['String']>;
  version?: Maybe<Scalars['String']>;
  canal?: Maybe<Scalars['Int']>;
  serial?: Maybe<Scalars['String']>;
  operacion?: Maybe<Scalars['String']>;
};


export type MutationLoginArgs = {
  username?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
};


export type MutationRegisterArgs = {
  cedula?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
};


export type MutationLoginPinArgs = {
  username?: Maybe<Scalars['String']>;
  pin?: Maybe<Scalars['String']>;
  serialTerminal?: Maybe<Scalars['String']>;
};


export type MutationLoginPinV2Args = {
  username?: Maybe<Scalars['String']>;
  pin?: Maybe<Scalars['String']>;
  serialTerminal?: Maybe<Scalars['String']>;
};


export type MutationAgenciaArgs = {
  numero?: Maybe<Scalars['Int']>;
};


export type MutationAddUmnArgs = {
  nombre?: Maybe<Scalars['String']>;
  numero?: Maybe<Scalars['Int']>;
  agencia_numero?: Maybe<Scalars['Int']>;
};


export type MutationCreateInviteArgs = {
  metadata?: Maybe<Scalars['JSON']>;
};


export type MutationDeleteCorredorArgs = {
  usuario_id: Scalars['Int'];
};


export type MutationUmnRegisterArgs = {
  nombre?: Maybe<Scalars['String']>;
  apellido?: Maybe<Scalars['String']>;
  celular?: Maybe<Scalars['String']>;
  nrodoc?: Maybe<Scalars['Int']>;
  code?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
};


export type MutationUmnInviteConfirmArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type MutationAddUserArgs = {
  nrodoc: Scalars['String'];
  password: Scalars['String'];
  pin: Scalars['String'];
  domicilio: Scalars['String'];
  telefono: Scalars['String'];
  umn_id: Scalars['Int'];
};


export type MutationSetPasswordToUserArgs = {
  usuario_id: Scalars['ID'];
  value: Scalars['String'];
};


export type MutationSetPinToUserArgs = {
  usuario_id: Scalars['ID'];
  value: Scalars['String'];
};


export type MutationAddCorredorToUserArgs = {
  usuario_id: Scalars['ID'];
  umn_id: Scalars['Int'];
  corredor_numero: Scalars['Int'];
  linea: Scalars['Int'];
};


export type MutationAddCobradorToUserArgs = {
  usuario_id: Scalars['ID'];
  umn_id: Scalars['ID'];
  cobrador_numero: Scalars['Int'];
  linea: Scalars['Int'];
};


export type MutationAddUmnToUserArgs = {
  usuario_id: Scalars['ID'];
  umn_id: Scalars['Int'];
};


export type MutationChangeUmnToUserArgs = {
  usuario_id: Scalars['ID'];
  new_umn_id?: Maybe<Array<Scalars['ID']>>;
};


export type MutationAddPermisoToUserArgs = {
  usuario_id: Scalars['ID'];
  role_id: Scalars['Int'];
  rights?: Maybe<Array<Maybe<Scalars['Int']>>>;
};


export type MutationAbrirCajaArgs = {
  corredor_id: Scalars['ID'];
  terminal_id: Scalars['Int'];
  referencia: Scalars['String'];
};


export type MutationCerrarCajaArgs = {
  corredor_id?: Maybe<Scalars['ID']>;
  caja_id: Scalars['Int'];
  terminal_id: Scalars['Int'];
  referencia: Scalars['String'];
};


export type MutationEntregaArgs = {
  corredor_id: Scalars['ID'];
  referencia: Scalars['String'];
  monto: Scalars['Int'];
};


export type MutationEditarNumeroCorredorArgs = {
  corredor_id?: Maybe<Scalars['Int']>;
  numero?: Maybe<Scalars['Int']>;
};


export type MutationEditarNumeroTelefonoCorredorArgs = {
  corredor_id?: Maybe<Scalars['Int']>;
  numero: Scalars['String'];
};


export type MutationEntregaConfirmArgs = {
  entrega_id: Scalars['ID'];
  referencia: Scalars['String'];
  cobrador_id?: Maybe<Scalars['Int']>;
};


export type MutationGenerarCobroArgs = {
  referencia: Scalars['String'];
  cobrador_id: Scalars['Int'];
  corredor_id: Scalars['Int'];
  monto: Scalars['Int'];
};


export type MutationGenerarAjusteArgs = {
  referencia: Scalars['String'];
  corredor_id: Scalars['Int'];
  monto: Scalars['Int'];
  detalle: Scalars['String'];
};


export type MutationGenerarAjusteUmnArgs = {
  referencia: Scalars['String'];
  umn_id: Scalars['Int'];
  monto: Scalars['Int'];
  detalle: Scalars['String'];
  tipo: Scalars['String'];
};


export type MutationGenerarDepositoArgs = {
  referencia: Scalars['String'];
  umn_id: Scalars['ID'];
  detalle?: Maybe<Scalars['String']>;
  medio?: Maybe<Scalars['String']>;
  nro_recibo?: Maybe<Scalars['Int']>;
  monto?: Maybe<Scalars['Int']>;
  fecha?: Maybe<Scalars['Date']>;
};


export type MutationEntregaDenyArgs = {
  entrega_id: Scalars['ID'];
};


export type MutationAnularDepositoArgs = {
  id_deposito: Scalars['ID'];
};


export type MutationChangeSwitchEntregasArgs = {
  umn_id?: Maybe<Array<Scalars['ID']>>;
};


export type MutationChangeSwitchEntregasCorredorArgs = {
  corredor_id: Scalars['ID'];
};


export type MutationDeshabilitarCorredorArgs = {
  corredor_id: Scalars['ID'];
};


export type MutationChangeDataUmnArgs = {
  umn_id?: Maybe<Array<Scalars['ID']>>;
  nombre?: Maybe<Scalars['String']>;
  numero?: Maybe<Scalars['Int']>;
};


export type MutationSolicitudDepositoArgs = {
  comprobante?: Maybe<Scalars['String']>;
  banco?: Maybe<Scalars['String']>;
  monto?: Maybe<Scalars['Int']>;
  umn_id?: Maybe<Scalars['ID']>;
};


export type MutationReconsolidarArgs = {
  desde?: Maybe<Scalars['Date']>;
  hasta?: Maybe<Scalars['Date']>;
  umn_id?: Maybe<Array<Maybe<Scalars['ID']>>>;
};


export type MutationQuinielaBatchArgs = {
  payload?: Maybe<Scalars['String']>;
};


export type MutationQuinielaEglobalArgs = {
  json?: Maybe<Scalars['JSON']>;
};


export type MutationAltaUsuarioTerminalArgs = {
  dispositivo_id: Scalars['Int'];
  umn_id: Scalars['Int'];
  sucursal?: Maybe<Scalars['String']>;
};


export type MutationCreateNumerosLimitadosArgs = {
  numerosLimitados: NumerosLimitadosInput;
};


export type MutationSolicitudAnulacionArgs = {
  juego_id?: Maybe<Scalars['ID']>;
  motivo_id?: Maybe<Scalars['Int']>;
};


export type MutationSetResultadoSorteoArgs = {
  sorteo_id: Scalars['ID'];
  resultados: Array<Maybe<Scalars['String']>>;
};


export type MutationLiquidarSorteoArgs = {
  sorteo_id: Scalars['ID'];
};


export type MutationAnulacionElevacionArgs = {
  anulacion_id: Scalars['ID'];
  elevacion: Scalars['Int'];
};


export type MutationAnulacionDenyArgs = {
  anulacion_id: Scalars['ID'];
};


export type MutationAnulacionConfirmArgs = {
  anulacion_id: Scalars['ID'];
};


export type MutationUpdateEntregaArgs = {
  entrega_id: Scalars['ID'];
  monto: Scalars['Int'];
};


export type MutationUpdateEstadoTerminalArgs = {
  estado_id: Scalars['String'];
  terminal_id: Scalars['String'];
  estado_anterior: Scalars['String'];
};


export type MutationUpdateOneTerminalArgs = {
  estado_id: Scalars['String'];
  terminal_id: Scalars['String'];
  estado_anterior: Scalars['String'];
};


export type MutationCreateSorteoEspecialArgs = {
  resultados?: Maybe<Array<Maybe<Scalars['String']>>>;
  sorteo_base_id: Scalars['ID'];
  fecha: Scalars['Date'];
};


export type MutationUpdateRastreoArgs = {
  serial: Scalars['String'];
  estado_id: Scalars['Int'];
  umn_id?: Maybe<Array<Maybe<Scalars['Int']>>>;
};


export type MutationAcceptOrRejectSolicitudDepositoArgs = {
  solicitud_id: Scalars['ID'];
  type?: Maybe<Scalars['String']>;
};


export type MutationRecargaPedidoArgs = {
  caja_id: Scalars['Int'];
  referencia: Scalars['String'];
  producto_id: Scalars['Int'];
  metadata: Scalars['JSON'];
};


export type MutationBilletajeBatchArgs = {
  payload?: Maybe<Scalars['String']>;
};


export type MutationPagarPremioArgs = {
  serie?: Maybe<Scalars['String']>;
  ticket?: Maybe<Scalars['Float']>;
  fecha?: Maybe<Scalars['Date']>;
  umn_id?: Maybe<Scalars['ID']>;
  monto?: Maybe<Scalars['Int']>;
  id_pago?: Maybe<Scalars['Float']>;
};


export type MutationExtornoArgs = {
  id_pago: Scalars['Float'];
  umn_id?: Maybe<Scalars['Int']>;
};


export type MutationEditarCiArgs = {
  ci?: Maybe<Scalars['String']>;
  usuario_id?: Maybe<Scalars['Int']>;
};


export type MutationRaspaditaAsignacionCorredorArgs = {
  corredor_id: Scalars['ID'];
  umn_id: Scalars['ID'];
  boletas?: Maybe<Array<Maybe<BoletaInput>>>;
};


export type MutationRaspaditaDevolucionDeBoletaArgs = {
  corredor_id: Scalars['ID'];
  umn_id: Scalars['ID'];
  boletas?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type MutationRevertEventoCajaArgs = {
  terminalId: Scalars['ID'];
};


export type MutationRecalcularExtractoDesdeCierreCajaArgs = {
  extracto_id: Scalars['ID'];
};


export type MutationEditarEntregaDeExtractoArgs = {
  extracto_id: Scalars['ID'];
  monto: Scalars['Int'];
};


export type MutationEventoCajasArgs = {
  images?: Maybe<Array<Maybe<Scalars['FileUpload']>>>;
  motivo: Scalars['String'];
  terminalId: Scalars['ID'];
  cajaId: Scalars['ID'];
  corredorId: Scalars['ID'];
  estadoPos: Scalars['Int'];
};


export type MutationCreateComisionByUmnArgs = {
  producto: Scalars['ID'];
  umn_id?: Maybe<Scalars['ID']>;
  comision: Scalars['Int'];
};


export type MutationBorrarExtractoAnulacionArgs = {
  extracto_id: Scalars['ID'];
};


export type MutationSolicitudAnulacionPremioPagadoArgs = {
  premio_pagado_id?: Maybe<Scalars['ID']>;
};


export type MutationConfirmarAnulacionPremioPagadoArgs = {
  premio_pagado_id?: Maybe<Scalars['ID']>;
};


export type MutationRejectAnulacionPremioPagadoArgs = {
  premio_pagado_id?: Maybe<Scalars['ID']>;
};


export type MutationCreateSumarioCorredorArgs = {
  desde: Scalars['Date'];
  hasta?: Maybe<Scalars['Date']>;
};


export type MutationQuinielaPartitionArgs = {
  jugadas?: Maybe<Array<Maybe<Scalars['JSON']>>>;
};


export type MutationPlanillaCobrov2Args = {
  umn_id: Scalars['Int'];
  desde: Scalars['Date'];
  hasta: Scalars['Date'];
  corredor_id?: Maybe<Scalars['Int']>;
};


export type MutationPromedioCorredoresArgs = {
  agenciaNumero: Scalars['Int'];
};


export type MutationPremiarBoletasByCodesArgs = {
  umn_id: Scalars['ID'];
  code: Array<Maybe<Scalars['String']>>;
};


export type MutationVerificarPremioMegalotoArgs = {
  pedidoId: Scalars['ID'];
  umnId: Scalars['ID'];
};


export type MutationAnularTransaccionMegalotoArgs = {
  id: Scalars['ID'];
  motivoId?: Maybe<Scalars['String']>;
};


export type MutationGenerateWuTransactionRequestArgs = {
  mtcn: Scalars['String'];
  reference: Scalars['String'];
};


export type MutationVerifyUserArgs = {
  doc_type_enum: Scalars['String'];
  ci: Scalars['String'];
  country_code_phone: Scalars['String'];
  phone_number: Scalars['String'];
  pago_agil?: Maybe<Scalars['Boolean']>;
  reference: Scalars['String'];
};


export type MutationCreateUserWuFormArgs = {
  doc_type: Scalars['String'];
  ci: Scalars['String'];
  phone_number: Scalars['String'];
  country_code_phone: Scalars['String'];
  names: Scalars['String'];
  last_names: Scalars['String'];
  middle_name: Scalars['String'];
  birth_date: Scalars['String'];
  residence_country: Scalars['String'];
  departamento: Scalars['String'];
  city: Scalars['String'];
  adress: Scalars['String'];
  doc_emision: Scalars['String'];
  doc_vencimiento: Scalars['String'];
  front_doc_img: Scalars['String'];
  back_doc_img: Scalars['String'];
  selfie_img: Scalars['String'];
  country_birth: Scalars['String'];
  paisDoc: Scalars['String'];
  source_funds: Scalars['String'];
  position_level: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  occupation: Scalars['String'];
  transaction_reason: Scalars['String'];
  pep?: Maybe<Scalars['String']>;
  cpep?: Maybe<Scalars['String']>;
  pagoAgil?: Maybe<Scalars['Boolean']>;
  reference: Scalars['String'];
};


export type MutationGenerateWuTransaccionArgs = {
  client_id: Scalars['String'];
  reference: Scalars['String'];
  motivo_transaccion: Scalars['String'];
};


export type MutationCreateRegimenWuArgs = {
  input: CreateRegimerWuInput;
};


export type MutationGetTicketArgs = {
  reference: Scalars['String'];
};


export type MutationPagarCuotasTeeteAsgardArgs = {
  banco?: Maybe<Scalars['String']>;
  comprobante?: Maybe<Scalars['String']>;
  monto_total?: Maybe<Scalars['Int']>;
  cuotas?: Maybe<Array<Maybe<Scalars['JSON']>>>;
};


export type MutationGetComisionesExcelArgs = {
  fecha__gte?: Maybe<Scalars['String']>;
  fecha__lte?: Maybe<Scalars['String']>;
  agencia_numero?: Maybe<Scalars['String']>;
};


export type MutationGenerarFacturaElectronicaArgs = {
  producto_id: Scalars['Int'];
  desde: Scalars['Date'];
  hasta?: Maybe<Scalars['Date']>;
};

export type NumerosLimitados = {
  __typename?: 'NumerosLimitados';
  id: Scalars['ID'];
  fecha: Scalars['Date'];
  agencia: Scalars['Int'];
  numeros: Array<Maybe<Scalars['String']>>;
};

export type NumerosLimitadosInput = {
  fecha: Scalars['Date'];
  agencia: Scalars['Int'];
  numeros: Array<Maybe<Scalars['String']>>;
};

export type Pedido = {
  __typename?: 'Pedido';
  id?: Maybe<Scalars['ID']>;
  created_at?: Maybe<Scalars['Date']>;
  Producto?: Maybe<Producto>;
  Caja?: Maybe<Caja>;
  Transaccion?: Maybe<Transaccion>;
  monto?: Maybe<Scalars['Int']>;
  estado?: Maybe<Scalars['Int']>;
  resultado?: Maybe<Scalars['String']>;
  metadata?: Maybe<Scalars['JSON']>;
};

export type Perfil = {
  __typename?: 'Perfil';
  username?: Maybe<Scalars['String']>;
  Person?: Maybe<Persona>;
  Umns?: Maybe<Array<Maybe<Umn>>>;
  Corredores?: Maybe<Array<Maybe<Corredor>>>;
};

export type Permiso = {
  __typename?: 'Permiso';
  id: Scalars['ID'];
  usuario?: Maybe<Usuario>;
  Role?: Maybe<Role>;
  Rights?: Maybe<Array<Maybe<Right>>>;
};

export type Person = {
  __typename?: 'Person';
  id?: Maybe<Scalars['ID']>;
  nombre?: Maybe<Scalars['String']>;
  apellido?: Maybe<Scalars['String']>;
  fechanac?: Maybe<Scalars['Date']>;
  nacionalidad?: Maybe<Scalars['Int']>;
  nrodoc?: Maybe<Scalars['String']>;
  domicilio?: Maybe<Scalars['String']>;
  telefono?: Maybe<Scalars['String']>;
  fotoCarnet?: Maybe<Scalars['String']>;
  references?: Maybe<Array<Maybe<PersonReference>>>;
  referenciado?: Maybe<Scalars['Boolean']>;
  created_at?: Maybe<Scalars['Date']>;
  updated_at?: Maybe<Scalars['Date']>;
};

export type PersonReference = {
  __typename?: 'PersonReference';
  nombre?: Maybe<Scalars['String']>;
  apellido?: Maybe<Scalars['String']>;
  telefono?: Maybe<Scalars['String']>;
};

export type PersonReferenceInput = {
  nombre?: Maybe<Scalars['String']>;
  apellido?: Maybe<Scalars['String']>;
  telefono?: Maybe<Scalars['String']>;
};

export type Persona = {
  __typename?: 'Persona';
  id: Scalars['ID'];
  nombre?: Maybe<Scalars['String']>;
  apellido?: Maybe<Scalars['String']>;
  nrodoc?: Maybe<Scalars['String']>;
  celular?: Maybe<Scalars['String']>;
  direccion?: Maybe<Scalars['String']>;
  departamento?: Maybe<Scalars['String']>;
  distrito?: Maybe<Scalars['String']>;
};

export type PnPersona = {
  __typename?: 'PnPersona';
  nombre?: Maybe<Scalars['String']>;
  apellido?: Maybe<Scalars['String']>;
  nrodoc?: Maybe<Scalars['String']>;
  fechanac?: Maybe<Scalars['Date']>;
  estado_civil?: Maybe<Scalars['String']>;
  nacionalidad?: Maybe<Scalars['Int']>;
};

export type PreSigned = {
  __typename?: 'PreSigned';
  name?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type Producto = {
  __typename?: 'Producto';
  id?: Maybe<Scalars['ID']>;
  nombre?: Maybe<Scalars['String']>;
  identificador?: Maybe<Scalars['String']>;
};

export type Query = {
  __typename?: 'Query';
  raspaditaDetallesCorredorPorAgencia?: Maybe<Scalars['JSON']>;
  getCubridas?: Maybe<Scalars['JSON']>;
  getcubridasDetalles?: Maybe<Scalars['JSON']>;
  getTotalCubridas?: Maybe<Scalars['JSON']>;
  getCubridasFirstLevel?: Maybe<Scalars['JSON']>;
  getQuinielaSorteoRecaudacion?: Maybe<Scalars['JSON']>;
  getAnalisisDeConsistencia?: Maybe<Scalars['JSON']>;
  getClasificacionDeApuestas?: Maybe<Scalars['JSON']>;
  getRechargeAndWithdrawal?: Maybe<Scalars['JSON']>;
  dataQwebForChart?: Maybe<Scalars['JSON']>;
  getTransactionsWebBySorteo?: Maybe<Scalars['JSON']>;
  getWebUserTicketsBySorteo?: Maybe<Scalars['JSON']>;
  getWebTicketsByUser?: Maybe<Scalars['JSON']>;
  getWebUserInformation?: Maybe<Scalars['JSON']>;
  getWebUserInformationUser?: Maybe<Scalars['JSON']>;
  getWebUserSaldoApuesta?: Maybe<Scalars['JSON']>;
  getWebUserSaldoGanado?: Maybe<Scalars['JSON']>;
  getWebTotalApuestas?: Maybe<Scalars['JSON']>;
  getWebTransactionsSaldos?: Maybe<Scalars['JSON']>;
  getWebUserSaldoRetirado?: Maybe<Scalars['JSON']>;
  getWebTransaccionRecargaSaldo?: Maybe<Scalars['JSON']>;
  getWebTransaccionRecargaSaldoByUser?: Maybe<Scalars['JSON']>;
  getWebTransaccionTransferenciaSaldo?: Maybe<Scalars['JSON']>;
  getWebTransaccionTransferenciaSaldoByUser?: Maybe<Scalars['JSON']>;
  getWebUserSaldoGanadoGroupUser?: Maybe<Scalars['JSON']>;
  getWebUserSaldoGanadoGroupSorteo?: Maybe<Scalars['JSON']>;
  me?: Maybe<Me>;
  perfil?: Maybe<Perfil>;
  viewer?: Maybe<Usuario>;
  procesarSorteoBoletas?: Maybe<Scalars['JSON']>;
  usuarios?: Maybe<Array<Maybe<Usuario>>>;
  getSorteoDelDia?: Maybe<Scalars['JSON']>;
  evaluarBoleta?: Maybe<Scalars['JSON']>;
  activaciones?: Maybe<Array<Maybe<Usuario>>>;
  activacionesLen?: Maybe<Scalars['Int']>;
  activacionesPorSerial?: Maybe<Array<Maybe<Activacion>>>;
  sumsTodayByHour?: Maybe<Array<Maybe<SumatoriaHora>>>;
  activacionesRecientes?: Maybe<Array<Maybe<Usuario>>>;
  ventasPorUsuario?: Maybe<Array<Maybe<VentasCorredor>>>;
  corredorTransaccionesAgrupadasProducto?: Maybe<Array<Maybe<TransaccionesProductoAgrupado>>>;
  countries?: Maybe<Array<Maybe<Country>>>;
  getDataPos?: Maybe<Scalars['JSON']>;
  presigned?: Maybe<PreSigned>;
  pn_persona?: Maybe<PnPersona>;
  admdepo?: Maybe<Scalars['JSON']>;
  crudCorredor?: Maybe<Scalars['JSON']>;
  persons?: Maybe<Array<Maybe<Person>>>;
  agencias?: Maybe<Array<Maybe<Agencia>>>;
  umns?: Maybe<Array<Maybe<Umn>>>;
  allUmns?: Maybe<Array<Maybe<Umn>>>;
  allAgencies?: Maybe<Array<Maybe<Agencias>>>;
  invites?: Maybe<Array<Maybe<Invite>>>;
  umnInvite?: Maybe<Invite>;
  umn?: Maybe<Umn>;
  eventoCajasById?: Maybe<Scalars['JSON']>;
  entregas?: Maybe<Array<Maybe<Entrega>>>;
  switchEntregas?: Maybe<Scalars['JSON']>;
  anulaciones?: Maybe<Array<Maybe<Anulacion>>>;
  corredoresConDeuda?: Maybe<Array<Maybe<Corredor>>>;
  depositos?: Maybe<Array<Maybe<Deposito>>>;
  depositosAnulados?: Maybe<Array<Maybe<Deposito>>>;
  getTransaccionByCorredorTerminal?: Maybe<Scalars['JSON']>;
  verUmnFinanzas?: Maybe<Scalars['JSON']>;
  verDepositosUmnFinanzas?: Maybe<Scalars['JSON']>;
  verAjustesUmnFinanzas?: Maybe<Scalars['JSON']>;
  verConsolidaciones?: Maybe<Array<Maybe<Consolidacion>>>;
  verConsolidacionesAgencia?: Maybe<Scalars['JSON']>;
  verConsolidacionesAgenciaGerencia?: Maybe<Scalars['JSON']>;
  verConsolidacionesGerencia?: Maybe<Scalars['JSON']>;
  consolidar?: Maybe<Scalars['JSON']>;
  estadoUmn?: Maybe<EstadoUmn>;
  verAjustes?: Maybe<Array<Maybe<Ajuste>>>;
  verAjustesUmn?: Maybe<Array<Maybe<AjusteUmn>>>;
  cajasAbiertas?: Maybe<Array<Maybe<ResumenCajaCorredor>>>;
  getUser?: Maybe<Usuario>;
  cajasAbiertasAgencia?: Maybe<Scalars['JSON']>;
  cajasSegunEstado?: Maybe<Scalars['JSON']>;
  lastTicketQuiniela?: Maybe<Array<Maybe<TicketQuiniela>>>;
  ticketQuiniela?: Maybe<TicketQuiniela>;
  jugadaQuiniela?: Maybe<Scalars['JSON']>;
  perfilCuenta?: Maybe<Cuenta>;
  actualCaja?: Maybe<Caja>;
  caja?: Maybe<Caja>;
  cajaTotal?: Maybe<Scalars['Int']>;
  resumenCaja?: Maybe<ResumenCaja>;
  resumenUmn?: Maybe<ResumenUmn>;
  resumenUmnProducto?: Maybe<Scalars['JSON']>;
  resumenUmnProductoGestionar?: Maybe<Scalars['JSON']>;
  resumenUmnProductoGestionarGerencia?: Maybe<Scalars['JSON']>;
  resumenTicketsRaspadita?: Maybe<Scalars['JSON']>;
  detalleCajaProducto?: Maybe<DetalleCaja>;
  pedidosFechaCorredor?: Maybe<Array<Maybe<Pedido>>>;
  producto?: Maybe<Producto>;
  productos?: Maybe<Array<Maybe<Producto>>>;
  getCajas?: Maybe<Array<Maybe<Caja>>>;
  sherlockMode?: Maybe<Scalars['JSON']>;
  boletasPorMontos?: Maybe<Scalars['JSON']>;
  historialDeSorteos?: Maybe<Scalars['JSON']>;
  ocurrencias?: Maybe<Scalars['JSON']>;
  ocurrenciasDosNumeros?: Maybe<Scalars['JSON']>;
  tombolaNumeros?: Maybe<Scalars['JSON']>;
  bolillero?: Maybe<Scalars['JSON']>;
  resumenUmnGerenciaProducto?: Maybe<Scalars['JSON']>;
  resumenUmnGerenciaProductoUmn?: Maybe<Scalars['JSON']>;
  resumenUmnGerenciaUmn?: Maybe<Scalars['JSON']>;
  resumenAgenciaUmnProductos?: Maybe<Scalars['JSON']>;
  corredor?: Maybe<Corredor>;
  corredorCajas?: Maybe<Array<Maybe<Caja>>>;
  corredorFechas?: Maybe<Array<Maybe<CajaFecha>>>;
  corredorFechasRetrasadas?: Maybe<Array<Maybe<CajaFecha>>>;
  corredorTransaccionesFecha?: Maybe<Array<Maybe<Transaccion>>>;
  corredorPedidosFecha?: Maybe<Array<Maybe<Pedido>>>;
  corredorProductosPedidos?: Maybe<Array<Maybe<Pedido>>>;
  corredorExtracto?: Maybe<Array<Maybe<Extracto>>>;
  corredorExtracto2?: Maybe<Array<Maybe<Extracto>>>;
  corredorCobros?: Maybe<Scalars['JSON']>;
  corredorProductos?: Maybe<Scalars['JSON']>;
  qWebCantidades?: Maybe<Scalars['JSON']>;
  resumenUmnAgencia?: Maybe<Scalars['JSON']>;
  cobrador?: Maybe<Cobrador>;
  cobradorFechas?: Maybe<Array<Maybe<CajaFecha>>>;
  cobradorExtracto?: Maybe<Array<Maybe<Extracto>>>;
  umnExtracto?: Maybe<Array<Maybe<ExtractoUmn>>>;
  cobradorFecha?: Maybe<Array<Maybe<Entrega>>>;
  terminalesUmn?: Maybe<Scalars['JSON']>;
  terminalesUmnAll?: Maybe<Scalars['JSON']>;
  terminal?: Maybe<Terminal>;
  terminales?: Maybe<Array<Maybe<Terminal>>>;
  heartbeats?: Maybe<Array<Maybe<Heartbeat>>>;
  usoTerminal?: Maybe<Array<Maybe<Uso>>>;
  roles?: Maybe<Array<Maybe<Role>>>;
  gerenciaResumenQuinielaAgencia?: Maybe<Scalars['JSON']>;
  premiacionAgenciaGerencia?: Maybe<Scalars['JSON']>;
  sumarioResumenQuinielaProductoParaRaspa?: Maybe<Scalars['JSON']>;
  sumarioResumenQuinielaProductoGerencia?: Maybe<Scalars['JSON']>;
  gerenciaResumenQuinielaAgenciaProducto?: Maybe<Scalars['JSON']>;
  transaccionesRetirosByCorredor?: Maybe<Scalars['JSON']>;
  getTransaccionByCorredor?: Maybe<Scalars['JSON']>;
  gerenciaResumenQuinielaUmn?: Maybe<Scalars['JSON']>;
  gerenciaResumenQuinielaSorteo?: Maybe<Scalars['JSON']>;
  gerenciaDetalleQuiniela?: Maybe<Scalars['JSON']>;
  gerenciaResumenQuinielaFecha?: Maybe<Scalars['JSON']>;
  ventasTotalesAgencia?: Maybe<Scalars['JSON']>;
  quinielaFechaPorCorredor?: Maybe<Scalars['JSON']>;
  detallesPremiacionTipo?: Maybe<Scalars['JSON']>;
  detallesPremiosComposicion?: Maybe<Scalars['JSON']>;
  stockTerminales?: Maybe<Scalars['JSON']>;
  stockTerminales2?: Maybe<Scalars['JSON']>;
  stockTerminal3?: Maybe<Scalars['JSON']>;
  resumenTerminales2?: Maybe<Scalars['JSON']>;
  historialTerminal2?: Maybe<Scalars['JSON']>;
  reporteMediosElectronicos?: Maybe<Scalars['JSON']>;
  listaDeEstadosTerminales?: Maybe<Scalars['JSON']>;
  terminalHistorico?: Maybe<Scalars['JSON']>;
  corredorHistorico?: Maybe<Scalars['JSON']>;
  datosAnalisisFrecuencia?: Maybe<Scalars['JSON']>;
  getOcurrenciasAndChart?: Maybe<Scalars['JSON']>;
  getConsolidacion?: Maybe<Scalars['JSON']>;
  getConsolidacionDetalle?: Maybe<Scalars['JSON']>;
  sorteosPrograma?: Maybe<Array<Maybe<SorteoPrograma>>>;
  sorteos?: Maybe<Array<Maybe<Sorteo>>>;
  numerosLimitados?: Maybe<Array<Maybe<NumerosLimitados>>>;
  corredoresExternos?: Maybe<Scalars['JSON']>;
  ticketsSorteo?: Maybe<Array<Maybe<TicketQuiniela>>>;
  ticketResult?: Maybe<Array<Maybe<TicketQuiniela>>>;
  ticketPremiados?: Maybe<Array<Maybe<TicketQuiniela>>>;
  premiadosAgrupados?: Maybe<Scalars['JSON']>;
  sorteoResults?: Maybe<Sorteo>;
  entregasCorredor?: Maybe<Array<Maybe<Entrega>>>;
  historialTerminal?: Maybe<Scalars['JSON']>;
  getSolicitudesDepositoPorAgencia?: Maybe<Scalars['JSON']>;
  premiadosEglobal?: Maybe<Scalars['JSON']>;
  gerenciaResumenQuinielaUmns?: Maybe<Scalars['JSON']>;
  findEntrega?: Maybe<Scalars['JSON']>;
  getZonasByAgencia?: Maybe<Scalars['JSON']>;
  proyeccionPremiacion?: Maybe<Scalars['JSON']>;
  transaccionesAgrupadasCorredor?: Maybe<Scalars['JSON']>;
  ticketCorredor?: Maybe<Scalars['JSON']>;
  currentSorteos?: Maybe<Array<Maybe<Sorteo>>>;
  limitedNumbers?: Maybe<Scalars['JSON']>;
  billetajeResult?: Maybe<Scalars['JSON']>;
  reportePagoPremios?: Maybe<Scalars['JSON']>;
  reporteFacturacionAgencia?: Maybe<Scalars['JSON']>;
  reporteFacturacionDiaria?: Maybe<Scalars['JSON']>;
  reporteDeSistema?: Maybe<Scalars['JSON']>;
  reporteVentasPorAgencia?: Maybe<Scalars['JSON']>;
  reporteGanadoresPorAgencia?: Maybe<Scalars['JSON']>;
  reporteVentasRaspadita?: Maybe<Scalars['JSON']>;
  reporteVentasPorTerminal?: Maybe<Scalars['JSON']>;
  uptimeServer?: Maybe<Scalars['JSON']>;
  conajzar?: Maybe<Scalars['Boolean']>;
  reporteAnulaciones?: Maybe<Scalars['JSON']>;
  reporteHearbeat?: Maybe<Scalars['JSON']>;
  reporteVentasElectronicas?: Maybe<Scalars['JSON']>;
  recalculoDiferencias?: Maybe<Scalars['JSON']>;
  loginFailed?: Maybe<Scalars['JSON']>;
  accesoRemoto?: Maybe<Scalars['JSON']>;
  anulacionesByDay?: Maybe<Scalars['JSON']>;
  allPrizes?: Maybe<Scalars['JSON']>;
  resumenTerminales?: Maybe<Scalars['JSON']>;
  listAgenciasForFilter?: Maybe<Scalars['JSON']>;
  listAnulacionPremioPagado?: Maybe<Scalars['JSON']>;
  gerenciaAnalisisQuinielaBySorteo?: Maybe<Scalars['JSON']>;
  gerenciaAnalisisQuinielaByAgencia?: Maybe<Scalars['JSON']>;
  gerenciaAnalisisQuinielaByCorredor?: Maybe<Scalars['JSON']>;
  listAnulacionesMegaloto?: Maybe<Scalars['JSON']>;
  listTicketsMegaloto?: Maybe<Scalars['JSON']>;
  getCuotasTeeteAsgard?: Maybe<Scalars['JSON']>;
  getResumenFacturacionTelefonia?: Maybe<Scalars['JSON']>;
  getDetallesFacturaTelefonia?: Maybe<Scalars['JSON']>;
};


export type QueryRaspaditaDetallesCorredorPorAgenciaArgs = {
  desde?: Maybe<Scalars['Date']>;
  hasta?: Maybe<Scalars['Date']>;
  agencia?: Maybe<Scalars['Int']>;
};


export type QueryGetCubridasArgs = {
  desde?: Maybe<Scalars['Date']>;
  hasta?: Maybe<Scalars['Date']>;
  agencia?: Maybe<Scalars['Int']>;
  corredor?: Maybe<Scalars['Int']>;
};


export type QueryGetcubridasDetallesArgs = {
  corredor_id?: Maybe<Scalars['String']>;
  minuto?: Maybe<Scalars['Date']>;
  numero?: Maybe<Scalars['String']>;
};


export type QueryGetTotalCubridasArgs = {
  desde?: Maybe<Scalars['Date']>;
  hasta?: Maybe<Scalars['Date']>;
  agencia?: Maybe<Scalars['Int']>;
  corredor?: Maybe<Scalars['Int']>;
};


export type QueryGetCubridasFirstLevelArgs = {
  desde?: Maybe<Scalars['Date']>;
  hasta?: Maybe<Scalars['Date']>;
  agencia?: Maybe<Scalars['Int']>;
};


export type QueryGetQuinielaSorteoRecaudacionArgs = {
  desde?: Maybe<Scalars['Date']>;
  hasta?: Maybe<Scalars['Date']>;
  agencias?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sorteos?: Maybe<Array<Maybe<Scalars['Int']>>>;
};


export type QueryGetAnalisisDeConsistenciaArgs = {
  nroDoc?: Maybe<Scalars['String']>;
};


export type QueryGetClasificacionDeApuestasArgs = {
  nroDoc?: Maybe<Scalars['String']>;
};


export type QueryGetRechargeAndWithdrawalArgs = {
  nroDoc?: Maybe<Scalars['String']>;
};


export type QueryDataQwebForChartArgs = {
  nroDoc?: Maybe<Scalars['String']>;
};


export type QueryGetTransactionsWebBySorteoArgs = {
  desde?: Maybe<Scalars['Date']>;
  hasta?: Maybe<Scalars['Date']>;
  sorteo?: Maybe<Array<Maybe<Scalars['Int']>>>;
};


export type QueryGetWebUserTicketsBySorteoArgs = {
  desde?: Maybe<Scalars['Date']>;
  hasta?: Maybe<Scalars['Date']>;
  sorteo?: Maybe<Scalars['Int']>;
};


export type QueryGetWebTicketsByUserArgs = {
  desde?: Maybe<Scalars['Date']>;
  hasta?: Maybe<Scalars['Date']>;
  sorteo?: Maybe<Scalars['Int']>;
  corredor_id?: Maybe<Scalars['Int']>;
};


export type QueryGetWebUserInformationArgs = {
  corredor_id?: Maybe<Scalars['Int']>;
};


export type QueryGetWebUserInformationUserArgs = {
  usuario_id?: Maybe<Scalars['Int']>;
};


export type QueryGetWebTransaccionRecargaSaldoArgs = {
  desde?: Maybe<Scalars['Date']>;
  hasta?: Maybe<Scalars['Date']>;
};


export type QueryGetWebTransaccionRecargaSaldoByUserArgs = {
  desde?: Maybe<Scalars['Date']>;
  hasta?: Maybe<Scalars['Date']>;
  usuario_id?: Maybe<Scalars['Int']>;
};


export type QueryGetWebTransaccionTransferenciaSaldoArgs = {
  desde?: Maybe<Scalars['Date']>;
  hasta?: Maybe<Scalars['Date']>;
};


export type QueryGetWebTransaccionTransferenciaSaldoByUserArgs = {
  desde?: Maybe<Scalars['Date']>;
  hasta?: Maybe<Scalars['Date']>;
  usuario_id?: Maybe<Scalars['Int']>;
};


export type QueryGetWebUserSaldoGanadoGroupSorteoArgs = {
  corredor_id?: Maybe<Scalars['Int']>;
};


export type QueryProcesarSorteoBoletasArgs = {
  sorteo_id?: Maybe<Array<Maybe<Scalars['ID']>>>;
};


export type QueryUsuariosArgs = {
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  nrodoc?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
  umn_id?: Maybe<Array<Maybe<Scalars['ID']>>>;
  nro_corredor?: Maybe<Scalars['Int']>;
};


export type QueryGetSorteoDelDiaArgs = {
  desde?: Maybe<Scalars['Date']>;
  hasta?: Maybe<Scalars['Date']>;
  sorteo_base_id?: Maybe<Scalars['ID']>;
};


export type QueryEvaluarBoletaArgs = {
  desde?: Maybe<Scalars['Date']>;
  hasta?: Maybe<Scalars['Date']>;
  umn_id?: Maybe<Array<Maybe<Scalars['ID']>>>;
  numeros?: Maybe<Array<Maybe<Scalars['ID']>>>;
  postura?: Maybe<Array<Maybe<Scalars['ID']>>>;
  serie?: Maybe<Array<Maybe<Scalars['String']>>>;
  jugada_tipo?: Maybe<Array<Maybe<Scalars['ID']>>>;
  sorteo_base_id?: Maybe<Array<Maybe<Scalars['ID']>>>;
  corredor_numero?: Maybe<Array<Maybe<Scalars['ID']>>>;
  liquidado?: Maybe<Scalars['Boolean']>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type QueryActivacionesArgs = {
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};


export type QueryActivacionesPorSerialArgs = {
  serial: Scalars['String'];
};


export type QueryVentasPorUsuarioArgs = {
  fecha?: Maybe<Scalars['Date']>;
};


export type QueryCorredorTransaccionesAgrupadasProductoArgs = {
  corredor_id: Scalars['ID'];
  desde?: Maybe<Scalars['Date']>;
  hasta?: Maybe<Scalars['Date']>;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};


export type QueryGetDataPosArgs = {
  desde?: Maybe<Scalars['Date']>;
  hasta?: Maybe<Scalars['Date']>;
  agencia?: Maybe<Array<Maybe<Scalars['ID']>>>;
};


export type QueryPresignedArgs = {
  mimeType?: Maybe<Scalars['String']>;
};


export type QueryPn_PersonaArgs = {
  nrodoc?: Maybe<Scalars['String']>;
};


export type QueryCrudCorredorArgs = {
  operacion: Scalars['String'];
  corredor?: Maybe<Scalars['JSON']>;
};


export type QueryAllUmnsArgs = {
  agencia?: Maybe<Array<Maybe<Scalars['Int']>>>;
  zona?: Maybe<Array<Maybe<Scalars['Int']>>>;
};


export type QueryInvitesArgs = {
  type?: Maybe<Scalars['String']>;
};


export type QueryUmnInviteArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type QueryUmnArgs = {
  id?: Maybe<Array<Maybe<Scalars['ID']>>>;
};


export type QueryEventoCajasByIdArgs = {
  terminalId?: Maybe<Scalars['ID']>;
};


export type QueryEntregasArgs = {
  umn_id?: Maybe<Array<Scalars['ID']>>;
  desde: Scalars['Date'];
  hasta?: Maybe<Scalars['Date']>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  estado?: Maybe<Array<Maybe<Scalars['String']>>>;
  nro_corredor?: Maybe<Scalars['Int']>;
  nrodoc?: Maybe<Scalars['String']>;
  cobrador_id?: Maybe<Scalars['Int']>;
};


export type QuerySwitchEntregasArgs = {
  umn_id?: Maybe<Array<Scalars['ID']>>;
};


export type QueryAnulacionesArgs = {
  umn_id?: Maybe<Array<Scalars['ID']>>;
  desde: Scalars['Date'];
  hasta?: Maybe<Scalars['Date']>;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  estado?: Maybe<Array<Maybe<Scalars['String']>>>;
  nivel?: Maybe<Array<Maybe<Scalars['Int']>>>;
  nro_corredor?: Maybe<Scalars['Int']>;
  nrodoc?: Maybe<Scalars['String']>;
  sorteo_base_id?: Maybe<Array<Scalars['ID']>>;
};


export type QueryCorredoresConDeudaArgs = {
  umn_id: Scalars['ID'];
};


export type QueryDepositosArgs = {
  desde: Scalars['Date'];
  hasta?: Maybe<Scalars['Date']>;
  umn_id?: Maybe<Array<Maybe<Scalars['ID']>>>;
};


export type QueryDepositosAnuladosArgs = {
  desde: Scalars['Date'];
  hasta?: Maybe<Scalars['Date']>;
  umn_id?: Maybe<Array<Maybe<Scalars['ID']>>>;
};


export type QueryGetTransaccionByCorredorTerminalArgs = {
  corredor_id?: Maybe<Scalars['ID']>;
  terminal_id?: Maybe<Scalars['ID']>;
};


export type QueryVerUmnFinanzasArgs = {
  desde: Scalars['Date'];
  hasta?: Maybe<Scalars['Date']>;
  umn_id?: Maybe<Array<Maybe<Scalars['ID']>>>;
};


export type QueryVerDepositosUmnFinanzasArgs = {
  desde: Scalars['Date'];
  hasta?: Maybe<Scalars['Date']>;
  umn_id?: Maybe<Array<Maybe<Scalars['ID']>>>;
};


export type QueryVerAjustesUmnFinanzasArgs = {
  desde: Scalars['Date'];
  umn_id?: Maybe<Array<Maybe<Scalars['ID']>>>;
  hasta?: Maybe<Scalars['Date']>;
};


export type QueryVerConsolidacionesArgs = {
  desde: Scalars['Date'];
  hasta?: Maybe<Scalars['Date']>;
  umn_id?: Maybe<Array<Maybe<Scalars['ID']>>>;
};


export type QueryVerConsolidacionesAgenciaArgs = {
  desde: Scalars['Date'];
  hasta?: Maybe<Scalars['Date']>;
  agencia?: Maybe<Array<Maybe<Scalars['Int']>>>;
};


export type QueryVerConsolidacionesAgenciaGerenciaArgs = {
  desde: Scalars['Date'];
  hasta?: Maybe<Scalars['Date']>;
  agencia?: Maybe<Array<Maybe<Scalars['Int']>>>;
};


export type QueryVerConsolidacionesGerenciaArgs = {
  desde: Scalars['Date'];
  hasta?: Maybe<Scalars['Date']>;
  agencia?: Maybe<Array<Maybe<Scalars['Int']>>>;
};


export type QueryConsolidarArgs = {
  diaconsol?: Maybe<Scalars['Date']>;
};


export type QueryEstadoUmnArgs = {
  umn_id?: Maybe<Scalars['ID']>;
  desde?: Maybe<Scalars['Date']>;
  hasta?: Maybe<Scalars['Date']>;
};


export type QueryVerAjustesArgs = {
  nrodoc?: Maybe<Scalars['String']>;
  numero_corredor?: Maybe<Scalars['Int']>;
  desde: Scalars['Date'];
  umn_id?: Maybe<Array<Scalars['ID']>>;
  hasta?: Maybe<Scalars['Date']>;
};


export type QueryVerAjustesUmnArgs = {
  desde: Scalars['Date'];
  umn_id?: Maybe<Array<Scalars['ID']>>;
  hasta?: Maybe<Scalars['Date']>;
};


export type QueryCajasAbiertasArgs = {
  umn_id?: Maybe<Array<Scalars['ID']>>;
};


export type QueryGetUserArgs = {
  usuario_id: Scalars['ID'];
};


export type QueryCajasAbiertasAgenciaArgs = {
  desde?: Maybe<Scalars['Date']>;
  hasta?: Maybe<Scalars['Date']>;
  agencia?: Maybe<Array<Maybe<Scalars['ID']>>>;
};


export type QueryCajasSegunEstadoArgs = {
  umn_id?: Maybe<Array<Maybe<Scalars['ID']>>>;
  estado?: Maybe<Scalars['String']>;
  colas?: Maybe<Scalars['Boolean']>;
};


export type QueryLastTicketQuinielaArgs = {
  corredor_id?: Maybe<Scalars['ID']>;
};


export type QueryTicketQuinielaArgs = {
  juego_id?: Maybe<Scalars['ID']>;
};


export type QueryJugadaQuinielaArgs = {
  tipo?: Maybe<Scalars['Int']>;
  desde: Scalars['Date'];
  hasta?: Maybe<Scalars['Date']>;
  umn_id?: Maybe<Array<Scalars['ID']>>;
};


export type QueryPerfilCuentaArgs = {
  corredor_id: Scalars['ID'];
};


export type QueryActualCajaArgs = {
  corredor_id?: Maybe<Scalars['ID']>;
  terminal_id?: Maybe<Scalars['Int']>;
};


export type QueryCajaArgs = {
  caja_id?: Maybe<Scalars['ID']>;
};


export type QueryCajaTotalArgs = {
  caja_id?: Maybe<Scalars['ID']>;
};


export type QueryResumenCajaArgs = {
  caja_id: Scalars['ID'];
};


export type QueryResumenUmnArgs = {
  umn_id?: Maybe<Array<Maybe<Scalars['ID']>>>;
  desde?: Maybe<Scalars['Date']>;
  hasta?: Maybe<Scalars['Date']>;
};


export type QueryResumenUmnProductoArgs = {
  producto_id: Scalars['ID'];
  desde?: Maybe<Scalars['Date']>;
  hasta?: Maybe<Scalars['Date']>;
};


export type QueryResumenUmnProductoGestionarArgs = {
  producto_id: Scalars['ID'];
  desde?: Maybe<Scalars['Date']>;
  hasta?: Maybe<Scalars['Date']>;
  umn_id?: Maybe<Array<Maybe<Scalars['Int']>>>;
  agrupar?: Maybe<Scalars['Boolean']>;
};


export type QueryResumenUmnProductoGestionarGerenciaArgs = {
  producto_id: Scalars['ID'];
  desde?: Maybe<Scalars['Date']>;
  hasta?: Maybe<Scalars['Date']>;
  agencia_numero?: Maybe<Array<Maybe<Scalars['Int']>>>;
  agrupar?: Maybe<Scalars['Boolean']>;
  juego_id?: Maybe<Array<Maybe<Scalars['Int']>>>;
};


export type QueryResumenTicketsRaspaditaArgs = {
  desde?: Maybe<Scalars['Date']>;
  hasta?: Maybe<Scalars['Date']>;
};


export type QueryDetalleCajaProductoArgs = {
  caja_id: Scalars['ID'];
  producto_id: Scalars['Int'];
};


export type QueryPedidosFechaCorredorArgs = {
  corredor_id: Scalars['ID'];
  desde: Scalars['Date'];
};


export type QueryProductoArgs = {
  producto_id: Scalars['Int'];
};


export type QueryGetCajasArgs = {
  corredor_id?: Maybe<Scalars['ID']>;
  estado?: Maybe<Scalars['String']>;
};


export type QuerySherlockModeArgs = {
  desde: Scalars['Date'];
  hasta?: Maybe<Scalars['Date']>;
  corredor_numero?: Maybe<Scalars['ID']>;
  agencia?: Maybe<Scalars['ID']>;
  sorteo_numero?: Maybe<Scalars['ID']>;
  order?: Maybe<Array<Maybe<Scalars['ID']>>>;
};


export type QueryBoletasPorMontosArgs = {
  desde: Scalars['Date'];
  hasta?: Maybe<Scalars['Date']>;
  agencia?: Maybe<Array<Maybe<Scalars['ID']>>>;
  sorteo?: Maybe<Array<Maybe<Scalars['ID']>>>;
};


export type QueryHistorialDeSorteosArgs = {
  desde: Scalars['Date'];
  hasta?: Maybe<Scalars['Date']>;
  sorteo?: Maybe<Array<Maybe<Scalars['ID']>>>;
};


export type QueryOcurrenciasArgs = {
  desde: Scalars['Date'];
  hasta?: Maybe<Scalars['Date']>;
  sorteo?: Maybe<Array<Maybe<Scalars['ID']>>>;
  postura?: Maybe<Scalars['Int']>;
};


export type QueryOcurrenciasDosNumerosArgs = {
  desde: Scalars['Date'];
  hasta?: Maybe<Scalars['Date']>;
  sorteo?: Maybe<Array<Maybe<Scalars['ID']>>>;
  postura?: Maybe<Scalars['Int']>;
};


export type QueryTombolaNumerosArgs = {
  desde: Scalars['Date'];
  hasta?: Maybe<Scalars['Date']>;
  sorteo?: Maybe<Array<Maybe<Scalars['ID']>>>;
  postura?: Maybe<Scalars['Int']>;
};


export type QueryBolilleroArgs = {
  desde: Scalars['Date'];
  hasta?: Maybe<Scalars['Date']>;
  sorteo?: Maybe<Array<Maybe<Scalars['ID']>>>;
};


export type QueryResumenUmnGerenciaProductoArgs = {
  agencia?: Maybe<Array<Maybe<Scalars['Int']>>>;
  desde: Scalars['Date'];
  hasta?: Maybe<Scalars['Date']>;
  corredor_numero?: Maybe<Scalars['Int']>;
  sorteo?: Maybe<Array<Maybe<Scalars['Int']>>>;
  producto_id?: Maybe<Scalars['ID']>;
  generado?: Maybe<Scalars['Boolean']>;
};


export type QueryResumenUmnGerenciaProductoUmnArgs = {
  agencia?: Maybe<Scalars['Int']>;
  desde: Scalars['Date'];
  hasta?: Maybe<Scalars['Date']>;
  corredor_numero?: Maybe<Scalars['Int']>;
  sorteo?: Maybe<Array<Maybe<Scalars['Int']>>>;
};


export type QueryResumenUmnGerenciaUmnArgs = {
  agencia?: Maybe<Array<Maybe<Scalars['Int']>>>;
  desde: Scalars['Date'];
  hasta?: Maybe<Scalars['Date']>;
  corredor_numero?: Maybe<Scalars['Int']>;
};


export type QueryResumenAgenciaUmnProductosArgs = {
  umn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  desde: Scalars['Date'];
  hasta?: Maybe<Scalars['Date']>;
};


export type QueryCorredorArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type QueryCorredorCajasArgs = {
  corredor_id: Scalars['ID'];
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};


export type QueryCorredorFechasArgs = {
  corredor_id: Scalars['ID'];
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};


export type QueryCorredorFechasRetrasadasArgs = {
  corredor_id: Scalars['ID'];
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};


export type QueryCorredorTransaccionesFechaArgs = {
  corredor_id: Scalars['ID'];
  fecha?: Maybe<Scalars['Date']>;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};


export type QueryCorredorPedidosFechaArgs = {
  corredor_id: Scalars['ID'];
  fecha?: Maybe<Scalars['Date']>;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};


export type QueryCorredorProductosPedidosArgs = {
  corredor_id: Scalars['ID'];
  desde?: Maybe<Scalars['Date']>;
  hasta?: Maybe<Scalars['Date']>;
  producto_id?: Maybe<Scalars['ID']>;
  juego_id?: Maybe<Array<Maybe<Scalars['Int']>>>;
};


export type QueryCorredorExtractoArgs = {
  corredor_id: Scalars['ID'];
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};


export type QueryCorredorExtracto2Args = {
  corredor_id: Scalars['ID'];
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};


export type QueryCorredorCobrosArgs = {
  umn_id?: Maybe<Array<Maybe<Scalars['ID']>>>;
  corredor_id?: Maybe<Array<Maybe<Scalars['ID']>>>;
  desde?: Maybe<Scalars['Date']>;
  hasta?: Maybe<Scalars['Date']>;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};


export type QueryCorredorProductosArgs = {
  umn_id?: Maybe<Array<Maybe<Scalars['ID']>>>;
  corredor_id?: Maybe<Array<Maybe<Scalars['ID']>>>;
  desde?: Maybe<Scalars['Date']>;
  hasta?: Maybe<Scalars['Date']>;
  producto_id?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sorteo_id?: Maybe<Array<Maybe<Scalars['Int']>>>;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  juego_id?: Maybe<Array<Maybe<Scalars['Int']>>>;
};


export type QueryQWebCantidadesArgs = {
  desde?: Maybe<Scalars['Date']>;
  hasta?: Maybe<Scalars['Date']>;
  promo_id?: Maybe<Array<Maybe<Scalars['Int']>>>;
};


export type QueryResumenUmnAgenciaArgs = {
  umn_id?: Maybe<Array<Maybe<Scalars['ID']>>>;
  desde: Scalars['Date'];
  hasta?: Maybe<Scalars['Date']>;
};


export type QueryCobradorArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type QueryCobradorFechasArgs = {
  cobrador_id: Scalars['ID'];
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};


export type QueryCobradorExtractoArgs = {
  cobrador_id: Scalars['ID'];
};


export type QueryUmnExtractoArgs = {
  umn_id?: Maybe<Array<Scalars['ID']>>;
};


export type QueryCobradorFechaArgs = {
  cobrador_id: Scalars['ID'];
  fecha: Scalars['Date'];
  estado?: Maybe<Array<Maybe<Scalars['String']>>>;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};


export type QueryTerminalesUmnArgs = {
  umn_id?: Maybe<Array<Scalars['ID']>>;
  nro_corredor?: Maybe<Scalars['Int']>;
  serial?: Maybe<Scalars['String']>;
};


export type QueryTerminalesUmnAllArgs = {
  umn_id?: Maybe<Array<Scalars['ID']>>;
};


export type QueryTerminalArgs = {
  terminal_id: Scalars['ID'];
};


export type QueryTerminalesArgs = {
  umn_id?: Maybe<Array<Maybe<Scalars['ID']>>>;
};


export type QueryHeartbeatsArgs = {
  terminal_id?: Maybe<Scalars['ID']>;
};


export type QueryUsoTerminalArgs = {
  terminal_id: Scalars['ID'];
};


export type QueryGerenciaResumenQuinielaAgenciaArgs = {
  desde: Scalars['Date'];
  hasta?: Maybe<Scalars['Date']>;
  agencia?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sorteo?: Maybe<Array<Maybe<Scalars['Int']>>>;
  corredor_numero?: Maybe<Scalars['Int']>;
};


export type QueryPremiacionAgenciaGerenciaArgs = {
  desde: Scalars['Date'];
  hasta?: Maybe<Scalars['Date']>;
  agencia?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sorteo?: Maybe<Array<Maybe<Scalars['Int']>>>;
  corredor_numero?: Maybe<Scalars['Int']>;
  producto_id?: Maybe<Scalars['Int']>;
};


export type QuerySumarioResumenQuinielaProductoParaRaspaArgs = {
  desde: Scalars['Date'];
  hasta?: Maybe<Scalars['Date']>;
  agencia?: Maybe<Array<Maybe<Scalars['Int']>>>;
  corredor_numero?: Maybe<Scalars['Int']>;
  detail?: Maybe<Scalars['Boolean']>;
  bycorredor?: Maybe<Scalars['Boolean']>;
  umn_id?: Maybe<Scalars['Int']>;
  producto_id?: Maybe<Scalars['ID']>;
};


export type QuerySumarioResumenQuinielaProductoGerenciaArgs = {
  desde: Scalars['Date'];
  hasta?: Maybe<Scalars['Date']>;
  agencia?: Maybe<Array<Maybe<Scalars['Int']>>>;
  corredor_numero?: Maybe<Scalars['Int']>;
  detail?: Maybe<Scalars['Boolean']>;
  bycorredor?: Maybe<Scalars['Boolean']>;
  umn_id?: Maybe<Scalars['Int']>;
  producto_id?: Maybe<Scalars['ID']>;
};


export type QueryGerenciaResumenQuinielaAgenciaProductoArgs = {
  desde: Scalars['Date'];
  hasta?: Maybe<Scalars['Date']>;
  agencia?: Maybe<Array<Maybe<Scalars['Int']>>>;
  corredor_numero?: Maybe<Scalars['Int']>;
  detail?: Maybe<Scalars['Boolean']>;
  bycorredor?: Maybe<Scalars['Boolean']>;
  umn_id?: Maybe<Scalars['Int']>;
  producto_id?: Maybe<Scalars['ID']>;
};


export type QueryTransaccionesRetirosByCorredorArgs = {
  desde: Scalars['Date'];
  hasta?: Maybe<Scalars['Date']>;
  agencia?: Maybe<Array<Maybe<Scalars['Int']>>>;
  corredor_id?: Maybe<Scalars['Int']>;
  producto_id?: Maybe<Scalars['ID']>;
};


export type QueryGetTransaccionByCorredorArgs = {
  desde: Scalars['Date'];
  hasta?: Maybe<Scalars['Date']>;
  agencia?: Maybe<Scalars['Int']>;
  corredor_numero?: Maybe<Scalars['Int']>;
  umn_id?: Maybe<Scalars['Int']>;
  producto_id?: Maybe<Scalars['Int']>;
};


export type QueryGerenciaResumenQuinielaUmnArgs = {
  desde: Scalars['Date'];
  hasta?: Maybe<Scalars['Date']>;
  agencia?: Maybe<Scalars['Int']>;
  sorteo?: Maybe<Array<Maybe<Scalars['Int']>>>;
  corredor_numero?: Maybe<Scalars['Int']>;
};


export type QueryGerenciaResumenQuinielaSorteoArgs = {
  desde: Scalars['Date'];
  hasta?: Maybe<Scalars['Date']>;
  sorteo?: Maybe<Array<Maybe<Scalars['Int']>>>;
  agencia?: Maybe<Array<Maybe<Scalars['Int']>>>;
  corredor_numero?: Maybe<Scalars['Int']>;
};


export type QueryGerenciaDetalleQuinielaArgs = {
  desde: Scalars['Date'];
  hasta?: Maybe<Scalars['Date']>;
  agencia?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sorteo?: Maybe<Array<Maybe<Scalars['Int']>>>;
};


export type QueryGerenciaResumenQuinielaFechaArgs = {
  fechaFrom: Scalars['Date'];
  fechaTo: Scalars['Date'];
  agencia?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sorteo?: Maybe<Array<Maybe<Scalars['Int']>>>;
  corredor_numero?: Maybe<Scalars['Int']>;
};


export type QueryVentasTotalesAgenciaArgs = {
  desde: Scalars['Date'];
  hasta?: Maybe<Scalars['Date']>;
  agencia?: Maybe<Scalars['Int']>;
};


export type QueryQuinielaFechaPorCorredorArgs = {
  desde: Scalars['Date'];
  hasta?: Maybe<Scalars['Date']>;
  umn?: Maybe<Array<Maybe<Scalars['Int']>>>;
};


export type QueryDetallesPremiacionTipoArgs = {
  agencia?: Maybe<Array<Maybe<Scalars['Int']>>>;
  desde: Scalars['Date'];
  hasta?: Maybe<Scalars['Date']>;
  sorteo?: Maybe<Array<Maybe<Scalars['Int']>>>;
};


export type QueryDetallesPremiosComposicionArgs = {
  agencia?: Maybe<Array<Maybe<Scalars['Int']>>>;
  desde: Scalars['Date'];
  hasta?: Maybe<Scalars['Date']>;
  sorteo?: Maybe<Array<Maybe<Scalars['Int']>>>;
  tipo?: Maybe<Scalars['Int']>;
};


export type QueryStockTerminalesArgs = {
  desde: Scalars['Date'];
  hasta?: Maybe<Scalars['Date']>;
  serial?: Maybe<Scalars['String']>;
  estado?: Maybe<Scalars['String']>;
  nro_corredor?: Maybe<Scalars['String']>;
  umn_id?: Maybe<Array<Scalars['ID']>>;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};


export type QueryStockTerminales2Args = {
  desde: Scalars['Date'];
  hasta?: Maybe<Scalars['Date']>;
  serial?: Maybe<Scalars['String']>;
  estado?: Maybe<Scalars['String']>;
  nro_corredor?: Maybe<Scalars['String']>;
  umn_id?: Maybe<Array<Scalars['ID']>>;
};


export type QueryStockTerminal3Args = {
  desde: Scalars['Date'];
  hasta?: Maybe<Scalars['Date']>;
  serial?: Maybe<Scalars['String']>;
  estado?: Maybe<Scalars['String']>;
  nro_corredor?: Maybe<Scalars['String']>;
  umn_id?: Maybe<Array<Scalars['ID']>>;
};


export type QueryResumenTerminales2Args = {
  umn_id?: Maybe<Array<Maybe<Scalars['Int']>>>;
};


export type QueryHistorialTerminal2Args = {
  terminalId?: Maybe<Scalars['ID']>;
};


export type QueryReporteMediosElectronicosArgs = {
  desde: Scalars['Date'];
  hasta?: Maybe<Scalars['Date']>;
  sorteo_id?: Maybe<Array<Maybe<Scalars['ID']>>>;
  umn_id?: Maybe<Array<Maybe<Scalars['ID']>>>;
};


export type QueryTerminalHistoricoArgs = {
  desde: Scalars['Date'];
  hasta?: Maybe<Scalars['Date']>;
  terminal_id: Scalars['ID'];
};


export type QueryCorredorHistoricoArgs = {
  desde: Scalars['Date'];
  hasta?: Maybe<Scalars['Date']>;
  corredor_id: Scalars['ID'];
};


export type QueryDatosAnalisisFrecuenciaArgs = {
  input: Scalars['String'];
};


export type QueryGetOcurrenciasAndChartArgs = {
  desde: Scalars['Date'];
  hasta?: Maybe<Scalars['Date']>;
  flag?: Maybe<Scalars['Boolean']>;
};


export type QueryGetConsolidacionDetalleArgs = {
  desde?: Maybe<Scalars['Date']>;
  hasta?: Maybe<Scalars['Date']>;
  agencia?: Maybe<Scalars['Int']>;
};


export type QueryCorredoresExternosArgs = {
  umn_id?: Maybe<Array<Maybe<Scalars['ID']>>>;
};


export type QueryTicketsSorteoArgs = {
  sorteo_id: Scalars['ID'];
  premiados?: Maybe<Scalars['Boolean']>;
  serie?: Maybe<Scalars['String']>;
  ticket?: Maybe<Scalars['Float']>;
  monto?: Maybe<Scalars['Int']>;
  caja_id?: Maybe<Scalars['Int']>;
  anuladas?: Maybe<Scalars['Boolean']>;
  umn_id?: Maybe<Array<Maybe<Scalars['ID']>>>;
  pendientes?: Maybe<Scalars['Boolean']>;
};


export type QueryTicketResultArgs = {
  premiados?: Maybe<Scalars['Boolean']>;
  serie?: Maybe<Scalars['String']>;
  ticket?: Maybe<Scalars['Float']>;
  monto?: Maybe<Scalars['Int']>;
  nro_corredor?: Maybe<Scalars['Int']>;
  anuladas?: Maybe<Scalars['Boolean']>;
  umn_id?: Maybe<Array<Scalars['ID']>>;
  desde: Scalars['Date'];
  hasta?: Maybe<Scalars['Date']>;
  sorteo_id?: Maybe<Array<Maybe<Scalars['Int']>>>;
  retrasadas?: Maybe<Scalars['Boolean']>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type QueryTicketPremiadosArgs = {
  serie?: Maybe<Scalars['String']>;
  ticket?: Maybe<Scalars['Float']>;
  desde: Scalars['Date'];
  hasta?: Maybe<Scalars['Date']>;
  numero_corredor?: Maybe<Scalars['Int']>;
  sorteo_id?: Maybe<Array<Maybe<Scalars['Int']>>>;
  umn_id?: Maybe<Array<Scalars['ID']>>;
  retrasadas?: Maybe<Scalars['Boolean']>;
  agencia?: Maybe<Array<Maybe<Scalars['ID']>>>;
  premiados?: Maybe<Scalars['Boolean']>;
};


export type QueryPremiadosAgrupadosArgs = {
  serie?: Maybe<Scalars['String']>;
  ticket?: Maybe<Scalars['Float']>;
  desde: Scalars['Date'];
  hasta?: Maybe<Scalars['Date']>;
  numero_corredor?: Maybe<Scalars['Int']>;
  sorteo_id?: Maybe<Array<Maybe<Scalars['Int']>>>;
  umn_id?: Maybe<Array<Scalars['ID']>>;
  premiado?: Maybe<Scalars['Boolean']>;
  agencia?: Maybe<Array<Maybe<Scalars['ID']>>>;
  sorteoNoAgrupado?: Maybe<Scalars['Boolean']>;
  fechaNoAgrupado?: Maybe<Scalars['Boolean']>;
};


export type QuerySorteoResultsArgs = {
  sorteo_id: Scalars['ID'];
};


export type QueryEntregasCorredorArgs = {
  corredor_id: Scalars['ID'];
  estado?: Maybe<Array<Maybe<Scalars['String']>>>;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};


export type QueryHistorialTerminalArgs = {
  terminalId?: Maybe<Scalars['ID']>;
};


export type QueryGetSolicitudesDepositoPorAgenciaArgs = {
  umn_id?: Maybe<Array<Maybe<Scalars['ID']>>>;
};


export type QueryPremiadosEglobalArgs = {
  ticket: Scalars['Int'];
  serie?: Maybe<Scalars['String']>;
  sorteo?: Maybe<Scalars['Int']>;
  fecha?: Maybe<Scalars['String']>;
};


export type QueryGerenciaResumenQuinielaUmnsArgs = {
  desde: Scalars['Date'];
  hasta?: Maybe<Scalars['Date']>;
  agencia?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sorteo?: Maybe<Array<Maybe<Scalars['Int']>>>;
  corredor_numero?: Maybe<Scalars['Int']>;
};


export type QueryFindEntregaArgs = {
  entrega_id: Scalars['ID'];
};


export type QueryGetZonasByAgenciaArgs = {
  agencia?: Maybe<Scalars['Int']>;
};


export type QueryProyeccionPremiacionArgs = {
  sorteo_id?: Maybe<Array<Maybe<Scalars['Int']>>>;
  cifras?: Maybe<Scalars['Int']>;
  fecha: Scalars['Date'];
  agencia?: Maybe<Array<Maybe<Scalars['Int']>>>;
};


export type QueryTransaccionesAgrupadasCorredorArgs = {
  corredor_id: Scalars['Int'];
  desde: Scalars['Date'];
  hasta?: Maybe<Scalars['Date']>;
};


export type QueryTicketCorredorArgs = {
  desde?: Maybe<Scalars['Date']>;
  hasta?: Maybe<Scalars['Date']>;
  corredor_id?: Maybe<Scalars['Int']>;
  serie?: Maybe<Scalars['String']>;
  ticket?: Maybe<Scalars['Int']>;
  sorteo_id?: Maybe<Array<Maybe<Scalars['Int']>>>;
  all?: Maybe<Scalars['Boolean']>;
};


export type QueryCurrentSorteosArgs = {
  desde: Scalars['Date'];
  hasta?: Maybe<Scalars['Date']>;
};


export type QueryLimitedNumbersArgs = {
  sorteo_id?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type QueryBilletajeResultArgs = {
  smp?: Maybe<Scalars['Float']>;
  consecutivo?: Maybe<Scalars['Float']>;
  serie?: Maybe<Scalars['String']>;
  ticket?: Maybe<Scalars['Float']>;
  monto?: Maybe<Scalars['Int']>;
  nro_corredor?: Maybe<Scalars['Int']>;
  umn_id?: Maybe<Array<Scalars['ID']>>;
  desde: Scalars['Date'];
  hasta?: Maybe<Scalars['Date']>;
  retrasadas?: Maybe<Scalars['Boolean']>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type QueryReportePagoPremiosArgs = {
  desde: Scalars['Date'];
  hasta?: Maybe<Scalars['Date']>;
  sorteo_id?: Maybe<Array<Maybe<Scalars['ID']>>>;
  agencia?: Maybe<Array<Maybe<Scalars['ID']>>>;
};


export type QueryReporteFacturacionAgenciaArgs = {
  desde: Scalars['Date'];
  hasta?: Maybe<Scalars['Date']>;
  sorteo_id?: Maybe<Array<Maybe<Scalars['ID']>>>;
  agencia?: Maybe<Array<Maybe<Scalars['ID']>>>;
};


export type QueryReporteFacturacionDiariaArgs = {
  desde: Scalars['Date'];
  hasta?: Maybe<Scalars['Date']>;
  sorteo_id?: Maybe<Array<Maybe<Scalars['ID']>>>;
  agencia?: Maybe<Array<Maybe<Scalars['ID']>>>;
};


export type QueryReporteDeSistemaArgs = {
  desde: Scalars['Date'];
  hasta?: Maybe<Scalars['Date']>;
  sorteo_id?: Maybe<Array<Maybe<Scalars['ID']>>>;
  agencia?: Maybe<Array<Maybe<Scalars['ID']>>>;
};


export type QueryReporteVentasPorAgenciaArgs = {
  desde: Scalars['Date'];
  hasta?: Maybe<Scalars['Date']>;
  sorteo_id?: Maybe<Array<Maybe<Scalars['ID']>>>;
  agencia?: Maybe<Array<Maybe<Scalars['ID']>>>;
};


export type QueryReporteGanadoresPorAgenciaArgs = {
  desde: Scalars['Date'];
  hasta?: Maybe<Scalars['Date']>;
  agencia?: Maybe<Array<Maybe<Scalars['Int']>>>;
  juego_id?: Maybe<Scalars['Int']>;
  corredor?: Maybe<Scalars['Boolean']>;
};


export type QueryReporteVentasRaspaditaArgs = {
  desde: Scalars['Date'];
  hasta?: Maybe<Scalars['Date']>;
  agencia?: Maybe<Array<Maybe<Scalars['Int']>>>;
  juego_id?: Maybe<Scalars['Int']>;
  corredor?: Maybe<Scalars['Boolean']>;
};


export type QueryReporteVentasPorTerminalArgs = {
  desde: Scalars['Date'];
  hasta?: Maybe<Scalars['Date']>;
  sorteo_id?: Maybe<Array<Maybe<Scalars['ID']>>>;
  umn_id?: Maybe<Array<Maybe<Scalars['ID']>>>;
};


export type QueryConajzarArgs = {
  desde?: Maybe<Scalars['Date']>;
  hasta?: Maybe<Scalars['Date']>;
};


export type QueryReporteAnulacionesArgs = {
  desde: Scalars['Date'];
  hasta?: Maybe<Scalars['Date']>;
  sorteo_id?: Maybe<Array<Maybe<Scalars['ID']>>>;
  umn_id?: Maybe<Array<Maybe<Scalars['ID']>>>;
};


export type QueryReporteHearbeatArgs = {
  desde: Scalars['Date'];
  hasta?: Maybe<Scalars['Date']>;
  sorteo_id?: Maybe<Array<Maybe<Scalars['ID']>>>;
  umn_id?: Maybe<Array<Maybe<Scalars['ID']>>>;
};


export type QueryReporteVentasElectronicasArgs = {
  desde: Scalars['Date'];
  hasta?: Maybe<Scalars['Date']>;
  sorteo_id?: Maybe<Array<Maybe<Scalars['ID']>>>;
  umn_id?: Maybe<Array<Maybe<Scalars['ID']>>>;
};


export type QueryRecalculoDiferenciasArgs = {
  desde?: Maybe<Scalars['Date']>;
  hasta?: Maybe<Scalars['Date']>;
};


export type QueryLoginFailedArgs = {
  desde?: Maybe<Scalars['Date']>;
  hasta?: Maybe<Scalars['Date']>;
};


export type QueryAccesoRemotoArgs = {
  desde?: Maybe<Scalars['Date']>;
  hasta?: Maybe<Scalars['Date']>;
};


export type QueryAnulacionesByDayArgs = {
  desde?: Maybe<Scalars['Date']>;
  hasta?: Maybe<Scalars['Date']>;
};


export type QueryAllPrizesArgs = {
  desde?: Maybe<Scalars['Date']>;
  hasta?: Maybe<Scalars['Date']>;
  umn_id?: Maybe<Array<Scalars['ID']>>;
  serie?: Maybe<Scalars['String']>;
  ticket?: Maybe<Scalars['Float']>;
};


export type QueryResumenTerminalesArgs = {
  umn_id?: Maybe<Array<Maybe<Scalars['Int']>>>;
};


export type QueryListAnulacionPremioPagadoArgs = {
  desde?: Maybe<Scalars['Date']>;
  hasta?: Maybe<Scalars['Date']>;
  umn_id?: Maybe<Array<Scalars['ID']>>;
  estado?: Maybe<Scalars['Int']>;
  serie?: Maybe<Scalars['String']>;
  ticket?: Maybe<Scalars['Float']>;
};


export type QueryGerenciaAnalisisQuinielaBySorteoArgs = {
  desde?: Maybe<Scalars['Date']>;
  hasta?: Maybe<Scalars['Date']>;
  sorteo_base_id?: Maybe<Array<Maybe<Scalars['ID']>>>;
};


export type QueryGerenciaAnalisisQuinielaByAgenciaArgs = {
  desde?: Maybe<Scalars['Date']>;
  hasta?: Maybe<Scalars['Date']>;
  sorteo_base_id: Scalars['ID'];
  agencia?: Maybe<Array<Maybe<Scalars['ID']>>>;
};


export type QueryGerenciaAnalisisQuinielaByCorredorArgs = {
  desde?: Maybe<Scalars['Date']>;
  hasta?: Maybe<Scalars['Date']>;
  sorteo_base_id: Scalars['ID'];
  agencia?: Maybe<Scalars['ID']>;
};


export type QueryListAnulacionesMegalotoArgs = {
  desde: Scalars['Date'];
  hasta: Scalars['Date'];
  agencia: Scalars['ID'];
  corredor?: Maybe<Scalars['ID']>;
  ticket?: Maybe<Scalars['String']>;
};


export type QueryListTicketsMegalotoArgs = {
  ticket?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type QueryGetCuotasTeeteAsgardArgs = {
  agencia?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
  solicitud?: Maybe<Scalars['String']>;
  estado?: Maybe<Scalars['String']>;
};


export type QueryGetResumenFacturacionTelefoniaArgs = {
  desde: Scalars['Date'];
  hasta?: Maybe<Scalars['Date']>;
  agencia?: Maybe<Array<Maybe<Scalars['Int']>>>;
};


export type QueryGetDetallesFacturaTelefoniaArgs = {
  desde: Scalars['Date'];
  hasta?: Maybe<Scalars['Date']>;
  agencia: Scalars['Int'];
};

export type RecargaResponse = {
  __typename?: 'RecargaResponse';
  caja?: Maybe<Caja>;
  msg?: Maybe<Scalars['String']>;
};

export type Reporte = {
  __typename?: 'Reporte';
  html?: Maybe<Scalars['String']>;
};

export type ResumenCaja = {
  __typename?: 'ResumenCaja';
  TransaccionesAgrupadas?: Maybe<Array<Maybe<GrupoTransacciones>>>;
};

export type ResumenCajaCorredor = {
  __typename?: 'ResumenCajaCorredor';
  id: Scalars['ID'];
  apertura?: Maybe<Scalars['Date']>;
  cantidad?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
  totalComisionA?: Maybe<Scalars['Int']>;
  totalComisionC?: Maybe<Scalars['Int']>;
  Corredor?: Maybe<Corredor>;
};

export type ResumenUmn = {
  __typename?: 'ResumenUmn';
  TransaccionesAgrupadas?: Maybe<Scalars['JSON']>;
  quinielaMontos?: Maybe<Scalars['JSON']>;
  raspaditaMontos?: Maybe<Scalars['JSON']>;
  depositos?: Maybe<Scalars['JSON']>;
  saldoAnterior?: Maybe<Scalars['JSON']>;
};

export type Right = {
  __typename?: 'Right';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
};

export type Role = {
  __typename?: 'Role';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
};

export type Session = {
  __typename?: 'Session';
  token?: Maybe<Scalars['String']>;
  initialParams?: Maybe<Scalars['JSONObject']>;
  user?: Maybe<Usuario>;
};

export type Sorteo = {
  __typename?: 'Sorteo';
  id: Scalars['ID'];
  SorteoBase: SorteoBase;
  fecha: Scalars['Date'];
  apertura: Scalars['Date'];
  cierre: Scalars['Date'];
  transmision: Scalars['Date'];
  resultados?: Maybe<Array<Maybe<Scalars['String']>>>;
  status: Scalars['String'];
  liquidado?: Maybe<Scalars['Boolean']>;
  pendientes?: Maybe<Scalars['Int']>;
};

export type SorteoBase = {
  __typename?: 'SorteoBase';
  id?: Maybe<Scalars['ID']>;
  nombre: Scalars['String'];
  numero: Scalars['Int'];
  tipo?: Maybe<Scalars['Int']>;
};

export type SorteoPrograma = {
  __typename?: 'SorteoPrograma';
  id: Scalars['ID'];
  SorteoBase: SorteoBase;
  dia: Scalars['Int'];
  apertura: Scalars['Time'];
  cierre: Scalars['Time'];
  transmision: Scalars['Time'];
  status: Scalars['String'];
};

export type SumatoriaHora = {
  __typename?: 'SumatoriaHora';
  id: Scalars['ID'];
  hora?: Maybe<Scalars['String']>;
  count?: Maybe<Scalars['Int']>;
  monto?: Maybe<Scalars['Int']>;
  premio?: Maybe<Scalars['Int']>;
};

export type Terminal = {
  __typename?: 'Terminal';
  id?: Maybe<Scalars['ID']>;
  serial?: Maybe<Scalars['String']>;
  version_asignada?: Maybe<Scalars['String']>;
  Heartbeats?: Maybe<Array<Maybe<Heartbeat>>>;
};

export type TicketPremiado = {
  __typename?: 'TicketPremiado';
  id?: Maybe<Scalars['ID']>;
  Anulacion?: Maybe<Anulacion>;
  Transaccion?: Maybe<Transaccion>;
  Sorteo?: Maybe<Sorteo>;
  serie?: Maybe<Scalars['String']>;
  ticket?: Maybe<Scalars['Float']>;
  jugadas?: Maybe<Array<Maybe<JugadaQuiniela>>>;
  monto_apostado?: Maybe<Scalars['Int']>;
  monto_ganado?: Maybe<Scalars['Int']>;
  numero_sorteado?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['Date']>;
  generado?: Maybe<Scalars['Date']>;
};

export type TicketQuiniela = {
  __typename?: 'TicketQuiniela';
  id?: Maybe<Scalars['ID']>;
  nombre?: Maybe<Scalars['String']>;
  apellido?: Maybe<Scalars['String']>;
  Anulacion?: Maybe<Anulacion>;
  Transaccion?: Maybe<Transaccion>;
  Sorteo?: Maybe<Sorteo>;
  serie?: Maybe<Scalars['String']>;
  ticket?: Maybe<Scalars['Float']>;
  jugadas?: Maybe<Array<Maybe<JugadaQuiniela>>>;
  monto_apostado?: Maybe<Scalars['Int']>;
  monto_ganado?: Maybe<Scalars['Int']>;
  numero_sorteado?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['Date']>;
  generado?: Maybe<Scalars['Date']>;
};


export type Transaccion = {
  __typename?: 'Transaccion';
  id?: Maybe<Scalars['ID']>;
  created_at?: Maybe<Scalars['Date']>;
  generado?: Maybe<Scalars['Date']>;
  Caja?: Maybe<Caja>;
  Producto?: Maybe<Producto>;
  monto?: Maybe<Scalars['Int']>;
  comision?: Maybe<Scalars['Int']>;
  anulado?: Maybe<Scalars['Boolean']>;
};

export type TransaccionesProductoAgrupado = {
  __typename?: 'TransaccionesProductoAgrupado';
  monto?: Maybe<Scalars['Int']>;
  cantidad?: Maybe<Scalars['Int']>;
  totalComisionA?: Maybe<Scalars['Int']>;
  totalComisionC?: Maybe<Scalars['Int']>;
  Producto?: Maybe<Producto>;
};

export type Umn = {
  __typename?: 'Umn';
  id?: Maybe<Scalars['ID']>;
  now?: Maybe<Scalars['Date']>;
  created_at?: Maybe<Scalars['Date']>;
  numero?: Maybe<Scalars['Int']>;
  nombre?: Maybe<Scalars['String']>;
  agencia_numero?: Maybe<Scalars['Int']>;
  zona?: Maybe<Scalars['Int']>;
  Corredores?: Maybe<Array<Maybe<Corredor>>>;
  Cobradores?: Maybe<Array<Maybe<Cobrador>>>;
  Transacciones?: Maybe<Array<Maybe<Transaccion>>>;
  totalCorredores?: Maybe<Scalars['Int']>;
  totalCajasAbiertas?: Maybe<Scalars['Int']>;
  totalLinea?: Maybe<Scalars['Int']>;
  totalDeuda?: Maybe<Scalars['Int']>;
  totalVentasConCierre?: Maybe<Scalars['Int']>;
  totalVentasSinCierre?: Maybe<Scalars['Int']>;
};


export type User = {
  __typename?: 'User';
  id?: Maybe<Scalars['ID']>;
  username?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['Date']>;
  views?: Maybe<Scalars['Int']>;
};

export type Uso = {
  __typename?: 'Uso';
  id?: Maybe<Scalars['ID']>;
  apertura?: Maybe<Scalars['Date']>;
  cierre?: Maybe<Scalars['Date']>;
  Corredor?: Maybe<Corredor>;
  Terminal?: Maybe<Terminal>;
};

export type Usuario = {
  __typename?: 'Usuario';
  id?: Maybe<Scalars['ID']>;
  type?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['Date']>;
  Person?: Maybe<Person>;
  Corredores?: Maybe<Array<Maybe<Corredor>>>;
  Cobradores?: Maybe<Array<Maybe<Cobrador>>>;
  pin?: Maybe<Scalars['String']>;
  Permisos?: Maybe<Array<Maybe<Permiso>>>;
  Umns?: Maybe<Array<Maybe<Umn>>>;
};

export type VentasCorredor = {
  __typename?: 'VentasCorredor';
  fecha?: Maybe<Scalars['String']>;
  sorteo?: Maybe<Scalars['Int']>;
  usuario?: Maybe<Array<Maybe<Usuario>>>;
  cantidad?: Maybe<Scalars['Int']>;
  montototal?: Maybe<Scalars['Int']>;
  montoganado?: Maybe<Scalars['Int']>;
};

export type AddCobradorMutationVariables = Exact<{
  usuario_id: Scalars['ID'];
  umn_id: Scalars['ID'];
  cobrador_numero: Scalars['Int'];
  linea: Scalars['Int'];
}>;


export type AddCobradorMutation = (
  { __typename?: 'Mutation' }
  & { addCobradorToUser?: Maybe<(
    { __typename?: 'Usuario' }
    & Pick<Usuario, 'id'>
    & { Cobradores?: Maybe<Array<Maybe<(
      { __typename?: 'Cobrador' }
      & Pick<Cobrador, 'numero' | 'linea'>
    )>>> }
  )> }
);

export type AddCorredorMutationVariables = Exact<{
  usuario_id: Scalars['ID'];
  umn_id: Scalars['Int'];
  corredor_numero: Scalars['Int'];
  linea: Scalars['Int'];
}>;


export type AddCorredorMutation = (
  { __typename?: 'Mutation' }
  & { addCorredorToUser?: Maybe<(
    { __typename?: 'Usuario' }
    & Pick<Usuario, 'id'>
    & { Corredores?: Maybe<Array<Maybe<(
      { __typename?: 'Corredor' }
      & Pick<Corredor, 'numero' | 'linea'>
    )>>> }
  )> }
);

export type AddPermisoMutationVariables = Exact<{
  usuario_id: Scalars['ID'];
  role_id: Scalars['Int'];
  rights: Array<Maybe<Scalars['Int']>> | Maybe<Scalars['Int']>;
}>;


export type AddPermisoMutation = (
  { __typename?: 'Mutation' }
  & { addPermisoToUser?: Maybe<(
    { __typename?: 'Permiso' }
    & { Role?: Maybe<(
      { __typename?: 'Role' }
      & Pick<Role, 'name'>
    )>, Rights?: Maybe<Array<Maybe<(
      { __typename?: 'Right' }
      & Pick<Right, 'name'>
    )>>> }
  )> }
);

export type AddPinMutationVariables = Exact<{
  usuario_id: Scalars['ID'];
  value: Scalars['String'];
}>;


export type AddPinMutation = (
  { __typename?: 'Mutation' }
  & { setPinToUser?: Maybe<(
    { __typename?: 'Usuario' }
    & Pick<Usuario, 'id'>
  )> }
);

export type AddUmnMutationVariables = Exact<{
  usuario_id: Scalars['ID'];
  umn_id: Scalars['Int'];
}>;


export type AddUmnMutation = (
  { __typename?: 'Mutation' }
  & { addUmnToUser?: Maybe<(
    { __typename?: 'Usuario' }
    & Pick<Usuario, 'id'>
    & { Umns?: Maybe<Array<Maybe<(
      { __typename?: 'Umn' }
      & Pick<Umn, 'numero' | 'agencia_numero' | 'id'>
    )>>>, Person?: Maybe<(
      { __typename?: 'Person' }
      & Pick<Person, 'id'>
    )> }
  )> }
);

export type PagarPremioAgenciaMutationVariables = Exact<{
  serie?: Maybe<Scalars['String']>;
  ticket?: Maybe<Scalars['Float']>;
  fecha?: Maybe<Scalars['Date']>;
  umn_id?: Maybe<Scalars['ID']>;
  monto?: Maybe<Scalars['Int']>;
  id_pago?: Maybe<Scalars['Float']>;
}>;


export type PagarPremioAgenciaMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'pagarPremio'>
);

export type AcceptOrRejectSolicitudDepositoMutationVariables = Exact<{
  solicitud_id: Scalars['ID'];
  type?: Maybe<Scalars['String']>;
}>;


export type AcceptOrRejectSolicitudDepositoMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'acceptOrRejectSolicitudDeposito'>
);

export type AddNewUmnMutationVariables = Exact<{
  nombre?: Maybe<Scalars['String']>;
  agencia_numero?: Maybe<Scalars['Int']>;
  numero?: Maybe<Scalars['Int']>;
}>;


export type AddNewUmnMutation = (
  { __typename?: 'Mutation' }
  & { addUMN?: Maybe<(
    { __typename?: 'Umn' }
    & Pick<Umn, 'numero' | 'agencia_numero' | 'nombre'>
  )> }
);

export type AddPasswordMutationVariables = Exact<{
  usuario_id: Scalars['ID'];
  value: Scalars['String'];
}>;


export type AddPasswordMutation = (
  { __typename?: 'Mutation' }
  & { setPasswordToUser?: Maybe<(
    { __typename?: 'Usuario' }
    & Pick<Usuario, 'id'>
  )> }
);

export type AddNewUserMutationVariables = Exact<{
  nrodoc: Scalars['String'];
  password: Scalars['String'];
  pin: Scalars['String'];
  domicilio: Scalars['String'];
  telefono: Scalars['String'];
  umn_id: Scalars['Int'];
}>;


export type AddNewUserMutation = (
  { __typename?: 'Mutation' }
  & { addUser?: Maybe<(
    { __typename?: 'Usuario' }
    & Pick<Usuario, 'id' | 'username'>
    & { Person?: Maybe<(
      { __typename?: 'Person' }
      & Pick<Person, 'nombre' | 'apellido' | 'domicilio' | 'telefono' | 'id'>
    )> }
  )> }
);

export type AnulacionMutationVariables = Exact<{
  anulacion_id: Scalars['ID'];
}>;


export type AnulacionMutation = (
  { __typename?: 'Mutation' }
  & { anulacionConfirm?: Maybe<(
    { __typename?: 'Anulacion' }
    & Pick<Anulacion, 'id'>
  )> }
);

export type AnulacionDenyMutationVariables = Exact<{
  anulacion_id: Scalars['ID'];
}>;


export type AnulacionDenyMutation = (
  { __typename?: 'Mutation' }
  & { anulacionDeny?: Maybe<(
    { __typename?: 'Anulacion' }
    & Pick<Anulacion, 'id'>
  )> }
);

export type AnulacionElevarMutationVariables = Exact<{
  anulacion_id: Scalars['ID'];
  elevacion: Scalars['Int'];
}>;


export type AnulacionElevarMutation = (
  { __typename?: 'Mutation' }
  & { anulacionElevacion?: Maybe<(
    { __typename?: 'Anulacion' }
    & Pick<Anulacion, 'id'>
  )> }
);

export type ConfirmarAnulacionPremioPagadoMutationVariables = Exact<{
  premio_pagado_id: Scalars['ID'];
}>;


export type ConfirmarAnulacionPremioPagadoMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'confirmarAnulacionPremioPagado'>
);

export type AnularDepositoMutationVariables = Exact<{
  id_deposito: Scalars['ID'];
}>;


export type AnularDepositoMutation = (
  { __typename?: 'Mutation' }
  & { anularDeposito?: Maybe<(
    { __typename?: 'Deposito' }
    & Pick<Deposito, 'id' | 'monto' | 'medio'>
  )> }
);

export type AnularTransaccionMegalotoMutationVariables = Exact<{
  id: Scalars['ID'];
  motivoId?: Maybe<Scalars['String']>;
}>;


export type AnularTransaccionMegalotoMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'anularTransaccionMegaloto'>
);

export type CerrarCajaMutationVariables = Exact<{
  corredor_id?: Maybe<Scalars['ID']>;
  caja_id: Scalars['Int'];
  terminal_id: Scalars['Int'];
  referencia: Scalars['String'];
}>;


export type CerrarCajaMutation = (
  { __typename?: 'Mutation' }
  & { cerrarCaja?: Maybe<(
    { __typename?: 'Caja' }
    & Pick<Caja, 'id' | 'apertura' | 'cierre' | 'linea'>
    & { Terminal?: Maybe<(
      { __typename?: 'Terminal' }
      & Pick<Terminal, 'id'>
    )> }
  )> }
);

export type ChangeSwitchEntregasMutationVariables = Exact<{
  umn_id?: Maybe<Array<Scalars['ID']> | Scalars['ID']>;
}>;


export type ChangeSwitchEntregasMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'changeSwitchEntregas'>
);

export type ChangeSwitchEntregasCorredorMutationVariables = Exact<{
  corredor_id: Scalars['ID'];
}>;


export type ChangeSwitchEntregasCorredorMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'changeSwitchEntregasCorredor'>
);

export type ChangeUmnUserMutationVariables = Exact<{
  usuario_id: Scalars['ID'];
  new_umn_id?: Maybe<Array<Scalars['ID']> | Scalars['ID']>;
}>;


export type ChangeUmnUserMutation = (
  { __typename?: 'Mutation' }
  & { changeUmnToUser?: Maybe<(
    { __typename?: 'Usuario' }
    & Pick<Usuario, 'id'>
    & { Umns?: Maybe<Array<Maybe<(
      { __typename?: 'Umn' }
      & Pick<Umn, 'id' | 'numero'>
    )>>> }
  )> }
);

export type CobradorMutationVariables = Exact<{
  entrega_id: Scalars['ID'];
  referencia: Scalars['String'];
  cobrador_id?: Maybe<Scalars['Int']>;
}>;


export type CobradorMutation = (
  { __typename?: 'Mutation' }
  & { entregaConfirm?: Maybe<(
    { __typename?: 'Entrega' }
    & Pick<Entrega, 'id'>
  )> }
);

export type CobradorRechazarMutationVariables = Exact<{
  entrega_id: Scalars['ID'];
}>;


export type CobradorRechazarMutation = (
  { __typename?: 'Mutation' }
  & { entregaDeny?: Maybe<(
    { __typename?: 'Entrega' }
    & Pick<Entrega, 'id'>
  )> }
);

export type CreateCorredorExternoMutationVariables = Exact<{
  documento: Scalars['String'];
  nombre: Scalars['String'];
  apellido: Scalars['String'];
  numero_corredor: Scalars['String'];
  celular: Scalars['String'];
  direccion: Scalars['String'];
  umn_id: Scalars['String'];
}>;


export type CreateCorredorExternoMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createCorredorExterno'>
);

export type CreateNumerosLimitadosMutationVariables = Exact<{
  numerosLimitados: NumerosLimitadosInput;
}>;


export type CreateNumerosLimitadosMutation = (
  { __typename?: 'Mutation' }
  & { createNumerosLimitados?: Maybe<(
    { __typename?: 'NumerosLimitados' }
    & Pick<NumerosLimitados, 'id' | 'fecha' | 'agencia' | 'numeros'>
  )> }
);

export type CreateRegimenWuMutationVariables = Exact<{
  input: CreateRegimerWuInput;
}>;


export type CreateRegimenWuMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createRegimenWu'>
);

export type CreateSorteoEspecialMutationVariables = Exact<{
  resultados: Array<Scalars['String']> | Scalars['String'];
  sorteo_base_id: Scalars['ID'];
  fecha: Scalars['Date'];
}>;


export type CreateSorteoEspecialMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createSorteoEspecial'>
);

export type CreateUserWuFormMutationVariables = Exact<{
  doc_type: Scalars['String'];
  ci: Scalars['String'];
  phone_number: Scalars['String'];
  country_code_phone: Scalars['String'];
  names: Scalars['String'];
  last_names: Scalars['String'];
  middle_name: Scalars['String'];
  birth_date: Scalars['String'];
  residence_country: Scalars['String'];
  departamento: Scalars['String'];
  city: Scalars['String'];
  adress: Scalars['String'];
  doc_emision: Scalars['String'];
  doc_vencimiento: Scalars['String'];
  front_doc_img: Scalars['String'];
  back_doc_img: Scalars['String'];
  selfie_img: Scalars['String'];
  country_birth: Scalars['String'];
  paisDoc: Scalars['String'];
  source_funds: Scalars['String'];
  position_level: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  occupation: Scalars['String'];
  transaction_reason: Scalars['String'];
  pep?: Maybe<Scalars['String']>;
  cpep?: Maybe<Scalars['String']>;
  pagoAgil?: Maybe<Scalars['Boolean']>;
  reference: Scalars['String'];
}>;


export type CreateUserWuFormMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createUserWuForm'>
);

export type DeleteAnulacionExtractoMutationVariables = Exact<{
  extracto_id: Scalars['ID'];
}>;


export type DeleteAnulacionExtractoMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'borrarExtractoAnulacion'>
);

export type DeleteCorredorMutationVariables = Exact<{
  usuario_id: Scalars['Int'];
}>;


export type DeleteCorredorMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteCorredor'>
);

export type DeshabilitarCorredorMutationVariables = Exact<{
  corredor_id: Scalars['ID'];
}>;


export type DeshabilitarCorredorMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deshabilitarCorredor'>
);

export type EditarDocCorredorMutationVariables = Exact<{
  ci?: Maybe<Scalars['String']>;
  usuario_id?: Maybe<Scalars['Int']>;
}>;


export type EditarDocCorredorMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'editarCi'>
);

export type ChangeDataUmnMutationVariables = Exact<{
  umn_id?: Maybe<Array<Scalars['ID']> | Scalars['ID']>;
  nombre?: Maybe<Scalars['String']>;
  numero?: Maybe<Scalars['Int']>;
}>;


export type ChangeDataUmnMutation = (
  { __typename?: 'Mutation' }
  & { changeDataUMN?: Maybe<(
    { __typename?: 'Umn' }
    & Pick<Umn, 'id' | 'nombre' | 'numero' | 'agencia_numero'>
  )> }
);

export type EditarEntregaDeExtractoMutationVariables = Exact<{
  extracto_id: Scalars['ID'];
  monto: Scalars['Int'];
}>;


export type EditarEntregaDeExtractoMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'editarEntregaDeExtracto'>
);

export type EditarNumeroCorredorMutationVariables = Exact<{
  corredor_id?: Maybe<Scalars['Int']>;
  numero?: Maybe<Scalars['Int']>;
}>;


export type EditarNumeroCorredorMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'editarNumeroCorredor'>
);

export type EditarNumeroTelefonoCorredorMutationVariables = Exact<{
  corredor_id?: Maybe<Scalars['Int']>;
  numero: Scalars['String'];
}>;


export type EditarNumeroTelefonoCorredorMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'editarNumeroTelefonoCorredor'>
);

export type EventoCajasMutationVariables = Exact<{
  images?: Maybe<Array<Maybe<Scalars['FileUpload']>> | Maybe<Scalars['FileUpload']>>;
  motivo: Scalars['String'];
  terminalId: Scalars['ID'];
  cajaId: Scalars['ID'];
  corredorId: Scalars['ID'];
  estadoPos: Scalars['Int'];
}>;


export type EventoCajasMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'eventoCajas'>
);

export type GenerarAjusteMutationVariables = Exact<{
  referencia: Scalars['String'];
  corredor_id: Scalars['Int'];
  monto: Scalars['Int'];
  detalle: Scalars['String'];
}>;


export type GenerarAjusteMutation = (
  { __typename?: 'Mutation' }
  & { generarAjuste?: Maybe<(
    { __typename?: 'Ajuste' }
    & Pick<Ajuste, 'id' | 'estado' | 'created_at' | 'monto'>
    & { Corredor?: Maybe<(
      { __typename?: 'Corredor' }
      & Pick<Corredor, 'id' | 'numero' | 'cuenta' | 'linea'>
    )> }
  )> }
);

export type GenerarAjusteUmnMutationVariables = Exact<{
  umn_id: Scalars['Int'];
  monto: Scalars['Int'];
  detalle: Scalars['String'];
  referencia: Scalars['String'];
  tipo: Scalars['String'];
}>;


export type GenerarAjusteUmnMutation = (
  { __typename?: 'Mutation' }
  & { generarAjusteUmn?: Maybe<(
    { __typename?: 'AjusteUmn' }
    & Pick<AjusteUmn, 'id' | 'monto' | 'detalle'>
    & { aprobacion?: Maybe<(
      { __typename?: 'Usuario' }
      & Pick<Usuario, 'id' | 'username'>
    )>, Umn?: Maybe<(
      { __typename?: 'Umn' }
      & Pick<Umn, 'id' | 'numero' | 'nombre'>
    )> }
  )> }
);

export type GenerarCobroMutationVariables = Exact<{
  cobrador_id: Scalars['Int'];
  corredor_id: Scalars['Int'];
  referencia: Scalars['String'];
  monto: Scalars['Int'];
}>;


export type GenerarCobroMutation = (
  { __typename?: 'Mutation' }
  & { generarCobro?: Maybe<(
    { __typename?: 'Entrega' }
    & Pick<Entrega, 'id'>
  )> }
);

export type GenerarDepositoMutationVariables = Exact<{
  medio?: Maybe<Scalars['String']>;
  referencia: Scalars['String'];
  umn_id: Scalars['ID'];
  detalle?: Maybe<Scalars['String']>;
  monto?: Maybe<Scalars['Int']>;
  nro_recibo?: Maybe<Scalars['Int']>;
  fecha?: Maybe<Scalars['Date']>;
}>;


export type GenerarDepositoMutation = (
  { __typename?: 'Mutation' }
  & { generarDeposito?: Maybe<(
    { __typename?: 'Deposito' }
    & Pick<Deposito, 'id' | 'monto' | 'medio'>
  )> }
);

export type GenerarSolicitudDepositoMutationVariables = Exact<{
  comprobante?: Maybe<Scalars['String']>;
  banco?: Maybe<Scalars['String']>;
  monto?: Maybe<Scalars['Int']>;
  umn_id?: Maybe<Scalars['ID']>;
}>;


export type GenerarSolicitudDepositoMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'solicitudDeposito'>
);

export type GenerateWuTransactionRequestMutationVariables = Exact<{
  mtcn: Scalars['String'];
  reference: Scalars['String'];
}>;


export type GenerateWuTransactionRequestMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'generateWUTransactionRequest'>
);

export type GenerateWuTransaccionMutationVariables = Exact<{
  client_id: Scalars['String'];
  reference: Scalars['String'];
  motivo_transaccion: Scalars['String'];
}>;


export type GenerateWuTransaccionMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'generateWuTransaccion'>
);

export type GetComisionesExcelMutationVariables = Exact<{
  fecha__gte?: Maybe<Scalars['String']>;
  fecha__lte?: Maybe<Scalars['String']>;
  agencia_numero?: Maybe<Scalars['String']>;
}>;


export type GetComisionesExcelMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'getComisionesExcel'>
);

export type GetTicketMutationVariables = Exact<{
  reference: Scalars['String'];
}>;


export type GetTicketMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'getTicket'>
);

export type InhabilitarMutationVariables = Exact<{
  corredor_id: Scalars['Int'];
}>;


export type InhabilitarMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'inhabilitarCorredor'>
);

export type LiquidarMutationVariables = Exact<{
  sorteo_id: Scalars['ID'];
}>;


export type LiquidarMutation = (
  { __typename?: 'Mutation' }
  & { liquidarSorteo?: Maybe<(
    { __typename?: 'Sorteo' }
    & Pick<Sorteo, 'fecha'>
  )> }
);

export type ModificarEstadoCorredorMutationVariables = Exact<{
  corredor_id: Scalars['Int'];
}>;


export type ModificarEstadoCorredorMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'modificarEstadoCorredor'>
);

export type ModificarLineaMutationVariables = Exact<{
  corredor_id: Scalars['Int'];
  monto: Scalars['Int'];
  soloCaja?: Maybe<Scalars['Boolean']>;
}>;


export type ModificarLineaMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'modificarLinea'>
);

export type OperativaMutationVariables = Exact<{
  sorteo_id: Scalars['ID'];
  resultados: Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>;
}>;


export type OperativaMutation = (
  { __typename?: 'Mutation' }
  & { setResultadoSorteo?: Maybe<(
    { __typename?: 'Sorteo' }
    & Pick<Sorteo, 'resultados'>
  )> }
);

export type PagarCuotasTeeteAsgardMutationVariables = Exact<{
  banco?: Maybe<Scalars['String']>;
  comprobante?: Maybe<Scalars['String']>;
  monto_total?: Maybe<Scalars['Int']>;
  cuotas?: Maybe<Array<Maybe<Scalars['JSON']>> | Maybe<Scalars['JSON']>>;
}>;


export type PagarCuotasTeeteAsgardMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'pagarCuotasTeeteAsgard'>
);

export type PremiarBoletasByCodeMutationVariables = Exact<{
  umn_id: Scalars['ID'];
  code: Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>;
}>;


export type PremiarBoletasByCodeMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'premiarBoletasByCodes'>
);

export type RecalcularExtractoDesdeCierreCajaMutationVariables = Exact<{
  extracto_id: Scalars['ID'];
}>;


export type RecalcularExtractoDesdeCierreCajaMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'recalcularExtractoDesdeCierreCaja'>
);

export type ReconsolidarMutationVariables = Exact<{
  desde?: Maybe<Scalars['Date']>;
  hasta?: Maybe<Scalars['Date']>;
  umn_id?: Maybe<Array<Maybe<Scalars['ID']>> | Maybe<Scalars['ID']>>;
}>;


export type ReconsolidarMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'reconsolidar'>
);

export type VerificarPremioMegalotoMutationVariables = Exact<{
  pedidoId: Scalars['ID'];
  umnId: Scalars['ID'];
}>;


export type VerificarPremioMegalotoMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'verificarPremioMegaloto'>
);

export type RejectAnulacionPremioPagadoMutationVariables = Exact<{
  premio_pagado_id: Scalars['ID'];
}>;


export type RejectAnulacionPremioPagadoMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'rejectAnulacionPremioPagado'>
);

export type RevertEventoCajaMutationVariables = Exact<{
  terminalId: Scalars['ID'];
}>;


export type RevertEventoCajaMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'revertEventoCaja'>
);

export type SolicitarAnulacionPemiosMutationVariables = Exact<{
  premio_pagado_id: Scalars['ID'];
}>;


export type SolicitarAnulacionPemiosMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'solicitudAnulacionPremioPagado'>
);

export type SolicitudAnulacionMutationVariables = Exact<{
  juego_id?: Maybe<Scalars['ID']>;
  motivo_id?: Maybe<Scalars['Int']>;
}>;


export type SolicitudAnulacionMutation = (
  { __typename?: 'Mutation' }
  & { solicitudAnulacion?: Maybe<(
    { __typename?: 'Anulacion' }
    & Pick<Anulacion, 'id' | 'nivel' | 'estado'>
  )> }
);

export type TransaccionRaspaditasMutationVariables = Exact<{
  corredor_id: Scalars['ID'];
  umn_id: Scalars['ID'];
  boletas?: Maybe<Array<Maybe<BoletaInput>> | Maybe<BoletaInput>>;
}>;


export type TransaccionRaspaditasMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'raspaditaAsignacionCorredor'>
);

export type DevolucionRaspaditaMutationVariables = Exact<{
  corredor_id: Scalars['ID'];
  umn_id: Scalars['ID'];
  boletas?: Maybe<Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>>;
}>;


export type DevolucionRaspaditaMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'raspaditaDevolucionDeBoleta'>
);

export type UpdateEntregaMutationVariables = Exact<{
  entrega_id: Scalars['ID'];
  monto: Scalars['Int'];
}>;


export type UpdateEntregaMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateEntrega'>
);

export type UpdateRastreoMutationVariables = Exact<{
  serial: Scalars['String'];
  estado_id: Scalars['Int'];
  umn_id?: Maybe<Array<Maybe<Scalars['Int']>> | Maybe<Scalars['Int']>>;
}>;


export type UpdateRastreoMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateRastreo'>
);

export type UpdateStateTerminalMutationVariables = Exact<{
  terminal_id: Scalars['String'];
  estado_id: Scalars['String'];
  estado_anterior: Scalars['String'];
}>;


export type UpdateStateTerminalMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateEstadoTerminal'>
);

export type VerifyUserMutationVariables = Exact<{
  doc_type_enum: Scalars['String'];
  ci: Scalars['String'];
  country_code_phone: Scalars['String'];
  phone_number: Scalars['String'];
  pago_agil?: Maybe<Scalars['Boolean']>;
  reference: Scalars['String'];
}>;


export type VerifyUserMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'verifyUser'>
);

export type ListStockTerminalQueryVariables = Exact<{
  desde: Scalars['Date'];
  hasta?: Maybe<Scalars['Date']>;
  umn_id?: Maybe<Array<Scalars['ID']> | Scalars['ID']>;
  serial?: Maybe<Scalars['String']>;
  nro_corredor?: Maybe<Scalars['String']>;
  estado?: Maybe<Scalars['String']>;
}>;


export type ListStockTerminalQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'stockTerminales'>
);

export type ListStockTerminal2QueryVariables = Exact<{
  desde: Scalars['Date'];
  hasta?: Maybe<Scalars['Date']>;
  umn_id?: Maybe<Array<Scalars['ID']> | Scalars['ID']>;
  serial?: Maybe<Scalars['String']>;
  nro_corredor?: Maybe<Scalars['String']>;
  estado?: Maybe<Scalars['String']>;
}>;


export type ListStockTerminal2Query = (
  { __typename?: 'Query' }
  & Pick<Query, 'stockTerminales2'>
);

export type ListStockTerminal3QueryVariables = Exact<{
  desde: Scalars['Date'];
  hasta?: Maybe<Scalars['Date']>;
  umn_id?: Maybe<Array<Scalars['ID']> | Scalars['ID']>;
  serial?: Maybe<Scalars['String']>;
  nro_corredor?: Maybe<Scalars['String']>;
  estado?: Maybe<Scalars['String']>;
}>;


export type ListStockTerminal3Query = (
  { __typename?: 'Query' }
  & Pick<Query, 'stockTerminal3'>
);

export type TombolaNumerosQueryVariables = Exact<{
  desde: Scalars['Date'];
  hasta?: Maybe<Scalars['Date']>;
  sorteo?: Maybe<Array<Maybe<Scalars['ID']>> | Maybe<Scalars['ID']>>;
  postura?: Maybe<Scalars['Int']>;
}>;


export type TombolaNumerosQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'tombolaNumeros'>
);

export type ProductoAgrupadosCorredorQueryVariables = Exact<{
  corredor_id: Scalars['Int'];
  desde: Scalars['Date'];
  hasta?: Maybe<Scalars['Date']>;
}>;


export type ProductoAgrupadosCorredorQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'transaccionesAgrupadasCorredor'>
);

export type AllPrizesAgencieQueryVariables = Exact<{
  desde?: Maybe<Scalars['Date']>;
  hasta?: Maybe<Scalars['Date']>;
  umn_id?: Maybe<Array<Scalars['ID']> | Scalars['ID']>;
}>;


export type AllPrizesAgencieQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'allPrizes'>
);

export type BilletajeResultQueryVariables = Exact<{
  smp?: Maybe<Scalars['Float']>;
  consecutivo?: Maybe<Scalars['Float']>;
  serie?: Maybe<Scalars['String']>;
  ticket?: Maybe<Scalars['Float']>;
  monto?: Maybe<Scalars['Int']>;
  nro_corredor?: Maybe<Scalars['Int']>;
  umn_id?: Maybe<Array<Scalars['ID']> | Scalars['ID']>;
  desde: Scalars['Date'];
  hasta?: Maybe<Scalars['Date']>;
  retrasadas?: Maybe<Scalars['Boolean']>;
}>;


export type BilletajeResultQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'billetajeResult'>
);

export type BoletasPorMontosQueryVariables = Exact<{
  desde: Scalars['Date'];
  hasta?: Maybe<Scalars['Date']>;
  agencia?: Maybe<Array<Maybe<Scalars['ID']>> | Maybe<Scalars['ID']>>;
  sorteo?: Maybe<Array<Maybe<Scalars['ID']>> | Maybe<Scalars['ID']>>;
}>;


export type BoletasPorMontosQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'boletasPorMontos'>
);

export type BolilleroQueryVariables = Exact<{
  desde: Scalars['Date'];
  hasta?: Maybe<Scalars['Date']>;
  sorteo?: Maybe<Array<Maybe<Scalars['ID']>> | Maybe<Scalars['ID']>>;
}>;


export type BolilleroQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'bolillero'>
);

export type CajasAbiertasQueryQueryVariables = Exact<{
  umn_id?: Maybe<Array<Scalars['ID']> | Scalars['ID']>;
}>;


export type CajasAbiertasQueryQuery = (
  { __typename?: 'Query' }
  & { cajasAbiertas?: Maybe<Array<Maybe<(
    { __typename?: 'ResumenCajaCorredor' }
    & Pick<ResumenCajaCorredor, 'id' | 'apertura' | 'cantidad' | 'total' | 'totalComisionA' | 'totalComisionC'>
    & { Corredor?: Maybe<(
      { __typename?: 'Corredor' }
      & Pick<Corredor, 'id' | 'numero'>
      & { Usuario?: Maybe<(
        { __typename?: 'Usuario' }
        & { Person?: Maybe<(
          { __typename?: 'Person' }
          & Pick<Person, 'nombre' | 'apellido'>
        )> }
      )>, Umn?: Maybe<(
        { __typename?: 'Umn' }
        & Pick<Umn, 'numero' | 'agencia_numero'>
      )> }
    )> }
  )>>> }
);

export type ReportePagopremiosQueryVariables = Exact<{
  desde: Scalars['Date'];
  hasta?: Maybe<Scalars['Date']>;
  agencia?: Maybe<Array<Maybe<Scalars['ID']>> | Maybe<Scalars['ID']>>;
  sorteo_id?: Maybe<Array<Maybe<Scalars['ID']>> | Maybe<Scalars['ID']>>;
}>;


export type ReportePagopremiosQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'reportePagoPremios'>
);

export type ReporteFacturacionAgenciaQueryVariables = Exact<{
  desde: Scalars['Date'];
  hasta?: Maybe<Scalars['Date']>;
  agencia?: Maybe<Array<Maybe<Scalars['ID']>> | Maybe<Scalars['ID']>>;
  sorteo_id?: Maybe<Array<Maybe<Scalars['ID']>> | Maybe<Scalars['ID']>>;
}>;


export type ReporteFacturacionAgenciaQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'reporteFacturacionAgencia'>
);

export type ReporteDeSistemaQueryVariables = Exact<{
  desde: Scalars['Date'];
  hasta?: Maybe<Scalars['Date']>;
  agencia?: Maybe<Array<Maybe<Scalars['ID']>> | Maybe<Scalars['ID']>>;
  sorteo_id?: Maybe<Array<Maybe<Scalars['ID']>> | Maybe<Scalars['ID']>>;
}>;


export type ReporteDeSistemaQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'reporteDeSistema'>
);

export type ReporteVentasPorAgenciaQueryVariables = Exact<{
  desde: Scalars['Date'];
  hasta?: Maybe<Scalars['Date']>;
  agencia?: Maybe<Array<Maybe<Scalars['ID']>> | Maybe<Scalars['ID']>>;
  sorteo_id?: Maybe<Array<Maybe<Scalars['ID']>> | Maybe<Scalars['ID']>>;
}>;


export type ReporteVentasPorAgenciaQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'reporteVentasPorAgencia'>
);

export type ReporteGanadoresPorAgenciaQueryVariables = Exact<{
  desde: Scalars['Date'];
  hasta?: Maybe<Scalars['Date']>;
  agencia?: Maybe<Array<Maybe<Scalars['Int']>> | Maybe<Scalars['Int']>>;
  juego_id?: Maybe<Scalars['Int']>;
  corredor?: Maybe<Scalars['Boolean']>;
}>;


export type ReporteGanadoresPorAgenciaQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'reporteGanadoresPorAgencia'>
);

export type ReporteVentasRaspaditaQueryVariables = Exact<{
  desde: Scalars['Date'];
  hasta?: Maybe<Scalars['Date']>;
  agencia?: Maybe<Array<Maybe<Scalars['Int']>> | Maybe<Scalars['Int']>>;
  juego_id?: Maybe<Scalars['Int']>;
  corredor?: Maybe<Scalars['Boolean']>;
}>;


export type ReporteVentasRaspaditaQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'reporteVentasRaspadita'>
);

export type ReporteVentasPorTerminalQueryVariables = Exact<{
  desde: Scalars['Date'];
  hasta?: Maybe<Scalars['Date']>;
  umn_id?: Maybe<Array<Maybe<Scalars['ID']>> | Maybe<Scalars['ID']>>;
  sorteo_id?: Maybe<Array<Maybe<Scalars['ID']>> | Maybe<Scalars['ID']>>;
}>;


export type ReporteVentasPorTerminalQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'reporteVentasPorTerminal'>
);

export type ReporteMediosElectronicosQueryVariables = Exact<{
  desde: Scalars['Date'];
  hasta?: Maybe<Scalars['Date']>;
  umn_id?: Maybe<Array<Maybe<Scalars['ID']>> | Maybe<Scalars['ID']>>;
  sorteo_id?: Maybe<Array<Maybe<Scalars['ID']>> | Maybe<Scalars['ID']>>;
}>;


export type ReporteMediosElectronicosQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'reporteMediosElectronicos'>
);

export type ReporteVentasElectronicasQueryVariables = Exact<{
  desde: Scalars['Date'];
  hasta?: Maybe<Scalars['Date']>;
  umn_id?: Maybe<Array<Maybe<Scalars['ID']>> | Maybe<Scalars['ID']>>;
  sorteo_id?: Maybe<Array<Maybe<Scalars['ID']>> | Maybe<Scalars['ID']>>;
}>;


export type ReporteVentasElectronicasQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'reporteVentasElectronicas'>
);

export type UptimeServerQueryVariables = Exact<{ [key: string]: never; }>;


export type UptimeServerQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'uptimeServer'>
);

export type ReporteAnulacionesQueryVariables = Exact<{
  desde: Scalars['Date'];
  hasta?: Maybe<Scalars['Date']>;
  umn_id?: Maybe<Array<Maybe<Scalars['ID']>> | Maybe<Scalars['ID']>>;
  sorteo_id?: Maybe<Array<Maybe<Scalars['ID']>> | Maybe<Scalars['ID']>>;
}>;


export type ReporteAnulacionesQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'reporteAnulaciones'>
);

export type ReporteHearbeatQueryVariables = Exact<{
  desde: Scalars['Date'];
  hasta?: Maybe<Scalars['Date']>;
  umn_id?: Maybe<Array<Maybe<Scalars['ID']>> | Maybe<Scalars['ID']>>;
  sorteo_id?: Maybe<Array<Maybe<Scalars['ID']>> | Maybe<Scalars['ID']>>;
}>;


export type ReporteHearbeatQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'reporteHearbeat'>
);

export type LoginFailedQueryVariables = Exact<{
  desde: Scalars['Date'];
  hasta?: Maybe<Scalars['Date']>;
}>;


export type LoginFailedQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'loginFailed'>
);

export type AccesoRemotoQueryVariables = Exact<{
  desde: Scalars['Date'];
  hasta?: Maybe<Scalars['Date']>;
}>;


export type AccesoRemotoQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'accesoRemoto'>
);

export type DashCorredorFechasQueryVariables = Exact<{
  corredor_id: Scalars['ID'];
}>;


export type DashCorredorFechasQuery = (
  { __typename?: 'Query' }
  & { corredorFechas?: Maybe<Array<Maybe<(
    { __typename?: 'CajaFecha' }
    & Pick<CajaFecha, 'fecha' | 'cantidad' | 'total' | 'totalComisionA' | 'totalComisionC'>
  )>>> }
);

export type DashCorredorFechasRetrasadasQueryVariables = Exact<{
  corredor_id: Scalars['ID'];
}>;


export type DashCorredorFechasRetrasadasQuery = (
  { __typename?: 'Query' }
  & { corredorFechasRetrasadas?: Maybe<Array<Maybe<(
    { __typename?: 'CajaFecha' }
    & Pick<CajaFecha, 'fecha' | 'cantidad' | 'total' | 'totalComisionA' | 'totalComisionC'>
  )>>> }
);

export type DashDetallesFechaQueryVariables = Exact<{
  corredor_id: Scalars['ID'];
  fecha?: Maybe<Scalars['Date']>;
}>;


export type DashDetallesFechaQuery = (
  { __typename?: 'Query' }
  & { corredorTransaccionesFecha?: Maybe<Array<Maybe<(
    { __typename?: 'Transaccion' }
    & Pick<Transaccion, 'id' | 'generado' | 'monto' | 'comision'>
    & { Producto?: Maybe<(
      { __typename?: 'Producto' }
      & Pick<Producto, 'nombre'>
    )>, Caja?: Maybe<(
      { __typename?: 'Caja' }
      & Pick<Caja, 'id'>
    )> }
  )>>>, corredorPedidosFecha?: Maybe<Array<Maybe<(
    { __typename?: 'Pedido' }
    & Pick<Pedido, 'id' | 'created_at' | 'estado' | 'monto' | 'metadata' | 'resultado'>
    & { Producto?: Maybe<(
      { __typename?: 'Producto' }
      & Pick<Producto, 'nombre' | 'identificador'>
    )>, Transaccion?: Maybe<(
      { __typename?: 'Transaccion' }
      & Pick<Transaccion, 'generado' | 'monto'>
    )> }
  )>>> }
);

export type DashCorredorProductosPedidosQueryVariables = Exact<{
  corredor_id: Scalars['ID'];
  desde?: Maybe<Scalars['Date']>;
  hasta?: Maybe<Scalars['Date']>;
  producto_id?: Maybe<Scalars['ID']>;
  juego_id?: Maybe<Array<Maybe<Scalars['Int']>> | Maybe<Scalars['Int']>>;
}>;


export type DashCorredorProductosPedidosQuery = (
  { __typename?: 'Query' }
  & { corredorProductosPedidos?: Maybe<Array<Maybe<(
    { __typename?: 'Pedido' }
    & Pick<Pedido, 'id' | 'created_at' | 'estado' | 'monto' | 'metadata' | 'resultado'>
    & { Producto?: Maybe<(
      { __typename?: 'Producto' }
      & Pick<Producto, 'nombre' | 'identificador'>
    )>, Transaccion?: Maybe<(
      { __typename?: 'Transaccion' }
      & Pick<Transaccion, 'generado' | 'monto'>
    )> }
  )>>> }
);

export type DashCobradorQueryVariables = Exact<{
  cobrador_id: Scalars['ID'];
}>;


export type DashCobradorQuery = (
  { __typename?: 'Query' }
  & { cobrador?: Maybe<(
    { __typename?: 'Cobrador' }
    & Pick<Cobrador, 'id' | 'cuenta' | 'linea'>
    & { Umn?: Maybe<(
      { __typename?: 'Umn' }
      & Pick<Umn, 'numero' | 'agencia_numero'>
    )>, Usuario?: Maybe<(
      { __typename?: 'Usuario' }
      & { Person?: Maybe<(
        { __typename?: 'Person' }
        & Pick<Person, 'nombre' | 'apellido' | 'nrodoc' | 'telefono'>
      )> }
    )> }
  )>, cobradorFechas?: Maybe<Array<Maybe<(
    { __typename?: 'CajaFecha' }
    & Pick<CajaFecha, 'fecha' | 'total'>
  )>>>, cobradorExtracto?: Maybe<Array<Maybe<(
    { __typename?: 'Extracto' }
    & Pick<Extracto, 'id' | 'created_at' | 'detalle' | 'monto' | 'cuenta' | 'cuenta_anterior'>
  )>>> }
);

export type DashCobradorFechaQueryVariables = Exact<{
  cobrador_id: Scalars['ID'];
  fecha: Scalars['Date'];
}>;


export type DashCobradorFechaQuery = (
  { __typename?: 'Query' }
  & { cobradorFecha?: Maybe<Array<Maybe<(
    { __typename?: 'Entrega' }
    & Pick<Entrega, 'id' | 'created_at' | 'estado'>
    & { Corredor?: Maybe<(
      { __typename?: 'Corredor' }
      & Pick<Corredor, 'numero' | 'cuenta' | 'linea'>
      & { Usuario?: Maybe<(
        { __typename?: 'Usuario' }
        & { Person?: Maybe<(
          { __typename?: 'Person' }
          & Pick<Person, 'nombre' | 'nrodoc' | 'telefono'>
        )> }
      )> }
    )> }
  )>>> }
);

export type DashGerenciaQueryVariables = Exact<{
  desde: Scalars['Date'];
  hasta?: Maybe<Scalars['Date']>;
  agencia?: Maybe<Array<Maybe<Scalars['Int']>> | Maybe<Scalars['Int']>>;
  sorteo?: Maybe<Array<Maybe<Scalars['Int']>> | Maybe<Scalars['Int']>>;
}>;


export type DashGerenciaQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'gerenciaDetalleQuiniela'>
);

export type DashGerenciaSorteoQueryVariables = Exact<{
  desde: Scalars['Date'];
  hasta?: Maybe<Scalars['Date']>;
  sorteo?: Maybe<Array<Maybe<Scalars['Int']>> | Maybe<Scalars['Int']>>;
  agencia?: Maybe<Array<Maybe<Scalars['Int']>> | Maybe<Scalars['Int']>>;
  corredor_numero?: Maybe<Scalars['Int']>;
}>;


export type DashGerenciaSorteoQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'gerenciaResumenQuinielaSorteo'>
);

export type DashGerenciaAgenciaQueryVariables = Exact<{
  desde: Scalars['Date'];
  hasta?: Maybe<Scalars['Date']>;
  agencia?: Maybe<Array<Maybe<Scalars['Int']>> | Maybe<Scalars['Int']>>;
  sorteo?: Maybe<Array<Maybe<Scalars['Int']>> | Maybe<Scalars['Int']>>;
  corredor_numero?: Maybe<Scalars['Int']>;
}>;


export type DashGerenciaAgenciaQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'gerenciaResumenQuinielaAgencia'>
);

export type DashGerenciaAgrupadoQueryVariables = Exact<{
  fechaFrom: Scalars['Date'];
  fechaTo: Scalars['Date'];
  agencia?: Maybe<Array<Maybe<Scalars['Int']>> | Maybe<Scalars['Int']>>;
  sorteo?: Maybe<Array<Maybe<Scalars['Int']>> | Maybe<Scalars['Int']>>;
  corredor_numero?: Maybe<Scalars['Int']>;
}>;


export type DashGerenciaAgrupadoQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'gerenciaResumenQuinielaFecha'>
);

export type DashGerenciaAgenciaProductoQueryVariables = Exact<{
  desde: Scalars['Date'];
  hasta?: Maybe<Scalars['Date']>;
  agencia?: Maybe<Array<Maybe<Scalars['Int']>> | Maybe<Scalars['Int']>>;
  corredor_numero?: Maybe<Scalars['Int']>;
  detail?: Maybe<Scalars['Boolean']>;
  bycorredor?: Maybe<Scalars['Boolean']>;
  umn_id?: Maybe<Scalars['Int']>;
  producto_id?: Maybe<Scalars['ID']>;
}>;


export type DashGerenciaAgenciaProductoQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'gerenciaResumenQuinielaAgenciaProducto'>
);

export type DashboardAnulacionQueryVariables = Exact<{
  umn_id?: Maybe<Array<Scalars['ID']> | Scalars['ID']>;
  sorteo_base_id?: Maybe<Array<Scalars['ID']> | Scalars['ID']>;
  estado?: Maybe<Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>>;
  desde: Scalars['Date'];
  hasta?: Maybe<Scalars['Date']>;
  nro_corredor?: Maybe<Scalars['Int']>;
  nrodoc?: Maybe<Scalars['String']>;
}>;


export type DashboardAnulacionQuery = (
  { __typename?: 'Query' }
  & { anulaciones?: Maybe<Array<Maybe<(
    { __typename?: 'Anulacion' }
    & Pick<Anulacion, 'id' | 'estado' | 'nivel' | 'elevacion' | 'created_at' | 'excedido'>
    & { AnulacionMotivo?: Maybe<(
      { __typename?: 'Motivo' }
      & Pick<Motivo, 'descripcion'>
    )>, AprobadoPor?: Maybe<(
      { __typename?: 'Usuario' }
      & Pick<Usuario, 'id'>
      & { Person?: Maybe<(
        { __typename?: 'Person' }
        & Pick<Person, 'nombre' | 'apellido'>
      )> }
    )>, Juego?: Maybe<(
      { __typename?: 'TicketQuiniela' }
      & Pick<TicketQuiniela, 'serie' | 'ticket' | 'monto_apostado'>
      & { Sorteo?: Maybe<(
        { __typename?: 'Sorteo' }
        & Pick<Sorteo, 'fecha' | 'cierre'>
      )>, Transaccion?: Maybe<(
        { __typename?: 'Transaccion' }
        & { Caja?: Maybe<(
          { __typename?: 'Caja' }
          & Pick<Caja, 'id'>
          & { Corredor?: Maybe<(
            { __typename?: 'Corredor' }
            & Pick<Corredor, 'numero' | 'id'>
            & { Usuario?: Maybe<(
              { __typename?: 'Usuario' }
              & { Person?: Maybe<(
                { __typename?: 'Person' }
                & Pick<Person, 'nombre' | 'apellido'>
              )> }
            )>, Umn?: Maybe<(
              { __typename?: 'Umn' }
              & Pick<Umn, 'id' | 'agencia_numero' | 'numero'>
            )> }
          )> }
        )> }
      )> }
    )> }
  )>>> }
);

export type DashboardCajaQueryVariables = Exact<{
  caja_id: Scalars['ID'];
}>;


export type DashboardCajaQuery = (
  { __typename?: 'Query' }
  & { caja?: Maybe<(
    { __typename?: 'Caja' }
    & Pick<Caja, 'id' | 'apertura'>
    & { Corredor?: Maybe<(
      { __typename?: 'Corredor' }
      & Pick<Corredor, 'id' | 'cuenta' | 'linea'>
      & { Umn?: Maybe<(
        { __typename?: 'Umn' }
        & Pick<Umn, 'numero' | 'agencia_numero'>
      )>, Usuario?: Maybe<(
        { __typename?: 'Usuario' }
        & { Person?: Maybe<(
          { __typename?: 'Person' }
          & Pick<Person, 'nombre' | 'apellido' | 'nrodoc' | 'telefono'>
        )> }
      )> }
    )> }
  )>, resumenCaja?: Maybe<(
    { __typename?: 'ResumenCaja' }
    & { TransaccionesAgrupadas?: Maybe<Array<Maybe<(
      { __typename?: 'GrupoTransacciones' }
      & Pick<GrupoTransacciones, 'id' | 'nombre' | 'cantidad' | 'total' | 'totalComisionA' | 'totalComisionC'>
    )>>> }
  )> }
);

export type DashboardCajaDetalleProductoQueryVariables = Exact<{
  caja_id: Scalars['ID'];
  producto_id: Scalars['Int'];
}>;


export type DashboardCajaDetalleProductoQuery = (
  { __typename?: 'Query' }
  & { producto?: Maybe<(
    { __typename?: 'Producto' }
    & Pick<Producto, 'id' | 'nombre'>
  )>, caja?: Maybe<(
    { __typename?: 'Caja' }
    & Pick<Caja, 'id' | 'apertura'>
    & { Corredor?: Maybe<(
      { __typename?: 'Corredor' }
      & Pick<Corredor, 'id'>
      & { Usuario?: Maybe<(
        { __typename?: 'Usuario' }
        & { Person?: Maybe<(
          { __typename?: 'Person' }
          & Pick<Person, 'nombre' | 'apellido'>
        )> }
      )>, Umn?: Maybe<(
        { __typename?: 'Umn' }
        & Pick<Umn, 'agencia_numero' | 'numero'>
      )> }
    )> }
  )>, detalleCajaProducto?: Maybe<(
    { __typename?: 'DetalleCaja' }
    & { Transacciones?: Maybe<Array<Maybe<(
      { __typename?: 'Transaccion' }
      & Pick<Transaccion, 'id' | 'created_at' | 'monto'>
    )>>>, Extractos?: Maybe<Array<Maybe<(
      { __typename?: 'Extracto' }
      & Pick<Extracto, 'id' | 'detalle' | 'monto' | 'cuenta' | 'cuenta_anterior'>
    )>>>, Pedidos?: Maybe<Array<Maybe<(
      { __typename?: 'Pedido' }
      & Pick<Pedido, 'monto' | 'estado' | 'resultado'>
    )>>> }
  )> }
);

export type DashboardCobradorQueryVariables = Exact<{
  umn_id?: Maybe<Array<Scalars['ID']> | Scalars['ID']>;
  estado?: Maybe<Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>>;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  desde: Scalars['Date'];
  hasta?: Maybe<Scalars['Date']>;
  nrodoc?: Maybe<Scalars['String']>;
  nro_corredor?: Maybe<Scalars['Int']>;
  cobrador_id?: Maybe<Scalars['Int']>;
}>;


export type DashboardCobradorQuery = (
  { __typename?: 'Query' }
  & { entregas?: Maybe<Array<Maybe<(
    { __typename?: 'Entrega' }
    & Pick<Entrega, 'id' | 'created_at' | 'updated_at' | 'monto' | 'estado'>
    & { Corredor?: Maybe<(
      { __typename?: 'Corredor' }
      & Pick<Corredor, 'id' | 'numero'>
      & { Usuario?: Maybe<(
        { __typename?: 'Usuario' }
        & { Person?: Maybe<(
          { __typename?: 'Person' }
          & Pick<Person, 'nombre' | 'apellido'>
        )> }
      )>, Umn?: Maybe<(
        { __typename?: 'Umn' }
        & Pick<Umn, 'numero' | 'agencia_numero'>
      )> }
    )>, Cobrador?: Maybe<(
      { __typename?: 'Cobrador' }
      & Pick<Cobrador, 'id'>
      & { Usuario?: Maybe<(
        { __typename?: 'Usuario' }
        & { Person?: Maybe<(
          { __typename?: 'Person' }
          & Pick<Person, 'nombre' | 'apellido'>
        )> }
      )>, Umn?: Maybe<(
        { __typename?: 'Umn' }
        & Pick<Umn, 'numero' | 'agencia_numero'>
      )> }
    )> }
  )>>>, umn?: Maybe<(
    { __typename?: 'Umn' }
    & Pick<Umn, 'numero' | 'totalCorredores'>
    & { Corredores?: Maybe<Array<Maybe<(
      { __typename?: 'Corredor' }
      & Pick<Corredor, 'numero' | 'id'>
      & { Usuario?: Maybe<(
        { __typename?: 'Usuario' }
        & Pick<Usuario, 'username'>
        & { Person?: Maybe<(
          { __typename?: 'Person' }
          & Pick<Person, 'nombre' | 'apellido'>
        )> }
      )> }
    )>>> }
  )> }
);

export type DashboardCorredorQueryVariables = Exact<{
  corredor_id: Scalars['ID'];
}>;


export type DashboardCorredorQuery = (
  { __typename?: 'Query' }
  & { corredorExtracto?: Maybe<Array<Maybe<(
    { __typename?: 'Extracto' }
    & Pick<Extracto, 'id' | 'created_at' | 'detalle' | 'monto' | 'cuenta' | 'cuenta_anterior' | 'fecha_referencia' | 'annexable_id' | 'serial'>
  )>>>, corredor?: Maybe<(
    { __typename?: 'Corredor' }
    & Pick<Corredor, 'id' | 'numero' | 'cuenta' | 'linea' | 'entrega_corredor' | 'activo'>
    & { Umn?: Maybe<(
      { __typename?: 'Umn' }
      & Pick<Umn, 'numero' | 'agencia_numero'>
    )>, Cajas?: Maybe<Array<Maybe<(
      { __typename?: 'Caja' }
      & Pick<Caja, 'linea' | 'utilizado' | 'estado'>
    )>>>, Usuario?: Maybe<(
      { __typename?: 'Usuario' }
      & { Person?: Maybe<(
        { __typename?: 'Person' }
        & Pick<Person, 'nombre' | 'apellido' | 'nrodoc' | 'telefono'>
      )> }
    )> }
  )> }
);

export type DashboardResumenUmnQueryVariables = Exact<{
  umn_id?: Maybe<Array<Maybe<Scalars['ID']>> | Maybe<Scalars['ID']>>;
  desde?: Maybe<Scalars['Date']>;
  hasta?: Maybe<Scalars['Date']>;
}>;


export type DashboardResumenUmnQuery = (
  { __typename?: 'Query' }
  & { resumenUmn?: Maybe<(
    { __typename?: 'ResumenUmn' }
    & Pick<ResumenUmn, 'TransaccionesAgrupadas' | 'quinielaMontos' | 'raspaditaMontos' | 'depositos' | 'saldoAnterior'>
  )> }
);

export type DashboardUmnQueryVariables = Exact<{ [key: string]: never; }>;


export type DashboardUmnQuery = (
  { __typename?: 'Query' }
  & { umns?: Maybe<Array<Maybe<(
    { __typename?: 'Umn' }
    & Pick<Umn, 'numero' | 'id' | 'nombre' | 'agencia_numero'>
    & { Corredores?: Maybe<Array<Maybe<(
      { __typename?: 'Corredor' }
      & Pick<Corredor, 'numero' | 'id'>
      & { Usuario?: Maybe<(
        { __typename?: 'Usuario' }
        & Pick<Usuario, 'username' | 'pin'>
      )> }
    )>>> }
  )>>> }
);

export type DepositoUmnQueryVariables = Exact<{
  desde: Scalars['Date'];
  hasta?: Maybe<Scalars['Date']>;
  umn_id?: Maybe<Array<Maybe<Scalars['ID']>> | Maybe<Scalars['ID']>>;
}>;


export type DepositoUmnQuery = (
  { __typename?: 'Query' }
  & { depositos?: Maybe<Array<Maybe<(
    { __typename?: 'Deposito' }
    & Pick<Deposito, 'id' | 'umn_id' | 'monto' | 'detalle' | 'medio' | 'nro_recibo' | 'created_at' | 'generado' | 'anulado'>
    & { Umn?: Maybe<(
      { __typename?: 'Umn' }
      & Pick<Umn, 'id' | 'numero' | 'nombre' | 'agencia_numero'>
    )> }
  )>>> }
);

export type DepositoAnuladoUmnQueryVariables = Exact<{
  desde: Scalars['Date'];
  hasta?: Maybe<Scalars['Date']>;
  umn_id?: Maybe<Array<Maybe<Scalars['ID']>> | Maybe<Scalars['ID']>>;
}>;


export type DepositoAnuladoUmnQuery = (
  { __typename?: 'Query' }
  & { depositosAnulados?: Maybe<Array<Maybe<(
    { __typename?: 'Deposito' }
    & Pick<Deposito, 'id' | 'umn_id' | 'monto' | 'detalle' | 'medio' | 'nro_recibo' | 'created_at' | 'generado' | 'anulado'>
    & { Umn?: Maybe<(
      { __typename?: 'Umn' }
      & Pick<Umn, 'id' | 'numero' | 'nombre' | 'agencia_numero'>
    )> }
  )>>> }
);

export type DetalleTerminalQueryVariables = Exact<{
  terminal_id: Scalars['ID'];
}>;


export type DetalleTerminalQuery = (
  { __typename?: 'Query' }
  & { terminal?: Maybe<(
    { __typename?: 'Terminal' }
    & Pick<Terminal, 'id' | 'serial' | 'version_asignada'>
  )>, heartbeats?: Maybe<Array<Maybe<(
    { __typename?: 'Heartbeat' }
    & Pick<Heartbeat, 'created_at' | 'data'>
  )>>>, usoTerminal?: Maybe<Array<Maybe<(
    { __typename?: 'Uso' }
    & Pick<Uso, 'id' | 'apertura' | 'cierre'>
    & { Corredor?: Maybe<(
      { __typename?: 'Corredor' }
      & Pick<Corredor, 'numero'>
      & { Umn?: Maybe<(
        { __typename?: 'Umn' }
        & Pick<Umn, 'numero' | 'agencia_numero'>
      )>, Usuario?: Maybe<(
        { __typename?: 'Usuario' }
        & { Person?: Maybe<(
          { __typename?: 'Person' }
          & Pick<Person, 'nombre' | 'apellido'>
        )> }
      )> }
    )> }
  )>>> }
);

export type DetalleTerminalSerialQueryVariables = Exact<{
  terminal_id: Scalars['ID'];
}>;


export type DetalleTerminalSerialQuery = (
  { __typename?: 'Query' }
  & { terminal?: Maybe<(
    { __typename?: 'Terminal' }
    & Pick<Terminal, 'id' | 'serial' | 'version_asignada'>
  )> }
);

export type PlanillaCobrosEntregaQueryVariables = Exact<{
  corredor_id: Scalars['ID'];
}>;


export type PlanillaCobrosEntregaQuery = (
  { __typename?: 'Query' }
  & { entregasCorredor?: Maybe<Array<Maybe<(
    { __typename?: 'Entrega' }
    & Pick<Entrega, 'id' | 'monto' | 'created_at'>
  )>>> }
);

export type UmnExtractoQueryVariables = Exact<{
  umn_id?: Maybe<Array<Scalars['ID']> | Scalars['ID']>;
}>;


export type UmnExtractoQuery = (
  { __typename?: 'Query' }
  & { umnExtracto?: Maybe<Array<Maybe<(
    { __typename?: 'ExtractoUmn' }
    & Pick<ExtractoUmn, 'id' | 'detalle' | 'created_at' | 'monto' | 'cuenta' | 'cuenta_anterior'>
    & { Umn?: Maybe<(
      { __typename?: 'Umn' }
      & Pick<Umn, 'numero' | 'id' | 'nombre'>
    )> }
  )>>> }
);

export type FindEntregaCorredorQueryVariables = Exact<{
  entrega_id: Scalars['ID'];
}>;


export type FindEntregaCorredorQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'findEntrega'>
);

export type DashGerenciaPremiosQueryVariables = Exact<{
  desde: Scalars['Date'];
  hasta?: Maybe<Scalars['Date']>;
  agencia?: Maybe<Array<Maybe<Scalars['Int']>> | Maybe<Scalars['Int']>>;
  sorteo?: Maybe<Array<Maybe<Scalars['Int']>> | Maybe<Scalars['Int']>>;
  corredor_numero?: Maybe<Scalars['Int']>;
  producto_id?: Maybe<Scalars['Int']>;
}>;


export type DashGerenciaPremiosQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'premiacionAgenciaGerencia'>
);

export type GerenciaResumenQuinielaUmnsQueryVariables = Exact<{
  desde: Scalars['Date'];
  hasta?: Maybe<Scalars['Date']>;
  agencia?: Maybe<Array<Maybe<Scalars['Int']>> | Maybe<Scalars['Int']>>;
  sorteo?: Maybe<Array<Maybe<Scalars['Int']>> | Maybe<Scalars['Int']>>;
  corredor_numero?: Maybe<Scalars['Int']>;
}>;


export type GerenciaResumenQuinielaUmnsQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'gerenciaResumenQuinielaUmns'>
);

export type AllAgenciasQueryVariables = Exact<{ [key: string]: never; }>;


export type AllAgenciasQuery = (
  { __typename?: 'Query' }
  & { allAgencies?: Maybe<Array<Maybe<(
    { __typename?: 'Agencias' }
    & Pick<Agencias, 'numero' | 'nombre' | 'created_at'>
  )>>> }
);

export type GetAjustesUmnQueryVariables = Exact<{
  desde: Scalars['Date'];
  hasta?: Maybe<Scalars['Date']>;
  umn_id?: Maybe<Array<Scalars['ID']> | Scalars['ID']>;
}>;


export type GetAjustesUmnQuery = (
  { __typename?: 'Query' }
  & { verAjustesUmn?: Maybe<Array<Maybe<(
    { __typename?: 'AjusteUmn' }
    & Pick<AjusteUmn, 'id' | 'estado' | 'created_at' | 'monto' | 'detalle' | 'tipo'>
    & { Umn?: Maybe<(
      { __typename?: 'Umn' }
      & Pick<Umn, 'id' | 'numero' | 'agencia_numero'>
    )>, aprobacion?: Maybe<(
      { __typename?: 'Usuario' }
      & Pick<Usuario, 'id' | 'username'>
    )> }
  )>>> }
);

export type GetAjustesQueryVariables = Exact<{
  desde: Scalars['Date'];
  hasta?: Maybe<Scalars['Date']>;
  nrodoc?: Maybe<Scalars['String']>;
  umn_id?: Maybe<Array<Scalars['ID']> | Scalars['ID']>;
  numero_corredor?: Maybe<Scalars['Int']>;
}>;


export type GetAjustesQuery = (
  { __typename?: 'Query' }
  & { verAjustes?: Maybe<Array<Maybe<(
    { __typename?: 'Ajuste' }
    & Pick<Ajuste, 'id' | 'estado' | 'detalle' | 'created_at'>
    & { aprobacion?: Maybe<(
      { __typename?: 'Usuario' }
      & Pick<Usuario, 'username' | 'id'>
    )>, Corredor?: Maybe<(
      { __typename?: 'Corredor' }
      & { Umn?: Maybe<(
        { __typename?: 'Umn' }
        & Pick<Umn, 'numero' | 'id' | 'agencia_numero'>
      )>, Usuario?: Maybe<(
        { __typename?: 'Usuario' }
        & { Person?: Maybe<(
          { __typename?: 'Person' }
          & Pick<Person, 'nrodoc' | 'nombre' | 'apellido'>
        )> }
      )> }
    )> }
  )>>> }
);

export type GetAllTerminalesUmnQueryVariables = Exact<{
  umn_id?: Maybe<Array<Scalars['ID']> | Scalars['ID']>;
}>;


export type GetAllTerminalesUmnQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'terminalesUmnAll'>
);

export type GetBoletasQueryVariables = Exact<{
  umn_id?: Maybe<Array<Scalars['ID']> | Scalars['ID']>;
  desde?: Maybe<Scalars['Date']>;
  hasta?: Maybe<Scalars['Date']>;
  numeros?: Maybe<Array<Maybe<Scalars['ID']>> | Maybe<Scalars['ID']>>;
  postura?: Maybe<Array<Maybe<Scalars['ID']>> | Maybe<Scalars['ID']>>;
  serie?: Maybe<Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>>;
  jugada_tipo?: Maybe<Array<Maybe<Scalars['ID']>> | Maybe<Scalars['ID']>>;
  sorteo_base_id?: Maybe<Array<Maybe<Scalars['ID']>> | Maybe<Scalars['ID']>>;
  corredor_numero?: Maybe<Array<Maybe<Scalars['ID']>> | Maybe<Scalars['ID']>>;
  liquidado?: Maybe<Scalars['Boolean']>;
  limit: Scalars['Int'];
  offset: Scalars['Int'];
}>;


export type GetBoletasQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'evaluarBoleta'>
);

export type CajasByCorredorQueryVariables = Exact<{
  corredor_id?: Maybe<Scalars['ID']>;
  estado?: Maybe<Scalars['String']>;
}>;


export type CajasByCorredorQuery = (
  { __typename?: 'Query' }
  & { getCajas?: Maybe<Array<Maybe<(
    { __typename?: 'Caja' }
    & Pick<Caja, 'id'>
    & { Terminal?: Maybe<(
      { __typename?: 'Terminal' }
      & Pick<Terminal, 'id'>
    )> }
  )>>> }
);

export type CajasAbiertasAgenciaQueryVariables = Exact<{
  desde: Scalars['Date'];
  hasta?: Maybe<Scalars['Date']>;
  agencia?: Maybe<Array<Maybe<Scalars['ID']>> | Maybe<Scalars['ID']>>;
}>;


export type CajasAbiertasAgenciaQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'cajasAbiertasAgencia'>
);

export type GetCobrosQueryVariables = Exact<{
  umn_id?: Maybe<Array<Scalars['ID']> | Scalars['ID']>;
  desde?: Maybe<Scalars['Date']>;
  hasta?: Maybe<Scalars['Date']>;
  corredor_id?: Maybe<Array<Maybe<Scalars['ID']>> | Maybe<Scalars['ID']>>;
}>;


export type GetCobrosQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'corredorCobros'>
);

export type GetcorredoresbyumnQueryVariables = Exact<{
  id?: Maybe<Array<Maybe<Scalars['ID']>> | Maybe<Scalars['ID']>>;
}>;


export type GetcorredoresbyumnQuery = (
  { __typename?: 'Query' }
  & { umn?: Maybe<(
    { __typename?: 'Umn' }
    & { Corredores?: Maybe<Array<Maybe<(
      { __typename?: 'Corredor' }
      & Pick<Corredor, 'id' | 'numero'>
      & { Usuario?: Maybe<(
        { __typename?: 'Usuario' }
        & { Person?: Maybe<(
          { __typename?: 'Person' }
          & Pick<Person, 'nombre' | 'apellido'>
        )> }
      )> }
    )>>> }
  )> }
);

export type GetCorredoresExternosQueryVariables = Exact<{
  umn_id?: Maybe<Array<Maybe<Scalars['ID']>> | Maybe<Scalars['ID']>>;
}>;


export type GetCorredoresExternosQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'corredoresExternos'>
);

export type GetCuotasTeeteAsgardQueryVariables = Exact<{
  agencia?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
  solicitud?: Maybe<Scalars['String']>;
  estado?: Maybe<Scalars['String']>;
}>;


export type GetCuotasTeeteAsgardQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getCuotasTeeteAsgard'>
);

export type GetDataPosQueryVariables = Exact<{
  desde?: Maybe<Scalars['Date']>;
  hasta?: Maybe<Scalars['Date']>;
  agencia?: Maybe<Array<Maybe<Scalars['ID']>> | Maybe<Scalars['ID']>>;
}>;


export type GetDataPosQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getDataPos'>
);

export type EventoCajasByIdQueryVariables = Exact<{
  terminalId?: Maybe<Scalars['ID']>;
}>;


export type EventoCajasByIdQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'eventoCajasById'>
);

export type GetWebTransaccionRecargaSaldoQueryVariables = Exact<{
  desde?: Maybe<Scalars['Date']>;
  hasta?: Maybe<Scalars['Date']>;
}>;


export type GetWebTransaccionRecargaSaldoQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getWebTransaccionRecargaSaldo'>
);

export type GetWebTransaccionRecargaSaldoByUserQueryVariables = Exact<{
  desde?: Maybe<Scalars['Date']>;
  hasta?: Maybe<Scalars['Date']>;
  usuario_id?: Maybe<Scalars['Int']>;
}>;


export type GetWebTransaccionRecargaSaldoByUserQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getWebTransaccionRecargaSaldoByUser'>
);

export type GetWebUserSaldoRetiradoQueryVariables = Exact<{ [key: string]: never; }>;


export type GetWebUserSaldoRetiradoQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getWebUserSaldoRetirado'>
);

export type GetWebUserSaldoGanadoGroupUserQueryVariables = Exact<{ [key: string]: never; }>;


export type GetWebUserSaldoGanadoGroupUserQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getWebUserSaldoGanadoGroupUser'>
);

export type GetWebUserSaldoGanadoGroupSorteoQueryVariables = Exact<{
  corredor_id?: Maybe<Scalars['Int']>;
}>;


export type GetWebUserSaldoGanadoGroupSorteoQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getWebUserSaldoGanadoGroupSorteo'>
);

export type GetWebTransaccionTransferenciaSaldoQueryVariables = Exact<{
  desde?: Maybe<Scalars['Date']>;
  hasta?: Maybe<Scalars['Date']>;
}>;


export type GetWebTransaccionTransferenciaSaldoQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getWebTransaccionTransferenciaSaldo'>
);

export type GetWebTransaccionTransferenciaSaldoByUserQueryVariables = Exact<{
  desde?: Maybe<Scalars['Date']>;
  hasta?: Maybe<Scalars['Date']>;
  usuario_id?: Maybe<Scalars['Int']>;
}>;


export type GetWebTransaccionTransferenciaSaldoByUserQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getWebTransaccionTransferenciaSaldoByUser'>
);

export type GetJugadasQueryVariables = Exact<{
  tipo?: Maybe<Scalars['Int']>;
  desde: Scalars['Date'];
  hasta?: Maybe<Scalars['Date']>;
  umn_id?: Maybe<Array<Scalars['ID']> | Scalars['ID']>;
}>;


export type GetJugadasQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'jugadaQuiniela'>
);

export type GetResultsQueryVariables = Exact<{
  desde?: Maybe<Scalars['Date']>;
  hasta?: Maybe<Scalars['Date']>;
  sorteo_base_id?: Maybe<Scalars['ID']>;
}>;


export type GetResultsQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getSorteoDelDia'>
);

export type GetSolicitudesDepositoPorAgenciaQueryVariables = Exact<{
  umn_id?: Maybe<Array<Maybe<Scalars['ID']>> | Maybe<Scalars['ID']>>;
}>;


export type GetSolicitudesDepositoPorAgenciaQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getSolicitudesDepositoPorAgencia'>
);

export type DetallesPremiacionTipoQueryVariables = Exact<{
  agencia?: Maybe<Array<Maybe<Scalars['Int']>> | Maybe<Scalars['Int']>>;
  desde: Scalars['Date'];
  hasta?: Maybe<Scalars['Date']>;
  sorteo?: Maybe<Array<Maybe<Scalars['Int']>> | Maybe<Scalars['Int']>>;
}>;


export type DetallesPremiacionTipoQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'detallesPremiacionTipo'>
);

export type GetSwitchEntregasQueryVariables = Exact<{
  umn_id?: Maybe<Array<Scalars['ID']> | Scalars['ID']>;
}>;


export type GetSwitchEntregasQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'switchEntregas'>
);

export type GetTerminalByCorredorQueryVariables = Exact<{
  corredor_id?: Maybe<Scalars['ID']>;
  estado?: Maybe<Scalars['String']>;
}>;


export type GetTerminalByCorredorQuery = (
  { __typename?: 'Query' }
  & { getCajas?: Maybe<Array<Maybe<(
    { __typename?: 'Caja' }
    & Pick<Caja, 'id'>
    & { Terminal?: Maybe<(
      { __typename?: 'Terminal' }
      & Pick<Terminal, 'id' | 'serial'>
    )> }
  )>>> }
);

export type TicketsResultQueryVariables = Exact<{
  premiados?: Maybe<Scalars['Boolean']>;
  serie?: Maybe<Scalars['String']>;
  ticket?: Maybe<Scalars['Float']>;
  monto?: Maybe<Scalars['Int']>;
  nro_corredor?: Maybe<Scalars['Int']>;
  anuladas?: Maybe<Scalars['Boolean']>;
  umn_id?: Maybe<Array<Scalars['ID']> | Scalars['ID']>;
  desde: Scalars['Date'];
  hasta?: Maybe<Scalars['Date']>;
  sorteo_id?: Maybe<Array<Maybe<Scalars['Int']>> | Maybe<Scalars['Int']>>;
  retrasadas?: Maybe<Scalars['Boolean']>;
}>;


export type TicketsResultQuery = (
  { __typename?: 'Query' }
  & { ticketResult?: Maybe<Array<Maybe<(
    { __typename?: 'TicketQuiniela' }
    & Pick<TicketQuiniela, 'id' | 'serie' | 'ticket' | 'monto_ganado' | 'monto_apostado' | 'created_at' | 'generado'>
    & { Transaccion?: Maybe<(
      { __typename?: 'Transaccion' }
      & Pick<Transaccion, 'anulado'>
      & { Caja?: Maybe<(
        { __typename?: 'Caja' }
        & { Corredor?: Maybe<(
          { __typename?: 'Corredor' }
          & Pick<Corredor, 'numero'>
          & { Umn?: Maybe<(
            { __typename?: 'Umn' }
            & Pick<Umn, 'numero' | 'agencia_numero'>
          )>, Usuario?: Maybe<(
            { __typename?: 'Usuario' }
            & { Person?: Maybe<(
              { __typename?: 'Person' }
              & Pick<Person, 'nombre' | 'apellido'>
            )> }
          )> }
        )> }
      )> }
    )> }
  )>>> }
);

export type GetTotalTerminalesQueryVariables = Exact<{
  umn_id?: Maybe<Array<Maybe<Scalars['ID']>> | Maybe<Scalars['ID']>>;
  colas?: Maybe<Scalars['Boolean']>;
  estado?: Maybe<Scalars['String']>;
}>;


export type GetTotalTerminalesQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'cajasSegunEstado'>
);

export type GetTransaccionesCorredorTerminalQueryVariables = Exact<{
  corredor_id?: Maybe<Scalars['ID']>;
  terminal_id?: Maybe<Scalars['ID']>;
}>;


export type GetTransaccionesCorredorTerminalQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getTransaccionByCorredorTerminal'>
);

export type CorredorProductosQueryVariables = Exact<{
  umn_id?: Maybe<Array<Maybe<Scalars['ID']>> | Maybe<Scalars['ID']>>;
  corredor_id?: Maybe<Array<Maybe<Scalars['ID']>> | Maybe<Scalars['ID']>>;
  desde?: Maybe<Scalars['Date']>;
  hasta?: Maybe<Scalars['Date']>;
  producto_id?: Maybe<Array<Maybe<Scalars['Int']>> | Maybe<Scalars['Int']>>;
  sorteo_id?: Maybe<Array<Maybe<Scalars['Int']>> | Maybe<Scalars['Int']>>;
  juego_id?: Maybe<Array<Maybe<Scalars['Int']>> | Maybe<Scalars['Int']>>;
}>;


export type CorredorProductosQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'corredorProductos'>
);

export type GetTransactionsWebBySorteoQueryVariables = Exact<{
  desde?: Maybe<Scalars['Date']>;
  hasta?: Maybe<Scalars['Date']>;
  sorteo?: Maybe<Array<Maybe<Scalars['Int']>> | Maybe<Scalars['Int']>>;
}>;


export type GetTransactionsWebBySorteoQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getTransactionsWebBySorteo'>
);

export type GetWebUserTicketsBySorteoQueryVariables = Exact<{
  desde?: Maybe<Scalars['Date']>;
  hasta?: Maybe<Scalars['Date']>;
  sorteo?: Maybe<Scalars['Int']>;
}>;


export type GetWebUserTicketsBySorteoQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getWebUserTicketsBySorteo'>
);

export type UmnInfoQueryVariables = Exact<{
  id?: Maybe<Array<Maybe<Scalars['ID']>> | Maybe<Scalars['ID']>>;
}>;


export type UmnInfoQuery = (
  { __typename?: 'Query' }
  & { umn?: Maybe<(
    { __typename?: 'Umn' }
    & Pick<Umn, 'numero' | 'agencia_numero' | 'zona' | 'nombre'>
  )> }
);

export type GetUmnsQueryVariables = Exact<{
  agencia?: Maybe<Array<Maybe<Scalars['Int']>> | Maybe<Scalars['Int']>>;
  zona?: Maybe<Array<Maybe<Scalars['Int']>> | Maybe<Scalars['Int']>>;
}>;


export type GetUmnsQuery = (
  { __typename?: 'Query' }
  & { allUmns?: Maybe<Array<Maybe<(
    { __typename?: 'Umn' }
    & Pick<Umn, 'id' | 'nombre' | 'numero' | 'agencia_numero'>
  )>>> }
);

export type GetWebUserSaldoApuestaQueryVariables = Exact<{ [key: string]: never; }>;


export type GetWebUserSaldoApuestaQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getWebUserSaldoApuesta'>
);

export type GetWebUserSaldoGanadoQueryVariables = Exact<{ [key: string]: never; }>;


export type GetWebUserSaldoGanadoQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getWebUserSaldoGanado'>
);

export type GetWebTotalApuestasQueryVariables = Exact<{ [key: string]: never; }>;


export type GetWebTotalApuestasQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getWebTotalApuestas'>
);

export type GetWebTransactionsSaldosQueryVariables = Exact<{ [key: string]: never; }>;


export type GetWebTransactionsSaldosQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getWebTransactionsSaldos'>
);

export type GetWebTicketsByUserQueryVariables = Exact<{
  desde?: Maybe<Scalars['Date']>;
  hasta?: Maybe<Scalars['Date']>;
  sorteo?: Maybe<Scalars['Int']>;
  corredor_id?: Maybe<Scalars['Int']>;
}>;


export type GetWebTicketsByUserQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getWebTicketsByUser'>
);

export type GetWebUserInformationQueryVariables = Exact<{
  corredor_id?: Maybe<Scalars['Int']>;
}>;


export type GetWebUserInformationQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getWebUserInformation'>
);

export type GetWebUserInformationUserQueryVariables = Exact<{
  usuario_id?: Maybe<Scalars['Int']>;
}>;


export type GetWebUserInformationUserQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getWebUserInformationUser'>
);

export type HistorialDeSorteosQueryVariables = Exact<{
  desde: Scalars['Date'];
  hasta?: Maybe<Scalars['Date']>;
  sorteo?: Maybe<Array<Maybe<Scalars['ID']>> | Maybe<Scalars['ID']>>;
}>;


export type HistorialDeSorteosQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'historialDeSorteos'>
);

export type HistorialTerminalQueryVariables = Exact<{
  terminalId?: Maybe<Scalars['ID']>;
}>;


export type HistorialTerminalQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'historialTerminal'>
);

export type HistorialTerminal2QueryVariables = Exact<{
  terminalId?: Maybe<Scalars['ID']>;
}>;


export type HistorialTerminal2Query = (
  { __typename?: 'Query' }
  & Pick<Query, 'historialTerminal2'>
);

export type HistoryCorredorQueryVariables = Exact<{
  desde: Scalars['Date'];
  hasta?: Maybe<Scalars['Date']>;
  corredor_id: Scalars['ID'];
}>;


export type HistoryCorredorQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'corredorHistorico'>
);

export type HistoryTerminalQueryVariables = Exact<{
  desde: Scalars['Date'];
  hasta?: Maybe<Scalars['Date']>;
  terminal_id: Scalars['ID'];
}>;


export type HistoryTerminalQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'terminalHistorico'>
);

export type LimitedNumbersQueryVariables = Exact<{
  sorteo_id?: Maybe<Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>>;
}>;


export type LimitedNumbersQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'limitedNumbers'>
);

export type ListAgenciasForFilterQueryVariables = Exact<{ [key: string]: never; }>;


export type ListAgenciasForFilterQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'listAgenciasForFilter'>
);

export type ListAnulacionesMegalotoQueryVariables = Exact<{
  desde: Scalars['Date'];
  hasta: Scalars['Date'];
  agencia: Scalars['ID'];
  corredor?: Maybe<Scalars['ID']>;
  ticket?: Maybe<Scalars['String']>;
}>;


export type ListAnulacionesMegalotoQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'listAnulacionesMegaloto'>
);

export type ListAnulacionPremioPagadoQueryVariables = Exact<{
  desde: Scalars['Date'];
  hasta: Scalars['Date'];
  umn_id?: Maybe<Array<Scalars['ID']> | Scalars['ID']>;
}>;


export type ListAnulacionPremioPagadoQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'listAnulacionPremioPagado'>
);

export type ListaDeEstadosTerminalesQueryVariables = Exact<{ [key: string]: never; }>;


export type ListaDeEstadosTerminalesQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'listaDeEstadosTerminales'>
);

export type ListadoTerminalesQueryVariables = Exact<{ [key: string]: never; }>;


export type ListadoTerminalesQuery = (
  { __typename?: 'Query' }
  & { terminales?: Maybe<Array<Maybe<(
    { __typename?: 'Terminal' }
    & Pick<Terminal, 'id' | 'serial' | 'version_asignada'>
    & { Heartbeats?: Maybe<Array<Maybe<(
      { __typename?: 'Heartbeat' }
      & Pick<Heartbeat, 'updated_at' | 'data'>
    )>>> }
  )>>> }
);

export type NumerosLimitadosQueryVariables = Exact<{ [key: string]: never; }>;


export type NumerosLimitadosQuery = (
  { __typename?: 'Query' }
  & { numerosLimitados?: Maybe<Array<Maybe<(
    { __typename?: 'NumerosLimitados' }
    & Pick<NumerosLimitados, 'id' | 'agencia' | 'fecha' | 'numeros'>
  )>>> }
);

export type OcurrenciasQueryVariables = Exact<{
  desde: Scalars['Date'];
  hasta?: Maybe<Scalars['Date']>;
  sorteo?: Maybe<Array<Maybe<Scalars['ID']>> | Maybe<Scalars['ID']>>;
  postura?: Maybe<Scalars['Int']>;
}>;


export type OcurrenciasQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'ocurrencias'>
);

export type OcurrenciasDosNumerosQueryVariables = Exact<{
  desde: Scalars['Date'];
  hasta?: Maybe<Scalars['Date']>;
  sorteo?: Maybe<Array<Maybe<Scalars['ID']>> | Maybe<Scalars['ID']>>;
  postura?: Maybe<Scalars['Int']>;
}>;


export type OcurrenciasDosNumerosQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'ocurrenciasDosNumeros'>
);

export type GetOcurrenciasAndChartQueryVariables = Exact<{
  desde: Scalars['Date'];
  hasta: Scalars['Date'];
  flag?: Maybe<Scalars['Boolean']>;
}>;


export type GetOcurrenciasAndChartQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getOcurrenciasAndChart'>
);

export type SearchPersonaQueryVariables = Exact<{
  nrodoc?: Maybe<Scalars['String']>;
}>;


export type SearchPersonaQuery = (
  { __typename?: 'Query' }
  & { pn_persona?: Maybe<(
    { __typename?: 'PnPersona' }
    & Pick<PnPersona, 'nombre' | 'apellido' | 'fechanac' | 'nrodoc'>
  )> }
);

export type PlanillaAgenciaQueryVariables = Exact<{
  desde: Scalars['Date'];
  hasta?: Maybe<Scalars['Date']>;
  umn?: Maybe<Array<Maybe<Scalars['Int']>> | Maybe<Scalars['Int']>>;
}>;


export type PlanillaAgenciaQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'quinielaFechaPorCorredor'>
);

export type PlanillaGeneralQueryVariables = Exact<{
  umn_id?: Maybe<Array<Maybe<Scalars['ID']>> | Maybe<Scalars['ID']>>;
}>;


export type PlanillaGeneralQuery = (
  { __typename?: 'Query' }
  & { umn?: Maybe<(
    { __typename?: 'Umn' }
    & Pick<Umn, 'now' | 'numero' | 'nombre' | 'agencia_numero' | 'totalCorredores' | 'totalLinea' | 'totalDeuda' | 'totalVentasConCierre' | 'totalVentasSinCierre' | 'totalCajasAbiertas'>
    & { Cobradores?: Maybe<Array<Maybe<(
      { __typename?: 'Cobrador' }
      & Pick<Cobrador, 'numero' | 'id' | 'cuenta' | 'linea'>
      & { Usuario?: Maybe<(
        { __typename?: 'Usuario' }
        & Pick<Usuario, 'username'>
        & { Person?: Maybe<(
          { __typename?: 'Person' }
          & Pick<Person, 'nombre' | 'apellido' | 'nrodoc'>
        )> }
      )> }
    )>>>, Corredores?: Maybe<Array<Maybe<(
      { __typename?: 'Corredor' }
      & Pick<Corredor, 'numero' | 'id' | 'cuenta' | 'linea'>
      & { Cajas?: Maybe<Array<Maybe<(
        { __typename?: 'Caja' }
        & Pick<Caja, 'id' | 'apertura' | 'cierre' | 'estado'>
        & { Transacciones?: Maybe<Array<Maybe<(
          { __typename?: 'Transaccion' }
          & Pick<Transaccion, 'monto'>
        )>>> }
      )>>>, Usuario?: Maybe<(
        { __typename?: 'Usuario' }
        & Pick<Usuario, 'username'>
        & { Person?: Maybe<(
          { __typename?: 'Person' }
          & Pick<Person, 'nombre' | 'apellido' | 'nrodoc'>
        )> }
      )> }
    )>>> }
  )> }
);

export type PremiadosQueryVariables = Exact<{
  serie?: Maybe<Scalars['String']>;
  ticket?: Maybe<Scalars['Float']>;
  desde: Scalars['Date'];
  hasta?: Maybe<Scalars['Date']>;
  numero_corredor?: Maybe<Scalars['Int']>;
  sorteo_id?: Maybe<Array<Maybe<Scalars['Int']>> | Maybe<Scalars['Int']>>;
  umn_id?: Maybe<Array<Scalars['ID']> | Scalars['ID']>;
  retrasadas?: Maybe<Scalars['Boolean']>;
  agencia?: Maybe<Array<Maybe<Scalars['ID']>> | Maybe<Scalars['ID']>>;
  premiados?: Maybe<Scalars['Boolean']>;
}>;


export type PremiadosQuery = (
  { __typename?: 'Query' }
  & { ticketPremiados?: Maybe<Array<Maybe<(
    { __typename?: 'TicketQuiniela' }
    & Pick<TicketQuiniela, 'id' | 'nombre' | 'apellido' | 'serie' | 'ticket' | 'monto_ganado' | 'monto_apostado' | 'generado' | 'created_at'>
    & { Sorteo?: Maybe<(
      { __typename?: 'Sorteo' }
      & { SorteoBase: (
        { __typename?: 'SorteoBase' }
        & Pick<SorteoBase, 'tipo' | 'nombre' | 'id'>
      ) }
    )>, Transaccion?: Maybe<(
      { __typename?: 'Transaccion' }
      & { Caja?: Maybe<(
        { __typename?: 'Caja' }
        & { Corredor?: Maybe<(
          { __typename?: 'Corredor' }
          & Pick<Corredor, 'numero' | 'id'>
          & { Umn?: Maybe<(
            { __typename?: 'Umn' }
            & Pick<Umn, 'numero' | 'agencia_numero'>
          )>, Usuario?: Maybe<(
            { __typename?: 'Usuario' }
            & { Person?: Maybe<(
              { __typename?: 'Person' }
              & Pick<Person, 'nombre' | 'apellido'>
            )> }
          )> }
        )> }
      )> }
    )> }
  )>>> }
);

export type PremiadosAgrupadosQueryVariables = Exact<{
  serie?: Maybe<Scalars['String']>;
  ticket?: Maybe<Scalars['Float']>;
  desde: Scalars['Date'];
  hasta?: Maybe<Scalars['Date']>;
  numero_corredor?: Maybe<Scalars['Int']>;
  sorteo_id?: Maybe<Array<Maybe<Scalars['Int']>> | Maybe<Scalars['Int']>>;
  umn_id?: Maybe<Array<Scalars['ID']> | Scalars['ID']>;
  premiado?: Maybe<Scalars['Boolean']>;
  agencia?: Maybe<Array<Maybe<Scalars['ID']>> | Maybe<Scalars['ID']>>;
  sorteoNoAgrupado?: Maybe<Scalars['Boolean']>;
  fechaNoAgrupado?: Maybe<Scalars['Boolean']>;
}>;


export type PremiadosAgrupadosQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'premiadosAgrupados'>
);

export type ProyeccionesQueryVariables = Exact<{
  fecha: Scalars['Date'];
  sorteo_id?: Maybe<Array<Maybe<Scalars['Int']>> | Maybe<Scalars['Int']>>;
  agencia?: Maybe<Array<Maybe<Scalars['Int']>> | Maybe<Scalars['Int']>>;
  cifras?: Maybe<Scalars['Int']>;
}>;


export type ProyeccionesQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'proyeccionPremiacion'>
);

export type QWebCantidadesQueryVariables = Exact<{
  desde?: Maybe<Scalars['Date']>;
  hasta?: Maybe<Scalars['Date']>;
}>;


export type QWebCantidadesQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'qWebCantidades'>
);

export type TransaccionesRetirosByCorredorQueryVariables = Exact<{
  desde: Scalars['Date'];
  hasta: Scalars['Date'];
  corredor_id: Scalars['Int'];
  agencia_numero?: Maybe<Array<Maybe<Scalars['Int']>> | Maybe<Scalars['Int']>>;
  producto_id?: Maybe<Scalars['ID']>;
}>;


export type TransaccionesRetirosByCorredorQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'transaccionesRetirosByCorredor'>
);

export type ReporteFacturacionDiariaQueryVariables = Exact<{
  desde: Scalars['Date'];
  hasta?: Maybe<Scalars['Date']>;
  sorteo_id?: Maybe<Array<Maybe<Scalars['ID']>> | Maybe<Scalars['ID']>>;
  agencia?: Maybe<Array<Maybe<Scalars['ID']>> | Maybe<Scalars['ID']>>;
}>;


export type ReporteFacturacionDiariaQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'reporteFacturacionDiaria'>
);

export type ResumenAgenciaUmnProductosQueryVariables = Exact<{
  umn?: Maybe<Array<Maybe<Scalars['ID']>> | Maybe<Scalars['ID']>>;
  desde: Scalars['Date'];
  hasta?: Maybe<Scalars['Date']>;
}>;


export type ResumenAgenciaUmnProductosQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'resumenAgenciaUmnProductos'>
);

export type GetResumenFacturacionTelefoniaQueryVariables = Exact<{
  desde: Scalars['Date'];
  hasta?: Maybe<Scalars['Date']>;
  agencia?: Maybe<Array<Maybe<Scalars['Int']>> | Maybe<Scalars['Int']>>;
}>;


export type GetResumenFacturacionTelefoniaQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getResumenFacturacionTelefonia'>
);

export type GetDetallesFacturaTelefoniaQueryVariables = Exact<{
  desde: Scalars['Date'];
  hasta?: Maybe<Scalars['Date']>;
  agencia: Scalars['Int'];
}>;


export type GetDetallesFacturaTelefoniaQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getDetallesFacturaTelefonia'>
);

export type ResumenTerminalesQueryVariables = Exact<{
  umn_id?: Maybe<Array<Maybe<Scalars['Int']>> | Maybe<Scalars['Int']>>;
}>;


export type ResumenTerminalesQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'resumenTerminales'>
);

export type ResumenTerminales2QueryVariables = Exact<{
  umn_id?: Maybe<Array<Maybe<Scalars['Int']>> | Maybe<Scalars['Int']>>;
}>;


export type ResumenTerminales2Query = (
  { __typename?: 'Query' }
  & Pick<Query, 'resumenTerminales2'>
);

export type ProductoGestionarRapasapditaQueryVariables = Exact<{
  desde?: Maybe<Scalars['Date']>;
  hasta?: Maybe<Scalars['Date']>;
}>;


export type ProductoGestionarRapasapditaQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'resumenTicketsRaspadita'>
);

export type ResumenUmnAgenciaQueryVariables = Exact<{
  umn_id?: Maybe<Array<Maybe<Scalars['ID']>> | Maybe<Scalars['ID']>>;
  desde: Scalars['Date'];
  hasta: Scalars['Date'];
}>;


export type ResumenUmnAgenciaQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'resumenUmnAgencia'>
);

export type AgenciaProductoGestionarQueryVariables = Exact<{
  producto_id: Scalars['ID'];
  desde?: Maybe<Scalars['Date']>;
  hasta?: Maybe<Scalars['Date']>;
  umn_id?: Maybe<Array<Maybe<Scalars['Int']>> | Maybe<Scalars['Int']>>;
  agrupar?: Maybe<Scalars['Boolean']>;
}>;


export type AgenciaProductoGestionarQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'resumenUmnProductoGestionar'>
);

export type AgenciaProductoGestionarGerenciaQueryVariables = Exact<{
  producto_id: Scalars['ID'];
  desde?: Maybe<Scalars['Date']>;
  hasta?: Maybe<Scalars['Date']>;
  agencia_numero?: Maybe<Array<Maybe<Scalars['Int']>> | Maybe<Scalars['Int']>>;
  agrupar?: Maybe<Scalars['Boolean']>;
  juego_id?: Maybe<Array<Maybe<Scalars['Int']>> | Maybe<Scalars['Int']>>;
}>;


export type AgenciaProductoGestionarGerenciaQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'resumenUmnProductoGestionarGerencia'>
);

export type RtmCorredorDataQueryVariables = Exact<{
  corredor_id: Scalars['ID'];
}>;


export type RtmCorredorDataQuery = (
  { __typename?: 'Query' }
  & { corredor?: Maybe<(
    { __typename?: 'Corredor' }
    & Pick<Corredor, 'id' | 'numero' | 'cuenta' | 'linea' | 'entrega_corredor' | 'activo'>
    & { Umn?: Maybe<(
      { __typename?: 'Umn' }
      & Pick<Umn, 'numero' | 'agencia_numero'>
    )>, Cajas?: Maybe<Array<Maybe<(
      { __typename?: 'Caja' }
      & Pick<Caja, 'linea' | 'utilizado' | 'estado'>
    )>>>, Usuario?: Maybe<(
      { __typename?: 'Usuario' }
      & { Person?: Maybe<(
        { __typename?: 'Person' }
        & Pick<Person, 'nombre' | 'apellido' | 'nrodoc' | 'telefono'>
      )> }
    )> }
  )> }
);

export type RtmExtractoCorredorQueryVariables = Exact<{
  corredor_id: Scalars['ID'];
}>;


export type RtmExtractoCorredorQuery = (
  { __typename?: 'Query' }
  & { corredorExtracto?: Maybe<Array<Maybe<(
    { __typename?: 'Extracto' }
    & Pick<Extracto, 'id' | 'created_at' | 'detalle' | 'monto' | 'cuenta' | 'cuenta_anterior' | 'fecha_referencia' | 'annexable_id' | 'serial'>
  )>>> }
);

export type SherlockModeQueryVariables = Exact<{
  desde: Scalars['Date'];
  hasta?: Maybe<Scalars['Date']>;
  corredor_numero?: Maybe<Scalars['ID']>;
  agencia?: Maybe<Scalars['ID']>;
  sorteo_numero?: Maybe<Scalars['ID']>;
  order?: Maybe<Array<Maybe<Scalars['ID']>> | Maybe<Scalars['ID']>>;
}>;


export type SherlockModeQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'sherlockMode'>
);

export type TicketsSorteoGerenciaQueryVariables = Exact<{
  matutino: Scalars['ID'];
  vespertino: Scalars['ID'];
  nocturno: Scalars['ID'];
  purete: Scalars['ID'];
  premiados?: Maybe<Scalars['Boolean']>;
}>;


export type TicketsSorteoGerenciaQuery = (
  { __typename?: 'Query' }
  & { sorteo1?: Maybe<Array<Maybe<(
    { __typename?: 'TicketQuiniela' }
    & Pick<TicketQuiniela, 'id' | 'serie' | 'ticket' | 'monto_ganado' | 'monto_apostado' | 'generado'>
    & { Anulacion?: Maybe<(
      { __typename?: 'Anulacion' }
      & Pick<Anulacion, 'estado'>
    )>, Transaccion?: Maybe<(
      { __typename?: 'Transaccion' }
      & { Caja?: Maybe<(
        { __typename?: 'Caja' }
        & Pick<Caja, 'id'>
        & { Corredor?: Maybe<(
          { __typename?: 'Corredor' }
          & Pick<Corredor, 'numero'>
          & { Umn?: Maybe<(
            { __typename?: 'Umn' }
            & Pick<Umn, 'numero' | 'agencia_numero'>
          )>, Usuario?: Maybe<(
            { __typename?: 'Usuario' }
            & { Person?: Maybe<(
              { __typename?: 'Person' }
              & Pick<Person, 'nombre' | 'apellido'>
            )> }
          )> }
        )> }
      )> }
    )> }
  )>>>, sorteo2?: Maybe<Array<Maybe<(
    { __typename?: 'TicketQuiniela' }
    & Pick<TicketQuiniela, 'id' | 'serie' | 'ticket' | 'monto_ganado' | 'monto_apostado' | 'generado'>
    & { Anulacion?: Maybe<(
      { __typename?: 'Anulacion' }
      & Pick<Anulacion, 'estado'>
    )>, Transaccion?: Maybe<(
      { __typename?: 'Transaccion' }
      & { Caja?: Maybe<(
        { __typename?: 'Caja' }
        & Pick<Caja, 'id'>
        & { Corredor?: Maybe<(
          { __typename?: 'Corredor' }
          & Pick<Corredor, 'numero'>
          & { Umn?: Maybe<(
            { __typename?: 'Umn' }
            & Pick<Umn, 'numero' | 'agencia_numero'>
          )>, Usuario?: Maybe<(
            { __typename?: 'Usuario' }
            & { Person?: Maybe<(
              { __typename?: 'Person' }
              & Pick<Person, 'nombre' | 'apellido'>
            )> }
          )> }
        )> }
      )> }
    )> }
  )>>>, sorteo3?: Maybe<Array<Maybe<(
    { __typename?: 'TicketQuiniela' }
    & Pick<TicketQuiniela, 'id' | 'serie' | 'ticket' | 'monto_ganado' | 'monto_apostado' | 'generado'>
    & { Anulacion?: Maybe<(
      { __typename?: 'Anulacion' }
      & Pick<Anulacion, 'estado'>
    )>, Transaccion?: Maybe<(
      { __typename?: 'Transaccion' }
      & { Caja?: Maybe<(
        { __typename?: 'Caja' }
        & Pick<Caja, 'id'>
        & { Corredor?: Maybe<(
          { __typename?: 'Corredor' }
          & Pick<Corredor, 'numero'>
          & { Umn?: Maybe<(
            { __typename?: 'Umn' }
            & Pick<Umn, 'numero' | 'agencia_numero'>
          )>, Usuario?: Maybe<(
            { __typename?: 'Usuario' }
            & { Person?: Maybe<(
              { __typename?: 'Person' }
              & Pick<Person, 'nombre' | 'apellido'>
            )> }
          )> }
        )> }
      )> }
    )> }
  )>>>, sorteo4?: Maybe<Array<Maybe<(
    { __typename?: 'TicketQuiniela' }
    & Pick<TicketQuiniela, 'id' | 'serie' | 'ticket' | 'monto_ganado' | 'monto_apostado' | 'generado'>
    & { Anulacion?: Maybe<(
      { __typename?: 'Anulacion' }
      & Pick<Anulacion, 'estado'>
    )>, Transaccion?: Maybe<(
      { __typename?: 'Transaccion' }
      & { Caja?: Maybe<(
        { __typename?: 'Caja' }
        & Pick<Caja, 'id'>
        & { Corredor?: Maybe<(
          { __typename?: 'Corredor' }
          & Pick<Corredor, 'numero'>
          & { Umn?: Maybe<(
            { __typename?: 'Umn' }
            & Pick<Umn, 'numero' | 'agencia_numero'>
          )>, Usuario?: Maybe<(
            { __typename?: 'Usuario' }
            & { Person?: Maybe<(
              { __typename?: 'Person' }
              & Pick<Person, 'nombre' | 'apellido'>
            )> }
          )> }
        )> }
      )> }
    )> }
  )>>> }
);

export type SorteoResultsQueryVariables = Exact<{
  sorteo_id: Scalars['ID'];
}>;


export type SorteoResultsQuery = (
  { __typename?: 'Query' }
  & { sorteoResults?: Maybe<(
    { __typename?: 'Sorteo' }
    & Pick<Sorteo, 'fecha' | 'apertura' | 'cierre' | 'transmision' | 'resultados'>
    & { SorteoBase: (
      { __typename?: 'SorteoBase' }
      & Pick<SorteoBase, 'nombre' | 'numero'>
    ) }
  )> }
);

export type SorteoPremiadosQueryVariables = Exact<{
  sorteo_id: Scalars['ID'];
  umn_id?: Maybe<Array<Maybe<Scalars['ID']>> | Maybe<Scalars['ID']>>;
}>;


export type SorteoPremiadosQuery = (
  { __typename?: 'Query' }
  & { ticketsSorteo?: Maybe<Array<Maybe<(
    { __typename?: 'TicketQuiniela' }
    & Pick<TicketQuiniela, 'id' | 'serie' | 'ticket' | 'monto_ganado' | 'monto_apostado' | 'generado'>
    & { Transaccion?: Maybe<(
      { __typename?: 'Transaccion' }
      & { Caja?: Maybe<(
        { __typename?: 'Caja' }
        & { Corredor?: Maybe<(
          { __typename?: 'Corredor' }
          & Pick<Corredor, 'numero'>
          & { Umn?: Maybe<(
            { __typename?: 'Umn' }
            & Pick<Umn, 'numero' | 'agencia_numero'>
          )>, Usuario?: Maybe<(
            { __typename?: 'Usuario' }
            & { Person?: Maybe<(
              { __typename?: 'Person' }
              & Pick<Person, 'nombre' | 'apellido'>
            )> }
          )> }
        )> }
      )> }
    )> }
  )>>> }
);

export type SorteosQueryVariables = Exact<{ [key: string]: never; }>;


export type SorteosQuery = (
  { __typename?: 'Query' }
  & { sorteos?: Maybe<Array<Maybe<(
    { __typename?: 'Sorteo' }
    & Pick<Sorteo, 'fecha' | 'id' | 'apertura' | 'cierre' | 'transmision' | 'resultados' | 'liquidado' | 'pendientes'>
    & { SorteoBase: (
      { __typename?: 'SorteoBase' }
      & Pick<SorteoBase, 'nombre' | 'numero'>
    ) }
  )>>> }
);

export type SorteosProgramaQueryVariables = Exact<{ [key: string]: never; }>;


export type SorteosProgramaQuery = (
  { __typename?: 'Query' }
  & { sorteosPrograma?: Maybe<Array<Maybe<(
    { __typename?: 'SorteoPrograma' }
    & Pick<SorteoPrograma, 'id' | 'dia' | 'apertura' | 'cierre' | 'transmision' | 'status'>
    & { SorteoBase: (
      { __typename?: 'SorteoBase' }
      & Pick<SorteoBase, 'nombre' | 'numero'>
    ) }
  )>>> }
);

export type SorteosfechaQueryVariables = Exact<{
  desde: Scalars['Date'];
  hasta?: Maybe<Scalars['Date']>;
}>;


export type SorteosfechaQuery = (
  { __typename?: 'Query' }
  & { currentSorteos?: Maybe<Array<Maybe<(
    { __typename?: 'Sorteo' }
    & Pick<Sorteo, 'resultados' | 'id'>
    & { SorteoBase: (
      { __typename?: 'SorteoBase' }
      & Pick<SorteoBase, 'nombre' | 'numero'>
    ) }
  )>>> }
);

export type TerminalesQueryVariables = Exact<{
  umn_id?: Maybe<Array<Maybe<Scalars['ID']>> | Maybe<Scalars['ID']>>;
}>;


export type TerminalesQuery = (
  { __typename?: 'Query' }
  & { terminales?: Maybe<Array<Maybe<(
    { __typename?: 'Terminal' }
    & Pick<Terminal, 'id' | 'serial'>
  )>>> }
);

export type TerminalesUmnQueryVariables = Exact<{
  umn_id?: Maybe<Array<Scalars['ID']> | Scalars['ID']>;
  nro_corredor?: Maybe<Scalars['Int']>;
  serial?: Maybe<Scalars['String']>;
}>;


export type TerminalesUmnQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'terminalesUmn'>
);

export type TicketsByCorredorQueryVariables = Exact<{
  desde?: Maybe<Scalars['Date']>;
  hasta?: Maybe<Scalars['Date']>;
  corredor_id?: Maybe<Scalars['Int']>;
  serie?: Maybe<Scalars['String']>;
  sorteo_id?: Maybe<Array<Maybe<Scalars['Int']>> | Maybe<Scalars['Int']>>;
  ticket?: Maybe<Scalars['Int']>;
  all?: Maybe<Scalars['Boolean']>;
}>;


export type TicketsByCorredorQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'ticketCorredor'>
);

export type TicketQuinielaQueryVariables = Exact<{
  juego_id: Scalars['ID'];
}>;


export type TicketQuinielaQuery = (
  { __typename?: 'Query' }
  & { ticketQuiniela?: Maybe<(
    { __typename?: 'TicketQuiniela' }
    & Pick<TicketQuiniela, 'id' | 'serie' | 'ticket' | 'monto_ganado' | 'monto_apostado' | 'generado' | 'numero_sorteado'>
    & { Transaccion?: Maybe<(
      { __typename?: 'Transaccion' }
      & { Caja?: Maybe<(
        { __typename?: 'Caja' }
        & Pick<Caja, 'id'>
        & { Corredor?: Maybe<(
          { __typename?: 'Corredor' }
          & Pick<Corredor, 'numero'>
          & { Umn?: Maybe<(
            { __typename?: 'Umn' }
            & Pick<Umn, 'numero' | 'agencia_numero'>
          )>, Usuario?: Maybe<(
            { __typename?: 'Usuario' }
            & { Person?: Maybe<(
              { __typename?: 'Person' }
              & Pick<Person, 'nombre' | 'apellido'>
            )> }
          )> }
        )> }
      )> }
    )>, Sorteo?: Maybe<(
      { __typename?: 'Sorteo' }
      & Pick<Sorteo, 'resultados' | 'id'>
      & { SorteoBase: (
        { __typename?: 'SorteoBase' }
        & Pick<SorteoBase, 'nombre' | 'numero'>
      ) }
    )>, jugadas?: Maybe<Array<Maybe<(
      { __typename?: 'JugadaQuiniela' }
      & Pick<JugadaQuiniela, 'id' | 'tipo' | 'numero' | 'postura' | 'monto_apostado' | 'monto_ganado'>
    )>>> }
  )> }
);

export type TicketsSorteoQueryVariables = Exact<{
  sorteo_id: Scalars['ID'];
  premiados?: Maybe<Scalars['Boolean']>;
  serie?: Maybe<Scalars['String']>;
  ticket?: Maybe<Scalars['Float']>;
  monto?: Maybe<Scalars['Int']>;
  caja_id?: Maybe<Scalars['Int']>;
  umn_id?: Maybe<Array<Maybe<Scalars['ID']>> | Maybe<Scalars['ID']>>;
  pendientes?: Maybe<Scalars['Boolean']>;
}>;


export type TicketsSorteoQuery = (
  { __typename?: 'Query' }
  & { ticketsSorteo?: Maybe<Array<Maybe<(
    { __typename?: 'TicketQuiniela' }
    & Pick<TicketQuiniela, 'id' | 'serie' | 'ticket' | 'monto_ganado' | 'monto_apostado' | 'generado' | 'created_at'>
    & { Anulacion?: Maybe<(
      { __typename?: 'Anulacion' }
      & Pick<Anulacion, 'estado'>
    )>, Transaccion?: Maybe<(
      { __typename?: 'Transaccion' }
      & { Caja?: Maybe<(
        { __typename?: 'Caja' }
        & Pick<Caja, 'id'>
        & { Corredor?: Maybe<(
          { __typename?: 'Corredor' }
          & Pick<Corredor, 'numero'>
          & { Umn?: Maybe<(
            { __typename?: 'Umn' }
            & Pick<Umn, 'numero' | 'agencia_numero'>
          )>, Usuario?: Maybe<(
            { __typename?: 'Usuario' }
            & { Person?: Maybe<(
              { __typename?: 'Person' }
              & Pick<Person, 'nombre' | 'apellido'>
            )> }
          )> }
        )> }
      )> }
    )> }
  )>>> }
);

export type AgenciaProductoQueryVariables = Exact<{
  producto_id: Scalars['ID'];
  desde?: Maybe<Scalars['Date']>;
  hasta?: Maybe<Scalars['Date']>;
}>;


export type AgenciaProductoQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'resumenUmnProducto'>
);

export type ResumenUmnGerenciaUmnQueryVariables = Exact<{
  agencia?: Maybe<Array<Maybe<Scalars['Int']>> | Maybe<Scalars['Int']>>;
  desde: Scalars['Date'];
  hasta: Scalars['Date'];
  corredor_numero?: Maybe<Scalars['Int']>;
}>;


export type ResumenUmnGerenciaUmnQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'resumenUmnGerenciaUmn'>
);

export type ResumenUmnGerenciaProductoQueryVariables = Exact<{
  agencia?: Maybe<Array<Maybe<Scalars['Int']>> | Maybe<Scalars['Int']>>;
  desde: Scalars['Date'];
  hasta: Scalars['Date'];
  corredor_numero?: Maybe<Scalars['Int']>;
  sorteo?: Maybe<Array<Maybe<Scalars['Int']>> | Maybe<Scalars['Int']>>;
  generado?: Maybe<Scalars['Boolean']>;
}>;


export type ResumenUmnGerenciaProductoQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'resumenUmnGerenciaProducto'>
);

export type GetAgencyByUmnQueryVariables = Exact<{
  id?: Maybe<Array<Maybe<Scalars['ID']>> | Maybe<Scalars['ID']>>;
}>;


export type GetAgencyByUmnQuery = (
  { __typename?: 'Query' }
  & { umn?: Maybe<(
    { __typename?: 'Umn' }
    & Pick<Umn, 'agencia_numero' | 'numero' | 'nombre' | 'zona'>
  )> }
);

export type UsuarioListQueryVariables = Exact<{
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  nrodoc?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
  umn_id?: Maybe<Array<Maybe<Scalars['ID']>> | Maybe<Scalars['ID']>>;
  nro_corredor?: Maybe<Scalars['Int']>;
}>;


export type UsuarioListQuery = (
  { __typename?: 'Query' }
  & { usuarios?: Maybe<Array<Maybe<(
    { __typename?: 'Usuario' }
    & Pick<Usuario, 'id' | 'username' | 'password' | 'pin'>
    & { Permisos?: Maybe<Array<Maybe<(
      { __typename?: 'Permiso' }
      & { Role?: Maybe<(
        { __typename?: 'Role' }
        & Pick<Role, 'id' | 'name'>
      )> }
    )>>>, Person?: Maybe<(
      { __typename?: 'Person' }
      & Pick<Person, 'nombre' | 'apellido' | 'nrodoc' | 'telefono'>
    )>, Corredores?: Maybe<Array<Maybe<(
      { __typename?: 'Corredor' }
      & Pick<Corredor, 'numero' | 'id'>
    )>>>, Umns?: Maybe<Array<Maybe<(
      { __typename?: 'Umn' }
      & Pick<Umn, 'id' | 'numero' | 'nombre' | 'agencia_numero'>
    )>>>, Cobradores?: Maybe<Array<Maybe<(
      { __typename?: 'Cobrador' }
      & Pick<Cobrador, 'numero'>
    )>>> }
  )>>> }
);

export type UsoTerminalQueryVariables = Exact<{
  terminal_id: Scalars['ID'];
}>;


export type UsoTerminalQuery = (
  { __typename?: 'Query' }
  & { terminal?: Maybe<(
    { __typename?: 'Terminal' }
    & Pick<Terminal, 'id' | 'serial'>
  )>, usoTerminal?: Maybe<Array<Maybe<(
    { __typename?: 'Uso' }
    & Pick<Uso, 'id' | 'apertura' | 'cierre'>
    & { Corredor?: Maybe<(
      { __typename?: 'Corredor' }
      & Pick<Corredor, 'numero'>
      & { Umn?: Maybe<(
        { __typename?: 'Umn' }
        & Pick<Umn, 'numero' | 'agencia_numero'>
      )>, Usuario?: Maybe<(
        { __typename?: 'Usuario' }
        & { Person?: Maybe<(
          { __typename?: 'Person' }
          & Pick<Person, 'nombre' | 'apellido'>
        )> }
      )> }
    )> }
  )>>> }
);

export type RaspaditaDetallesCorredorPorAgenciaQueryVariables = Exact<{
  desde?: Maybe<Scalars['Date']>;
  hasta?: Maybe<Scalars['Date']>;
  agencia?: Maybe<Scalars['Int']>;
}>;


export type RaspaditaDetallesCorredorPorAgenciaQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'raspaditaDetallesCorredorPorAgencia'>
);

export type AjusteUmnFinanzasQueryVariables = Exact<{
  desde: Scalars['Date'];
  hasta: Scalars['Date'];
  umn_id?: Maybe<Array<Maybe<Scalars['ID']>> | Maybe<Scalars['ID']>>;
}>;


export type AjusteUmnFinanzasQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'verAjustesUmnFinanzas'>
);

export type VerConsolidacionesQueryVariables = Exact<{
  umn_id?: Maybe<Array<Maybe<Scalars['ID']>> | Maybe<Scalars['ID']>>;
  desde: Scalars['Date'];
  hasta?: Maybe<Scalars['Date']>;
}>;


export type VerConsolidacionesQuery = (
  { __typename?: 'Query' }
  & { verConsolidaciones?: Maybe<Array<Maybe<(
    { __typename?: 'Consolidacion' }
    & Pick<Consolidacion, 'id' | 'agencia_id' | 'umn_id' | 'monto' | 'comision' | 'premio' | 'reclamos' | 'recuperacion' | 'ocasional' | 'multas' | 'monto_retrasadas' | 'comision_retrasadas' | 'premios_retrasadas' | 'cantidad_boletas' | 'cantidad_corredores' | 'saldo_anterior' | 'depositos' | 'neto' | 'umn' | 'fecha_movimiento' | 'saldo_a_depositar'>
  )>>> }
);

export type VerConsolidacionesAgenciaQueryVariables = Exact<{
  agencia?: Maybe<Array<Maybe<Scalars['Int']>> | Maybe<Scalars['Int']>>;
  desde: Scalars['Date'];
  hasta?: Maybe<Scalars['Date']>;
}>;


export type VerConsolidacionesAgenciaQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'verConsolidacionesAgencia'>
);

export type VerFinanzasGerenciaQueryVariables = Exact<{
  agencia?: Maybe<Array<Maybe<Scalars['Int']>> | Maybe<Scalars['Int']>>;
  desde: Scalars['Date'];
  hasta?: Maybe<Scalars['Date']>;
}>;


export type VerFinanzasGerenciaQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'verConsolidacionesGerencia'>
);

export type VerFinanzasGerenciaDetallesQueryVariables = Exact<{
  agencia?: Maybe<Array<Maybe<Scalars['Int']>> | Maybe<Scalars['Int']>>;
  desde: Scalars['Date'];
  hasta?: Maybe<Scalars['Date']>;
}>;


export type VerFinanzasGerenciaDetallesQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'verConsolidacionesAgenciaGerencia'>
);

export type DepositosUmnFinanzasQueryVariables = Exact<{
  desde: Scalars['Date'];
  hasta: Scalars['Date'];
  umn_id?: Maybe<Array<Maybe<Scalars['ID']>> | Maybe<Scalars['ID']>>;
}>;


export type DepositosUmnFinanzasQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'verDepositosUmnFinanzas'>
);

export type UmnResumenFinanzasQueryVariables = Exact<{
  desde: Scalars['Date'];
  hasta: Scalars['Date'];
  umn_id?: Maybe<Array<Maybe<Scalars['ID']>> | Maybe<Scalars['ID']>>;
}>;


export type UmnResumenFinanzasQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'verUmnFinanzas'>
);

export type ZonaAgenciaQueryVariables = Exact<{
  agencia?: Maybe<Scalars['Int']>;
}>;


export type ZonaAgenciaQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getZonasByAgencia'>
);


export const AddCobradorDocument = gql`
    mutation addCobrador($usuario_id: ID!, $umn_id: ID!, $cobrador_numero: Int!, $linea: Int!) {
  addCobradorToUser(
    usuario_id: $usuario_id
    umn_id: $umn_id
    cobrador_numero: $cobrador_numero
    linea: $linea
  ) {
    id
    Cobradores {
      numero
      linea
    }
  }
}
    `;
export type AddCobradorMutationFn = Apollo.MutationFunction<AddCobradorMutation, AddCobradorMutationVariables>;

/**
 * __useAddCobradorMutation__
 *
 * To run a mutation, you first call `useAddCobradorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddCobradorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addCobradorMutation, { data, loading, error }] = useAddCobradorMutation({
 *   variables: {
 *      usuario_id: // value for 'usuario_id'
 *      umn_id: // value for 'umn_id'
 *      cobrador_numero: // value for 'cobrador_numero'
 *      linea: // value for 'linea'
 *   },
 * });
 */
export function useAddCobradorMutation(baseOptions?: Apollo.MutationHookOptions<AddCobradorMutation, AddCobradorMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddCobradorMutation, AddCobradorMutationVariables>(AddCobradorDocument, options);
      }
export type AddCobradorMutationHookResult = ReturnType<typeof useAddCobradorMutation>;
export type AddCobradorMutationResult = Apollo.MutationResult<AddCobradorMutation>;
export type AddCobradorMutationOptions = Apollo.BaseMutationOptions<AddCobradorMutation, AddCobradorMutationVariables>;
export const AddCorredorDocument = gql`
    mutation addCorredor($usuario_id: ID!, $umn_id: Int!, $corredor_numero: Int!, $linea: Int!) {
  addCorredorToUser(
    usuario_id: $usuario_id
    umn_id: $umn_id
    corredor_numero: $corredor_numero
    linea: $linea
  ) {
    id
    Corredores {
      numero
      linea
    }
  }
}
    `;
export type AddCorredorMutationFn = Apollo.MutationFunction<AddCorredorMutation, AddCorredorMutationVariables>;

/**
 * __useAddCorredorMutation__
 *
 * To run a mutation, you first call `useAddCorredorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddCorredorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addCorredorMutation, { data, loading, error }] = useAddCorredorMutation({
 *   variables: {
 *      usuario_id: // value for 'usuario_id'
 *      umn_id: // value for 'umn_id'
 *      corredor_numero: // value for 'corredor_numero'
 *      linea: // value for 'linea'
 *   },
 * });
 */
export function useAddCorredorMutation(baseOptions?: Apollo.MutationHookOptions<AddCorredorMutation, AddCorredorMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddCorredorMutation, AddCorredorMutationVariables>(AddCorredorDocument, options);
      }
export type AddCorredorMutationHookResult = ReturnType<typeof useAddCorredorMutation>;
export type AddCorredorMutationResult = Apollo.MutationResult<AddCorredorMutation>;
export type AddCorredorMutationOptions = Apollo.BaseMutationOptions<AddCorredorMutation, AddCorredorMutationVariables>;
export const AddPermisoDocument = gql`
    mutation addPermiso($usuario_id: ID!, $role_id: Int!, $rights: [Int]!) {
  addPermisoToUser(usuario_id: $usuario_id, role_id: $role_id, rights: $rights) {
    Role {
      name
    }
    Rights {
      name
    }
  }
}
    `;
export type AddPermisoMutationFn = Apollo.MutationFunction<AddPermisoMutation, AddPermisoMutationVariables>;

/**
 * __useAddPermisoMutation__
 *
 * To run a mutation, you first call `useAddPermisoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddPermisoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addPermisoMutation, { data, loading, error }] = useAddPermisoMutation({
 *   variables: {
 *      usuario_id: // value for 'usuario_id'
 *      role_id: // value for 'role_id'
 *      rights: // value for 'rights'
 *   },
 * });
 */
export function useAddPermisoMutation(baseOptions?: Apollo.MutationHookOptions<AddPermisoMutation, AddPermisoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddPermisoMutation, AddPermisoMutationVariables>(AddPermisoDocument, options);
      }
export type AddPermisoMutationHookResult = ReturnType<typeof useAddPermisoMutation>;
export type AddPermisoMutationResult = Apollo.MutationResult<AddPermisoMutation>;
export type AddPermisoMutationOptions = Apollo.BaseMutationOptions<AddPermisoMutation, AddPermisoMutationVariables>;
export const AddPinDocument = gql`
    mutation addPin($usuario_id: ID!, $value: String!) {
  setPinToUser(usuario_id: $usuario_id, value: $value) {
    id
  }
}
    `;
export type AddPinMutationFn = Apollo.MutationFunction<AddPinMutation, AddPinMutationVariables>;

/**
 * __useAddPinMutation__
 *
 * To run a mutation, you first call `useAddPinMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddPinMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addPinMutation, { data, loading, error }] = useAddPinMutation({
 *   variables: {
 *      usuario_id: // value for 'usuario_id'
 *      value: // value for 'value'
 *   },
 * });
 */
export function useAddPinMutation(baseOptions?: Apollo.MutationHookOptions<AddPinMutation, AddPinMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddPinMutation, AddPinMutationVariables>(AddPinDocument, options);
      }
export type AddPinMutationHookResult = ReturnType<typeof useAddPinMutation>;
export type AddPinMutationResult = Apollo.MutationResult<AddPinMutation>;
export type AddPinMutationOptions = Apollo.BaseMutationOptions<AddPinMutation, AddPinMutationVariables>;
export const AddUmnDocument = gql`
    mutation addUmn($usuario_id: ID!, $umn_id: Int!) {
  addUmnToUser(usuario_id: $usuario_id, umn_id: $umn_id) {
    id
    Umns {
      numero
      agencia_numero
      id
    }
    Person {
      id
    }
  }
}
    `;
export type AddUmnMutationFn = Apollo.MutationFunction<AddUmnMutation, AddUmnMutationVariables>;

/**
 * __useAddUmnMutation__
 *
 * To run a mutation, you first call `useAddUmnMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddUmnMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addUmnMutation, { data, loading, error }] = useAddUmnMutation({
 *   variables: {
 *      usuario_id: // value for 'usuario_id'
 *      umn_id: // value for 'umn_id'
 *   },
 * });
 */
export function useAddUmnMutation(baseOptions?: Apollo.MutationHookOptions<AddUmnMutation, AddUmnMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddUmnMutation, AddUmnMutationVariables>(AddUmnDocument, options);
      }
export type AddUmnMutationHookResult = ReturnType<typeof useAddUmnMutation>;
export type AddUmnMutationResult = Apollo.MutationResult<AddUmnMutation>;
export type AddUmnMutationOptions = Apollo.BaseMutationOptions<AddUmnMutation, AddUmnMutationVariables>;
export const PagarPremioAgenciaDocument = gql`
    mutation PagarPremioAgencia($serie: String, $ticket: Float, $fecha: Date, $umn_id: ID, $monto: Int, $id_pago: Float) {
  pagarPremio(
    serie: $serie
    ticket: $ticket
    fecha: $fecha
    umn_id: $umn_id
    monto: $monto
    id_pago: $id_pago
  )
}
    `;
export type PagarPremioAgenciaMutationFn = Apollo.MutationFunction<PagarPremioAgenciaMutation, PagarPremioAgenciaMutationVariables>;

/**
 * __usePagarPremioAgenciaMutation__
 *
 * To run a mutation, you first call `usePagarPremioAgenciaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePagarPremioAgenciaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [pagarPremioAgenciaMutation, { data, loading, error }] = usePagarPremioAgenciaMutation({
 *   variables: {
 *      serie: // value for 'serie'
 *      ticket: // value for 'ticket'
 *      fecha: // value for 'fecha'
 *      umn_id: // value for 'umn_id'
 *      monto: // value for 'monto'
 *      id_pago: // value for 'id_pago'
 *   },
 * });
 */
export function usePagarPremioAgenciaMutation(baseOptions?: Apollo.MutationHookOptions<PagarPremioAgenciaMutation, PagarPremioAgenciaMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PagarPremioAgenciaMutation, PagarPremioAgenciaMutationVariables>(PagarPremioAgenciaDocument, options);
      }
export type PagarPremioAgenciaMutationHookResult = ReturnType<typeof usePagarPremioAgenciaMutation>;
export type PagarPremioAgenciaMutationResult = Apollo.MutationResult<PagarPremioAgenciaMutation>;
export type PagarPremioAgenciaMutationOptions = Apollo.BaseMutationOptions<PagarPremioAgenciaMutation, PagarPremioAgenciaMutationVariables>;
export const AcceptOrRejectSolicitudDepositoDocument = gql`
    mutation acceptOrRejectSolicitudDeposito($solicitud_id: ID!, $type: String) {
  acceptOrRejectSolicitudDeposito(solicitud_id: $solicitud_id, type: $type)
}
    `;
export type AcceptOrRejectSolicitudDepositoMutationFn = Apollo.MutationFunction<AcceptOrRejectSolicitudDepositoMutation, AcceptOrRejectSolicitudDepositoMutationVariables>;

/**
 * __useAcceptOrRejectSolicitudDepositoMutation__
 *
 * To run a mutation, you first call `useAcceptOrRejectSolicitudDepositoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptOrRejectSolicitudDepositoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptOrRejectSolicitudDepositoMutation, { data, loading, error }] = useAcceptOrRejectSolicitudDepositoMutation({
 *   variables: {
 *      solicitud_id: // value for 'solicitud_id'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useAcceptOrRejectSolicitudDepositoMutation(baseOptions?: Apollo.MutationHookOptions<AcceptOrRejectSolicitudDepositoMutation, AcceptOrRejectSolicitudDepositoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AcceptOrRejectSolicitudDepositoMutation, AcceptOrRejectSolicitudDepositoMutationVariables>(AcceptOrRejectSolicitudDepositoDocument, options);
      }
export type AcceptOrRejectSolicitudDepositoMutationHookResult = ReturnType<typeof useAcceptOrRejectSolicitudDepositoMutation>;
export type AcceptOrRejectSolicitudDepositoMutationResult = Apollo.MutationResult<AcceptOrRejectSolicitudDepositoMutation>;
export type AcceptOrRejectSolicitudDepositoMutationOptions = Apollo.BaseMutationOptions<AcceptOrRejectSolicitudDepositoMutation, AcceptOrRejectSolicitudDepositoMutationVariables>;
export const AddNewUmnDocument = gql`
    mutation addNewUmn($nombre: String, $agencia_numero: Int, $numero: Int) {
  addUMN(nombre: $nombre, agencia_numero: $agencia_numero, numero: $numero) {
    numero
    agencia_numero
    nombre
  }
}
    `;
export type AddNewUmnMutationFn = Apollo.MutationFunction<AddNewUmnMutation, AddNewUmnMutationVariables>;

/**
 * __useAddNewUmnMutation__
 *
 * To run a mutation, you first call `useAddNewUmnMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddNewUmnMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addNewUmnMutation, { data, loading, error }] = useAddNewUmnMutation({
 *   variables: {
 *      nombre: // value for 'nombre'
 *      agencia_numero: // value for 'agencia_numero'
 *      numero: // value for 'numero'
 *   },
 * });
 */
export function useAddNewUmnMutation(baseOptions?: Apollo.MutationHookOptions<AddNewUmnMutation, AddNewUmnMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddNewUmnMutation, AddNewUmnMutationVariables>(AddNewUmnDocument, options);
      }
export type AddNewUmnMutationHookResult = ReturnType<typeof useAddNewUmnMutation>;
export type AddNewUmnMutationResult = Apollo.MutationResult<AddNewUmnMutation>;
export type AddNewUmnMutationOptions = Apollo.BaseMutationOptions<AddNewUmnMutation, AddNewUmnMutationVariables>;
export const AddPasswordDocument = gql`
    mutation addPassword($usuario_id: ID!, $value: String!) {
  setPasswordToUser(usuario_id: $usuario_id, value: $value) {
    id
  }
}
    `;
export type AddPasswordMutationFn = Apollo.MutationFunction<AddPasswordMutation, AddPasswordMutationVariables>;

/**
 * __useAddPasswordMutation__
 *
 * To run a mutation, you first call `useAddPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addPasswordMutation, { data, loading, error }] = useAddPasswordMutation({
 *   variables: {
 *      usuario_id: // value for 'usuario_id'
 *      value: // value for 'value'
 *   },
 * });
 */
export function useAddPasswordMutation(baseOptions?: Apollo.MutationHookOptions<AddPasswordMutation, AddPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddPasswordMutation, AddPasswordMutationVariables>(AddPasswordDocument, options);
      }
export type AddPasswordMutationHookResult = ReturnType<typeof useAddPasswordMutation>;
export type AddPasswordMutationResult = Apollo.MutationResult<AddPasswordMutation>;
export type AddPasswordMutationOptions = Apollo.BaseMutationOptions<AddPasswordMutation, AddPasswordMutationVariables>;
export const AddNewUserDocument = gql`
    mutation addNewUser($nrodoc: String!, $password: String!, $pin: String!, $domicilio: String!, $telefono: String!, $umn_id: Int!) {
  addUser(
    nrodoc: $nrodoc
    password: $password
    pin: $pin
    domicilio: $domicilio
    telefono: $telefono
    umn_id: $umn_id
  ) {
    id
    username
    Person {
      nombre
      apellido
      domicilio
      telefono
      id
    }
  }
}
    `;
export type AddNewUserMutationFn = Apollo.MutationFunction<AddNewUserMutation, AddNewUserMutationVariables>;

/**
 * __useAddNewUserMutation__
 *
 * To run a mutation, you first call `useAddNewUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddNewUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addNewUserMutation, { data, loading, error }] = useAddNewUserMutation({
 *   variables: {
 *      nrodoc: // value for 'nrodoc'
 *      password: // value for 'password'
 *      pin: // value for 'pin'
 *      domicilio: // value for 'domicilio'
 *      telefono: // value for 'telefono'
 *      umn_id: // value for 'umn_id'
 *   },
 * });
 */
export function useAddNewUserMutation(baseOptions?: Apollo.MutationHookOptions<AddNewUserMutation, AddNewUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddNewUserMutation, AddNewUserMutationVariables>(AddNewUserDocument, options);
      }
export type AddNewUserMutationHookResult = ReturnType<typeof useAddNewUserMutation>;
export type AddNewUserMutationResult = Apollo.MutationResult<AddNewUserMutation>;
export type AddNewUserMutationOptions = Apollo.BaseMutationOptions<AddNewUserMutation, AddNewUserMutationVariables>;
export const AnulacionDocument = gql`
    mutation anulacion($anulacion_id: ID!) {
  anulacionConfirm(anulacion_id: $anulacion_id) {
    id
  }
}
    `;
export type AnulacionMutationFn = Apollo.MutationFunction<AnulacionMutation, AnulacionMutationVariables>;

/**
 * __useAnulacionMutation__
 *
 * To run a mutation, you first call `useAnulacionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAnulacionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [anulacionMutation, { data, loading, error }] = useAnulacionMutation({
 *   variables: {
 *      anulacion_id: // value for 'anulacion_id'
 *   },
 * });
 */
export function useAnulacionMutation(baseOptions?: Apollo.MutationHookOptions<AnulacionMutation, AnulacionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AnulacionMutation, AnulacionMutationVariables>(AnulacionDocument, options);
      }
export type AnulacionMutationHookResult = ReturnType<typeof useAnulacionMutation>;
export type AnulacionMutationResult = Apollo.MutationResult<AnulacionMutation>;
export type AnulacionMutationOptions = Apollo.BaseMutationOptions<AnulacionMutation, AnulacionMutationVariables>;
export const AnulacionDenyDocument = gql`
    mutation anulacionDeny($anulacion_id: ID!) {
  anulacionDeny(anulacion_id: $anulacion_id) {
    id
  }
}
    `;
export type AnulacionDenyMutationFn = Apollo.MutationFunction<AnulacionDenyMutation, AnulacionDenyMutationVariables>;

/**
 * __useAnulacionDenyMutation__
 *
 * To run a mutation, you first call `useAnulacionDenyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAnulacionDenyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [anulacionDenyMutation, { data, loading, error }] = useAnulacionDenyMutation({
 *   variables: {
 *      anulacion_id: // value for 'anulacion_id'
 *   },
 * });
 */
export function useAnulacionDenyMutation(baseOptions?: Apollo.MutationHookOptions<AnulacionDenyMutation, AnulacionDenyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AnulacionDenyMutation, AnulacionDenyMutationVariables>(AnulacionDenyDocument, options);
      }
export type AnulacionDenyMutationHookResult = ReturnType<typeof useAnulacionDenyMutation>;
export type AnulacionDenyMutationResult = Apollo.MutationResult<AnulacionDenyMutation>;
export type AnulacionDenyMutationOptions = Apollo.BaseMutationOptions<AnulacionDenyMutation, AnulacionDenyMutationVariables>;
export const AnulacionElevarDocument = gql`
    mutation anulacionElevar($anulacion_id: ID!, $elevacion: Int!) {
  anulacionElevacion(anulacion_id: $anulacion_id, elevacion: $elevacion) {
    id
  }
}
    `;
export type AnulacionElevarMutationFn = Apollo.MutationFunction<AnulacionElevarMutation, AnulacionElevarMutationVariables>;

/**
 * __useAnulacionElevarMutation__
 *
 * To run a mutation, you first call `useAnulacionElevarMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAnulacionElevarMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [anulacionElevarMutation, { data, loading, error }] = useAnulacionElevarMutation({
 *   variables: {
 *      anulacion_id: // value for 'anulacion_id'
 *      elevacion: // value for 'elevacion'
 *   },
 * });
 */
export function useAnulacionElevarMutation(baseOptions?: Apollo.MutationHookOptions<AnulacionElevarMutation, AnulacionElevarMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AnulacionElevarMutation, AnulacionElevarMutationVariables>(AnulacionElevarDocument, options);
      }
export type AnulacionElevarMutationHookResult = ReturnType<typeof useAnulacionElevarMutation>;
export type AnulacionElevarMutationResult = Apollo.MutationResult<AnulacionElevarMutation>;
export type AnulacionElevarMutationOptions = Apollo.BaseMutationOptions<AnulacionElevarMutation, AnulacionElevarMutationVariables>;
export const ConfirmarAnulacionPremioPagadoDocument = gql`
    mutation confirmarAnulacionPremioPagado($premio_pagado_id: ID!) {
  confirmarAnulacionPremioPagado(premio_pagado_id: $premio_pagado_id)
}
    `;
export type ConfirmarAnulacionPremioPagadoMutationFn = Apollo.MutationFunction<ConfirmarAnulacionPremioPagadoMutation, ConfirmarAnulacionPremioPagadoMutationVariables>;

/**
 * __useConfirmarAnulacionPremioPagadoMutation__
 *
 * To run a mutation, you first call `useConfirmarAnulacionPremioPagadoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfirmarAnulacionPremioPagadoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [confirmarAnulacionPremioPagadoMutation, { data, loading, error }] = useConfirmarAnulacionPremioPagadoMutation({
 *   variables: {
 *      premio_pagado_id: // value for 'premio_pagado_id'
 *   },
 * });
 */
export function useConfirmarAnulacionPremioPagadoMutation(baseOptions?: Apollo.MutationHookOptions<ConfirmarAnulacionPremioPagadoMutation, ConfirmarAnulacionPremioPagadoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ConfirmarAnulacionPremioPagadoMutation, ConfirmarAnulacionPremioPagadoMutationVariables>(ConfirmarAnulacionPremioPagadoDocument, options);
      }
export type ConfirmarAnulacionPremioPagadoMutationHookResult = ReturnType<typeof useConfirmarAnulacionPremioPagadoMutation>;
export type ConfirmarAnulacionPremioPagadoMutationResult = Apollo.MutationResult<ConfirmarAnulacionPremioPagadoMutation>;
export type ConfirmarAnulacionPremioPagadoMutationOptions = Apollo.BaseMutationOptions<ConfirmarAnulacionPremioPagadoMutation, ConfirmarAnulacionPremioPagadoMutationVariables>;
export const AnularDepositoDocument = gql`
    mutation anularDeposito($id_deposito: ID!) {
  anularDeposito(id_deposito: $id_deposito) {
    id
    monto
    medio
  }
}
    `;
export type AnularDepositoMutationFn = Apollo.MutationFunction<AnularDepositoMutation, AnularDepositoMutationVariables>;

/**
 * __useAnularDepositoMutation__
 *
 * To run a mutation, you first call `useAnularDepositoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAnularDepositoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [anularDepositoMutation, { data, loading, error }] = useAnularDepositoMutation({
 *   variables: {
 *      id_deposito: // value for 'id_deposito'
 *   },
 * });
 */
export function useAnularDepositoMutation(baseOptions?: Apollo.MutationHookOptions<AnularDepositoMutation, AnularDepositoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AnularDepositoMutation, AnularDepositoMutationVariables>(AnularDepositoDocument, options);
      }
export type AnularDepositoMutationHookResult = ReturnType<typeof useAnularDepositoMutation>;
export type AnularDepositoMutationResult = Apollo.MutationResult<AnularDepositoMutation>;
export type AnularDepositoMutationOptions = Apollo.BaseMutationOptions<AnularDepositoMutation, AnularDepositoMutationVariables>;
export const AnularTransaccionMegalotoDocument = gql`
    mutation anularTransaccionMegaloto($id: ID!, $motivoId: String) {
  anularTransaccionMegaloto(id: $id, motivoId: $motivoId)
}
    `;
export type AnularTransaccionMegalotoMutationFn = Apollo.MutationFunction<AnularTransaccionMegalotoMutation, AnularTransaccionMegalotoMutationVariables>;

/**
 * __useAnularTransaccionMegalotoMutation__
 *
 * To run a mutation, you first call `useAnularTransaccionMegalotoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAnularTransaccionMegalotoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [anularTransaccionMegalotoMutation, { data, loading, error }] = useAnularTransaccionMegalotoMutation({
 *   variables: {
 *      id: // value for 'id'
 *      motivoId: // value for 'motivoId'
 *   },
 * });
 */
export function useAnularTransaccionMegalotoMutation(baseOptions?: Apollo.MutationHookOptions<AnularTransaccionMegalotoMutation, AnularTransaccionMegalotoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AnularTransaccionMegalotoMutation, AnularTransaccionMegalotoMutationVariables>(AnularTransaccionMegalotoDocument, options);
      }
export type AnularTransaccionMegalotoMutationHookResult = ReturnType<typeof useAnularTransaccionMegalotoMutation>;
export type AnularTransaccionMegalotoMutationResult = Apollo.MutationResult<AnularTransaccionMegalotoMutation>;
export type AnularTransaccionMegalotoMutationOptions = Apollo.BaseMutationOptions<AnularTransaccionMegalotoMutation, AnularTransaccionMegalotoMutationVariables>;
export const CerrarCajaDocument = gql`
    mutation cerrarCaja($corredor_id: ID, $caja_id: Int!, $terminal_id: Int!, $referencia: String!) {
  cerrarCaja(
    corredor_id: $corredor_id
    caja_id: $caja_id
    terminal_id: $terminal_id
    referencia: $referencia
  ) {
    id
    apertura
    cierre
    linea
    Terminal {
      id
    }
  }
}
    `;
export type CerrarCajaMutationFn = Apollo.MutationFunction<CerrarCajaMutation, CerrarCajaMutationVariables>;

/**
 * __useCerrarCajaMutation__
 *
 * To run a mutation, you first call `useCerrarCajaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCerrarCajaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cerrarCajaMutation, { data, loading, error }] = useCerrarCajaMutation({
 *   variables: {
 *      corredor_id: // value for 'corredor_id'
 *      caja_id: // value for 'caja_id'
 *      terminal_id: // value for 'terminal_id'
 *      referencia: // value for 'referencia'
 *   },
 * });
 */
export function useCerrarCajaMutation(baseOptions?: Apollo.MutationHookOptions<CerrarCajaMutation, CerrarCajaMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CerrarCajaMutation, CerrarCajaMutationVariables>(CerrarCajaDocument, options);
      }
export type CerrarCajaMutationHookResult = ReturnType<typeof useCerrarCajaMutation>;
export type CerrarCajaMutationResult = Apollo.MutationResult<CerrarCajaMutation>;
export type CerrarCajaMutationOptions = Apollo.BaseMutationOptions<CerrarCajaMutation, CerrarCajaMutationVariables>;
export const ChangeSwitchEntregasDocument = gql`
    mutation changeSwitchEntregas($umn_id: [ID!]) {
  changeSwitchEntregas(umn_id: $umn_id)
}
    `;
export type ChangeSwitchEntregasMutationFn = Apollo.MutationFunction<ChangeSwitchEntregasMutation, ChangeSwitchEntregasMutationVariables>;

/**
 * __useChangeSwitchEntregasMutation__
 *
 * To run a mutation, you first call `useChangeSwitchEntregasMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeSwitchEntregasMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeSwitchEntregasMutation, { data, loading, error }] = useChangeSwitchEntregasMutation({
 *   variables: {
 *      umn_id: // value for 'umn_id'
 *   },
 * });
 */
export function useChangeSwitchEntregasMutation(baseOptions?: Apollo.MutationHookOptions<ChangeSwitchEntregasMutation, ChangeSwitchEntregasMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangeSwitchEntregasMutation, ChangeSwitchEntregasMutationVariables>(ChangeSwitchEntregasDocument, options);
      }
export type ChangeSwitchEntregasMutationHookResult = ReturnType<typeof useChangeSwitchEntregasMutation>;
export type ChangeSwitchEntregasMutationResult = Apollo.MutationResult<ChangeSwitchEntregasMutation>;
export type ChangeSwitchEntregasMutationOptions = Apollo.BaseMutationOptions<ChangeSwitchEntregasMutation, ChangeSwitchEntregasMutationVariables>;
export const ChangeSwitchEntregasCorredorDocument = gql`
    mutation changeSwitchEntregasCorredor($corredor_id: ID!) {
  changeSwitchEntregasCorredor(corredor_id: $corredor_id)
}
    `;
export type ChangeSwitchEntregasCorredorMutationFn = Apollo.MutationFunction<ChangeSwitchEntregasCorredorMutation, ChangeSwitchEntregasCorredorMutationVariables>;

/**
 * __useChangeSwitchEntregasCorredorMutation__
 *
 * To run a mutation, you first call `useChangeSwitchEntregasCorredorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeSwitchEntregasCorredorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeSwitchEntregasCorredorMutation, { data, loading, error }] = useChangeSwitchEntregasCorredorMutation({
 *   variables: {
 *      corredor_id: // value for 'corredor_id'
 *   },
 * });
 */
export function useChangeSwitchEntregasCorredorMutation(baseOptions?: Apollo.MutationHookOptions<ChangeSwitchEntregasCorredorMutation, ChangeSwitchEntregasCorredorMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangeSwitchEntregasCorredorMutation, ChangeSwitchEntregasCorredorMutationVariables>(ChangeSwitchEntregasCorredorDocument, options);
      }
export type ChangeSwitchEntregasCorredorMutationHookResult = ReturnType<typeof useChangeSwitchEntregasCorredorMutation>;
export type ChangeSwitchEntregasCorredorMutationResult = Apollo.MutationResult<ChangeSwitchEntregasCorredorMutation>;
export type ChangeSwitchEntregasCorredorMutationOptions = Apollo.BaseMutationOptions<ChangeSwitchEntregasCorredorMutation, ChangeSwitchEntregasCorredorMutationVariables>;
export const ChangeUmnUserDocument = gql`
    mutation changeUmnUser($usuario_id: ID!, $new_umn_id: [ID!]) {
  changeUmnToUser(usuario_id: $usuario_id, new_umn_id: $new_umn_id) {
    id
    Umns {
      id
      numero
    }
  }
}
    `;
export type ChangeUmnUserMutationFn = Apollo.MutationFunction<ChangeUmnUserMutation, ChangeUmnUserMutationVariables>;

/**
 * __useChangeUmnUserMutation__
 *
 * To run a mutation, you first call `useChangeUmnUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeUmnUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeUmnUserMutation, { data, loading, error }] = useChangeUmnUserMutation({
 *   variables: {
 *      usuario_id: // value for 'usuario_id'
 *      new_umn_id: // value for 'new_umn_id'
 *   },
 * });
 */
export function useChangeUmnUserMutation(baseOptions?: Apollo.MutationHookOptions<ChangeUmnUserMutation, ChangeUmnUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangeUmnUserMutation, ChangeUmnUserMutationVariables>(ChangeUmnUserDocument, options);
      }
export type ChangeUmnUserMutationHookResult = ReturnType<typeof useChangeUmnUserMutation>;
export type ChangeUmnUserMutationResult = Apollo.MutationResult<ChangeUmnUserMutation>;
export type ChangeUmnUserMutationOptions = Apollo.BaseMutationOptions<ChangeUmnUserMutation, ChangeUmnUserMutationVariables>;
export const CobradorDocument = gql`
    mutation cobrador($entrega_id: ID!, $referencia: String!, $cobrador_id: Int) {
  entregaConfirm(
    entrega_id: $entrega_id
    referencia: $referencia
    cobrador_id: $cobrador_id
  ) {
    id
  }
}
    `;
export type CobradorMutationFn = Apollo.MutationFunction<CobradorMutation, CobradorMutationVariables>;

/**
 * __useCobradorMutation__
 *
 * To run a mutation, you first call `useCobradorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCobradorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cobradorMutation, { data, loading, error }] = useCobradorMutation({
 *   variables: {
 *      entrega_id: // value for 'entrega_id'
 *      referencia: // value for 'referencia'
 *      cobrador_id: // value for 'cobrador_id'
 *   },
 * });
 */
export function useCobradorMutation(baseOptions?: Apollo.MutationHookOptions<CobradorMutation, CobradorMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CobradorMutation, CobradorMutationVariables>(CobradorDocument, options);
      }
export type CobradorMutationHookResult = ReturnType<typeof useCobradorMutation>;
export type CobradorMutationResult = Apollo.MutationResult<CobradorMutation>;
export type CobradorMutationOptions = Apollo.BaseMutationOptions<CobradorMutation, CobradorMutationVariables>;
export const CobradorRechazarDocument = gql`
    mutation cobradorRechazar($entrega_id: ID!) {
  entregaDeny(entrega_id: $entrega_id) {
    id
  }
}
    `;
export type CobradorRechazarMutationFn = Apollo.MutationFunction<CobradorRechazarMutation, CobradorRechazarMutationVariables>;

/**
 * __useCobradorRechazarMutation__
 *
 * To run a mutation, you first call `useCobradorRechazarMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCobradorRechazarMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cobradorRechazarMutation, { data, loading, error }] = useCobradorRechazarMutation({
 *   variables: {
 *      entrega_id: // value for 'entrega_id'
 *   },
 * });
 */
export function useCobradorRechazarMutation(baseOptions?: Apollo.MutationHookOptions<CobradorRechazarMutation, CobradorRechazarMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CobradorRechazarMutation, CobradorRechazarMutationVariables>(CobradorRechazarDocument, options);
      }
export type CobradorRechazarMutationHookResult = ReturnType<typeof useCobradorRechazarMutation>;
export type CobradorRechazarMutationResult = Apollo.MutationResult<CobradorRechazarMutation>;
export type CobradorRechazarMutationOptions = Apollo.BaseMutationOptions<CobradorRechazarMutation, CobradorRechazarMutationVariables>;
export const CreateCorredorExternoDocument = gql`
    mutation createCorredorExterno($documento: String!, $nombre: String!, $apellido: String!, $numero_corredor: String!, $celular: String!, $direccion: String!, $umn_id: String!) {
  createCorredorExterno(
    documento: $documento
    nombre: $nombre
    apellido: $apellido
    numero_corredor: $numero_corredor
    celular: $celular
    direccion: $direccion
    umn_id: $umn_id
  )
}
    `;
export type CreateCorredorExternoMutationFn = Apollo.MutationFunction<CreateCorredorExternoMutation, CreateCorredorExternoMutationVariables>;

/**
 * __useCreateCorredorExternoMutation__
 *
 * To run a mutation, you first call `useCreateCorredorExternoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCorredorExternoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCorredorExternoMutation, { data, loading, error }] = useCreateCorredorExternoMutation({
 *   variables: {
 *      documento: // value for 'documento'
 *      nombre: // value for 'nombre'
 *      apellido: // value for 'apellido'
 *      numero_corredor: // value for 'numero_corredor'
 *      celular: // value for 'celular'
 *      direccion: // value for 'direccion'
 *      umn_id: // value for 'umn_id'
 *   },
 * });
 */
export function useCreateCorredorExternoMutation(baseOptions?: Apollo.MutationHookOptions<CreateCorredorExternoMutation, CreateCorredorExternoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCorredorExternoMutation, CreateCorredorExternoMutationVariables>(CreateCorredorExternoDocument, options);
      }
export type CreateCorredorExternoMutationHookResult = ReturnType<typeof useCreateCorredorExternoMutation>;
export type CreateCorredorExternoMutationResult = Apollo.MutationResult<CreateCorredorExternoMutation>;
export type CreateCorredorExternoMutationOptions = Apollo.BaseMutationOptions<CreateCorredorExternoMutation, CreateCorredorExternoMutationVariables>;
export const CreateNumerosLimitadosDocument = gql`
    mutation createNumerosLimitados($numerosLimitados: NumerosLimitadosInput!) {
  createNumerosLimitados(numerosLimitados: $numerosLimitados) {
    id
    fecha
    agencia
    numeros
  }
}
    `;
export type CreateNumerosLimitadosMutationFn = Apollo.MutationFunction<CreateNumerosLimitadosMutation, CreateNumerosLimitadosMutationVariables>;

/**
 * __useCreateNumerosLimitadosMutation__
 *
 * To run a mutation, you first call `useCreateNumerosLimitadosMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateNumerosLimitadosMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createNumerosLimitadosMutation, { data, loading, error }] = useCreateNumerosLimitadosMutation({
 *   variables: {
 *      numerosLimitados: // value for 'numerosLimitados'
 *   },
 * });
 */
export function useCreateNumerosLimitadosMutation(baseOptions?: Apollo.MutationHookOptions<CreateNumerosLimitadosMutation, CreateNumerosLimitadosMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateNumerosLimitadosMutation, CreateNumerosLimitadosMutationVariables>(CreateNumerosLimitadosDocument, options);
      }
export type CreateNumerosLimitadosMutationHookResult = ReturnType<typeof useCreateNumerosLimitadosMutation>;
export type CreateNumerosLimitadosMutationResult = Apollo.MutationResult<CreateNumerosLimitadosMutation>;
export type CreateNumerosLimitadosMutationOptions = Apollo.BaseMutationOptions<CreateNumerosLimitadosMutation, CreateNumerosLimitadosMutationVariables>;
export const CreateRegimenWuDocument = gql`
    mutation createRegimenWu($input: CreateRegimerWuInput!) {
  createRegimenWu(input: $input)
}
    `;
export type CreateRegimenWuMutationFn = Apollo.MutationFunction<CreateRegimenWuMutation, CreateRegimenWuMutationVariables>;

/**
 * __useCreateRegimenWuMutation__
 *
 * To run a mutation, you first call `useCreateRegimenWuMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRegimenWuMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRegimenWuMutation, { data, loading, error }] = useCreateRegimenWuMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateRegimenWuMutation(baseOptions?: Apollo.MutationHookOptions<CreateRegimenWuMutation, CreateRegimenWuMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateRegimenWuMutation, CreateRegimenWuMutationVariables>(CreateRegimenWuDocument, options);
      }
export type CreateRegimenWuMutationHookResult = ReturnType<typeof useCreateRegimenWuMutation>;
export type CreateRegimenWuMutationResult = Apollo.MutationResult<CreateRegimenWuMutation>;
export type CreateRegimenWuMutationOptions = Apollo.BaseMutationOptions<CreateRegimenWuMutation, CreateRegimenWuMutationVariables>;
export const CreateSorteoEspecialDocument = gql`
    mutation createSorteoEspecial($resultados: [String!]!, $sorteo_base_id: ID!, $fecha: Date!) {
  createSorteoEspecial(
    resultados: $resultados
    sorteo_base_id: $sorteo_base_id
    fecha: $fecha
  )
}
    `;
export type CreateSorteoEspecialMutationFn = Apollo.MutationFunction<CreateSorteoEspecialMutation, CreateSorteoEspecialMutationVariables>;

/**
 * __useCreateSorteoEspecialMutation__
 *
 * To run a mutation, you first call `useCreateSorteoEspecialMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSorteoEspecialMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSorteoEspecialMutation, { data, loading, error }] = useCreateSorteoEspecialMutation({
 *   variables: {
 *      resultados: // value for 'resultados'
 *      sorteo_base_id: // value for 'sorteo_base_id'
 *      fecha: // value for 'fecha'
 *   },
 * });
 */
export function useCreateSorteoEspecialMutation(baseOptions?: Apollo.MutationHookOptions<CreateSorteoEspecialMutation, CreateSorteoEspecialMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateSorteoEspecialMutation, CreateSorteoEspecialMutationVariables>(CreateSorteoEspecialDocument, options);
      }
export type CreateSorteoEspecialMutationHookResult = ReturnType<typeof useCreateSorteoEspecialMutation>;
export type CreateSorteoEspecialMutationResult = Apollo.MutationResult<CreateSorteoEspecialMutation>;
export type CreateSorteoEspecialMutationOptions = Apollo.BaseMutationOptions<CreateSorteoEspecialMutation, CreateSorteoEspecialMutationVariables>;
export const CreateUserWuFormDocument = gql`
    mutation createUserWuForm($doc_type: String!, $ci: String!, $phone_number: String!, $country_code_phone: String!, $names: String!, $last_names: String!, $middle_name: String!, $birth_date: String!, $residence_country: String!, $departamento: String!, $city: String!, $adress: String!, $doc_emision: String!, $doc_vencimiento: String!, $front_doc_img: String!, $back_doc_img: String!, $selfie_img: String!, $country_birth: String!, $paisDoc: String!, $source_funds: String!, $position_level: String!, $email: String, $occupation: String!, $transaction_reason: String!, $pep: String, $cpep: String, $pagoAgil: Boolean, $reference: String!) {
  createUserWuForm(
    doc_type: $doc_type
    ci: $ci
    phone_number: $phone_number
    country_code_phone: $country_code_phone
    names: $names
    last_names: $last_names
    middle_name: $middle_name
    birth_date: $birth_date
    residence_country: $residence_country
    departamento: $departamento
    city: $city
    adress: $adress
    doc_emision: $doc_emision
    doc_vencimiento: $doc_vencimiento
    front_doc_img: $front_doc_img
    back_doc_img: $back_doc_img
    selfie_img: $selfie_img
    country_birth: $country_birth
    paisDoc: $paisDoc
    source_funds: $source_funds
    position_level: $position_level
    email: $email
    occupation: $occupation
    transaction_reason: $transaction_reason
    pep: $pep
    cpep: $cpep
    pagoAgil: $pagoAgil
    reference: $reference
  )
}
    `;
export type CreateUserWuFormMutationFn = Apollo.MutationFunction<CreateUserWuFormMutation, CreateUserWuFormMutationVariables>;

/**
 * __useCreateUserWuFormMutation__
 *
 * To run a mutation, you first call `useCreateUserWuFormMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserWuFormMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserWuFormMutation, { data, loading, error }] = useCreateUserWuFormMutation({
 *   variables: {
 *      doc_type: // value for 'doc_type'
 *      ci: // value for 'ci'
 *      phone_number: // value for 'phone_number'
 *      country_code_phone: // value for 'country_code_phone'
 *      names: // value for 'names'
 *      last_names: // value for 'last_names'
 *      middle_name: // value for 'middle_name'
 *      birth_date: // value for 'birth_date'
 *      residence_country: // value for 'residence_country'
 *      departamento: // value for 'departamento'
 *      city: // value for 'city'
 *      adress: // value for 'adress'
 *      doc_emision: // value for 'doc_emision'
 *      doc_vencimiento: // value for 'doc_vencimiento'
 *      front_doc_img: // value for 'front_doc_img'
 *      back_doc_img: // value for 'back_doc_img'
 *      selfie_img: // value for 'selfie_img'
 *      country_birth: // value for 'country_birth'
 *      paisDoc: // value for 'paisDoc'
 *      source_funds: // value for 'source_funds'
 *      position_level: // value for 'position_level'
 *      email: // value for 'email'
 *      occupation: // value for 'occupation'
 *      transaction_reason: // value for 'transaction_reason'
 *      pep: // value for 'pep'
 *      cpep: // value for 'cpep'
 *      pagoAgil: // value for 'pagoAgil'
 *      reference: // value for 'reference'
 *   },
 * });
 */
export function useCreateUserWuFormMutation(baseOptions?: Apollo.MutationHookOptions<CreateUserWuFormMutation, CreateUserWuFormMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateUserWuFormMutation, CreateUserWuFormMutationVariables>(CreateUserWuFormDocument, options);
      }
export type CreateUserWuFormMutationHookResult = ReturnType<typeof useCreateUserWuFormMutation>;
export type CreateUserWuFormMutationResult = Apollo.MutationResult<CreateUserWuFormMutation>;
export type CreateUserWuFormMutationOptions = Apollo.BaseMutationOptions<CreateUserWuFormMutation, CreateUserWuFormMutationVariables>;
export const DeleteAnulacionExtractoDocument = gql`
    mutation deleteAnulacionExtracto($extracto_id: ID!) {
  borrarExtractoAnulacion(extracto_id: $extracto_id)
}
    `;
export type DeleteAnulacionExtractoMutationFn = Apollo.MutationFunction<DeleteAnulacionExtractoMutation, DeleteAnulacionExtractoMutationVariables>;

/**
 * __useDeleteAnulacionExtractoMutation__
 *
 * To run a mutation, you first call `useDeleteAnulacionExtractoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAnulacionExtractoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAnulacionExtractoMutation, { data, loading, error }] = useDeleteAnulacionExtractoMutation({
 *   variables: {
 *      extracto_id: // value for 'extracto_id'
 *   },
 * });
 */
export function useDeleteAnulacionExtractoMutation(baseOptions?: Apollo.MutationHookOptions<DeleteAnulacionExtractoMutation, DeleteAnulacionExtractoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteAnulacionExtractoMutation, DeleteAnulacionExtractoMutationVariables>(DeleteAnulacionExtractoDocument, options);
      }
export type DeleteAnulacionExtractoMutationHookResult = ReturnType<typeof useDeleteAnulacionExtractoMutation>;
export type DeleteAnulacionExtractoMutationResult = Apollo.MutationResult<DeleteAnulacionExtractoMutation>;
export type DeleteAnulacionExtractoMutationOptions = Apollo.BaseMutationOptions<DeleteAnulacionExtractoMutation, DeleteAnulacionExtractoMutationVariables>;
export const DeleteCorredorDocument = gql`
    mutation deleteCorredor($usuario_id: Int!) {
  deleteCorredor(usuario_id: $usuario_id)
}
    `;
export type DeleteCorredorMutationFn = Apollo.MutationFunction<DeleteCorredorMutation, DeleteCorredorMutationVariables>;

/**
 * __useDeleteCorredorMutation__
 *
 * To run a mutation, you first call `useDeleteCorredorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCorredorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCorredorMutation, { data, loading, error }] = useDeleteCorredorMutation({
 *   variables: {
 *      usuario_id: // value for 'usuario_id'
 *   },
 * });
 */
export function useDeleteCorredorMutation(baseOptions?: Apollo.MutationHookOptions<DeleteCorredorMutation, DeleteCorredorMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteCorredorMutation, DeleteCorredorMutationVariables>(DeleteCorredorDocument, options);
      }
export type DeleteCorredorMutationHookResult = ReturnType<typeof useDeleteCorredorMutation>;
export type DeleteCorredorMutationResult = Apollo.MutationResult<DeleteCorredorMutation>;
export type DeleteCorredorMutationOptions = Apollo.BaseMutationOptions<DeleteCorredorMutation, DeleteCorredorMutationVariables>;
export const DeshabilitarCorredorDocument = gql`
    mutation deshabilitarCorredor($corredor_id: ID!) {
  deshabilitarCorredor(corredor_id: $corredor_id)
}
    `;
export type DeshabilitarCorredorMutationFn = Apollo.MutationFunction<DeshabilitarCorredorMutation, DeshabilitarCorredorMutationVariables>;

/**
 * __useDeshabilitarCorredorMutation__
 *
 * To run a mutation, you first call `useDeshabilitarCorredorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeshabilitarCorredorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deshabilitarCorredorMutation, { data, loading, error }] = useDeshabilitarCorredorMutation({
 *   variables: {
 *      corredor_id: // value for 'corredor_id'
 *   },
 * });
 */
export function useDeshabilitarCorredorMutation(baseOptions?: Apollo.MutationHookOptions<DeshabilitarCorredorMutation, DeshabilitarCorredorMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeshabilitarCorredorMutation, DeshabilitarCorredorMutationVariables>(DeshabilitarCorredorDocument, options);
      }
export type DeshabilitarCorredorMutationHookResult = ReturnType<typeof useDeshabilitarCorredorMutation>;
export type DeshabilitarCorredorMutationResult = Apollo.MutationResult<DeshabilitarCorredorMutation>;
export type DeshabilitarCorredorMutationOptions = Apollo.BaseMutationOptions<DeshabilitarCorredorMutation, DeshabilitarCorredorMutationVariables>;
export const EditarDocCorredorDocument = gql`
    mutation editarDocCorredor($ci: String, $usuario_id: Int) {
  editarCi(ci: $ci, usuario_id: $usuario_id)
}
    `;
export type EditarDocCorredorMutationFn = Apollo.MutationFunction<EditarDocCorredorMutation, EditarDocCorredorMutationVariables>;

/**
 * __useEditarDocCorredorMutation__
 *
 * To run a mutation, you first call `useEditarDocCorredorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditarDocCorredorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editarDocCorredorMutation, { data, loading, error }] = useEditarDocCorredorMutation({
 *   variables: {
 *      ci: // value for 'ci'
 *      usuario_id: // value for 'usuario_id'
 *   },
 * });
 */
export function useEditarDocCorredorMutation(baseOptions?: Apollo.MutationHookOptions<EditarDocCorredorMutation, EditarDocCorredorMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditarDocCorredorMutation, EditarDocCorredorMutationVariables>(EditarDocCorredorDocument, options);
      }
export type EditarDocCorredorMutationHookResult = ReturnType<typeof useEditarDocCorredorMutation>;
export type EditarDocCorredorMutationResult = Apollo.MutationResult<EditarDocCorredorMutation>;
export type EditarDocCorredorMutationOptions = Apollo.BaseMutationOptions<EditarDocCorredorMutation, EditarDocCorredorMutationVariables>;
export const ChangeDataUmnDocument = gql`
    mutation changeDataUMN($umn_id: [ID!], $nombre: String, $numero: Int) {
  changeDataUMN(umn_id: $umn_id, nombre: $nombre, numero: $numero) {
    id
    nombre
    numero
    agencia_numero
  }
}
    `;
export type ChangeDataUmnMutationFn = Apollo.MutationFunction<ChangeDataUmnMutation, ChangeDataUmnMutationVariables>;

/**
 * __useChangeDataUmnMutation__
 *
 * To run a mutation, you first call `useChangeDataUmnMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeDataUmnMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeDataUmnMutation, { data, loading, error }] = useChangeDataUmnMutation({
 *   variables: {
 *      umn_id: // value for 'umn_id'
 *      nombre: // value for 'nombre'
 *      numero: // value for 'numero'
 *   },
 * });
 */
export function useChangeDataUmnMutation(baseOptions?: Apollo.MutationHookOptions<ChangeDataUmnMutation, ChangeDataUmnMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangeDataUmnMutation, ChangeDataUmnMutationVariables>(ChangeDataUmnDocument, options);
      }
export type ChangeDataUmnMutationHookResult = ReturnType<typeof useChangeDataUmnMutation>;
export type ChangeDataUmnMutationResult = Apollo.MutationResult<ChangeDataUmnMutation>;
export type ChangeDataUmnMutationOptions = Apollo.BaseMutationOptions<ChangeDataUmnMutation, ChangeDataUmnMutationVariables>;
export const EditarEntregaDeExtractoDocument = gql`
    mutation editarEntregaDeExtracto($extracto_id: ID!, $monto: Int!) {
  editarEntregaDeExtracto(extracto_id: $extracto_id, monto: $monto)
}
    `;
export type EditarEntregaDeExtractoMutationFn = Apollo.MutationFunction<EditarEntregaDeExtractoMutation, EditarEntregaDeExtractoMutationVariables>;

/**
 * __useEditarEntregaDeExtractoMutation__
 *
 * To run a mutation, you first call `useEditarEntregaDeExtractoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditarEntregaDeExtractoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editarEntregaDeExtractoMutation, { data, loading, error }] = useEditarEntregaDeExtractoMutation({
 *   variables: {
 *      extracto_id: // value for 'extracto_id'
 *      monto: // value for 'monto'
 *   },
 * });
 */
export function useEditarEntregaDeExtractoMutation(baseOptions?: Apollo.MutationHookOptions<EditarEntregaDeExtractoMutation, EditarEntregaDeExtractoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditarEntregaDeExtractoMutation, EditarEntregaDeExtractoMutationVariables>(EditarEntregaDeExtractoDocument, options);
      }
export type EditarEntregaDeExtractoMutationHookResult = ReturnType<typeof useEditarEntregaDeExtractoMutation>;
export type EditarEntregaDeExtractoMutationResult = Apollo.MutationResult<EditarEntregaDeExtractoMutation>;
export type EditarEntregaDeExtractoMutationOptions = Apollo.BaseMutationOptions<EditarEntregaDeExtractoMutation, EditarEntregaDeExtractoMutationVariables>;
export const EditarNumeroCorredorDocument = gql`
    mutation editarNumeroCorredor($corredor_id: Int, $numero: Int) {
  editarNumeroCorredor(corredor_id: $corredor_id, numero: $numero)
}
    `;
export type EditarNumeroCorredorMutationFn = Apollo.MutationFunction<EditarNumeroCorredorMutation, EditarNumeroCorredorMutationVariables>;

/**
 * __useEditarNumeroCorredorMutation__
 *
 * To run a mutation, you first call `useEditarNumeroCorredorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditarNumeroCorredorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editarNumeroCorredorMutation, { data, loading, error }] = useEditarNumeroCorredorMutation({
 *   variables: {
 *      corredor_id: // value for 'corredor_id'
 *      numero: // value for 'numero'
 *   },
 * });
 */
export function useEditarNumeroCorredorMutation(baseOptions?: Apollo.MutationHookOptions<EditarNumeroCorredorMutation, EditarNumeroCorredorMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditarNumeroCorredorMutation, EditarNumeroCorredorMutationVariables>(EditarNumeroCorredorDocument, options);
      }
export type EditarNumeroCorredorMutationHookResult = ReturnType<typeof useEditarNumeroCorredorMutation>;
export type EditarNumeroCorredorMutationResult = Apollo.MutationResult<EditarNumeroCorredorMutation>;
export type EditarNumeroCorredorMutationOptions = Apollo.BaseMutationOptions<EditarNumeroCorredorMutation, EditarNumeroCorredorMutationVariables>;
export const EditarNumeroTelefonoCorredorDocument = gql`
    mutation editarNumeroTelefonoCorredor($corredor_id: Int, $numero: String!) {
  editarNumeroTelefonoCorredor(corredor_id: $corredor_id, numero: $numero)
}
    `;
export type EditarNumeroTelefonoCorredorMutationFn = Apollo.MutationFunction<EditarNumeroTelefonoCorredorMutation, EditarNumeroTelefonoCorredorMutationVariables>;

/**
 * __useEditarNumeroTelefonoCorredorMutation__
 *
 * To run a mutation, you first call `useEditarNumeroTelefonoCorredorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditarNumeroTelefonoCorredorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editarNumeroTelefonoCorredorMutation, { data, loading, error }] = useEditarNumeroTelefonoCorredorMutation({
 *   variables: {
 *      corredor_id: // value for 'corredor_id'
 *      numero: // value for 'numero'
 *   },
 * });
 */
export function useEditarNumeroTelefonoCorredorMutation(baseOptions?: Apollo.MutationHookOptions<EditarNumeroTelefonoCorredorMutation, EditarNumeroTelefonoCorredorMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditarNumeroTelefonoCorredorMutation, EditarNumeroTelefonoCorredorMutationVariables>(EditarNumeroTelefonoCorredorDocument, options);
      }
export type EditarNumeroTelefonoCorredorMutationHookResult = ReturnType<typeof useEditarNumeroTelefonoCorredorMutation>;
export type EditarNumeroTelefonoCorredorMutationResult = Apollo.MutationResult<EditarNumeroTelefonoCorredorMutation>;
export type EditarNumeroTelefonoCorredorMutationOptions = Apollo.BaseMutationOptions<EditarNumeroTelefonoCorredorMutation, EditarNumeroTelefonoCorredorMutationVariables>;
export const EventoCajasDocument = gql`
    mutation eventoCajas($images: [FileUpload], $motivo: String!, $terminalId: ID!, $cajaId: ID!, $corredorId: ID!, $estadoPos: Int!) {
  eventoCajas(
    images: $images
    motivo: $motivo
    terminalId: $terminalId
    cajaId: $cajaId
    corredorId: $corredorId
    estadoPos: $estadoPos
  )
}
    `;
export type EventoCajasMutationFn = Apollo.MutationFunction<EventoCajasMutation, EventoCajasMutationVariables>;

/**
 * __useEventoCajasMutation__
 *
 * To run a mutation, you first call `useEventoCajasMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEventoCajasMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [eventoCajasMutation, { data, loading, error }] = useEventoCajasMutation({
 *   variables: {
 *      images: // value for 'images'
 *      motivo: // value for 'motivo'
 *      terminalId: // value for 'terminalId'
 *      cajaId: // value for 'cajaId'
 *      corredorId: // value for 'corredorId'
 *      estadoPos: // value for 'estadoPos'
 *   },
 * });
 */
export function useEventoCajasMutation(baseOptions?: Apollo.MutationHookOptions<EventoCajasMutation, EventoCajasMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EventoCajasMutation, EventoCajasMutationVariables>(EventoCajasDocument, options);
      }
export type EventoCajasMutationHookResult = ReturnType<typeof useEventoCajasMutation>;
export type EventoCajasMutationResult = Apollo.MutationResult<EventoCajasMutation>;
export type EventoCajasMutationOptions = Apollo.BaseMutationOptions<EventoCajasMutation, EventoCajasMutationVariables>;
export const GenerarAjusteDocument = gql`
    mutation generarAjuste($referencia: String!, $corredor_id: Int!, $monto: Int!, $detalle: String!) {
  generarAjuste(
    referencia: $referencia
    corredor_id: $corredor_id
    monto: $monto
    detalle: $detalle
  ) {
    id
    estado
    created_at
    monto
    Corredor {
      id
      numero
      cuenta
      linea
    }
  }
}
    `;
export type GenerarAjusteMutationFn = Apollo.MutationFunction<GenerarAjusteMutation, GenerarAjusteMutationVariables>;

/**
 * __useGenerarAjusteMutation__
 *
 * To run a mutation, you first call `useGenerarAjusteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerarAjusteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generarAjusteMutation, { data, loading, error }] = useGenerarAjusteMutation({
 *   variables: {
 *      referencia: // value for 'referencia'
 *      corredor_id: // value for 'corredor_id'
 *      monto: // value for 'monto'
 *      detalle: // value for 'detalle'
 *   },
 * });
 */
export function useGenerarAjusteMutation(baseOptions?: Apollo.MutationHookOptions<GenerarAjusteMutation, GenerarAjusteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GenerarAjusteMutation, GenerarAjusteMutationVariables>(GenerarAjusteDocument, options);
      }
export type GenerarAjusteMutationHookResult = ReturnType<typeof useGenerarAjusteMutation>;
export type GenerarAjusteMutationResult = Apollo.MutationResult<GenerarAjusteMutation>;
export type GenerarAjusteMutationOptions = Apollo.BaseMutationOptions<GenerarAjusteMutation, GenerarAjusteMutationVariables>;
export const GenerarAjusteUmnDocument = gql`
    mutation generarAjusteUmn($umn_id: Int!, $monto: Int!, $detalle: String!, $referencia: String!, $tipo: String!) {
  generarAjusteUmn(
    umn_id: $umn_id
    monto: $monto
    detalle: $detalle
    referencia: $referencia
    tipo: $tipo
  ) {
    id
    aprobacion {
      id
      username
    }
    monto
    detalle
    Umn {
      id
      numero
      nombre
    }
  }
}
    `;
export type GenerarAjusteUmnMutationFn = Apollo.MutationFunction<GenerarAjusteUmnMutation, GenerarAjusteUmnMutationVariables>;

/**
 * __useGenerarAjusteUmnMutation__
 *
 * To run a mutation, you first call `useGenerarAjusteUmnMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerarAjusteUmnMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generarAjusteUmnMutation, { data, loading, error }] = useGenerarAjusteUmnMutation({
 *   variables: {
 *      umn_id: // value for 'umn_id'
 *      monto: // value for 'monto'
 *      detalle: // value for 'detalle'
 *      referencia: // value for 'referencia'
 *      tipo: // value for 'tipo'
 *   },
 * });
 */
export function useGenerarAjusteUmnMutation(baseOptions?: Apollo.MutationHookOptions<GenerarAjusteUmnMutation, GenerarAjusteUmnMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GenerarAjusteUmnMutation, GenerarAjusteUmnMutationVariables>(GenerarAjusteUmnDocument, options);
      }
export type GenerarAjusteUmnMutationHookResult = ReturnType<typeof useGenerarAjusteUmnMutation>;
export type GenerarAjusteUmnMutationResult = Apollo.MutationResult<GenerarAjusteUmnMutation>;
export type GenerarAjusteUmnMutationOptions = Apollo.BaseMutationOptions<GenerarAjusteUmnMutation, GenerarAjusteUmnMutationVariables>;
export const GenerarCobroDocument = gql`
    mutation generarCobro($cobrador_id: Int!, $corredor_id: Int!, $referencia: String!, $monto: Int!) {
  generarCobro(
    cobrador_id: $cobrador_id
    corredor_id: $corredor_id
    referencia: $referencia
    monto: $monto
  ) {
    id
  }
}
    `;
export type GenerarCobroMutationFn = Apollo.MutationFunction<GenerarCobroMutation, GenerarCobroMutationVariables>;

/**
 * __useGenerarCobroMutation__
 *
 * To run a mutation, you first call `useGenerarCobroMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerarCobroMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generarCobroMutation, { data, loading, error }] = useGenerarCobroMutation({
 *   variables: {
 *      cobrador_id: // value for 'cobrador_id'
 *      corredor_id: // value for 'corredor_id'
 *      referencia: // value for 'referencia'
 *      monto: // value for 'monto'
 *   },
 * });
 */
export function useGenerarCobroMutation(baseOptions?: Apollo.MutationHookOptions<GenerarCobroMutation, GenerarCobroMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GenerarCobroMutation, GenerarCobroMutationVariables>(GenerarCobroDocument, options);
      }
export type GenerarCobroMutationHookResult = ReturnType<typeof useGenerarCobroMutation>;
export type GenerarCobroMutationResult = Apollo.MutationResult<GenerarCobroMutation>;
export type GenerarCobroMutationOptions = Apollo.BaseMutationOptions<GenerarCobroMutation, GenerarCobroMutationVariables>;
export const GenerarDepositoDocument = gql`
    mutation generarDeposito($medio: String, $referencia: String!, $umn_id: ID!, $detalle: String, $monto: Int, $nro_recibo: Int, $fecha: Date) {
  generarDeposito(
    medio: $medio
    referencia: $referencia
    umn_id: $umn_id
    detalle: $detalle
    monto: $monto
    nro_recibo: $nro_recibo
    fecha: $fecha
  ) {
    id
    monto
    medio
  }
}
    `;
export type GenerarDepositoMutationFn = Apollo.MutationFunction<GenerarDepositoMutation, GenerarDepositoMutationVariables>;

/**
 * __useGenerarDepositoMutation__
 *
 * To run a mutation, you first call `useGenerarDepositoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerarDepositoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generarDepositoMutation, { data, loading, error }] = useGenerarDepositoMutation({
 *   variables: {
 *      medio: // value for 'medio'
 *      referencia: // value for 'referencia'
 *      umn_id: // value for 'umn_id'
 *      detalle: // value for 'detalle'
 *      monto: // value for 'monto'
 *      nro_recibo: // value for 'nro_recibo'
 *      fecha: // value for 'fecha'
 *   },
 * });
 */
export function useGenerarDepositoMutation(baseOptions?: Apollo.MutationHookOptions<GenerarDepositoMutation, GenerarDepositoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GenerarDepositoMutation, GenerarDepositoMutationVariables>(GenerarDepositoDocument, options);
      }
export type GenerarDepositoMutationHookResult = ReturnType<typeof useGenerarDepositoMutation>;
export type GenerarDepositoMutationResult = Apollo.MutationResult<GenerarDepositoMutation>;
export type GenerarDepositoMutationOptions = Apollo.BaseMutationOptions<GenerarDepositoMutation, GenerarDepositoMutationVariables>;
export const GenerarSolicitudDepositoDocument = gql`
    mutation generarSolicitudDeposito($comprobante: String, $banco: String, $monto: Int, $umn_id: ID) {
  solicitudDeposito(
    comprobante: $comprobante
    banco: $banco
    monto: $monto
    umn_id: $umn_id
  )
}
    `;
export type GenerarSolicitudDepositoMutationFn = Apollo.MutationFunction<GenerarSolicitudDepositoMutation, GenerarSolicitudDepositoMutationVariables>;

/**
 * __useGenerarSolicitudDepositoMutation__
 *
 * To run a mutation, you first call `useGenerarSolicitudDepositoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerarSolicitudDepositoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generarSolicitudDepositoMutation, { data, loading, error }] = useGenerarSolicitudDepositoMutation({
 *   variables: {
 *      comprobante: // value for 'comprobante'
 *      banco: // value for 'banco'
 *      monto: // value for 'monto'
 *      umn_id: // value for 'umn_id'
 *   },
 * });
 */
export function useGenerarSolicitudDepositoMutation(baseOptions?: Apollo.MutationHookOptions<GenerarSolicitudDepositoMutation, GenerarSolicitudDepositoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GenerarSolicitudDepositoMutation, GenerarSolicitudDepositoMutationVariables>(GenerarSolicitudDepositoDocument, options);
      }
export type GenerarSolicitudDepositoMutationHookResult = ReturnType<typeof useGenerarSolicitudDepositoMutation>;
export type GenerarSolicitudDepositoMutationResult = Apollo.MutationResult<GenerarSolicitudDepositoMutation>;
export type GenerarSolicitudDepositoMutationOptions = Apollo.BaseMutationOptions<GenerarSolicitudDepositoMutation, GenerarSolicitudDepositoMutationVariables>;
export const GenerateWuTransactionRequestDocument = gql`
    mutation generateWUTransactionRequest($mtcn: String!, $reference: String!) {
  generateWUTransactionRequest(mtcn: $mtcn, reference: $reference)
}
    `;
export type GenerateWuTransactionRequestMutationFn = Apollo.MutationFunction<GenerateWuTransactionRequestMutation, GenerateWuTransactionRequestMutationVariables>;

/**
 * __useGenerateWuTransactionRequestMutation__
 *
 * To run a mutation, you first call `useGenerateWuTransactionRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateWuTransactionRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateWuTransactionRequestMutation, { data, loading, error }] = useGenerateWuTransactionRequestMutation({
 *   variables: {
 *      mtcn: // value for 'mtcn'
 *      reference: // value for 'reference'
 *   },
 * });
 */
export function useGenerateWuTransactionRequestMutation(baseOptions?: Apollo.MutationHookOptions<GenerateWuTransactionRequestMutation, GenerateWuTransactionRequestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GenerateWuTransactionRequestMutation, GenerateWuTransactionRequestMutationVariables>(GenerateWuTransactionRequestDocument, options);
      }
export type GenerateWuTransactionRequestMutationHookResult = ReturnType<typeof useGenerateWuTransactionRequestMutation>;
export type GenerateWuTransactionRequestMutationResult = Apollo.MutationResult<GenerateWuTransactionRequestMutation>;
export type GenerateWuTransactionRequestMutationOptions = Apollo.BaseMutationOptions<GenerateWuTransactionRequestMutation, GenerateWuTransactionRequestMutationVariables>;
export const GenerateWuTransaccionDocument = gql`
    mutation generateWuTransaccion($client_id: String!, $reference: String!, $motivo_transaccion: String!) {
  generateWuTransaccion(
    client_id: $client_id
    reference: $reference
    motivo_transaccion: $motivo_transaccion
  )
}
    `;
export type GenerateWuTransaccionMutationFn = Apollo.MutationFunction<GenerateWuTransaccionMutation, GenerateWuTransaccionMutationVariables>;

/**
 * __useGenerateWuTransaccionMutation__
 *
 * To run a mutation, you first call `useGenerateWuTransaccionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateWuTransaccionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateWuTransaccionMutation, { data, loading, error }] = useGenerateWuTransaccionMutation({
 *   variables: {
 *      client_id: // value for 'client_id'
 *      reference: // value for 'reference'
 *      motivo_transaccion: // value for 'motivo_transaccion'
 *   },
 * });
 */
export function useGenerateWuTransaccionMutation(baseOptions?: Apollo.MutationHookOptions<GenerateWuTransaccionMutation, GenerateWuTransaccionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GenerateWuTransaccionMutation, GenerateWuTransaccionMutationVariables>(GenerateWuTransaccionDocument, options);
      }
export type GenerateWuTransaccionMutationHookResult = ReturnType<typeof useGenerateWuTransaccionMutation>;
export type GenerateWuTransaccionMutationResult = Apollo.MutationResult<GenerateWuTransaccionMutation>;
export type GenerateWuTransaccionMutationOptions = Apollo.BaseMutationOptions<GenerateWuTransaccionMutation, GenerateWuTransaccionMutationVariables>;
export const GetComisionesExcelDocument = gql`
    mutation getComisionesExcel($fecha__gte: String, $fecha__lte: String, $agencia_numero: String) {
  getComisionesExcel(
    fecha__gte: $fecha__gte
    fecha__lte: $fecha__lte
    agencia_numero: $agencia_numero
  )
}
    `;
export type GetComisionesExcelMutationFn = Apollo.MutationFunction<GetComisionesExcelMutation, GetComisionesExcelMutationVariables>;

/**
 * __useGetComisionesExcelMutation__
 *
 * To run a mutation, you first call `useGetComisionesExcelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGetComisionesExcelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [getComisionesExcelMutation, { data, loading, error }] = useGetComisionesExcelMutation({
 *   variables: {
 *      fecha__gte: // value for 'fecha__gte'
 *      fecha__lte: // value for 'fecha__lte'
 *      agencia_numero: // value for 'agencia_numero'
 *   },
 * });
 */
export function useGetComisionesExcelMutation(baseOptions?: Apollo.MutationHookOptions<GetComisionesExcelMutation, GetComisionesExcelMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GetComisionesExcelMutation, GetComisionesExcelMutationVariables>(GetComisionesExcelDocument, options);
      }
export type GetComisionesExcelMutationHookResult = ReturnType<typeof useGetComisionesExcelMutation>;
export type GetComisionesExcelMutationResult = Apollo.MutationResult<GetComisionesExcelMutation>;
export type GetComisionesExcelMutationOptions = Apollo.BaseMutationOptions<GetComisionesExcelMutation, GetComisionesExcelMutationVariables>;
export const GetTicketDocument = gql`
    mutation getTicket($reference: String!) {
  getTicket(reference: $reference)
}
    `;
export type GetTicketMutationFn = Apollo.MutationFunction<GetTicketMutation, GetTicketMutationVariables>;

/**
 * __useGetTicketMutation__
 *
 * To run a mutation, you first call `useGetTicketMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGetTicketMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [getTicketMutation, { data, loading, error }] = useGetTicketMutation({
 *   variables: {
 *      reference: // value for 'reference'
 *   },
 * });
 */
export function useGetTicketMutation(baseOptions?: Apollo.MutationHookOptions<GetTicketMutation, GetTicketMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GetTicketMutation, GetTicketMutationVariables>(GetTicketDocument, options);
      }
export type GetTicketMutationHookResult = ReturnType<typeof useGetTicketMutation>;
export type GetTicketMutationResult = Apollo.MutationResult<GetTicketMutation>;
export type GetTicketMutationOptions = Apollo.BaseMutationOptions<GetTicketMutation, GetTicketMutationVariables>;
export const InhabilitarDocument = gql`
    mutation inhabilitar($corredor_id: Int!) {
  inhabilitarCorredor(corredor_id: $corredor_id)
}
    `;
export type InhabilitarMutationFn = Apollo.MutationFunction<InhabilitarMutation, InhabilitarMutationVariables>;

/**
 * __useInhabilitarMutation__
 *
 * To run a mutation, you first call `useInhabilitarMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInhabilitarMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [inhabilitarMutation, { data, loading, error }] = useInhabilitarMutation({
 *   variables: {
 *      corredor_id: // value for 'corredor_id'
 *   },
 * });
 */
export function useInhabilitarMutation(baseOptions?: Apollo.MutationHookOptions<InhabilitarMutation, InhabilitarMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InhabilitarMutation, InhabilitarMutationVariables>(InhabilitarDocument, options);
      }
export type InhabilitarMutationHookResult = ReturnType<typeof useInhabilitarMutation>;
export type InhabilitarMutationResult = Apollo.MutationResult<InhabilitarMutation>;
export type InhabilitarMutationOptions = Apollo.BaseMutationOptions<InhabilitarMutation, InhabilitarMutationVariables>;
export const LiquidarDocument = gql`
    mutation liquidar($sorteo_id: ID!) {
  liquidarSorteo(sorteo_id: $sorteo_id) {
    fecha
  }
}
    `;
export type LiquidarMutationFn = Apollo.MutationFunction<LiquidarMutation, LiquidarMutationVariables>;

/**
 * __useLiquidarMutation__
 *
 * To run a mutation, you first call `useLiquidarMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLiquidarMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [liquidarMutation, { data, loading, error }] = useLiquidarMutation({
 *   variables: {
 *      sorteo_id: // value for 'sorteo_id'
 *   },
 * });
 */
export function useLiquidarMutation(baseOptions?: Apollo.MutationHookOptions<LiquidarMutation, LiquidarMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LiquidarMutation, LiquidarMutationVariables>(LiquidarDocument, options);
      }
export type LiquidarMutationHookResult = ReturnType<typeof useLiquidarMutation>;
export type LiquidarMutationResult = Apollo.MutationResult<LiquidarMutation>;
export type LiquidarMutationOptions = Apollo.BaseMutationOptions<LiquidarMutation, LiquidarMutationVariables>;
export const ModificarEstadoCorredorDocument = gql`
    mutation modificarEstadoCorredor($corredor_id: Int!) {
  modificarEstadoCorredor(corredor_id: $corredor_id)
}
    `;
export type ModificarEstadoCorredorMutationFn = Apollo.MutationFunction<ModificarEstadoCorredorMutation, ModificarEstadoCorredorMutationVariables>;

/**
 * __useModificarEstadoCorredorMutation__
 *
 * To run a mutation, you first call `useModificarEstadoCorredorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useModificarEstadoCorredorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [modificarEstadoCorredorMutation, { data, loading, error }] = useModificarEstadoCorredorMutation({
 *   variables: {
 *      corredor_id: // value for 'corredor_id'
 *   },
 * });
 */
export function useModificarEstadoCorredorMutation(baseOptions?: Apollo.MutationHookOptions<ModificarEstadoCorredorMutation, ModificarEstadoCorredorMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ModificarEstadoCorredorMutation, ModificarEstadoCorredorMutationVariables>(ModificarEstadoCorredorDocument, options);
      }
export type ModificarEstadoCorredorMutationHookResult = ReturnType<typeof useModificarEstadoCorredorMutation>;
export type ModificarEstadoCorredorMutationResult = Apollo.MutationResult<ModificarEstadoCorredorMutation>;
export type ModificarEstadoCorredorMutationOptions = Apollo.BaseMutationOptions<ModificarEstadoCorredorMutation, ModificarEstadoCorredorMutationVariables>;
export const ModificarLineaDocument = gql`
    mutation modificarLinea($corredor_id: Int!, $monto: Int!, $soloCaja: Boolean) {
  modificarLinea(corredor_id: $corredor_id, monto: $monto, soloCaja: $soloCaja)
}
    `;
export type ModificarLineaMutationFn = Apollo.MutationFunction<ModificarLineaMutation, ModificarLineaMutationVariables>;

/**
 * __useModificarLineaMutation__
 *
 * To run a mutation, you first call `useModificarLineaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useModificarLineaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [modificarLineaMutation, { data, loading, error }] = useModificarLineaMutation({
 *   variables: {
 *      corredor_id: // value for 'corredor_id'
 *      monto: // value for 'monto'
 *      soloCaja: // value for 'soloCaja'
 *   },
 * });
 */
export function useModificarLineaMutation(baseOptions?: Apollo.MutationHookOptions<ModificarLineaMutation, ModificarLineaMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ModificarLineaMutation, ModificarLineaMutationVariables>(ModificarLineaDocument, options);
      }
export type ModificarLineaMutationHookResult = ReturnType<typeof useModificarLineaMutation>;
export type ModificarLineaMutationResult = Apollo.MutationResult<ModificarLineaMutation>;
export type ModificarLineaMutationOptions = Apollo.BaseMutationOptions<ModificarLineaMutation, ModificarLineaMutationVariables>;
export const OperativaDocument = gql`
    mutation operativa($sorteo_id: ID!, $resultados: [String]!) {
  setResultadoSorteo(sorteo_id: $sorteo_id, resultados: $resultados) {
    resultados
  }
}
    `;
export type OperativaMutationFn = Apollo.MutationFunction<OperativaMutation, OperativaMutationVariables>;

/**
 * __useOperativaMutation__
 *
 * To run a mutation, you first call `useOperativaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOperativaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [operativaMutation, { data, loading, error }] = useOperativaMutation({
 *   variables: {
 *      sorteo_id: // value for 'sorteo_id'
 *      resultados: // value for 'resultados'
 *   },
 * });
 */
export function useOperativaMutation(baseOptions?: Apollo.MutationHookOptions<OperativaMutation, OperativaMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<OperativaMutation, OperativaMutationVariables>(OperativaDocument, options);
      }
export type OperativaMutationHookResult = ReturnType<typeof useOperativaMutation>;
export type OperativaMutationResult = Apollo.MutationResult<OperativaMutation>;
export type OperativaMutationOptions = Apollo.BaseMutationOptions<OperativaMutation, OperativaMutationVariables>;
export const PagarCuotasTeeteAsgardDocument = gql`
    mutation pagarCuotasTeeteAsgard($banco: String, $comprobante: String, $monto_total: Int, $cuotas: [JSON]) {
  pagarCuotasTeeteAsgard(
    banco: $banco
    comprobante: $comprobante
    monto_total: $monto_total
    cuotas: $cuotas
  )
}
    `;
export type PagarCuotasTeeteAsgardMutationFn = Apollo.MutationFunction<PagarCuotasTeeteAsgardMutation, PagarCuotasTeeteAsgardMutationVariables>;

/**
 * __usePagarCuotasTeeteAsgardMutation__
 *
 * To run a mutation, you first call `usePagarCuotasTeeteAsgardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePagarCuotasTeeteAsgardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [pagarCuotasTeeteAsgardMutation, { data, loading, error }] = usePagarCuotasTeeteAsgardMutation({
 *   variables: {
 *      banco: // value for 'banco'
 *      comprobante: // value for 'comprobante'
 *      monto_total: // value for 'monto_total'
 *      cuotas: // value for 'cuotas'
 *   },
 * });
 */
export function usePagarCuotasTeeteAsgardMutation(baseOptions?: Apollo.MutationHookOptions<PagarCuotasTeeteAsgardMutation, PagarCuotasTeeteAsgardMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PagarCuotasTeeteAsgardMutation, PagarCuotasTeeteAsgardMutationVariables>(PagarCuotasTeeteAsgardDocument, options);
      }
export type PagarCuotasTeeteAsgardMutationHookResult = ReturnType<typeof usePagarCuotasTeeteAsgardMutation>;
export type PagarCuotasTeeteAsgardMutationResult = Apollo.MutationResult<PagarCuotasTeeteAsgardMutation>;
export type PagarCuotasTeeteAsgardMutationOptions = Apollo.BaseMutationOptions<PagarCuotasTeeteAsgardMutation, PagarCuotasTeeteAsgardMutationVariables>;
export const PremiarBoletasByCodeDocument = gql`
    mutation premiarBoletasByCode($umn_id: ID!, $code: [String]!) {
  premiarBoletasByCodes(umn_id: $umn_id, code: $code)
}
    `;
export type PremiarBoletasByCodeMutationFn = Apollo.MutationFunction<PremiarBoletasByCodeMutation, PremiarBoletasByCodeMutationVariables>;

/**
 * __usePremiarBoletasByCodeMutation__
 *
 * To run a mutation, you first call `usePremiarBoletasByCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePremiarBoletasByCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [premiarBoletasByCodeMutation, { data, loading, error }] = usePremiarBoletasByCodeMutation({
 *   variables: {
 *      umn_id: // value for 'umn_id'
 *      code: // value for 'code'
 *   },
 * });
 */
export function usePremiarBoletasByCodeMutation(baseOptions?: Apollo.MutationHookOptions<PremiarBoletasByCodeMutation, PremiarBoletasByCodeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PremiarBoletasByCodeMutation, PremiarBoletasByCodeMutationVariables>(PremiarBoletasByCodeDocument, options);
      }
export type PremiarBoletasByCodeMutationHookResult = ReturnType<typeof usePremiarBoletasByCodeMutation>;
export type PremiarBoletasByCodeMutationResult = Apollo.MutationResult<PremiarBoletasByCodeMutation>;
export type PremiarBoletasByCodeMutationOptions = Apollo.BaseMutationOptions<PremiarBoletasByCodeMutation, PremiarBoletasByCodeMutationVariables>;
export const RecalcularExtractoDesdeCierreCajaDocument = gql`
    mutation recalcularExtractoDesdeCierreCaja($extracto_id: ID!) {
  recalcularExtractoDesdeCierreCaja(extracto_id: $extracto_id)
}
    `;
export type RecalcularExtractoDesdeCierreCajaMutationFn = Apollo.MutationFunction<RecalcularExtractoDesdeCierreCajaMutation, RecalcularExtractoDesdeCierreCajaMutationVariables>;

/**
 * __useRecalcularExtractoDesdeCierreCajaMutation__
 *
 * To run a mutation, you first call `useRecalcularExtractoDesdeCierreCajaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRecalcularExtractoDesdeCierreCajaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [recalcularExtractoDesdeCierreCajaMutation, { data, loading, error }] = useRecalcularExtractoDesdeCierreCajaMutation({
 *   variables: {
 *      extracto_id: // value for 'extracto_id'
 *   },
 * });
 */
export function useRecalcularExtractoDesdeCierreCajaMutation(baseOptions?: Apollo.MutationHookOptions<RecalcularExtractoDesdeCierreCajaMutation, RecalcularExtractoDesdeCierreCajaMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RecalcularExtractoDesdeCierreCajaMutation, RecalcularExtractoDesdeCierreCajaMutationVariables>(RecalcularExtractoDesdeCierreCajaDocument, options);
      }
export type RecalcularExtractoDesdeCierreCajaMutationHookResult = ReturnType<typeof useRecalcularExtractoDesdeCierreCajaMutation>;
export type RecalcularExtractoDesdeCierreCajaMutationResult = Apollo.MutationResult<RecalcularExtractoDesdeCierreCajaMutation>;
export type RecalcularExtractoDesdeCierreCajaMutationOptions = Apollo.BaseMutationOptions<RecalcularExtractoDesdeCierreCajaMutation, RecalcularExtractoDesdeCierreCajaMutationVariables>;
export const ReconsolidarDocument = gql`
    mutation reconsolidar($desde: Date, $hasta: Date, $umn_id: [ID]) {
  reconsolidar(desde: $desde, hasta: $hasta, umn_id: $umn_id)
}
    `;
export type ReconsolidarMutationFn = Apollo.MutationFunction<ReconsolidarMutation, ReconsolidarMutationVariables>;

/**
 * __useReconsolidarMutation__
 *
 * To run a mutation, you first call `useReconsolidarMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReconsolidarMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reconsolidarMutation, { data, loading, error }] = useReconsolidarMutation({
 *   variables: {
 *      desde: // value for 'desde'
 *      hasta: // value for 'hasta'
 *      umn_id: // value for 'umn_id'
 *   },
 * });
 */
export function useReconsolidarMutation(baseOptions?: Apollo.MutationHookOptions<ReconsolidarMutation, ReconsolidarMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ReconsolidarMutation, ReconsolidarMutationVariables>(ReconsolidarDocument, options);
      }
export type ReconsolidarMutationHookResult = ReturnType<typeof useReconsolidarMutation>;
export type ReconsolidarMutationResult = Apollo.MutationResult<ReconsolidarMutation>;
export type ReconsolidarMutationOptions = Apollo.BaseMutationOptions<ReconsolidarMutation, ReconsolidarMutationVariables>;
export const VerificarPremioMegalotoDocument = gql`
    mutation verificarPremioMegaloto($pedidoId: ID!, $umnId: ID!) {
  verificarPremioMegaloto(pedidoId: $pedidoId, umnId: $umnId)
}
    `;
export type VerificarPremioMegalotoMutationFn = Apollo.MutationFunction<VerificarPremioMegalotoMutation, VerificarPremioMegalotoMutationVariables>;

/**
 * __useVerificarPremioMegalotoMutation__
 *
 * To run a mutation, you first call `useVerificarPremioMegalotoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerificarPremioMegalotoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verificarPremioMegalotoMutation, { data, loading, error }] = useVerificarPremioMegalotoMutation({
 *   variables: {
 *      pedidoId: // value for 'pedidoId'
 *      umnId: // value for 'umnId'
 *   },
 * });
 */
export function useVerificarPremioMegalotoMutation(baseOptions?: Apollo.MutationHookOptions<VerificarPremioMegalotoMutation, VerificarPremioMegalotoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<VerificarPremioMegalotoMutation, VerificarPremioMegalotoMutationVariables>(VerificarPremioMegalotoDocument, options);
      }
export type VerificarPremioMegalotoMutationHookResult = ReturnType<typeof useVerificarPremioMegalotoMutation>;
export type VerificarPremioMegalotoMutationResult = Apollo.MutationResult<VerificarPremioMegalotoMutation>;
export type VerificarPremioMegalotoMutationOptions = Apollo.BaseMutationOptions<VerificarPremioMegalotoMutation, VerificarPremioMegalotoMutationVariables>;
export const RejectAnulacionPremioPagadoDocument = gql`
    mutation rejectAnulacionPremioPagado($premio_pagado_id: ID!) {
  rejectAnulacionPremioPagado(premio_pagado_id: $premio_pagado_id)
}
    `;
export type RejectAnulacionPremioPagadoMutationFn = Apollo.MutationFunction<RejectAnulacionPremioPagadoMutation, RejectAnulacionPremioPagadoMutationVariables>;

/**
 * __useRejectAnulacionPremioPagadoMutation__
 *
 * To run a mutation, you first call `useRejectAnulacionPremioPagadoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRejectAnulacionPremioPagadoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rejectAnulacionPremioPagadoMutation, { data, loading, error }] = useRejectAnulacionPremioPagadoMutation({
 *   variables: {
 *      premio_pagado_id: // value for 'premio_pagado_id'
 *   },
 * });
 */
export function useRejectAnulacionPremioPagadoMutation(baseOptions?: Apollo.MutationHookOptions<RejectAnulacionPremioPagadoMutation, RejectAnulacionPremioPagadoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RejectAnulacionPremioPagadoMutation, RejectAnulacionPremioPagadoMutationVariables>(RejectAnulacionPremioPagadoDocument, options);
      }
export type RejectAnulacionPremioPagadoMutationHookResult = ReturnType<typeof useRejectAnulacionPremioPagadoMutation>;
export type RejectAnulacionPremioPagadoMutationResult = Apollo.MutationResult<RejectAnulacionPremioPagadoMutation>;
export type RejectAnulacionPremioPagadoMutationOptions = Apollo.BaseMutationOptions<RejectAnulacionPremioPagadoMutation, RejectAnulacionPremioPagadoMutationVariables>;
export const RevertEventoCajaDocument = gql`
    mutation revertEventoCaja($terminalId: ID!) {
  revertEventoCaja(terminalId: $terminalId)
}
    `;
export type RevertEventoCajaMutationFn = Apollo.MutationFunction<RevertEventoCajaMutation, RevertEventoCajaMutationVariables>;

/**
 * __useRevertEventoCajaMutation__
 *
 * To run a mutation, you first call `useRevertEventoCajaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRevertEventoCajaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [revertEventoCajaMutation, { data, loading, error }] = useRevertEventoCajaMutation({
 *   variables: {
 *      terminalId: // value for 'terminalId'
 *   },
 * });
 */
export function useRevertEventoCajaMutation(baseOptions?: Apollo.MutationHookOptions<RevertEventoCajaMutation, RevertEventoCajaMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RevertEventoCajaMutation, RevertEventoCajaMutationVariables>(RevertEventoCajaDocument, options);
      }
export type RevertEventoCajaMutationHookResult = ReturnType<typeof useRevertEventoCajaMutation>;
export type RevertEventoCajaMutationResult = Apollo.MutationResult<RevertEventoCajaMutation>;
export type RevertEventoCajaMutationOptions = Apollo.BaseMutationOptions<RevertEventoCajaMutation, RevertEventoCajaMutationVariables>;
export const SolicitarAnulacionPemiosDocument = gql`
    mutation solicitarAnulacionPemios($premio_pagado_id: ID!) {
  solicitudAnulacionPremioPagado(premio_pagado_id: $premio_pagado_id)
}
    `;
export type SolicitarAnulacionPemiosMutationFn = Apollo.MutationFunction<SolicitarAnulacionPemiosMutation, SolicitarAnulacionPemiosMutationVariables>;

/**
 * __useSolicitarAnulacionPemiosMutation__
 *
 * To run a mutation, you first call `useSolicitarAnulacionPemiosMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSolicitarAnulacionPemiosMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [solicitarAnulacionPemiosMutation, { data, loading, error }] = useSolicitarAnulacionPemiosMutation({
 *   variables: {
 *      premio_pagado_id: // value for 'premio_pagado_id'
 *   },
 * });
 */
export function useSolicitarAnulacionPemiosMutation(baseOptions?: Apollo.MutationHookOptions<SolicitarAnulacionPemiosMutation, SolicitarAnulacionPemiosMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SolicitarAnulacionPemiosMutation, SolicitarAnulacionPemiosMutationVariables>(SolicitarAnulacionPemiosDocument, options);
      }
export type SolicitarAnulacionPemiosMutationHookResult = ReturnType<typeof useSolicitarAnulacionPemiosMutation>;
export type SolicitarAnulacionPemiosMutationResult = Apollo.MutationResult<SolicitarAnulacionPemiosMutation>;
export type SolicitarAnulacionPemiosMutationOptions = Apollo.BaseMutationOptions<SolicitarAnulacionPemiosMutation, SolicitarAnulacionPemiosMutationVariables>;
export const SolicitudAnulacionDocument = gql`
    mutation solicitudAnulacion($juego_id: ID, $motivo_id: Int) {
  solicitudAnulacion(juego_id: $juego_id, motivo_id: $motivo_id) {
    id
    nivel
    estado
  }
}
    `;
export type SolicitudAnulacionMutationFn = Apollo.MutationFunction<SolicitudAnulacionMutation, SolicitudAnulacionMutationVariables>;

/**
 * __useSolicitudAnulacionMutation__
 *
 * To run a mutation, you first call `useSolicitudAnulacionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSolicitudAnulacionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [solicitudAnulacionMutation, { data, loading, error }] = useSolicitudAnulacionMutation({
 *   variables: {
 *      juego_id: // value for 'juego_id'
 *      motivo_id: // value for 'motivo_id'
 *   },
 * });
 */
export function useSolicitudAnulacionMutation(baseOptions?: Apollo.MutationHookOptions<SolicitudAnulacionMutation, SolicitudAnulacionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SolicitudAnulacionMutation, SolicitudAnulacionMutationVariables>(SolicitudAnulacionDocument, options);
      }
export type SolicitudAnulacionMutationHookResult = ReturnType<typeof useSolicitudAnulacionMutation>;
export type SolicitudAnulacionMutationResult = Apollo.MutationResult<SolicitudAnulacionMutation>;
export type SolicitudAnulacionMutationOptions = Apollo.BaseMutationOptions<SolicitudAnulacionMutation, SolicitudAnulacionMutationVariables>;
export const TransaccionRaspaditasDocument = gql`
    mutation transaccionRaspaditas($corredor_id: ID!, $umn_id: ID!, $boletas: [BoletaInput]) {
  raspaditaAsignacionCorredor(
    corredor_id: $corredor_id
    umn_id: $umn_id
    boletas: $boletas
  )
}
    `;
export type TransaccionRaspaditasMutationFn = Apollo.MutationFunction<TransaccionRaspaditasMutation, TransaccionRaspaditasMutationVariables>;

/**
 * __useTransaccionRaspaditasMutation__
 *
 * To run a mutation, you first call `useTransaccionRaspaditasMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTransaccionRaspaditasMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [transaccionRaspaditasMutation, { data, loading, error }] = useTransaccionRaspaditasMutation({
 *   variables: {
 *      corredor_id: // value for 'corredor_id'
 *      umn_id: // value for 'umn_id'
 *      boletas: // value for 'boletas'
 *   },
 * });
 */
export function useTransaccionRaspaditasMutation(baseOptions?: Apollo.MutationHookOptions<TransaccionRaspaditasMutation, TransaccionRaspaditasMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TransaccionRaspaditasMutation, TransaccionRaspaditasMutationVariables>(TransaccionRaspaditasDocument, options);
      }
export type TransaccionRaspaditasMutationHookResult = ReturnType<typeof useTransaccionRaspaditasMutation>;
export type TransaccionRaspaditasMutationResult = Apollo.MutationResult<TransaccionRaspaditasMutation>;
export type TransaccionRaspaditasMutationOptions = Apollo.BaseMutationOptions<TransaccionRaspaditasMutation, TransaccionRaspaditasMutationVariables>;
export const DevolucionRaspaditaDocument = gql`
    mutation devolucionRaspadita($corredor_id: ID!, $umn_id: ID!, $boletas: [String]) {
  raspaditaDevolucionDeBoleta(
    corredor_id: $corredor_id
    umn_id: $umn_id
    boletas: $boletas
  )
}
    `;
export type DevolucionRaspaditaMutationFn = Apollo.MutationFunction<DevolucionRaspaditaMutation, DevolucionRaspaditaMutationVariables>;

/**
 * __useDevolucionRaspaditaMutation__
 *
 * To run a mutation, you first call `useDevolucionRaspaditaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDevolucionRaspaditaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [devolucionRaspaditaMutation, { data, loading, error }] = useDevolucionRaspaditaMutation({
 *   variables: {
 *      corredor_id: // value for 'corredor_id'
 *      umn_id: // value for 'umn_id'
 *      boletas: // value for 'boletas'
 *   },
 * });
 */
export function useDevolucionRaspaditaMutation(baseOptions?: Apollo.MutationHookOptions<DevolucionRaspaditaMutation, DevolucionRaspaditaMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DevolucionRaspaditaMutation, DevolucionRaspaditaMutationVariables>(DevolucionRaspaditaDocument, options);
      }
export type DevolucionRaspaditaMutationHookResult = ReturnType<typeof useDevolucionRaspaditaMutation>;
export type DevolucionRaspaditaMutationResult = Apollo.MutationResult<DevolucionRaspaditaMutation>;
export type DevolucionRaspaditaMutationOptions = Apollo.BaseMutationOptions<DevolucionRaspaditaMutation, DevolucionRaspaditaMutationVariables>;
export const UpdateEntregaDocument = gql`
    mutation updateEntrega($entrega_id: ID!, $monto: Int!) {
  updateEntrega(entrega_id: $entrega_id, monto: $monto)
}
    `;
export type UpdateEntregaMutationFn = Apollo.MutationFunction<UpdateEntregaMutation, UpdateEntregaMutationVariables>;

/**
 * __useUpdateEntregaMutation__
 *
 * To run a mutation, you first call `useUpdateEntregaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEntregaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEntregaMutation, { data, loading, error }] = useUpdateEntregaMutation({
 *   variables: {
 *      entrega_id: // value for 'entrega_id'
 *      monto: // value for 'monto'
 *   },
 * });
 */
export function useUpdateEntregaMutation(baseOptions?: Apollo.MutationHookOptions<UpdateEntregaMutation, UpdateEntregaMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateEntregaMutation, UpdateEntregaMutationVariables>(UpdateEntregaDocument, options);
      }
export type UpdateEntregaMutationHookResult = ReturnType<typeof useUpdateEntregaMutation>;
export type UpdateEntregaMutationResult = Apollo.MutationResult<UpdateEntregaMutation>;
export type UpdateEntregaMutationOptions = Apollo.BaseMutationOptions<UpdateEntregaMutation, UpdateEntregaMutationVariables>;
export const UpdateRastreoDocument = gql`
    mutation updateRastreo($serial: String!, $estado_id: Int!, $umn_id: [Int]) {
  updateRastreo(serial: $serial, estado_id: $estado_id, umn_id: $umn_id)
}
    `;
export type UpdateRastreoMutationFn = Apollo.MutationFunction<UpdateRastreoMutation, UpdateRastreoMutationVariables>;

/**
 * __useUpdateRastreoMutation__
 *
 * To run a mutation, you first call `useUpdateRastreoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRastreoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRastreoMutation, { data, loading, error }] = useUpdateRastreoMutation({
 *   variables: {
 *      serial: // value for 'serial'
 *      estado_id: // value for 'estado_id'
 *      umn_id: // value for 'umn_id'
 *   },
 * });
 */
export function useUpdateRastreoMutation(baseOptions?: Apollo.MutationHookOptions<UpdateRastreoMutation, UpdateRastreoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateRastreoMutation, UpdateRastreoMutationVariables>(UpdateRastreoDocument, options);
      }
export type UpdateRastreoMutationHookResult = ReturnType<typeof useUpdateRastreoMutation>;
export type UpdateRastreoMutationResult = Apollo.MutationResult<UpdateRastreoMutation>;
export type UpdateRastreoMutationOptions = Apollo.BaseMutationOptions<UpdateRastreoMutation, UpdateRastreoMutationVariables>;
export const UpdateStateTerminalDocument = gql`
    mutation updateStateTerminal($terminal_id: String!, $estado_id: String!, $estado_anterior: String!) {
  updateEstadoTerminal(
    terminal_id: $terminal_id
    estado_id: $estado_id
    estado_anterior: $estado_anterior
  )
}
    `;
export type UpdateStateTerminalMutationFn = Apollo.MutationFunction<UpdateStateTerminalMutation, UpdateStateTerminalMutationVariables>;

/**
 * __useUpdateStateTerminalMutation__
 *
 * To run a mutation, you first call `useUpdateStateTerminalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateStateTerminalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateStateTerminalMutation, { data, loading, error }] = useUpdateStateTerminalMutation({
 *   variables: {
 *      terminal_id: // value for 'terminal_id'
 *      estado_id: // value for 'estado_id'
 *      estado_anterior: // value for 'estado_anterior'
 *   },
 * });
 */
export function useUpdateStateTerminalMutation(baseOptions?: Apollo.MutationHookOptions<UpdateStateTerminalMutation, UpdateStateTerminalMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateStateTerminalMutation, UpdateStateTerminalMutationVariables>(UpdateStateTerminalDocument, options);
      }
export type UpdateStateTerminalMutationHookResult = ReturnType<typeof useUpdateStateTerminalMutation>;
export type UpdateStateTerminalMutationResult = Apollo.MutationResult<UpdateStateTerminalMutation>;
export type UpdateStateTerminalMutationOptions = Apollo.BaseMutationOptions<UpdateStateTerminalMutation, UpdateStateTerminalMutationVariables>;
export const VerifyUserDocument = gql`
    mutation verifyUser($doc_type_enum: String!, $ci: String!, $country_code_phone: String!, $phone_number: String!, $pago_agil: Boolean, $reference: String!) {
  verifyUser(
    doc_type_enum: $doc_type_enum
    ci: $ci
    country_code_phone: $country_code_phone
    phone_number: $phone_number
    reference: $reference
    pago_agil: $pago_agil
  )
}
    `;
export type VerifyUserMutationFn = Apollo.MutationFunction<VerifyUserMutation, VerifyUserMutationVariables>;

/**
 * __useVerifyUserMutation__
 *
 * To run a mutation, you first call `useVerifyUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyUserMutation, { data, loading, error }] = useVerifyUserMutation({
 *   variables: {
 *      doc_type_enum: // value for 'doc_type_enum'
 *      ci: // value for 'ci'
 *      country_code_phone: // value for 'country_code_phone'
 *      phone_number: // value for 'phone_number'
 *      pago_agil: // value for 'pago_agil'
 *      reference: // value for 'reference'
 *   },
 * });
 */
export function useVerifyUserMutation(baseOptions?: Apollo.MutationHookOptions<VerifyUserMutation, VerifyUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<VerifyUserMutation, VerifyUserMutationVariables>(VerifyUserDocument, options);
      }
export type VerifyUserMutationHookResult = ReturnType<typeof useVerifyUserMutation>;
export type VerifyUserMutationResult = Apollo.MutationResult<VerifyUserMutation>;
export type VerifyUserMutationOptions = Apollo.BaseMutationOptions<VerifyUserMutation, VerifyUserMutationVariables>;
export const ListStockTerminalDocument = gql`
    query ListStockTerminal($desde: Date!, $hasta: Date, $umn_id: [ID!], $serial: String, $nro_corredor: String, $estado: String) {
  stockTerminales(
    desde: $desde
    hasta: $hasta
    umn_id: $umn_id
    serial: $serial
    nro_corredor: $nro_corredor
    estado: $estado
  )
}
    `;

/**
 * __useListStockTerminalQuery__
 *
 * To run a query within a React component, call `useListStockTerminalQuery` and pass it any options that fit your needs.
 * When your component renders, `useListStockTerminalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListStockTerminalQuery({
 *   variables: {
 *      desde: // value for 'desde'
 *      hasta: // value for 'hasta'
 *      umn_id: // value for 'umn_id'
 *      serial: // value for 'serial'
 *      nro_corredor: // value for 'nro_corredor'
 *      estado: // value for 'estado'
 *   },
 * });
 */
export function useListStockTerminalQuery(baseOptions: Apollo.QueryHookOptions<ListStockTerminalQuery, ListStockTerminalQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListStockTerminalQuery, ListStockTerminalQueryVariables>(ListStockTerminalDocument, options);
      }
export function useListStockTerminalLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListStockTerminalQuery, ListStockTerminalQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListStockTerminalQuery, ListStockTerminalQueryVariables>(ListStockTerminalDocument, options);
        }
export type ListStockTerminalQueryHookResult = ReturnType<typeof useListStockTerminalQuery>;
export type ListStockTerminalLazyQueryHookResult = ReturnType<typeof useListStockTerminalLazyQuery>;
export type ListStockTerminalQueryResult = Apollo.QueryResult<ListStockTerminalQuery, ListStockTerminalQueryVariables>;
export const ListStockTerminal2Document = gql`
    query ListStockTerminal2($desde: Date!, $hasta: Date, $umn_id: [ID!], $serial: String, $nro_corredor: String, $estado: String) {
  stockTerminales2(
    desde: $desde
    hasta: $hasta
    umn_id: $umn_id
    serial: $serial
    nro_corredor: $nro_corredor
    estado: $estado
  )
}
    `;

/**
 * __useListStockTerminal2Query__
 *
 * To run a query within a React component, call `useListStockTerminal2Query` and pass it any options that fit your needs.
 * When your component renders, `useListStockTerminal2Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListStockTerminal2Query({
 *   variables: {
 *      desde: // value for 'desde'
 *      hasta: // value for 'hasta'
 *      umn_id: // value for 'umn_id'
 *      serial: // value for 'serial'
 *      nro_corredor: // value for 'nro_corredor'
 *      estado: // value for 'estado'
 *   },
 * });
 */
export function useListStockTerminal2Query(baseOptions: Apollo.QueryHookOptions<ListStockTerminal2Query, ListStockTerminal2QueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListStockTerminal2Query, ListStockTerminal2QueryVariables>(ListStockTerminal2Document, options);
      }
export function useListStockTerminal2LazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListStockTerminal2Query, ListStockTerminal2QueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListStockTerminal2Query, ListStockTerminal2QueryVariables>(ListStockTerminal2Document, options);
        }
export type ListStockTerminal2QueryHookResult = ReturnType<typeof useListStockTerminal2Query>;
export type ListStockTerminal2LazyQueryHookResult = ReturnType<typeof useListStockTerminal2LazyQuery>;
export type ListStockTerminal2QueryResult = Apollo.QueryResult<ListStockTerminal2Query, ListStockTerminal2QueryVariables>;
export const ListStockTerminal3Document = gql`
    query ListStockTerminal3($desde: Date!, $hasta: Date, $umn_id: [ID!], $serial: String, $nro_corredor: String, $estado: String) {
  stockTerminal3(
    desde: $desde
    hasta: $hasta
    umn_id: $umn_id
    serial: $serial
    nro_corredor: $nro_corredor
    estado: $estado
  )
}
    `;

/**
 * __useListStockTerminal3Query__
 *
 * To run a query within a React component, call `useListStockTerminal3Query` and pass it any options that fit your needs.
 * When your component renders, `useListStockTerminal3Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListStockTerminal3Query({
 *   variables: {
 *      desde: // value for 'desde'
 *      hasta: // value for 'hasta'
 *      umn_id: // value for 'umn_id'
 *      serial: // value for 'serial'
 *      nro_corredor: // value for 'nro_corredor'
 *      estado: // value for 'estado'
 *   },
 * });
 */
export function useListStockTerminal3Query(baseOptions: Apollo.QueryHookOptions<ListStockTerminal3Query, ListStockTerminal3QueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListStockTerminal3Query, ListStockTerminal3QueryVariables>(ListStockTerminal3Document, options);
      }
export function useListStockTerminal3LazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListStockTerminal3Query, ListStockTerminal3QueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListStockTerminal3Query, ListStockTerminal3QueryVariables>(ListStockTerminal3Document, options);
        }
export type ListStockTerminal3QueryHookResult = ReturnType<typeof useListStockTerminal3Query>;
export type ListStockTerminal3LazyQueryHookResult = ReturnType<typeof useListStockTerminal3LazyQuery>;
export type ListStockTerminal3QueryResult = Apollo.QueryResult<ListStockTerminal3Query, ListStockTerminal3QueryVariables>;
export const TombolaNumerosDocument = gql`
    query tombolaNumeros($desde: Date!, $hasta: Date, $sorteo: [ID], $postura: Int) {
  tombolaNumeros(desde: $desde, hasta: $hasta, sorteo: $sorteo, postura: $postura)
}
    `;

/**
 * __useTombolaNumerosQuery__
 *
 * To run a query within a React component, call `useTombolaNumerosQuery` and pass it any options that fit your needs.
 * When your component renders, `useTombolaNumerosQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTombolaNumerosQuery({
 *   variables: {
 *      desde: // value for 'desde'
 *      hasta: // value for 'hasta'
 *      sorteo: // value for 'sorteo'
 *      postura: // value for 'postura'
 *   },
 * });
 */
export function useTombolaNumerosQuery(baseOptions: Apollo.QueryHookOptions<TombolaNumerosQuery, TombolaNumerosQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TombolaNumerosQuery, TombolaNumerosQueryVariables>(TombolaNumerosDocument, options);
      }
export function useTombolaNumerosLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TombolaNumerosQuery, TombolaNumerosQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TombolaNumerosQuery, TombolaNumerosQueryVariables>(TombolaNumerosDocument, options);
        }
export type TombolaNumerosQueryHookResult = ReturnType<typeof useTombolaNumerosQuery>;
export type TombolaNumerosLazyQueryHookResult = ReturnType<typeof useTombolaNumerosLazyQuery>;
export type TombolaNumerosQueryResult = Apollo.QueryResult<TombolaNumerosQuery, TombolaNumerosQueryVariables>;
export const ProductoAgrupadosCorredorDocument = gql`
    query productoAgrupadosCorredor($corredor_id: Int!, $desde: Date!, $hasta: Date) {
  transaccionesAgrupadasCorredor(
    corredor_id: $corredor_id
    desde: $desde
    hasta: $hasta
  )
}
    `;

/**
 * __useProductoAgrupadosCorredorQuery__
 *
 * To run a query within a React component, call `useProductoAgrupadosCorredorQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductoAgrupadosCorredorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductoAgrupadosCorredorQuery({
 *   variables: {
 *      corredor_id: // value for 'corredor_id'
 *      desde: // value for 'desde'
 *      hasta: // value for 'hasta'
 *   },
 * });
 */
export function useProductoAgrupadosCorredorQuery(baseOptions: Apollo.QueryHookOptions<ProductoAgrupadosCorredorQuery, ProductoAgrupadosCorredorQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProductoAgrupadosCorredorQuery, ProductoAgrupadosCorredorQueryVariables>(ProductoAgrupadosCorredorDocument, options);
      }
export function useProductoAgrupadosCorredorLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProductoAgrupadosCorredorQuery, ProductoAgrupadosCorredorQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProductoAgrupadosCorredorQuery, ProductoAgrupadosCorredorQueryVariables>(ProductoAgrupadosCorredorDocument, options);
        }
export type ProductoAgrupadosCorredorQueryHookResult = ReturnType<typeof useProductoAgrupadosCorredorQuery>;
export type ProductoAgrupadosCorredorLazyQueryHookResult = ReturnType<typeof useProductoAgrupadosCorredorLazyQuery>;
export type ProductoAgrupadosCorredorQueryResult = Apollo.QueryResult<ProductoAgrupadosCorredorQuery, ProductoAgrupadosCorredorQueryVariables>;
export const AllPrizesAgencieDocument = gql`
    query allPrizesAgencie($desde: Date, $hasta: Date, $umn_id: [ID!]) {
  allPrizes(desde: $desde, hasta: $hasta, umn_id: $umn_id)
}
    `;

/**
 * __useAllPrizesAgencieQuery__
 *
 * To run a query within a React component, call `useAllPrizesAgencieQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllPrizesAgencieQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllPrizesAgencieQuery({
 *   variables: {
 *      desde: // value for 'desde'
 *      hasta: // value for 'hasta'
 *      umn_id: // value for 'umn_id'
 *   },
 * });
 */
export function useAllPrizesAgencieQuery(baseOptions?: Apollo.QueryHookOptions<AllPrizesAgencieQuery, AllPrizesAgencieQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllPrizesAgencieQuery, AllPrizesAgencieQueryVariables>(AllPrizesAgencieDocument, options);
      }
export function useAllPrizesAgencieLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllPrizesAgencieQuery, AllPrizesAgencieQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllPrizesAgencieQuery, AllPrizesAgencieQueryVariables>(AllPrizesAgencieDocument, options);
        }
export type AllPrizesAgencieQueryHookResult = ReturnType<typeof useAllPrizesAgencieQuery>;
export type AllPrizesAgencieLazyQueryHookResult = ReturnType<typeof useAllPrizesAgencieLazyQuery>;
export type AllPrizesAgencieQueryResult = Apollo.QueryResult<AllPrizesAgencieQuery, AllPrizesAgencieQueryVariables>;
export const BilletajeResultDocument = gql`
    query billetajeResult($smp: Float, $consecutivo: Float, $serie: String, $ticket: Float, $monto: Int, $nro_corredor: Int, $umn_id: [ID!], $desde: Date!, $hasta: Date, $retrasadas: Boolean) {
  billetajeResult(
    smp: $smp
    consecutivo: $consecutivo
    serie: $serie
    ticket: $ticket
    monto: $monto
    nro_corredor: $nro_corredor
    umn_id: $umn_id
    desde: $desde
    hasta: $hasta
    retrasadas: $retrasadas
  )
}
    `;

/**
 * __useBilletajeResultQuery__
 *
 * To run a query within a React component, call `useBilletajeResultQuery` and pass it any options that fit your needs.
 * When your component renders, `useBilletajeResultQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBilletajeResultQuery({
 *   variables: {
 *      smp: // value for 'smp'
 *      consecutivo: // value for 'consecutivo'
 *      serie: // value for 'serie'
 *      ticket: // value for 'ticket'
 *      monto: // value for 'monto'
 *      nro_corredor: // value for 'nro_corredor'
 *      umn_id: // value for 'umn_id'
 *      desde: // value for 'desde'
 *      hasta: // value for 'hasta'
 *      retrasadas: // value for 'retrasadas'
 *   },
 * });
 */
export function useBilletajeResultQuery(baseOptions: Apollo.QueryHookOptions<BilletajeResultQuery, BilletajeResultQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BilletajeResultQuery, BilletajeResultQueryVariables>(BilletajeResultDocument, options);
      }
export function useBilletajeResultLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BilletajeResultQuery, BilletajeResultQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BilletajeResultQuery, BilletajeResultQueryVariables>(BilletajeResultDocument, options);
        }
export type BilletajeResultQueryHookResult = ReturnType<typeof useBilletajeResultQuery>;
export type BilletajeResultLazyQueryHookResult = ReturnType<typeof useBilletajeResultLazyQuery>;
export type BilletajeResultQueryResult = Apollo.QueryResult<BilletajeResultQuery, BilletajeResultQueryVariables>;
export const BoletasPorMontosDocument = gql`
    query boletasPorMontos($desde: Date!, $hasta: Date, $agencia: [ID], $sorteo: [ID]) {
  boletasPorMontos(
    desde: $desde
    hasta: $hasta
    agencia: $agencia
    sorteo: $sorteo
  )
}
    `;

/**
 * __useBoletasPorMontosQuery__
 *
 * To run a query within a React component, call `useBoletasPorMontosQuery` and pass it any options that fit your needs.
 * When your component renders, `useBoletasPorMontosQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBoletasPorMontosQuery({
 *   variables: {
 *      desde: // value for 'desde'
 *      hasta: // value for 'hasta'
 *      agencia: // value for 'agencia'
 *      sorteo: // value for 'sorteo'
 *   },
 * });
 */
export function useBoletasPorMontosQuery(baseOptions: Apollo.QueryHookOptions<BoletasPorMontosQuery, BoletasPorMontosQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BoletasPorMontosQuery, BoletasPorMontosQueryVariables>(BoletasPorMontosDocument, options);
      }
export function useBoletasPorMontosLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BoletasPorMontosQuery, BoletasPorMontosQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BoletasPorMontosQuery, BoletasPorMontosQueryVariables>(BoletasPorMontosDocument, options);
        }
export type BoletasPorMontosQueryHookResult = ReturnType<typeof useBoletasPorMontosQuery>;
export type BoletasPorMontosLazyQueryHookResult = ReturnType<typeof useBoletasPorMontosLazyQuery>;
export type BoletasPorMontosQueryResult = Apollo.QueryResult<BoletasPorMontosQuery, BoletasPorMontosQueryVariables>;
export const BolilleroDocument = gql`
    query bolillero($desde: Date!, $hasta: Date, $sorteo: [ID]) {
  bolillero(desde: $desde, hasta: $hasta, sorteo: $sorteo)
}
    `;

/**
 * __useBolilleroQuery__
 *
 * To run a query within a React component, call `useBolilleroQuery` and pass it any options that fit your needs.
 * When your component renders, `useBolilleroQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBolilleroQuery({
 *   variables: {
 *      desde: // value for 'desde'
 *      hasta: // value for 'hasta'
 *      sorteo: // value for 'sorteo'
 *   },
 * });
 */
export function useBolilleroQuery(baseOptions: Apollo.QueryHookOptions<BolilleroQuery, BolilleroQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BolilleroQuery, BolilleroQueryVariables>(BolilleroDocument, options);
      }
export function useBolilleroLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BolilleroQuery, BolilleroQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BolilleroQuery, BolilleroQueryVariables>(BolilleroDocument, options);
        }
export type BolilleroQueryHookResult = ReturnType<typeof useBolilleroQuery>;
export type BolilleroLazyQueryHookResult = ReturnType<typeof useBolilleroLazyQuery>;
export type BolilleroQueryResult = Apollo.QueryResult<BolilleroQuery, BolilleroQueryVariables>;
export const CajasAbiertasQueryDocument = gql`
    query cajasAbiertasQuery($umn_id: [ID!]) {
  cajasAbiertas(umn_id: $umn_id) {
    id
    apertura
    Corredor {
      id
      numero
      Usuario {
        Person {
          nombre
          apellido
        }
      }
      Umn {
        numero
        agencia_numero
      }
    }
    cantidad
    total
    totalComisionA
    totalComisionC
  }
}
    `;

/**
 * __useCajasAbiertasQueryQuery__
 *
 * To run a query within a React component, call `useCajasAbiertasQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useCajasAbiertasQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCajasAbiertasQueryQuery({
 *   variables: {
 *      umn_id: // value for 'umn_id'
 *   },
 * });
 */
export function useCajasAbiertasQueryQuery(baseOptions?: Apollo.QueryHookOptions<CajasAbiertasQueryQuery, CajasAbiertasQueryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CajasAbiertasQueryQuery, CajasAbiertasQueryQueryVariables>(CajasAbiertasQueryDocument, options);
      }
export function useCajasAbiertasQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CajasAbiertasQueryQuery, CajasAbiertasQueryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CajasAbiertasQueryQuery, CajasAbiertasQueryQueryVariables>(CajasAbiertasQueryDocument, options);
        }
export type CajasAbiertasQueryQueryHookResult = ReturnType<typeof useCajasAbiertasQueryQuery>;
export type CajasAbiertasQueryLazyQueryHookResult = ReturnType<typeof useCajasAbiertasQueryLazyQuery>;
export type CajasAbiertasQueryQueryResult = Apollo.QueryResult<CajasAbiertasQueryQuery, CajasAbiertasQueryQueryVariables>;
export const ReportePagopremiosDocument = gql`
    query reportePagopremios($desde: Date!, $hasta: Date, $agencia: [ID], $sorteo_id: [ID]) {
  reportePagoPremios(
    desde: $desde
    hasta: $hasta
    agencia: $agencia
    sorteo_id: $sorteo_id
  )
}
    `;

/**
 * __useReportePagopremiosQuery__
 *
 * To run a query within a React component, call `useReportePagopremiosQuery` and pass it any options that fit your needs.
 * When your component renders, `useReportePagopremiosQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReportePagopremiosQuery({
 *   variables: {
 *      desde: // value for 'desde'
 *      hasta: // value for 'hasta'
 *      agencia: // value for 'agencia'
 *      sorteo_id: // value for 'sorteo_id'
 *   },
 * });
 */
export function useReportePagopremiosQuery(baseOptions: Apollo.QueryHookOptions<ReportePagopremiosQuery, ReportePagopremiosQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReportePagopremiosQuery, ReportePagopremiosQueryVariables>(ReportePagopremiosDocument, options);
      }
export function useReportePagopremiosLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReportePagopremiosQuery, ReportePagopremiosQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReportePagopremiosQuery, ReportePagopremiosQueryVariables>(ReportePagopremiosDocument, options);
        }
export type ReportePagopremiosQueryHookResult = ReturnType<typeof useReportePagopremiosQuery>;
export type ReportePagopremiosLazyQueryHookResult = ReturnType<typeof useReportePagopremiosLazyQuery>;
export type ReportePagopremiosQueryResult = Apollo.QueryResult<ReportePagopremiosQuery, ReportePagopremiosQueryVariables>;
export const ReporteFacturacionAgenciaDocument = gql`
    query reporteFacturacionAgencia($desde: Date!, $hasta: Date, $agencia: [ID], $sorteo_id: [ID]) {
  reporteFacturacionAgencia(
    desde: $desde
    hasta: $hasta
    agencia: $agencia
    sorteo_id: $sorteo_id
  )
}
    `;

/**
 * __useReporteFacturacionAgenciaQuery__
 *
 * To run a query within a React component, call `useReporteFacturacionAgenciaQuery` and pass it any options that fit your needs.
 * When your component renders, `useReporteFacturacionAgenciaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReporteFacturacionAgenciaQuery({
 *   variables: {
 *      desde: // value for 'desde'
 *      hasta: // value for 'hasta'
 *      agencia: // value for 'agencia'
 *      sorteo_id: // value for 'sorteo_id'
 *   },
 * });
 */
export function useReporteFacturacionAgenciaQuery(baseOptions: Apollo.QueryHookOptions<ReporteFacturacionAgenciaQuery, ReporteFacturacionAgenciaQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReporteFacturacionAgenciaQuery, ReporteFacturacionAgenciaQueryVariables>(ReporteFacturacionAgenciaDocument, options);
      }
export function useReporteFacturacionAgenciaLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReporteFacturacionAgenciaQuery, ReporteFacturacionAgenciaQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReporteFacturacionAgenciaQuery, ReporteFacturacionAgenciaQueryVariables>(ReporteFacturacionAgenciaDocument, options);
        }
export type ReporteFacturacionAgenciaQueryHookResult = ReturnType<typeof useReporteFacturacionAgenciaQuery>;
export type ReporteFacturacionAgenciaLazyQueryHookResult = ReturnType<typeof useReporteFacturacionAgenciaLazyQuery>;
export type ReporteFacturacionAgenciaQueryResult = Apollo.QueryResult<ReporteFacturacionAgenciaQuery, ReporteFacturacionAgenciaQueryVariables>;
export const ReporteDeSistemaDocument = gql`
    query reporteDeSistema($desde: Date!, $hasta: Date, $agencia: [ID], $sorteo_id: [ID]) {
  reporteDeSistema(
    desde: $desde
    hasta: $hasta
    agencia: $agencia
    sorteo_id: $sorteo_id
  )
}
    `;

/**
 * __useReporteDeSistemaQuery__
 *
 * To run a query within a React component, call `useReporteDeSistemaQuery` and pass it any options that fit your needs.
 * When your component renders, `useReporteDeSistemaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReporteDeSistemaQuery({
 *   variables: {
 *      desde: // value for 'desde'
 *      hasta: // value for 'hasta'
 *      agencia: // value for 'agencia'
 *      sorteo_id: // value for 'sorteo_id'
 *   },
 * });
 */
export function useReporteDeSistemaQuery(baseOptions: Apollo.QueryHookOptions<ReporteDeSistemaQuery, ReporteDeSistemaQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReporteDeSistemaQuery, ReporteDeSistemaQueryVariables>(ReporteDeSistemaDocument, options);
      }
export function useReporteDeSistemaLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReporteDeSistemaQuery, ReporteDeSistemaQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReporteDeSistemaQuery, ReporteDeSistemaQueryVariables>(ReporteDeSistemaDocument, options);
        }
export type ReporteDeSistemaQueryHookResult = ReturnType<typeof useReporteDeSistemaQuery>;
export type ReporteDeSistemaLazyQueryHookResult = ReturnType<typeof useReporteDeSistemaLazyQuery>;
export type ReporteDeSistemaQueryResult = Apollo.QueryResult<ReporteDeSistemaQuery, ReporteDeSistemaQueryVariables>;
export const ReporteVentasPorAgenciaDocument = gql`
    query reporteVentasPorAgencia($desde: Date!, $hasta: Date, $agencia: [ID], $sorteo_id: [ID]) {
  reporteVentasPorAgencia(
    desde: $desde
    hasta: $hasta
    agencia: $agencia
    sorteo_id: $sorteo_id
  )
}
    `;

/**
 * __useReporteVentasPorAgenciaQuery__
 *
 * To run a query within a React component, call `useReporteVentasPorAgenciaQuery` and pass it any options that fit your needs.
 * When your component renders, `useReporteVentasPorAgenciaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReporteVentasPorAgenciaQuery({
 *   variables: {
 *      desde: // value for 'desde'
 *      hasta: // value for 'hasta'
 *      agencia: // value for 'agencia'
 *      sorteo_id: // value for 'sorteo_id'
 *   },
 * });
 */
export function useReporteVentasPorAgenciaQuery(baseOptions: Apollo.QueryHookOptions<ReporteVentasPorAgenciaQuery, ReporteVentasPorAgenciaQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReporteVentasPorAgenciaQuery, ReporteVentasPorAgenciaQueryVariables>(ReporteVentasPorAgenciaDocument, options);
      }
export function useReporteVentasPorAgenciaLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReporteVentasPorAgenciaQuery, ReporteVentasPorAgenciaQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReporteVentasPorAgenciaQuery, ReporteVentasPorAgenciaQueryVariables>(ReporteVentasPorAgenciaDocument, options);
        }
export type ReporteVentasPorAgenciaQueryHookResult = ReturnType<typeof useReporteVentasPorAgenciaQuery>;
export type ReporteVentasPorAgenciaLazyQueryHookResult = ReturnType<typeof useReporteVentasPorAgenciaLazyQuery>;
export type ReporteVentasPorAgenciaQueryResult = Apollo.QueryResult<ReporteVentasPorAgenciaQuery, ReporteVentasPorAgenciaQueryVariables>;
export const ReporteGanadoresPorAgenciaDocument = gql`
    query reporteGanadoresPorAgencia($desde: Date!, $hasta: Date, $agencia: [Int], $juego_id: Int, $corredor: Boolean) {
  reporteGanadoresPorAgencia(
    desde: $desde
    hasta: $hasta
    agencia: $agencia
    juego_id: $juego_id
    corredor: $corredor
  )
}
    `;

/**
 * __useReporteGanadoresPorAgenciaQuery__
 *
 * To run a query within a React component, call `useReporteGanadoresPorAgenciaQuery` and pass it any options that fit your needs.
 * When your component renders, `useReporteGanadoresPorAgenciaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReporteGanadoresPorAgenciaQuery({
 *   variables: {
 *      desde: // value for 'desde'
 *      hasta: // value for 'hasta'
 *      agencia: // value for 'agencia'
 *      juego_id: // value for 'juego_id'
 *      corredor: // value for 'corredor'
 *   },
 * });
 */
export function useReporteGanadoresPorAgenciaQuery(baseOptions: Apollo.QueryHookOptions<ReporteGanadoresPorAgenciaQuery, ReporteGanadoresPorAgenciaQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReporteGanadoresPorAgenciaQuery, ReporteGanadoresPorAgenciaQueryVariables>(ReporteGanadoresPorAgenciaDocument, options);
      }
export function useReporteGanadoresPorAgenciaLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReporteGanadoresPorAgenciaQuery, ReporteGanadoresPorAgenciaQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReporteGanadoresPorAgenciaQuery, ReporteGanadoresPorAgenciaQueryVariables>(ReporteGanadoresPorAgenciaDocument, options);
        }
export type ReporteGanadoresPorAgenciaQueryHookResult = ReturnType<typeof useReporteGanadoresPorAgenciaQuery>;
export type ReporteGanadoresPorAgenciaLazyQueryHookResult = ReturnType<typeof useReporteGanadoresPorAgenciaLazyQuery>;
export type ReporteGanadoresPorAgenciaQueryResult = Apollo.QueryResult<ReporteGanadoresPorAgenciaQuery, ReporteGanadoresPorAgenciaQueryVariables>;
export const ReporteVentasRaspaditaDocument = gql`
    query reporteVentasRaspadita($desde: Date!, $hasta: Date, $agencia: [Int], $juego_id: Int, $corredor: Boolean) {
  reporteVentasRaspadita(
    desde: $desde
    hasta: $hasta
    agencia: $agencia
    juego_id: $juego_id
    corredor: $corredor
  )
}
    `;

/**
 * __useReporteVentasRaspaditaQuery__
 *
 * To run a query within a React component, call `useReporteVentasRaspaditaQuery` and pass it any options that fit your needs.
 * When your component renders, `useReporteVentasRaspaditaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReporteVentasRaspaditaQuery({
 *   variables: {
 *      desde: // value for 'desde'
 *      hasta: // value for 'hasta'
 *      agencia: // value for 'agencia'
 *      juego_id: // value for 'juego_id'
 *      corredor: // value for 'corredor'
 *   },
 * });
 */
export function useReporteVentasRaspaditaQuery(baseOptions: Apollo.QueryHookOptions<ReporteVentasRaspaditaQuery, ReporteVentasRaspaditaQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReporteVentasRaspaditaQuery, ReporteVentasRaspaditaQueryVariables>(ReporteVentasRaspaditaDocument, options);
      }
export function useReporteVentasRaspaditaLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReporteVentasRaspaditaQuery, ReporteVentasRaspaditaQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReporteVentasRaspaditaQuery, ReporteVentasRaspaditaQueryVariables>(ReporteVentasRaspaditaDocument, options);
        }
export type ReporteVentasRaspaditaQueryHookResult = ReturnType<typeof useReporteVentasRaspaditaQuery>;
export type ReporteVentasRaspaditaLazyQueryHookResult = ReturnType<typeof useReporteVentasRaspaditaLazyQuery>;
export type ReporteVentasRaspaditaQueryResult = Apollo.QueryResult<ReporteVentasRaspaditaQuery, ReporteVentasRaspaditaQueryVariables>;
export const ReporteVentasPorTerminalDocument = gql`
    query reporteVentasPorTerminal($desde: Date!, $hasta: Date, $umn_id: [ID], $sorteo_id: [ID]) {
  reporteVentasPorTerminal(
    desde: $desde
    hasta: $hasta
    umn_id: $umn_id
    sorteo_id: $sorteo_id
  )
}
    `;

/**
 * __useReporteVentasPorTerminalQuery__
 *
 * To run a query within a React component, call `useReporteVentasPorTerminalQuery` and pass it any options that fit your needs.
 * When your component renders, `useReporteVentasPorTerminalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReporteVentasPorTerminalQuery({
 *   variables: {
 *      desde: // value for 'desde'
 *      hasta: // value for 'hasta'
 *      umn_id: // value for 'umn_id'
 *      sorteo_id: // value for 'sorteo_id'
 *   },
 * });
 */
export function useReporteVentasPorTerminalQuery(baseOptions: Apollo.QueryHookOptions<ReporteVentasPorTerminalQuery, ReporteVentasPorTerminalQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReporteVentasPorTerminalQuery, ReporteVentasPorTerminalQueryVariables>(ReporteVentasPorTerminalDocument, options);
      }
export function useReporteVentasPorTerminalLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReporteVentasPorTerminalQuery, ReporteVentasPorTerminalQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReporteVentasPorTerminalQuery, ReporteVentasPorTerminalQueryVariables>(ReporteVentasPorTerminalDocument, options);
        }
export type ReporteVentasPorTerminalQueryHookResult = ReturnType<typeof useReporteVentasPorTerminalQuery>;
export type ReporteVentasPorTerminalLazyQueryHookResult = ReturnType<typeof useReporteVentasPorTerminalLazyQuery>;
export type ReporteVentasPorTerminalQueryResult = Apollo.QueryResult<ReporteVentasPorTerminalQuery, ReporteVentasPorTerminalQueryVariables>;
export const ReporteMediosElectronicosDocument = gql`
    query reporteMediosElectronicos($desde: Date!, $hasta: Date, $umn_id: [ID], $sorteo_id: [ID]) {
  reporteMediosElectronicos(
    desde: $desde
    hasta: $hasta
    umn_id: $umn_id
    sorteo_id: $sorteo_id
  )
}
    `;

/**
 * __useReporteMediosElectronicosQuery__
 *
 * To run a query within a React component, call `useReporteMediosElectronicosQuery` and pass it any options that fit your needs.
 * When your component renders, `useReporteMediosElectronicosQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReporteMediosElectronicosQuery({
 *   variables: {
 *      desde: // value for 'desde'
 *      hasta: // value for 'hasta'
 *      umn_id: // value for 'umn_id'
 *      sorteo_id: // value for 'sorteo_id'
 *   },
 * });
 */
export function useReporteMediosElectronicosQuery(baseOptions: Apollo.QueryHookOptions<ReporteMediosElectronicosQuery, ReporteMediosElectronicosQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReporteMediosElectronicosQuery, ReporteMediosElectronicosQueryVariables>(ReporteMediosElectronicosDocument, options);
      }
export function useReporteMediosElectronicosLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReporteMediosElectronicosQuery, ReporteMediosElectronicosQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReporteMediosElectronicosQuery, ReporteMediosElectronicosQueryVariables>(ReporteMediosElectronicosDocument, options);
        }
export type ReporteMediosElectronicosQueryHookResult = ReturnType<typeof useReporteMediosElectronicosQuery>;
export type ReporteMediosElectronicosLazyQueryHookResult = ReturnType<typeof useReporteMediosElectronicosLazyQuery>;
export type ReporteMediosElectronicosQueryResult = Apollo.QueryResult<ReporteMediosElectronicosQuery, ReporteMediosElectronicosQueryVariables>;
export const ReporteVentasElectronicasDocument = gql`
    query reporteVentasElectronicas($desde: Date!, $hasta: Date, $umn_id: [ID], $sorteo_id: [ID]) {
  reporteVentasElectronicas(
    desde: $desde
    hasta: $hasta
    umn_id: $umn_id
    sorteo_id: $sorteo_id
  )
}
    `;

/**
 * __useReporteVentasElectronicasQuery__
 *
 * To run a query within a React component, call `useReporteVentasElectronicasQuery` and pass it any options that fit your needs.
 * When your component renders, `useReporteVentasElectronicasQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReporteVentasElectronicasQuery({
 *   variables: {
 *      desde: // value for 'desde'
 *      hasta: // value for 'hasta'
 *      umn_id: // value for 'umn_id'
 *      sorteo_id: // value for 'sorteo_id'
 *   },
 * });
 */
export function useReporteVentasElectronicasQuery(baseOptions: Apollo.QueryHookOptions<ReporteVentasElectronicasQuery, ReporteVentasElectronicasQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReporteVentasElectronicasQuery, ReporteVentasElectronicasQueryVariables>(ReporteVentasElectronicasDocument, options);
      }
export function useReporteVentasElectronicasLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReporteVentasElectronicasQuery, ReporteVentasElectronicasQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReporteVentasElectronicasQuery, ReporteVentasElectronicasQueryVariables>(ReporteVentasElectronicasDocument, options);
        }
export type ReporteVentasElectronicasQueryHookResult = ReturnType<typeof useReporteVentasElectronicasQuery>;
export type ReporteVentasElectronicasLazyQueryHookResult = ReturnType<typeof useReporteVentasElectronicasLazyQuery>;
export type ReporteVentasElectronicasQueryResult = Apollo.QueryResult<ReporteVentasElectronicasQuery, ReporteVentasElectronicasQueryVariables>;
export const UptimeServerDocument = gql`
    query uptimeServer {
  uptimeServer
}
    `;

/**
 * __useUptimeServerQuery__
 *
 * To run a query within a React component, call `useUptimeServerQuery` and pass it any options that fit your needs.
 * When your component renders, `useUptimeServerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUptimeServerQuery({
 *   variables: {
 *   },
 * });
 */
export function useUptimeServerQuery(baseOptions?: Apollo.QueryHookOptions<UptimeServerQuery, UptimeServerQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UptimeServerQuery, UptimeServerQueryVariables>(UptimeServerDocument, options);
      }
export function useUptimeServerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UptimeServerQuery, UptimeServerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UptimeServerQuery, UptimeServerQueryVariables>(UptimeServerDocument, options);
        }
export type UptimeServerQueryHookResult = ReturnType<typeof useUptimeServerQuery>;
export type UptimeServerLazyQueryHookResult = ReturnType<typeof useUptimeServerLazyQuery>;
export type UptimeServerQueryResult = Apollo.QueryResult<UptimeServerQuery, UptimeServerQueryVariables>;
export const ReporteAnulacionesDocument = gql`
    query reporteAnulaciones($desde: Date!, $hasta: Date, $umn_id: [ID], $sorteo_id: [ID]) {
  reporteAnulaciones(
    desde: $desde
    hasta: $hasta
    umn_id: $umn_id
    sorteo_id: $sorteo_id
  )
}
    `;

/**
 * __useReporteAnulacionesQuery__
 *
 * To run a query within a React component, call `useReporteAnulacionesQuery` and pass it any options that fit your needs.
 * When your component renders, `useReporteAnulacionesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReporteAnulacionesQuery({
 *   variables: {
 *      desde: // value for 'desde'
 *      hasta: // value for 'hasta'
 *      umn_id: // value for 'umn_id'
 *      sorteo_id: // value for 'sorteo_id'
 *   },
 * });
 */
export function useReporteAnulacionesQuery(baseOptions: Apollo.QueryHookOptions<ReporteAnulacionesQuery, ReporteAnulacionesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReporteAnulacionesQuery, ReporteAnulacionesQueryVariables>(ReporteAnulacionesDocument, options);
      }
export function useReporteAnulacionesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReporteAnulacionesQuery, ReporteAnulacionesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReporteAnulacionesQuery, ReporteAnulacionesQueryVariables>(ReporteAnulacionesDocument, options);
        }
export type ReporteAnulacionesQueryHookResult = ReturnType<typeof useReporteAnulacionesQuery>;
export type ReporteAnulacionesLazyQueryHookResult = ReturnType<typeof useReporteAnulacionesLazyQuery>;
export type ReporteAnulacionesQueryResult = Apollo.QueryResult<ReporteAnulacionesQuery, ReporteAnulacionesQueryVariables>;
export const ReporteHearbeatDocument = gql`
    query reporteHearbeat($desde: Date!, $hasta: Date, $umn_id: [ID], $sorteo_id: [ID]) {
  reporteHearbeat(
    desde: $desde
    hasta: $hasta
    umn_id: $umn_id
    sorteo_id: $sorteo_id
  )
}
    `;

/**
 * __useReporteHearbeatQuery__
 *
 * To run a query within a React component, call `useReporteHearbeatQuery` and pass it any options that fit your needs.
 * When your component renders, `useReporteHearbeatQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReporteHearbeatQuery({
 *   variables: {
 *      desde: // value for 'desde'
 *      hasta: // value for 'hasta'
 *      umn_id: // value for 'umn_id'
 *      sorteo_id: // value for 'sorteo_id'
 *   },
 * });
 */
export function useReporteHearbeatQuery(baseOptions: Apollo.QueryHookOptions<ReporteHearbeatQuery, ReporteHearbeatQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReporteHearbeatQuery, ReporteHearbeatQueryVariables>(ReporteHearbeatDocument, options);
      }
export function useReporteHearbeatLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReporteHearbeatQuery, ReporteHearbeatQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReporteHearbeatQuery, ReporteHearbeatQueryVariables>(ReporteHearbeatDocument, options);
        }
export type ReporteHearbeatQueryHookResult = ReturnType<typeof useReporteHearbeatQuery>;
export type ReporteHearbeatLazyQueryHookResult = ReturnType<typeof useReporteHearbeatLazyQuery>;
export type ReporteHearbeatQueryResult = Apollo.QueryResult<ReporteHearbeatQuery, ReporteHearbeatQueryVariables>;
export const LoginFailedDocument = gql`
    query loginFailed($desde: Date!, $hasta: Date) {
  loginFailed(desde: $desde, hasta: $hasta)
}
    `;

/**
 * __useLoginFailedQuery__
 *
 * To run a query within a React component, call `useLoginFailedQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoginFailedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoginFailedQuery({
 *   variables: {
 *      desde: // value for 'desde'
 *      hasta: // value for 'hasta'
 *   },
 * });
 */
export function useLoginFailedQuery(baseOptions: Apollo.QueryHookOptions<LoginFailedQuery, LoginFailedQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LoginFailedQuery, LoginFailedQueryVariables>(LoginFailedDocument, options);
      }
export function useLoginFailedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LoginFailedQuery, LoginFailedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LoginFailedQuery, LoginFailedQueryVariables>(LoginFailedDocument, options);
        }
export type LoginFailedQueryHookResult = ReturnType<typeof useLoginFailedQuery>;
export type LoginFailedLazyQueryHookResult = ReturnType<typeof useLoginFailedLazyQuery>;
export type LoginFailedQueryResult = Apollo.QueryResult<LoginFailedQuery, LoginFailedQueryVariables>;
export const AccesoRemotoDocument = gql`
    query accesoRemoto($desde: Date!, $hasta: Date) {
  accesoRemoto(desde: $desde, hasta: $hasta)
}
    `;

/**
 * __useAccesoRemotoQuery__
 *
 * To run a query within a React component, call `useAccesoRemotoQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccesoRemotoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccesoRemotoQuery({
 *   variables: {
 *      desde: // value for 'desde'
 *      hasta: // value for 'hasta'
 *   },
 * });
 */
export function useAccesoRemotoQuery(baseOptions: Apollo.QueryHookOptions<AccesoRemotoQuery, AccesoRemotoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AccesoRemotoQuery, AccesoRemotoQueryVariables>(AccesoRemotoDocument, options);
      }
export function useAccesoRemotoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AccesoRemotoQuery, AccesoRemotoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AccesoRemotoQuery, AccesoRemotoQueryVariables>(AccesoRemotoDocument, options);
        }
export type AccesoRemotoQueryHookResult = ReturnType<typeof useAccesoRemotoQuery>;
export type AccesoRemotoLazyQueryHookResult = ReturnType<typeof useAccesoRemotoLazyQuery>;
export type AccesoRemotoQueryResult = Apollo.QueryResult<AccesoRemotoQuery, AccesoRemotoQueryVariables>;
export const DashCorredorFechasDocument = gql`
    query dashCorredorFechas($corredor_id: ID!) {
  corredorFechas(corredor_id: $corredor_id) {
    fecha
    cantidad
    total
    totalComisionA
    totalComisionC
  }
}
    `;

/**
 * __useDashCorredorFechasQuery__
 *
 * To run a query within a React component, call `useDashCorredorFechasQuery` and pass it any options that fit your needs.
 * When your component renders, `useDashCorredorFechasQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDashCorredorFechasQuery({
 *   variables: {
 *      corredor_id: // value for 'corredor_id'
 *   },
 * });
 */
export function useDashCorredorFechasQuery(baseOptions: Apollo.QueryHookOptions<DashCorredorFechasQuery, DashCorredorFechasQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DashCorredorFechasQuery, DashCorredorFechasQueryVariables>(DashCorredorFechasDocument, options);
      }
export function useDashCorredorFechasLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DashCorredorFechasQuery, DashCorredorFechasQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DashCorredorFechasQuery, DashCorredorFechasQueryVariables>(DashCorredorFechasDocument, options);
        }
export type DashCorredorFechasQueryHookResult = ReturnType<typeof useDashCorredorFechasQuery>;
export type DashCorredorFechasLazyQueryHookResult = ReturnType<typeof useDashCorredorFechasLazyQuery>;
export type DashCorredorFechasQueryResult = Apollo.QueryResult<DashCorredorFechasQuery, DashCorredorFechasQueryVariables>;
export const DashCorredorFechasRetrasadasDocument = gql`
    query dashCorredorFechasRetrasadas($corredor_id: ID!) {
  corredorFechasRetrasadas(corredor_id: $corredor_id) {
    fecha
    cantidad
    total
    totalComisionA
    totalComisionC
  }
}
    `;

/**
 * __useDashCorredorFechasRetrasadasQuery__
 *
 * To run a query within a React component, call `useDashCorredorFechasRetrasadasQuery` and pass it any options that fit your needs.
 * When your component renders, `useDashCorredorFechasRetrasadasQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDashCorredorFechasRetrasadasQuery({
 *   variables: {
 *      corredor_id: // value for 'corredor_id'
 *   },
 * });
 */
export function useDashCorredorFechasRetrasadasQuery(baseOptions: Apollo.QueryHookOptions<DashCorredorFechasRetrasadasQuery, DashCorredorFechasRetrasadasQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DashCorredorFechasRetrasadasQuery, DashCorredorFechasRetrasadasQueryVariables>(DashCorredorFechasRetrasadasDocument, options);
      }
export function useDashCorredorFechasRetrasadasLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DashCorredorFechasRetrasadasQuery, DashCorredorFechasRetrasadasQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DashCorredorFechasRetrasadasQuery, DashCorredorFechasRetrasadasQueryVariables>(DashCorredorFechasRetrasadasDocument, options);
        }
export type DashCorredorFechasRetrasadasQueryHookResult = ReturnType<typeof useDashCorredorFechasRetrasadasQuery>;
export type DashCorredorFechasRetrasadasLazyQueryHookResult = ReturnType<typeof useDashCorredorFechasRetrasadasLazyQuery>;
export type DashCorredorFechasRetrasadasQueryResult = Apollo.QueryResult<DashCorredorFechasRetrasadasQuery, DashCorredorFechasRetrasadasQueryVariables>;
export const DashDetallesFechaDocument = gql`
    query dashDetallesFecha($corredor_id: ID!, $fecha: Date) {
  corredorTransaccionesFecha(corredor_id: $corredor_id, fecha: $fecha) {
    id
    generado
    Producto {
      nombre
    }
    Caja {
      id
    }
    monto
    comision
  }
  corredorPedidosFecha(corredor_id: $corredor_id, fecha: $fecha) {
    id
    created_at
    estado
    monto
    metadata
    resultado
    Producto {
      nombre
      identificador
    }
    Transaccion {
      generado
      monto
    }
  }
}
    `;

/**
 * __useDashDetallesFechaQuery__
 *
 * To run a query within a React component, call `useDashDetallesFechaQuery` and pass it any options that fit your needs.
 * When your component renders, `useDashDetallesFechaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDashDetallesFechaQuery({
 *   variables: {
 *      corredor_id: // value for 'corredor_id'
 *      fecha: // value for 'fecha'
 *   },
 * });
 */
export function useDashDetallesFechaQuery(baseOptions: Apollo.QueryHookOptions<DashDetallesFechaQuery, DashDetallesFechaQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DashDetallesFechaQuery, DashDetallesFechaQueryVariables>(DashDetallesFechaDocument, options);
      }
export function useDashDetallesFechaLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DashDetallesFechaQuery, DashDetallesFechaQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DashDetallesFechaQuery, DashDetallesFechaQueryVariables>(DashDetallesFechaDocument, options);
        }
export type DashDetallesFechaQueryHookResult = ReturnType<typeof useDashDetallesFechaQuery>;
export type DashDetallesFechaLazyQueryHookResult = ReturnType<typeof useDashDetallesFechaLazyQuery>;
export type DashDetallesFechaQueryResult = Apollo.QueryResult<DashDetallesFechaQuery, DashDetallesFechaQueryVariables>;
export const DashCorredorProductosPedidosDocument = gql`
    query dashCorredorProductosPedidos($corredor_id: ID!, $desde: Date, $hasta: Date, $producto_id: ID, $juego_id: [Int]) {
  corredorProductosPedidos(
    corredor_id: $corredor_id
    desde: $desde
    hasta: $hasta
    producto_id: $producto_id
    juego_id: $juego_id
  ) {
    id
    created_at
    estado
    monto
    metadata
    resultado
    Producto {
      nombre
      identificador
    }
    Transaccion {
      generado
      monto
    }
  }
}
    `;

/**
 * __useDashCorredorProductosPedidosQuery__
 *
 * To run a query within a React component, call `useDashCorredorProductosPedidosQuery` and pass it any options that fit your needs.
 * When your component renders, `useDashCorredorProductosPedidosQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDashCorredorProductosPedidosQuery({
 *   variables: {
 *      corredor_id: // value for 'corredor_id'
 *      desde: // value for 'desde'
 *      hasta: // value for 'hasta'
 *      producto_id: // value for 'producto_id'
 *      juego_id: // value for 'juego_id'
 *   },
 * });
 */
export function useDashCorredorProductosPedidosQuery(baseOptions: Apollo.QueryHookOptions<DashCorredorProductosPedidosQuery, DashCorredorProductosPedidosQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DashCorredorProductosPedidosQuery, DashCorredorProductosPedidosQueryVariables>(DashCorredorProductosPedidosDocument, options);
      }
export function useDashCorredorProductosPedidosLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DashCorredorProductosPedidosQuery, DashCorredorProductosPedidosQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DashCorredorProductosPedidosQuery, DashCorredorProductosPedidosQueryVariables>(DashCorredorProductosPedidosDocument, options);
        }
export type DashCorredorProductosPedidosQueryHookResult = ReturnType<typeof useDashCorredorProductosPedidosQuery>;
export type DashCorredorProductosPedidosLazyQueryHookResult = ReturnType<typeof useDashCorredorProductosPedidosLazyQuery>;
export type DashCorredorProductosPedidosQueryResult = Apollo.QueryResult<DashCorredorProductosPedidosQuery, DashCorredorProductosPedidosQueryVariables>;
export const DashCobradorDocument = gql`
    query dashCobrador($cobrador_id: ID!) {
  cobrador(id: $cobrador_id) {
    id
    cuenta
    linea
    Umn {
      numero
      agencia_numero
    }
    Usuario {
      Person {
        nombre
        apellido
        nrodoc
        telefono
      }
    }
  }
  cobradorFechas(cobrador_id: $cobrador_id) {
    fecha
    total
  }
  cobradorExtracto(cobrador_id: $cobrador_id) {
    id
    created_at
    detalle
    monto
    cuenta
    cuenta_anterior
  }
}
    `;

/**
 * __useDashCobradorQuery__
 *
 * To run a query within a React component, call `useDashCobradorQuery` and pass it any options that fit your needs.
 * When your component renders, `useDashCobradorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDashCobradorQuery({
 *   variables: {
 *      cobrador_id: // value for 'cobrador_id'
 *   },
 * });
 */
export function useDashCobradorQuery(baseOptions: Apollo.QueryHookOptions<DashCobradorQuery, DashCobradorQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DashCobradorQuery, DashCobradorQueryVariables>(DashCobradorDocument, options);
      }
export function useDashCobradorLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DashCobradorQuery, DashCobradorQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DashCobradorQuery, DashCobradorQueryVariables>(DashCobradorDocument, options);
        }
export type DashCobradorQueryHookResult = ReturnType<typeof useDashCobradorQuery>;
export type DashCobradorLazyQueryHookResult = ReturnType<typeof useDashCobradorLazyQuery>;
export type DashCobradorQueryResult = Apollo.QueryResult<DashCobradorQuery, DashCobradorQueryVariables>;
export const DashCobradorFechaDocument = gql`
    query dashCobradorFecha($cobrador_id: ID!, $fecha: Date!) {
  cobradorFecha(cobrador_id: $cobrador_id, fecha: $fecha) {
    id
    created_at
    estado
    Corredor {
      Usuario {
        Person {
          nombre
          nrodoc
          telefono
        }
      }
      numero
      cuenta
      linea
    }
  }
}
    `;

/**
 * __useDashCobradorFechaQuery__
 *
 * To run a query within a React component, call `useDashCobradorFechaQuery` and pass it any options that fit your needs.
 * When your component renders, `useDashCobradorFechaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDashCobradorFechaQuery({
 *   variables: {
 *      cobrador_id: // value for 'cobrador_id'
 *      fecha: // value for 'fecha'
 *   },
 * });
 */
export function useDashCobradorFechaQuery(baseOptions: Apollo.QueryHookOptions<DashCobradorFechaQuery, DashCobradorFechaQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DashCobradorFechaQuery, DashCobradorFechaQueryVariables>(DashCobradorFechaDocument, options);
      }
export function useDashCobradorFechaLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DashCobradorFechaQuery, DashCobradorFechaQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DashCobradorFechaQuery, DashCobradorFechaQueryVariables>(DashCobradorFechaDocument, options);
        }
export type DashCobradorFechaQueryHookResult = ReturnType<typeof useDashCobradorFechaQuery>;
export type DashCobradorFechaLazyQueryHookResult = ReturnType<typeof useDashCobradorFechaLazyQuery>;
export type DashCobradorFechaQueryResult = Apollo.QueryResult<DashCobradorFechaQuery, DashCobradorFechaQueryVariables>;
export const DashGerenciaDocument = gql`
    query dashGerencia($desde: Date!, $hasta: Date, $agencia: [Int], $sorteo: [Int]) {
  gerenciaDetalleQuiniela(
    desde: $desde
    hasta: $hasta
    agencia: $agencia
    sorteo: $sorteo
  )
}
    `;

/**
 * __useDashGerenciaQuery__
 *
 * To run a query within a React component, call `useDashGerenciaQuery` and pass it any options that fit your needs.
 * When your component renders, `useDashGerenciaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDashGerenciaQuery({
 *   variables: {
 *      desde: // value for 'desde'
 *      hasta: // value for 'hasta'
 *      agencia: // value for 'agencia'
 *      sorteo: // value for 'sorteo'
 *   },
 * });
 */
export function useDashGerenciaQuery(baseOptions: Apollo.QueryHookOptions<DashGerenciaQuery, DashGerenciaQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DashGerenciaQuery, DashGerenciaQueryVariables>(DashGerenciaDocument, options);
      }
export function useDashGerenciaLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DashGerenciaQuery, DashGerenciaQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DashGerenciaQuery, DashGerenciaQueryVariables>(DashGerenciaDocument, options);
        }
export type DashGerenciaQueryHookResult = ReturnType<typeof useDashGerenciaQuery>;
export type DashGerenciaLazyQueryHookResult = ReturnType<typeof useDashGerenciaLazyQuery>;
export type DashGerenciaQueryResult = Apollo.QueryResult<DashGerenciaQuery, DashGerenciaQueryVariables>;
export const DashGerenciaSorteoDocument = gql`
    query dashGerenciaSorteo($desde: Date!, $hasta: Date, $sorteo: [Int], $agencia: [Int], $corredor_numero: Int) {
  gerenciaResumenQuinielaSorteo(
    desde: $desde
    hasta: $hasta
    sorteo: $sorteo
    agencia: $agencia
    corredor_numero: $corredor_numero
  )
}
    `;

/**
 * __useDashGerenciaSorteoQuery__
 *
 * To run a query within a React component, call `useDashGerenciaSorteoQuery` and pass it any options that fit your needs.
 * When your component renders, `useDashGerenciaSorteoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDashGerenciaSorteoQuery({
 *   variables: {
 *      desde: // value for 'desde'
 *      hasta: // value for 'hasta'
 *      sorteo: // value for 'sorteo'
 *      agencia: // value for 'agencia'
 *      corredor_numero: // value for 'corredor_numero'
 *   },
 * });
 */
export function useDashGerenciaSorteoQuery(baseOptions: Apollo.QueryHookOptions<DashGerenciaSorteoQuery, DashGerenciaSorteoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DashGerenciaSorteoQuery, DashGerenciaSorteoQueryVariables>(DashGerenciaSorteoDocument, options);
      }
export function useDashGerenciaSorteoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DashGerenciaSorteoQuery, DashGerenciaSorteoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DashGerenciaSorteoQuery, DashGerenciaSorteoQueryVariables>(DashGerenciaSorteoDocument, options);
        }
export type DashGerenciaSorteoQueryHookResult = ReturnType<typeof useDashGerenciaSorteoQuery>;
export type DashGerenciaSorteoLazyQueryHookResult = ReturnType<typeof useDashGerenciaSorteoLazyQuery>;
export type DashGerenciaSorteoQueryResult = Apollo.QueryResult<DashGerenciaSorteoQuery, DashGerenciaSorteoQueryVariables>;
export const DashGerenciaAgenciaDocument = gql`
    query dashGerenciaAgencia($desde: Date!, $hasta: Date, $agencia: [Int], $sorteo: [Int], $corredor_numero: Int) {
  gerenciaResumenQuinielaAgencia(
    desde: $desde
    hasta: $hasta
    agencia: $agencia
    sorteo: $sorteo
    corredor_numero: $corredor_numero
  )
}
    `;

/**
 * __useDashGerenciaAgenciaQuery__
 *
 * To run a query within a React component, call `useDashGerenciaAgenciaQuery` and pass it any options that fit your needs.
 * When your component renders, `useDashGerenciaAgenciaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDashGerenciaAgenciaQuery({
 *   variables: {
 *      desde: // value for 'desde'
 *      hasta: // value for 'hasta'
 *      agencia: // value for 'agencia'
 *      sorteo: // value for 'sorteo'
 *      corredor_numero: // value for 'corredor_numero'
 *   },
 * });
 */
export function useDashGerenciaAgenciaQuery(baseOptions: Apollo.QueryHookOptions<DashGerenciaAgenciaQuery, DashGerenciaAgenciaQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DashGerenciaAgenciaQuery, DashGerenciaAgenciaQueryVariables>(DashGerenciaAgenciaDocument, options);
      }
export function useDashGerenciaAgenciaLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DashGerenciaAgenciaQuery, DashGerenciaAgenciaQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DashGerenciaAgenciaQuery, DashGerenciaAgenciaQueryVariables>(DashGerenciaAgenciaDocument, options);
        }
export type DashGerenciaAgenciaQueryHookResult = ReturnType<typeof useDashGerenciaAgenciaQuery>;
export type DashGerenciaAgenciaLazyQueryHookResult = ReturnType<typeof useDashGerenciaAgenciaLazyQuery>;
export type DashGerenciaAgenciaQueryResult = Apollo.QueryResult<DashGerenciaAgenciaQuery, DashGerenciaAgenciaQueryVariables>;
export const DashGerenciaAgrupadoDocument = gql`
    query dashGerenciaAgrupado($fechaFrom: Date!, $fechaTo: Date!, $agencia: [Int], $sorteo: [Int], $corredor_numero: Int) {
  gerenciaResumenQuinielaFecha(
    fechaFrom: $fechaFrom
    fechaTo: $fechaTo
    agencia: $agencia
    sorteo: $sorteo
    corredor_numero: $corredor_numero
  )
}
    `;

/**
 * __useDashGerenciaAgrupadoQuery__
 *
 * To run a query within a React component, call `useDashGerenciaAgrupadoQuery` and pass it any options that fit your needs.
 * When your component renders, `useDashGerenciaAgrupadoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDashGerenciaAgrupadoQuery({
 *   variables: {
 *      fechaFrom: // value for 'fechaFrom'
 *      fechaTo: // value for 'fechaTo'
 *      agencia: // value for 'agencia'
 *      sorteo: // value for 'sorteo'
 *      corredor_numero: // value for 'corredor_numero'
 *   },
 * });
 */
export function useDashGerenciaAgrupadoQuery(baseOptions: Apollo.QueryHookOptions<DashGerenciaAgrupadoQuery, DashGerenciaAgrupadoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DashGerenciaAgrupadoQuery, DashGerenciaAgrupadoQueryVariables>(DashGerenciaAgrupadoDocument, options);
      }
export function useDashGerenciaAgrupadoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DashGerenciaAgrupadoQuery, DashGerenciaAgrupadoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DashGerenciaAgrupadoQuery, DashGerenciaAgrupadoQueryVariables>(DashGerenciaAgrupadoDocument, options);
        }
export type DashGerenciaAgrupadoQueryHookResult = ReturnType<typeof useDashGerenciaAgrupadoQuery>;
export type DashGerenciaAgrupadoLazyQueryHookResult = ReturnType<typeof useDashGerenciaAgrupadoLazyQuery>;
export type DashGerenciaAgrupadoQueryResult = Apollo.QueryResult<DashGerenciaAgrupadoQuery, DashGerenciaAgrupadoQueryVariables>;
export const DashGerenciaAgenciaProductoDocument = gql`
    query dashGerenciaAgenciaProducto($desde: Date!, $hasta: Date, $agencia: [Int], $corredor_numero: Int, $detail: Boolean, $bycorredor: Boolean, $umn_id: Int, $producto_id: ID) {
  gerenciaResumenQuinielaAgenciaProducto(
    desde: $desde
    hasta: $hasta
    agencia: $agencia
    corredor_numero: $corredor_numero
    detail: $detail
    bycorredor: $bycorredor
    umn_id: $umn_id
    producto_id: $producto_id
  )
}
    `;

/**
 * __useDashGerenciaAgenciaProductoQuery__
 *
 * To run a query within a React component, call `useDashGerenciaAgenciaProductoQuery` and pass it any options that fit your needs.
 * When your component renders, `useDashGerenciaAgenciaProductoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDashGerenciaAgenciaProductoQuery({
 *   variables: {
 *      desde: // value for 'desde'
 *      hasta: // value for 'hasta'
 *      agencia: // value for 'agencia'
 *      corredor_numero: // value for 'corredor_numero'
 *      detail: // value for 'detail'
 *      bycorredor: // value for 'bycorredor'
 *      umn_id: // value for 'umn_id'
 *      producto_id: // value for 'producto_id'
 *   },
 * });
 */
export function useDashGerenciaAgenciaProductoQuery(baseOptions: Apollo.QueryHookOptions<DashGerenciaAgenciaProductoQuery, DashGerenciaAgenciaProductoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DashGerenciaAgenciaProductoQuery, DashGerenciaAgenciaProductoQueryVariables>(DashGerenciaAgenciaProductoDocument, options);
      }
export function useDashGerenciaAgenciaProductoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DashGerenciaAgenciaProductoQuery, DashGerenciaAgenciaProductoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DashGerenciaAgenciaProductoQuery, DashGerenciaAgenciaProductoQueryVariables>(DashGerenciaAgenciaProductoDocument, options);
        }
export type DashGerenciaAgenciaProductoQueryHookResult = ReturnType<typeof useDashGerenciaAgenciaProductoQuery>;
export type DashGerenciaAgenciaProductoLazyQueryHookResult = ReturnType<typeof useDashGerenciaAgenciaProductoLazyQuery>;
export type DashGerenciaAgenciaProductoQueryResult = Apollo.QueryResult<DashGerenciaAgenciaProductoQuery, DashGerenciaAgenciaProductoQueryVariables>;
export const DashboardAnulacionDocument = gql`
    query dashboardAnulacion($umn_id: [ID!], $sorteo_base_id: [ID!], $estado: [String], $desde: Date!, $hasta: Date, $nro_corredor: Int, $nrodoc: String) {
  anulaciones(
    umn_id: $umn_id
    sorteo_base_id: $sorteo_base_id
    estado: $estado
    desde: $desde
    hasta: $hasta
    nro_corredor: $nro_corredor
    nrodoc: $nrodoc
  ) {
    id
    estado
    nivel
    elevacion
    created_at
    excedido
    AnulacionMotivo {
      descripcion
    }
    AprobadoPor {
      id
      Person {
        nombre
        apellido
      }
    }
    Juego {
      serie
      ticket
      monto_apostado
      Sorteo {
        fecha
        cierre
      }
      Transaccion {
        Caja {
          id
          Corredor {
            numero
            id
            Usuario {
              Person {
                nombre
                apellido
              }
            }
            Umn {
              id
              agencia_numero
              numero
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useDashboardAnulacionQuery__
 *
 * To run a query within a React component, call `useDashboardAnulacionQuery` and pass it any options that fit your needs.
 * When your component renders, `useDashboardAnulacionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDashboardAnulacionQuery({
 *   variables: {
 *      umn_id: // value for 'umn_id'
 *      sorteo_base_id: // value for 'sorteo_base_id'
 *      estado: // value for 'estado'
 *      desde: // value for 'desde'
 *      hasta: // value for 'hasta'
 *      nro_corredor: // value for 'nro_corredor'
 *      nrodoc: // value for 'nrodoc'
 *   },
 * });
 */
export function useDashboardAnulacionQuery(baseOptions: Apollo.QueryHookOptions<DashboardAnulacionQuery, DashboardAnulacionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DashboardAnulacionQuery, DashboardAnulacionQueryVariables>(DashboardAnulacionDocument, options);
      }
export function useDashboardAnulacionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DashboardAnulacionQuery, DashboardAnulacionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DashboardAnulacionQuery, DashboardAnulacionQueryVariables>(DashboardAnulacionDocument, options);
        }
export type DashboardAnulacionQueryHookResult = ReturnType<typeof useDashboardAnulacionQuery>;
export type DashboardAnulacionLazyQueryHookResult = ReturnType<typeof useDashboardAnulacionLazyQuery>;
export type DashboardAnulacionQueryResult = Apollo.QueryResult<DashboardAnulacionQuery, DashboardAnulacionQueryVariables>;
export const DashboardCajaDocument = gql`
    query dashboardCaja($caja_id: ID!) {
  caja(caja_id: $caja_id) {
    id
    apertura
    Corredor {
      id
      cuenta
      linea
      Umn {
        numero
        agencia_numero
      }
      Usuario {
        Person {
          nombre
          apellido
          nrodoc
          telefono
        }
      }
      Umn {
        agencia_numero
        numero
      }
    }
  }
  resumenCaja(caja_id: $caja_id) {
    TransaccionesAgrupadas {
      id
      nombre
      cantidad
      total
      totalComisionA
      totalComisionC
    }
  }
}
    `;

/**
 * __useDashboardCajaQuery__
 *
 * To run a query within a React component, call `useDashboardCajaQuery` and pass it any options that fit your needs.
 * When your component renders, `useDashboardCajaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDashboardCajaQuery({
 *   variables: {
 *      caja_id: // value for 'caja_id'
 *   },
 * });
 */
export function useDashboardCajaQuery(baseOptions: Apollo.QueryHookOptions<DashboardCajaQuery, DashboardCajaQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DashboardCajaQuery, DashboardCajaQueryVariables>(DashboardCajaDocument, options);
      }
export function useDashboardCajaLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DashboardCajaQuery, DashboardCajaQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DashboardCajaQuery, DashboardCajaQueryVariables>(DashboardCajaDocument, options);
        }
export type DashboardCajaQueryHookResult = ReturnType<typeof useDashboardCajaQuery>;
export type DashboardCajaLazyQueryHookResult = ReturnType<typeof useDashboardCajaLazyQuery>;
export type DashboardCajaQueryResult = Apollo.QueryResult<DashboardCajaQuery, DashboardCajaQueryVariables>;
export const DashboardCajaDetalleProductoDocument = gql`
    query dashboardCajaDetalleProducto($caja_id: ID!, $producto_id: Int!) {
  producto(producto_id: $producto_id) {
    id
    nombre
  }
  caja(caja_id: $caja_id) {
    id
    apertura
    Corredor {
      id
      Usuario {
        Person {
          nombre
          apellido
        }
      }
      Umn {
        agencia_numero
        numero
      }
    }
  }
  producto(producto_id: $producto_id) {
    id
    nombre
  }
  detalleCajaProducto(caja_id: $caja_id, producto_id: $producto_id) {
    Transacciones {
      id
      created_at
      monto
    }
    Extractos {
      id
      detalle
      monto
      cuenta
      cuenta_anterior
    }
    Pedidos {
      monto
      estado
      resultado
    }
  }
}
    `;

/**
 * __useDashboardCajaDetalleProductoQuery__
 *
 * To run a query within a React component, call `useDashboardCajaDetalleProductoQuery` and pass it any options that fit your needs.
 * When your component renders, `useDashboardCajaDetalleProductoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDashboardCajaDetalleProductoQuery({
 *   variables: {
 *      caja_id: // value for 'caja_id'
 *      producto_id: // value for 'producto_id'
 *   },
 * });
 */
export function useDashboardCajaDetalleProductoQuery(baseOptions: Apollo.QueryHookOptions<DashboardCajaDetalleProductoQuery, DashboardCajaDetalleProductoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DashboardCajaDetalleProductoQuery, DashboardCajaDetalleProductoQueryVariables>(DashboardCajaDetalleProductoDocument, options);
      }
export function useDashboardCajaDetalleProductoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DashboardCajaDetalleProductoQuery, DashboardCajaDetalleProductoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DashboardCajaDetalleProductoQuery, DashboardCajaDetalleProductoQueryVariables>(DashboardCajaDetalleProductoDocument, options);
        }
export type DashboardCajaDetalleProductoQueryHookResult = ReturnType<typeof useDashboardCajaDetalleProductoQuery>;
export type DashboardCajaDetalleProductoLazyQueryHookResult = ReturnType<typeof useDashboardCajaDetalleProductoLazyQuery>;
export type DashboardCajaDetalleProductoQueryResult = Apollo.QueryResult<DashboardCajaDetalleProductoQuery, DashboardCajaDetalleProductoQueryVariables>;
export const DashboardCobradorDocument = gql`
    query dashboardCobrador($umn_id: [ID!], $estado: [String], $offset: Int, $limit: Int, $desde: Date!, $hasta: Date, $nrodoc: String, $nro_corredor: Int, $cobrador_id: Int) {
  entregas(
    umn_id: $umn_id
    estado: $estado
    offset: $offset
    limit: $limit
    desde: $desde
    hasta: $hasta
    nrodoc: $nrodoc
    nro_corredor: $nro_corredor
    cobrador_id: $cobrador_id
  ) {
    id
    created_at
    updated_at
    Corredor {
      id
      numero
      Usuario {
        Person {
          nombre
          apellido
        }
      }
      Umn {
        numero
        agencia_numero
      }
    }
    Cobrador {
      id
      Usuario {
        Person {
          nombre
          apellido
        }
      }
      Umn {
        numero
        agencia_numero
      }
    }
    monto
    estado
  }
  umn(id: $umn_id) {
    numero
    totalCorredores
    Corredores {
      numero
      id
      Usuario {
        username
        Person {
          nombre
          apellido
        }
      }
    }
  }
}
    `;

/**
 * __useDashboardCobradorQuery__
 *
 * To run a query within a React component, call `useDashboardCobradorQuery` and pass it any options that fit your needs.
 * When your component renders, `useDashboardCobradorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDashboardCobradorQuery({
 *   variables: {
 *      umn_id: // value for 'umn_id'
 *      estado: // value for 'estado'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      desde: // value for 'desde'
 *      hasta: // value for 'hasta'
 *      nrodoc: // value for 'nrodoc'
 *      nro_corredor: // value for 'nro_corredor'
 *      cobrador_id: // value for 'cobrador_id'
 *   },
 * });
 */
export function useDashboardCobradorQuery(baseOptions: Apollo.QueryHookOptions<DashboardCobradorQuery, DashboardCobradorQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DashboardCobradorQuery, DashboardCobradorQueryVariables>(DashboardCobradorDocument, options);
      }
export function useDashboardCobradorLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DashboardCobradorQuery, DashboardCobradorQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DashboardCobradorQuery, DashboardCobradorQueryVariables>(DashboardCobradorDocument, options);
        }
export type DashboardCobradorQueryHookResult = ReturnType<typeof useDashboardCobradorQuery>;
export type DashboardCobradorLazyQueryHookResult = ReturnType<typeof useDashboardCobradorLazyQuery>;
export type DashboardCobradorQueryResult = Apollo.QueryResult<DashboardCobradorQuery, DashboardCobradorQueryVariables>;
export const DashboardCorredorDocument = gql`
    query dashboardCorredor($corredor_id: ID!) {
  corredorExtracto(corredor_id: $corredor_id) {
    id
    created_at
    detalle
    monto
    cuenta
    cuenta_anterior
    fecha_referencia
    annexable_id
    serial
  }
  corredor(id: $corredor_id) {
    id
    numero
    cuenta
    linea
    entrega_corredor
    activo
    Umn {
      numero
      agencia_numero
    }
    Cajas {
      linea
      utilizado
      estado
    }
    Usuario {
      Person {
        nombre
        apellido
        nrodoc
        telefono
      }
    }
  }
}
    `;

/**
 * __useDashboardCorredorQuery__
 *
 * To run a query within a React component, call `useDashboardCorredorQuery` and pass it any options that fit your needs.
 * When your component renders, `useDashboardCorredorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDashboardCorredorQuery({
 *   variables: {
 *      corredor_id: // value for 'corredor_id'
 *   },
 * });
 */
export function useDashboardCorredorQuery(baseOptions: Apollo.QueryHookOptions<DashboardCorredorQuery, DashboardCorredorQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DashboardCorredorQuery, DashboardCorredorQueryVariables>(DashboardCorredorDocument, options);
      }
export function useDashboardCorredorLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DashboardCorredorQuery, DashboardCorredorQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DashboardCorredorQuery, DashboardCorredorQueryVariables>(DashboardCorredorDocument, options);
        }
export type DashboardCorredorQueryHookResult = ReturnType<typeof useDashboardCorredorQuery>;
export type DashboardCorredorLazyQueryHookResult = ReturnType<typeof useDashboardCorredorLazyQuery>;
export type DashboardCorredorQueryResult = Apollo.QueryResult<DashboardCorredorQuery, DashboardCorredorQueryVariables>;
export const DashboardResumenUmnDocument = gql`
    query dashboardResumenUmn($umn_id: [ID], $desde: Date, $hasta: Date) {
  resumenUmn(umn_id: $umn_id, desde: $desde, hasta: $hasta) {
    TransaccionesAgrupadas
    quinielaMontos
    raspaditaMontos
    depositos
    saldoAnterior
  }
}
    `;

/**
 * __useDashboardResumenUmnQuery__
 *
 * To run a query within a React component, call `useDashboardResumenUmnQuery` and pass it any options that fit your needs.
 * When your component renders, `useDashboardResumenUmnQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDashboardResumenUmnQuery({
 *   variables: {
 *      umn_id: // value for 'umn_id'
 *      desde: // value for 'desde'
 *      hasta: // value for 'hasta'
 *   },
 * });
 */
export function useDashboardResumenUmnQuery(baseOptions?: Apollo.QueryHookOptions<DashboardResumenUmnQuery, DashboardResumenUmnQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DashboardResumenUmnQuery, DashboardResumenUmnQueryVariables>(DashboardResumenUmnDocument, options);
      }
export function useDashboardResumenUmnLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DashboardResumenUmnQuery, DashboardResumenUmnQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DashboardResumenUmnQuery, DashboardResumenUmnQueryVariables>(DashboardResumenUmnDocument, options);
        }
export type DashboardResumenUmnQueryHookResult = ReturnType<typeof useDashboardResumenUmnQuery>;
export type DashboardResumenUmnLazyQueryHookResult = ReturnType<typeof useDashboardResumenUmnLazyQuery>;
export type DashboardResumenUmnQueryResult = Apollo.QueryResult<DashboardResumenUmnQuery, DashboardResumenUmnQueryVariables>;
export const DashboardUmnDocument = gql`
    query dashboardUmn {
  umns {
    numero
    id
    nombre
    agencia_numero
    Corredores {
      numero
      id
      Usuario {
        username
        pin
      }
    }
  }
}
    `;

/**
 * __useDashboardUmnQuery__
 *
 * To run a query within a React component, call `useDashboardUmnQuery` and pass it any options that fit your needs.
 * When your component renders, `useDashboardUmnQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDashboardUmnQuery({
 *   variables: {
 *   },
 * });
 */
export function useDashboardUmnQuery(baseOptions?: Apollo.QueryHookOptions<DashboardUmnQuery, DashboardUmnQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DashboardUmnQuery, DashboardUmnQueryVariables>(DashboardUmnDocument, options);
      }
export function useDashboardUmnLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DashboardUmnQuery, DashboardUmnQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DashboardUmnQuery, DashboardUmnQueryVariables>(DashboardUmnDocument, options);
        }
export type DashboardUmnQueryHookResult = ReturnType<typeof useDashboardUmnQuery>;
export type DashboardUmnLazyQueryHookResult = ReturnType<typeof useDashboardUmnLazyQuery>;
export type DashboardUmnQueryResult = Apollo.QueryResult<DashboardUmnQuery, DashboardUmnQueryVariables>;
export const DepositoUmnDocument = gql`
    query depositoUmn($desde: Date!, $hasta: Date, $umn_id: [ID]) {
  depositos(desde: $desde, hasta: $hasta, umn_id: $umn_id) {
    id
    umn_id
    monto
    detalle
    medio
    nro_recibo
    created_at
    generado
    anulado
    Umn {
      id
      numero
      nombre
      agencia_numero
    }
  }
}
    `;

/**
 * __useDepositoUmnQuery__
 *
 * To run a query within a React component, call `useDepositoUmnQuery` and pass it any options that fit your needs.
 * When your component renders, `useDepositoUmnQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDepositoUmnQuery({
 *   variables: {
 *      desde: // value for 'desde'
 *      hasta: // value for 'hasta'
 *      umn_id: // value for 'umn_id'
 *   },
 * });
 */
export function useDepositoUmnQuery(baseOptions: Apollo.QueryHookOptions<DepositoUmnQuery, DepositoUmnQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DepositoUmnQuery, DepositoUmnQueryVariables>(DepositoUmnDocument, options);
      }
export function useDepositoUmnLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DepositoUmnQuery, DepositoUmnQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DepositoUmnQuery, DepositoUmnQueryVariables>(DepositoUmnDocument, options);
        }
export type DepositoUmnQueryHookResult = ReturnType<typeof useDepositoUmnQuery>;
export type DepositoUmnLazyQueryHookResult = ReturnType<typeof useDepositoUmnLazyQuery>;
export type DepositoUmnQueryResult = Apollo.QueryResult<DepositoUmnQuery, DepositoUmnQueryVariables>;
export const DepositoAnuladoUmnDocument = gql`
    query depositoAnuladoUmn($desde: Date!, $hasta: Date, $umn_id: [ID]) {
  depositosAnulados(desde: $desde, hasta: $hasta, umn_id: $umn_id) {
    id
    umn_id
    monto
    detalle
    medio
    nro_recibo
    created_at
    generado
    anulado
    Umn {
      id
      numero
      nombre
      agencia_numero
    }
  }
}
    `;

/**
 * __useDepositoAnuladoUmnQuery__
 *
 * To run a query within a React component, call `useDepositoAnuladoUmnQuery` and pass it any options that fit your needs.
 * When your component renders, `useDepositoAnuladoUmnQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDepositoAnuladoUmnQuery({
 *   variables: {
 *      desde: // value for 'desde'
 *      hasta: // value for 'hasta'
 *      umn_id: // value for 'umn_id'
 *   },
 * });
 */
export function useDepositoAnuladoUmnQuery(baseOptions: Apollo.QueryHookOptions<DepositoAnuladoUmnQuery, DepositoAnuladoUmnQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DepositoAnuladoUmnQuery, DepositoAnuladoUmnQueryVariables>(DepositoAnuladoUmnDocument, options);
      }
export function useDepositoAnuladoUmnLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DepositoAnuladoUmnQuery, DepositoAnuladoUmnQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DepositoAnuladoUmnQuery, DepositoAnuladoUmnQueryVariables>(DepositoAnuladoUmnDocument, options);
        }
export type DepositoAnuladoUmnQueryHookResult = ReturnType<typeof useDepositoAnuladoUmnQuery>;
export type DepositoAnuladoUmnLazyQueryHookResult = ReturnType<typeof useDepositoAnuladoUmnLazyQuery>;
export type DepositoAnuladoUmnQueryResult = Apollo.QueryResult<DepositoAnuladoUmnQuery, DepositoAnuladoUmnQueryVariables>;
export const DetalleTerminalDocument = gql`
    query detalleTerminal($terminal_id: ID!) {
  terminal(terminal_id: $terminal_id) {
    id
    serial
    version_asignada
  }
  heartbeats(terminal_id: $terminal_id) {
    created_at
    data
  }
  usoTerminal(terminal_id: $terminal_id) {
    id
    apertura
    cierre
    Corredor {
      numero
      Umn {
        numero
        agencia_numero
      }
      Usuario {
        Person {
          nombre
          apellido
        }
      }
    }
  }
}
    `;

/**
 * __useDetalleTerminalQuery__
 *
 * To run a query within a React component, call `useDetalleTerminalQuery` and pass it any options that fit your needs.
 * When your component renders, `useDetalleTerminalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDetalleTerminalQuery({
 *   variables: {
 *      terminal_id: // value for 'terminal_id'
 *   },
 * });
 */
export function useDetalleTerminalQuery(baseOptions: Apollo.QueryHookOptions<DetalleTerminalQuery, DetalleTerminalQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DetalleTerminalQuery, DetalleTerminalQueryVariables>(DetalleTerminalDocument, options);
      }
export function useDetalleTerminalLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DetalleTerminalQuery, DetalleTerminalQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DetalleTerminalQuery, DetalleTerminalQueryVariables>(DetalleTerminalDocument, options);
        }
export type DetalleTerminalQueryHookResult = ReturnType<typeof useDetalleTerminalQuery>;
export type DetalleTerminalLazyQueryHookResult = ReturnType<typeof useDetalleTerminalLazyQuery>;
export type DetalleTerminalQueryResult = Apollo.QueryResult<DetalleTerminalQuery, DetalleTerminalQueryVariables>;
export const DetalleTerminalSerialDocument = gql`
    query detalleTerminalSerial($terminal_id: ID!) {
  terminal(terminal_id: $terminal_id) {
    id
    serial
    version_asignada
  }
}
    `;

/**
 * __useDetalleTerminalSerialQuery__
 *
 * To run a query within a React component, call `useDetalleTerminalSerialQuery` and pass it any options that fit your needs.
 * When your component renders, `useDetalleTerminalSerialQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDetalleTerminalSerialQuery({
 *   variables: {
 *      terminal_id: // value for 'terminal_id'
 *   },
 * });
 */
export function useDetalleTerminalSerialQuery(baseOptions: Apollo.QueryHookOptions<DetalleTerminalSerialQuery, DetalleTerminalSerialQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DetalleTerminalSerialQuery, DetalleTerminalSerialQueryVariables>(DetalleTerminalSerialDocument, options);
      }
export function useDetalleTerminalSerialLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DetalleTerminalSerialQuery, DetalleTerminalSerialQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DetalleTerminalSerialQuery, DetalleTerminalSerialQueryVariables>(DetalleTerminalSerialDocument, options);
        }
export type DetalleTerminalSerialQueryHookResult = ReturnType<typeof useDetalleTerminalSerialQuery>;
export type DetalleTerminalSerialLazyQueryHookResult = ReturnType<typeof useDetalleTerminalSerialLazyQuery>;
export type DetalleTerminalSerialQueryResult = Apollo.QueryResult<DetalleTerminalSerialQuery, DetalleTerminalSerialQueryVariables>;
export const PlanillaCobrosEntregaDocument = gql`
    query planillaCobrosEntrega($corredor_id: ID!) {
  entregasCorredor(corredor_id: $corredor_id, estado: "pendiente") {
    id
    monto
    created_at
  }
}
    `;

/**
 * __usePlanillaCobrosEntregaQuery__
 *
 * To run a query within a React component, call `usePlanillaCobrosEntregaQuery` and pass it any options that fit your needs.
 * When your component renders, `usePlanillaCobrosEntregaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePlanillaCobrosEntregaQuery({
 *   variables: {
 *      corredor_id: // value for 'corredor_id'
 *   },
 * });
 */
export function usePlanillaCobrosEntregaQuery(baseOptions: Apollo.QueryHookOptions<PlanillaCobrosEntregaQuery, PlanillaCobrosEntregaQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PlanillaCobrosEntregaQuery, PlanillaCobrosEntregaQueryVariables>(PlanillaCobrosEntregaDocument, options);
      }
export function usePlanillaCobrosEntregaLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PlanillaCobrosEntregaQuery, PlanillaCobrosEntregaQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PlanillaCobrosEntregaQuery, PlanillaCobrosEntregaQueryVariables>(PlanillaCobrosEntregaDocument, options);
        }
export type PlanillaCobrosEntregaQueryHookResult = ReturnType<typeof usePlanillaCobrosEntregaQuery>;
export type PlanillaCobrosEntregaLazyQueryHookResult = ReturnType<typeof usePlanillaCobrosEntregaLazyQuery>;
export type PlanillaCobrosEntregaQueryResult = Apollo.QueryResult<PlanillaCobrosEntregaQuery, PlanillaCobrosEntregaQueryVariables>;
export const UmnExtractoDocument = gql`
    query umnExtracto($umn_id: [ID!]) {
  umnExtracto(umn_id: $umn_id) {
    id
    detalle
    created_at
    monto
    cuenta
    cuenta_anterior
    Umn {
      numero
      id
      nombre
    }
  }
}
    `;

/**
 * __useUmnExtractoQuery__
 *
 * To run a query within a React component, call `useUmnExtractoQuery` and pass it any options that fit your needs.
 * When your component renders, `useUmnExtractoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUmnExtractoQuery({
 *   variables: {
 *      umn_id: // value for 'umn_id'
 *   },
 * });
 */
export function useUmnExtractoQuery(baseOptions?: Apollo.QueryHookOptions<UmnExtractoQuery, UmnExtractoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UmnExtractoQuery, UmnExtractoQueryVariables>(UmnExtractoDocument, options);
      }
export function useUmnExtractoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UmnExtractoQuery, UmnExtractoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UmnExtractoQuery, UmnExtractoQueryVariables>(UmnExtractoDocument, options);
        }
export type UmnExtractoQueryHookResult = ReturnType<typeof useUmnExtractoQuery>;
export type UmnExtractoLazyQueryHookResult = ReturnType<typeof useUmnExtractoLazyQuery>;
export type UmnExtractoQueryResult = Apollo.QueryResult<UmnExtractoQuery, UmnExtractoQueryVariables>;
export const FindEntregaCorredorDocument = gql`
    query findEntregaCorredor($entrega_id: ID!) {
  findEntrega(entrega_id: $entrega_id)
}
    `;

/**
 * __useFindEntregaCorredorQuery__
 *
 * To run a query within a React component, call `useFindEntregaCorredorQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindEntregaCorredorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindEntregaCorredorQuery({
 *   variables: {
 *      entrega_id: // value for 'entrega_id'
 *   },
 * });
 */
export function useFindEntregaCorredorQuery(baseOptions: Apollo.QueryHookOptions<FindEntregaCorredorQuery, FindEntregaCorredorQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindEntregaCorredorQuery, FindEntregaCorredorQueryVariables>(FindEntregaCorredorDocument, options);
      }
export function useFindEntregaCorredorLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindEntregaCorredorQuery, FindEntregaCorredorQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindEntregaCorredorQuery, FindEntregaCorredorQueryVariables>(FindEntregaCorredorDocument, options);
        }
export type FindEntregaCorredorQueryHookResult = ReturnType<typeof useFindEntregaCorredorQuery>;
export type FindEntregaCorredorLazyQueryHookResult = ReturnType<typeof useFindEntregaCorredorLazyQuery>;
export type FindEntregaCorredorQueryResult = Apollo.QueryResult<FindEntregaCorredorQuery, FindEntregaCorredorQueryVariables>;
export const DashGerenciaPremiosDocument = gql`
    query dashGerenciaPremios($desde: Date!, $hasta: Date, $agencia: [Int], $sorteo: [Int], $corredor_numero: Int, $producto_id: Int) {
  premiacionAgenciaGerencia(
    desde: $desde
    hasta: $hasta
    agencia: $agencia
    sorteo: $sorteo
    corredor_numero: $corredor_numero
    producto_id: $producto_id
  )
}
    `;

/**
 * __useDashGerenciaPremiosQuery__
 *
 * To run a query within a React component, call `useDashGerenciaPremiosQuery` and pass it any options that fit your needs.
 * When your component renders, `useDashGerenciaPremiosQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDashGerenciaPremiosQuery({
 *   variables: {
 *      desde: // value for 'desde'
 *      hasta: // value for 'hasta'
 *      agencia: // value for 'agencia'
 *      sorteo: // value for 'sorteo'
 *      corredor_numero: // value for 'corredor_numero'
 *      producto_id: // value for 'producto_id'
 *   },
 * });
 */
export function useDashGerenciaPremiosQuery(baseOptions: Apollo.QueryHookOptions<DashGerenciaPremiosQuery, DashGerenciaPremiosQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DashGerenciaPremiosQuery, DashGerenciaPremiosQueryVariables>(DashGerenciaPremiosDocument, options);
      }
export function useDashGerenciaPremiosLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DashGerenciaPremiosQuery, DashGerenciaPremiosQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DashGerenciaPremiosQuery, DashGerenciaPremiosQueryVariables>(DashGerenciaPremiosDocument, options);
        }
export type DashGerenciaPremiosQueryHookResult = ReturnType<typeof useDashGerenciaPremiosQuery>;
export type DashGerenciaPremiosLazyQueryHookResult = ReturnType<typeof useDashGerenciaPremiosLazyQuery>;
export type DashGerenciaPremiosQueryResult = Apollo.QueryResult<DashGerenciaPremiosQuery, DashGerenciaPremiosQueryVariables>;
export const GerenciaResumenQuinielaUmnsDocument = gql`
    query gerenciaResumenQuinielaUmns($desde: Date!, $hasta: Date, $agencia: [Int], $sorteo: [Int], $corredor_numero: Int) {
  gerenciaResumenQuinielaUmns(
    desde: $desde
    hasta: $hasta
    agencia: $agencia
    sorteo: $sorteo
    corredor_numero: $corredor_numero
  )
}
    `;

/**
 * __useGerenciaResumenQuinielaUmnsQuery__
 *
 * To run a query within a React component, call `useGerenciaResumenQuinielaUmnsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGerenciaResumenQuinielaUmnsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGerenciaResumenQuinielaUmnsQuery({
 *   variables: {
 *      desde: // value for 'desde'
 *      hasta: // value for 'hasta'
 *      agencia: // value for 'agencia'
 *      sorteo: // value for 'sorteo'
 *      corredor_numero: // value for 'corredor_numero'
 *   },
 * });
 */
export function useGerenciaResumenQuinielaUmnsQuery(baseOptions: Apollo.QueryHookOptions<GerenciaResumenQuinielaUmnsQuery, GerenciaResumenQuinielaUmnsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GerenciaResumenQuinielaUmnsQuery, GerenciaResumenQuinielaUmnsQueryVariables>(GerenciaResumenQuinielaUmnsDocument, options);
      }
export function useGerenciaResumenQuinielaUmnsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GerenciaResumenQuinielaUmnsQuery, GerenciaResumenQuinielaUmnsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GerenciaResumenQuinielaUmnsQuery, GerenciaResumenQuinielaUmnsQueryVariables>(GerenciaResumenQuinielaUmnsDocument, options);
        }
export type GerenciaResumenQuinielaUmnsQueryHookResult = ReturnType<typeof useGerenciaResumenQuinielaUmnsQuery>;
export type GerenciaResumenQuinielaUmnsLazyQueryHookResult = ReturnType<typeof useGerenciaResumenQuinielaUmnsLazyQuery>;
export type GerenciaResumenQuinielaUmnsQueryResult = Apollo.QueryResult<GerenciaResumenQuinielaUmnsQuery, GerenciaResumenQuinielaUmnsQueryVariables>;
export const AllAgenciasDocument = gql`
    query allAgencias {
  allAgencies {
    numero
    nombre
    created_at
  }
}
    `;

/**
 * __useAllAgenciasQuery__
 *
 * To run a query within a React component, call `useAllAgenciasQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllAgenciasQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllAgenciasQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllAgenciasQuery(baseOptions?: Apollo.QueryHookOptions<AllAgenciasQuery, AllAgenciasQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllAgenciasQuery, AllAgenciasQueryVariables>(AllAgenciasDocument, options);
      }
export function useAllAgenciasLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllAgenciasQuery, AllAgenciasQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllAgenciasQuery, AllAgenciasQueryVariables>(AllAgenciasDocument, options);
        }
export type AllAgenciasQueryHookResult = ReturnType<typeof useAllAgenciasQuery>;
export type AllAgenciasLazyQueryHookResult = ReturnType<typeof useAllAgenciasLazyQuery>;
export type AllAgenciasQueryResult = Apollo.QueryResult<AllAgenciasQuery, AllAgenciasQueryVariables>;
export const GetAjustesUmnDocument = gql`
    query getAjustesUmn($desde: Date!, $hasta: Date, $umn_id: [ID!]) {
  verAjustesUmn(desde: $desde, hasta: $hasta, umn_id: $umn_id) {
    id
    Umn {
      id
      numero
      agencia_numero
    }
    aprobacion {
      id
      username
    }
    estado
    created_at
    monto
    detalle
    tipo
  }
}
    `;

/**
 * __useGetAjustesUmnQuery__
 *
 * To run a query within a React component, call `useGetAjustesUmnQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAjustesUmnQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAjustesUmnQuery({
 *   variables: {
 *      desde: // value for 'desde'
 *      hasta: // value for 'hasta'
 *      umn_id: // value for 'umn_id'
 *   },
 * });
 */
export function useGetAjustesUmnQuery(baseOptions: Apollo.QueryHookOptions<GetAjustesUmnQuery, GetAjustesUmnQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAjustesUmnQuery, GetAjustesUmnQueryVariables>(GetAjustesUmnDocument, options);
      }
export function useGetAjustesUmnLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAjustesUmnQuery, GetAjustesUmnQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAjustesUmnQuery, GetAjustesUmnQueryVariables>(GetAjustesUmnDocument, options);
        }
export type GetAjustesUmnQueryHookResult = ReturnType<typeof useGetAjustesUmnQuery>;
export type GetAjustesUmnLazyQueryHookResult = ReturnType<typeof useGetAjustesUmnLazyQuery>;
export type GetAjustesUmnQueryResult = Apollo.QueryResult<GetAjustesUmnQuery, GetAjustesUmnQueryVariables>;
export const GetAjustesDocument = gql`
    query getAjustes($desde: Date!, $hasta: Date, $nrodoc: String, $umn_id: [ID!], $numero_corredor: Int) {
  verAjustes(
    desde: $desde
    hasta: $hasta
    nrodoc: $nrodoc
    umn_id: $umn_id
    numero_corredor: $numero_corredor
  ) {
    id
    estado
    detalle
    created_at
    aprobacion {
      username
      id
    }
    Corredor {
      Umn {
        numero
        id
        agencia_numero
      }
      Usuario {
        Person {
          nrodoc
          nombre
          apellido
        }
      }
    }
  }
}
    `;

/**
 * __useGetAjustesQuery__
 *
 * To run a query within a React component, call `useGetAjustesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAjustesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAjustesQuery({
 *   variables: {
 *      desde: // value for 'desde'
 *      hasta: // value for 'hasta'
 *      nrodoc: // value for 'nrodoc'
 *      umn_id: // value for 'umn_id'
 *      numero_corredor: // value for 'numero_corredor'
 *   },
 * });
 */
export function useGetAjustesQuery(baseOptions: Apollo.QueryHookOptions<GetAjustesQuery, GetAjustesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAjustesQuery, GetAjustesQueryVariables>(GetAjustesDocument, options);
      }
export function useGetAjustesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAjustesQuery, GetAjustesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAjustesQuery, GetAjustesQueryVariables>(GetAjustesDocument, options);
        }
export type GetAjustesQueryHookResult = ReturnType<typeof useGetAjustesQuery>;
export type GetAjustesLazyQueryHookResult = ReturnType<typeof useGetAjustesLazyQuery>;
export type GetAjustesQueryResult = Apollo.QueryResult<GetAjustesQuery, GetAjustesQueryVariables>;
export const GetAllTerminalesUmnDocument = gql`
    query getAllTerminalesUmn($umn_id: [ID!]) {
  terminalesUmnAll(umn_id: $umn_id)
}
    `;

/**
 * __useGetAllTerminalesUmnQuery__
 *
 * To run a query within a React component, call `useGetAllTerminalesUmnQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllTerminalesUmnQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllTerminalesUmnQuery({
 *   variables: {
 *      umn_id: // value for 'umn_id'
 *   },
 * });
 */
export function useGetAllTerminalesUmnQuery(baseOptions?: Apollo.QueryHookOptions<GetAllTerminalesUmnQuery, GetAllTerminalesUmnQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllTerminalesUmnQuery, GetAllTerminalesUmnQueryVariables>(GetAllTerminalesUmnDocument, options);
      }
export function useGetAllTerminalesUmnLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllTerminalesUmnQuery, GetAllTerminalesUmnQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllTerminalesUmnQuery, GetAllTerminalesUmnQueryVariables>(GetAllTerminalesUmnDocument, options);
        }
export type GetAllTerminalesUmnQueryHookResult = ReturnType<typeof useGetAllTerminalesUmnQuery>;
export type GetAllTerminalesUmnLazyQueryHookResult = ReturnType<typeof useGetAllTerminalesUmnLazyQuery>;
export type GetAllTerminalesUmnQueryResult = Apollo.QueryResult<GetAllTerminalesUmnQuery, GetAllTerminalesUmnQueryVariables>;
export const GetBoletasDocument = gql`
    query getBoletas($umn_id: [ID!], $desde: Date, $hasta: Date, $numeros: [ID], $postura: [ID], $serie: [String], $jugada_tipo: [ID], $sorteo_base_id: [ID], $corredor_numero: [ID], $liquidado: Boolean, $limit: Int!, $offset: Int!) {
  evaluarBoleta(
    umn_id: $umn_id
    desde: $desde
    hasta: $hasta
    numeros: $numeros
    postura: $postura
    serie: $serie
    jugada_tipo: $jugada_tipo
    sorteo_base_id: $sorteo_base_id
    corredor_numero: $corredor_numero
    liquidado: $liquidado
    limit: $limit
    offset: $offset
  )
}
    `;

/**
 * __useGetBoletasQuery__
 *
 * To run a query within a React component, call `useGetBoletasQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBoletasQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBoletasQuery({
 *   variables: {
 *      umn_id: // value for 'umn_id'
 *      desde: // value for 'desde'
 *      hasta: // value for 'hasta'
 *      numeros: // value for 'numeros'
 *      postura: // value for 'postura'
 *      serie: // value for 'serie'
 *      jugada_tipo: // value for 'jugada_tipo'
 *      sorteo_base_id: // value for 'sorteo_base_id'
 *      corredor_numero: // value for 'corredor_numero'
 *      liquidado: // value for 'liquidado'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useGetBoletasQuery(baseOptions: Apollo.QueryHookOptions<GetBoletasQuery, GetBoletasQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBoletasQuery, GetBoletasQueryVariables>(GetBoletasDocument, options);
      }
export function useGetBoletasLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBoletasQuery, GetBoletasQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBoletasQuery, GetBoletasQueryVariables>(GetBoletasDocument, options);
        }
export type GetBoletasQueryHookResult = ReturnType<typeof useGetBoletasQuery>;
export type GetBoletasLazyQueryHookResult = ReturnType<typeof useGetBoletasLazyQuery>;
export type GetBoletasQueryResult = Apollo.QueryResult<GetBoletasQuery, GetBoletasQueryVariables>;
export const CajasByCorredorDocument = gql`
    query cajasByCorredor($corredor_id: ID, $estado: String) {
  getCajas(corredor_id: $corredor_id, estado: $estado) {
    id
    Terminal {
      id
    }
  }
}
    `;

/**
 * __useCajasByCorredorQuery__
 *
 * To run a query within a React component, call `useCajasByCorredorQuery` and pass it any options that fit your needs.
 * When your component renders, `useCajasByCorredorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCajasByCorredorQuery({
 *   variables: {
 *      corredor_id: // value for 'corredor_id'
 *      estado: // value for 'estado'
 *   },
 * });
 */
export function useCajasByCorredorQuery(baseOptions?: Apollo.QueryHookOptions<CajasByCorredorQuery, CajasByCorredorQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CajasByCorredorQuery, CajasByCorredorQueryVariables>(CajasByCorredorDocument, options);
      }
export function useCajasByCorredorLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CajasByCorredorQuery, CajasByCorredorQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CajasByCorredorQuery, CajasByCorredorQueryVariables>(CajasByCorredorDocument, options);
        }
export type CajasByCorredorQueryHookResult = ReturnType<typeof useCajasByCorredorQuery>;
export type CajasByCorredorLazyQueryHookResult = ReturnType<typeof useCajasByCorredorLazyQuery>;
export type CajasByCorredorQueryResult = Apollo.QueryResult<CajasByCorredorQuery, CajasByCorredorQueryVariables>;
export const CajasAbiertasAgenciaDocument = gql`
    query cajasAbiertasAgencia($desde: Date!, $hasta: Date, $agencia: [ID]) {
  cajasAbiertasAgencia(desde: $desde, hasta: $hasta, agencia: $agencia)
}
    `;

/**
 * __useCajasAbiertasAgenciaQuery__
 *
 * To run a query within a React component, call `useCajasAbiertasAgenciaQuery` and pass it any options that fit your needs.
 * When your component renders, `useCajasAbiertasAgenciaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCajasAbiertasAgenciaQuery({
 *   variables: {
 *      desde: // value for 'desde'
 *      hasta: // value for 'hasta'
 *      agencia: // value for 'agencia'
 *   },
 * });
 */
export function useCajasAbiertasAgenciaQuery(baseOptions: Apollo.QueryHookOptions<CajasAbiertasAgenciaQuery, CajasAbiertasAgenciaQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CajasAbiertasAgenciaQuery, CajasAbiertasAgenciaQueryVariables>(CajasAbiertasAgenciaDocument, options);
      }
export function useCajasAbiertasAgenciaLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CajasAbiertasAgenciaQuery, CajasAbiertasAgenciaQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CajasAbiertasAgenciaQuery, CajasAbiertasAgenciaQueryVariables>(CajasAbiertasAgenciaDocument, options);
        }
export type CajasAbiertasAgenciaQueryHookResult = ReturnType<typeof useCajasAbiertasAgenciaQuery>;
export type CajasAbiertasAgenciaLazyQueryHookResult = ReturnType<typeof useCajasAbiertasAgenciaLazyQuery>;
export type CajasAbiertasAgenciaQueryResult = Apollo.QueryResult<CajasAbiertasAgenciaQuery, CajasAbiertasAgenciaQueryVariables>;
export const GetCobrosDocument = gql`
    query getCobros($umn_id: [ID!], $desde: Date, $hasta: Date, $corredor_id: [ID]) {
  corredorCobros(
    umn_id: $umn_id
    desde: $desde
    hasta: $hasta
    corredor_id: $corredor_id
  )
}
    `;

/**
 * __useGetCobrosQuery__
 *
 * To run a query within a React component, call `useGetCobrosQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCobrosQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCobrosQuery({
 *   variables: {
 *      umn_id: // value for 'umn_id'
 *      desde: // value for 'desde'
 *      hasta: // value for 'hasta'
 *      corredor_id: // value for 'corredor_id'
 *   },
 * });
 */
export function useGetCobrosQuery(baseOptions?: Apollo.QueryHookOptions<GetCobrosQuery, GetCobrosQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCobrosQuery, GetCobrosQueryVariables>(GetCobrosDocument, options);
      }
export function useGetCobrosLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCobrosQuery, GetCobrosQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCobrosQuery, GetCobrosQueryVariables>(GetCobrosDocument, options);
        }
export type GetCobrosQueryHookResult = ReturnType<typeof useGetCobrosQuery>;
export type GetCobrosLazyQueryHookResult = ReturnType<typeof useGetCobrosLazyQuery>;
export type GetCobrosQueryResult = Apollo.QueryResult<GetCobrosQuery, GetCobrosQueryVariables>;
export const GetcorredoresbyumnDocument = gql`
    query getcorredoresbyumn($id: [ID]) {
  umn(id: $id) {
    Corredores {
      id
      numero
      Usuario {
        Person {
          nombre
          apellido
        }
      }
    }
  }
}
    `;

/**
 * __useGetcorredoresbyumnQuery__
 *
 * To run a query within a React component, call `useGetcorredoresbyumnQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetcorredoresbyumnQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetcorredoresbyumnQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetcorredoresbyumnQuery(baseOptions?: Apollo.QueryHookOptions<GetcorredoresbyumnQuery, GetcorredoresbyumnQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetcorredoresbyumnQuery, GetcorredoresbyumnQueryVariables>(GetcorredoresbyumnDocument, options);
      }
export function useGetcorredoresbyumnLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetcorredoresbyumnQuery, GetcorredoresbyumnQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetcorredoresbyumnQuery, GetcorredoresbyumnQueryVariables>(GetcorredoresbyumnDocument, options);
        }
export type GetcorredoresbyumnQueryHookResult = ReturnType<typeof useGetcorredoresbyumnQuery>;
export type GetcorredoresbyumnLazyQueryHookResult = ReturnType<typeof useGetcorredoresbyumnLazyQuery>;
export type GetcorredoresbyumnQueryResult = Apollo.QueryResult<GetcorredoresbyumnQuery, GetcorredoresbyumnQueryVariables>;
export const GetCorredoresExternosDocument = gql`
    query getCorredoresExternos($umn_id: [ID]) {
  corredoresExternos(umn_id: $umn_id)
}
    `;

/**
 * __useGetCorredoresExternosQuery__
 *
 * To run a query within a React component, call `useGetCorredoresExternosQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCorredoresExternosQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCorredoresExternosQuery({
 *   variables: {
 *      umn_id: // value for 'umn_id'
 *   },
 * });
 */
export function useGetCorredoresExternosQuery(baseOptions?: Apollo.QueryHookOptions<GetCorredoresExternosQuery, GetCorredoresExternosQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCorredoresExternosQuery, GetCorredoresExternosQueryVariables>(GetCorredoresExternosDocument, options);
      }
export function useGetCorredoresExternosLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCorredoresExternosQuery, GetCorredoresExternosQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCorredoresExternosQuery, GetCorredoresExternosQueryVariables>(GetCorredoresExternosDocument, options);
        }
export type GetCorredoresExternosQueryHookResult = ReturnType<typeof useGetCorredoresExternosQuery>;
export type GetCorredoresExternosLazyQueryHookResult = ReturnType<typeof useGetCorredoresExternosLazyQuery>;
export type GetCorredoresExternosQueryResult = Apollo.QueryResult<GetCorredoresExternosQuery, GetCorredoresExternosQueryVariables>;
export const GetCuotasTeeteAsgardDocument = gql`
    query getCuotasTeeteAsgard($agencia: Int, $page: Int, $solicitud: String, $estado: String) {
  getCuotasTeeteAsgard(
    agencia: $agencia
    page: $page
    solicitud: $solicitud
    estado: $estado
  )
}
    `;

/**
 * __useGetCuotasTeeteAsgardQuery__
 *
 * To run a query within a React component, call `useGetCuotasTeeteAsgardQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCuotasTeeteAsgardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCuotasTeeteAsgardQuery({
 *   variables: {
 *      agencia: // value for 'agencia'
 *      page: // value for 'page'
 *      solicitud: // value for 'solicitud'
 *      estado: // value for 'estado'
 *   },
 * });
 */
export function useGetCuotasTeeteAsgardQuery(baseOptions?: Apollo.QueryHookOptions<GetCuotasTeeteAsgardQuery, GetCuotasTeeteAsgardQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCuotasTeeteAsgardQuery, GetCuotasTeeteAsgardQueryVariables>(GetCuotasTeeteAsgardDocument, options);
      }
export function useGetCuotasTeeteAsgardLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCuotasTeeteAsgardQuery, GetCuotasTeeteAsgardQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCuotasTeeteAsgardQuery, GetCuotasTeeteAsgardQueryVariables>(GetCuotasTeeteAsgardDocument, options);
        }
export type GetCuotasTeeteAsgardQueryHookResult = ReturnType<typeof useGetCuotasTeeteAsgardQuery>;
export type GetCuotasTeeteAsgardLazyQueryHookResult = ReturnType<typeof useGetCuotasTeeteAsgardLazyQuery>;
export type GetCuotasTeeteAsgardQueryResult = Apollo.QueryResult<GetCuotasTeeteAsgardQuery, GetCuotasTeeteAsgardQueryVariables>;
export const GetDataPosDocument = gql`
    query getDataPos($desde: Date, $hasta: Date, $agencia: [ID]) {
  getDataPos(desde: $desde, hasta: $hasta, agencia: $agencia)
}
    `;

/**
 * __useGetDataPosQuery__
 *
 * To run a query within a React component, call `useGetDataPosQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDataPosQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDataPosQuery({
 *   variables: {
 *      desde: // value for 'desde'
 *      hasta: // value for 'hasta'
 *      agencia: // value for 'agencia'
 *   },
 * });
 */
export function useGetDataPosQuery(baseOptions?: Apollo.QueryHookOptions<GetDataPosQuery, GetDataPosQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDataPosQuery, GetDataPosQueryVariables>(GetDataPosDocument, options);
      }
export function useGetDataPosLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDataPosQuery, GetDataPosQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDataPosQuery, GetDataPosQueryVariables>(GetDataPosDocument, options);
        }
export type GetDataPosQueryHookResult = ReturnType<typeof useGetDataPosQuery>;
export type GetDataPosLazyQueryHookResult = ReturnType<typeof useGetDataPosLazyQuery>;
export type GetDataPosQueryResult = Apollo.QueryResult<GetDataPosQuery, GetDataPosQueryVariables>;
export const EventoCajasByIdDocument = gql`
    query eventoCajasById($terminalId: ID) {
  eventoCajasById(terminalId: $terminalId)
}
    `;

/**
 * __useEventoCajasByIdQuery__
 *
 * To run a query within a React component, call `useEventoCajasByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useEventoCajasByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEventoCajasByIdQuery({
 *   variables: {
 *      terminalId: // value for 'terminalId'
 *   },
 * });
 */
export function useEventoCajasByIdQuery(baseOptions?: Apollo.QueryHookOptions<EventoCajasByIdQuery, EventoCajasByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EventoCajasByIdQuery, EventoCajasByIdQueryVariables>(EventoCajasByIdDocument, options);
      }
export function useEventoCajasByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EventoCajasByIdQuery, EventoCajasByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EventoCajasByIdQuery, EventoCajasByIdQueryVariables>(EventoCajasByIdDocument, options);
        }
export type EventoCajasByIdQueryHookResult = ReturnType<typeof useEventoCajasByIdQuery>;
export type EventoCajasByIdLazyQueryHookResult = ReturnType<typeof useEventoCajasByIdLazyQuery>;
export type EventoCajasByIdQueryResult = Apollo.QueryResult<EventoCajasByIdQuery, EventoCajasByIdQueryVariables>;
export const GetWebTransaccionRecargaSaldoDocument = gql`
    query getWebTransaccionRecargaSaldo($desde: Date, $hasta: Date) {
  getWebTransaccionRecargaSaldo(desde: $desde, hasta: $hasta)
}
    `;

/**
 * __useGetWebTransaccionRecargaSaldoQuery__
 *
 * To run a query within a React component, call `useGetWebTransaccionRecargaSaldoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWebTransaccionRecargaSaldoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWebTransaccionRecargaSaldoQuery({
 *   variables: {
 *      desde: // value for 'desde'
 *      hasta: // value for 'hasta'
 *   },
 * });
 */
export function useGetWebTransaccionRecargaSaldoQuery(baseOptions?: Apollo.QueryHookOptions<GetWebTransaccionRecargaSaldoQuery, GetWebTransaccionRecargaSaldoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetWebTransaccionRecargaSaldoQuery, GetWebTransaccionRecargaSaldoQueryVariables>(GetWebTransaccionRecargaSaldoDocument, options);
      }
export function useGetWebTransaccionRecargaSaldoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetWebTransaccionRecargaSaldoQuery, GetWebTransaccionRecargaSaldoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetWebTransaccionRecargaSaldoQuery, GetWebTransaccionRecargaSaldoQueryVariables>(GetWebTransaccionRecargaSaldoDocument, options);
        }
export type GetWebTransaccionRecargaSaldoQueryHookResult = ReturnType<typeof useGetWebTransaccionRecargaSaldoQuery>;
export type GetWebTransaccionRecargaSaldoLazyQueryHookResult = ReturnType<typeof useGetWebTransaccionRecargaSaldoLazyQuery>;
export type GetWebTransaccionRecargaSaldoQueryResult = Apollo.QueryResult<GetWebTransaccionRecargaSaldoQuery, GetWebTransaccionRecargaSaldoQueryVariables>;
export const GetWebTransaccionRecargaSaldoByUserDocument = gql`
    query getWebTransaccionRecargaSaldoByUser($desde: Date, $hasta: Date, $usuario_id: Int) {
  getWebTransaccionRecargaSaldoByUser(
    desde: $desde
    hasta: $hasta
    usuario_id: $usuario_id
  )
}
    `;

/**
 * __useGetWebTransaccionRecargaSaldoByUserQuery__
 *
 * To run a query within a React component, call `useGetWebTransaccionRecargaSaldoByUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWebTransaccionRecargaSaldoByUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWebTransaccionRecargaSaldoByUserQuery({
 *   variables: {
 *      desde: // value for 'desde'
 *      hasta: // value for 'hasta'
 *      usuario_id: // value for 'usuario_id'
 *   },
 * });
 */
export function useGetWebTransaccionRecargaSaldoByUserQuery(baseOptions?: Apollo.QueryHookOptions<GetWebTransaccionRecargaSaldoByUserQuery, GetWebTransaccionRecargaSaldoByUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetWebTransaccionRecargaSaldoByUserQuery, GetWebTransaccionRecargaSaldoByUserQueryVariables>(GetWebTransaccionRecargaSaldoByUserDocument, options);
      }
export function useGetWebTransaccionRecargaSaldoByUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetWebTransaccionRecargaSaldoByUserQuery, GetWebTransaccionRecargaSaldoByUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetWebTransaccionRecargaSaldoByUserQuery, GetWebTransaccionRecargaSaldoByUserQueryVariables>(GetWebTransaccionRecargaSaldoByUserDocument, options);
        }
export type GetWebTransaccionRecargaSaldoByUserQueryHookResult = ReturnType<typeof useGetWebTransaccionRecargaSaldoByUserQuery>;
export type GetWebTransaccionRecargaSaldoByUserLazyQueryHookResult = ReturnType<typeof useGetWebTransaccionRecargaSaldoByUserLazyQuery>;
export type GetWebTransaccionRecargaSaldoByUserQueryResult = Apollo.QueryResult<GetWebTransaccionRecargaSaldoByUserQuery, GetWebTransaccionRecargaSaldoByUserQueryVariables>;
export const GetWebUserSaldoRetiradoDocument = gql`
    query getWebUserSaldoRetirado {
  getWebUserSaldoRetirado
}
    `;

/**
 * __useGetWebUserSaldoRetiradoQuery__
 *
 * To run a query within a React component, call `useGetWebUserSaldoRetiradoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWebUserSaldoRetiradoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWebUserSaldoRetiradoQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetWebUserSaldoRetiradoQuery(baseOptions?: Apollo.QueryHookOptions<GetWebUserSaldoRetiradoQuery, GetWebUserSaldoRetiradoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetWebUserSaldoRetiradoQuery, GetWebUserSaldoRetiradoQueryVariables>(GetWebUserSaldoRetiradoDocument, options);
      }
export function useGetWebUserSaldoRetiradoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetWebUserSaldoRetiradoQuery, GetWebUserSaldoRetiradoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetWebUserSaldoRetiradoQuery, GetWebUserSaldoRetiradoQueryVariables>(GetWebUserSaldoRetiradoDocument, options);
        }
export type GetWebUserSaldoRetiradoQueryHookResult = ReturnType<typeof useGetWebUserSaldoRetiradoQuery>;
export type GetWebUserSaldoRetiradoLazyQueryHookResult = ReturnType<typeof useGetWebUserSaldoRetiradoLazyQuery>;
export type GetWebUserSaldoRetiradoQueryResult = Apollo.QueryResult<GetWebUserSaldoRetiradoQuery, GetWebUserSaldoRetiradoQueryVariables>;
export const GetWebUserSaldoGanadoGroupUserDocument = gql`
    query getWebUserSaldoGanadoGroupUser {
  getWebUserSaldoGanadoGroupUser
}
    `;

/**
 * __useGetWebUserSaldoGanadoGroupUserQuery__
 *
 * To run a query within a React component, call `useGetWebUserSaldoGanadoGroupUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWebUserSaldoGanadoGroupUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWebUserSaldoGanadoGroupUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetWebUserSaldoGanadoGroupUserQuery(baseOptions?: Apollo.QueryHookOptions<GetWebUserSaldoGanadoGroupUserQuery, GetWebUserSaldoGanadoGroupUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetWebUserSaldoGanadoGroupUserQuery, GetWebUserSaldoGanadoGroupUserQueryVariables>(GetWebUserSaldoGanadoGroupUserDocument, options);
      }
export function useGetWebUserSaldoGanadoGroupUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetWebUserSaldoGanadoGroupUserQuery, GetWebUserSaldoGanadoGroupUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetWebUserSaldoGanadoGroupUserQuery, GetWebUserSaldoGanadoGroupUserQueryVariables>(GetWebUserSaldoGanadoGroupUserDocument, options);
        }
export type GetWebUserSaldoGanadoGroupUserQueryHookResult = ReturnType<typeof useGetWebUserSaldoGanadoGroupUserQuery>;
export type GetWebUserSaldoGanadoGroupUserLazyQueryHookResult = ReturnType<typeof useGetWebUserSaldoGanadoGroupUserLazyQuery>;
export type GetWebUserSaldoGanadoGroupUserQueryResult = Apollo.QueryResult<GetWebUserSaldoGanadoGroupUserQuery, GetWebUserSaldoGanadoGroupUserQueryVariables>;
export const GetWebUserSaldoGanadoGroupSorteoDocument = gql`
    query getWebUserSaldoGanadoGroupSorteo($corredor_id: Int) {
  getWebUserSaldoGanadoGroupSorteo(corredor_id: $corredor_id)
}
    `;

/**
 * __useGetWebUserSaldoGanadoGroupSorteoQuery__
 *
 * To run a query within a React component, call `useGetWebUserSaldoGanadoGroupSorteoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWebUserSaldoGanadoGroupSorteoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWebUserSaldoGanadoGroupSorteoQuery({
 *   variables: {
 *      corredor_id: // value for 'corredor_id'
 *   },
 * });
 */
export function useGetWebUserSaldoGanadoGroupSorteoQuery(baseOptions?: Apollo.QueryHookOptions<GetWebUserSaldoGanadoGroupSorteoQuery, GetWebUserSaldoGanadoGroupSorteoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetWebUserSaldoGanadoGroupSorteoQuery, GetWebUserSaldoGanadoGroupSorteoQueryVariables>(GetWebUserSaldoGanadoGroupSorteoDocument, options);
      }
export function useGetWebUserSaldoGanadoGroupSorteoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetWebUserSaldoGanadoGroupSorteoQuery, GetWebUserSaldoGanadoGroupSorteoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetWebUserSaldoGanadoGroupSorteoQuery, GetWebUserSaldoGanadoGroupSorteoQueryVariables>(GetWebUserSaldoGanadoGroupSorteoDocument, options);
        }
export type GetWebUserSaldoGanadoGroupSorteoQueryHookResult = ReturnType<typeof useGetWebUserSaldoGanadoGroupSorteoQuery>;
export type GetWebUserSaldoGanadoGroupSorteoLazyQueryHookResult = ReturnType<typeof useGetWebUserSaldoGanadoGroupSorteoLazyQuery>;
export type GetWebUserSaldoGanadoGroupSorteoQueryResult = Apollo.QueryResult<GetWebUserSaldoGanadoGroupSorteoQuery, GetWebUserSaldoGanadoGroupSorteoQueryVariables>;
export const GetWebTransaccionTransferenciaSaldoDocument = gql`
    query getWebTransaccionTransferenciaSaldo($desde: Date, $hasta: Date) {
  getWebTransaccionTransferenciaSaldo(desde: $desde, hasta: $hasta)
}
    `;

/**
 * __useGetWebTransaccionTransferenciaSaldoQuery__
 *
 * To run a query within a React component, call `useGetWebTransaccionTransferenciaSaldoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWebTransaccionTransferenciaSaldoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWebTransaccionTransferenciaSaldoQuery({
 *   variables: {
 *      desde: // value for 'desde'
 *      hasta: // value for 'hasta'
 *   },
 * });
 */
export function useGetWebTransaccionTransferenciaSaldoQuery(baseOptions?: Apollo.QueryHookOptions<GetWebTransaccionTransferenciaSaldoQuery, GetWebTransaccionTransferenciaSaldoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetWebTransaccionTransferenciaSaldoQuery, GetWebTransaccionTransferenciaSaldoQueryVariables>(GetWebTransaccionTransferenciaSaldoDocument, options);
      }
export function useGetWebTransaccionTransferenciaSaldoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetWebTransaccionTransferenciaSaldoQuery, GetWebTransaccionTransferenciaSaldoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetWebTransaccionTransferenciaSaldoQuery, GetWebTransaccionTransferenciaSaldoQueryVariables>(GetWebTransaccionTransferenciaSaldoDocument, options);
        }
export type GetWebTransaccionTransferenciaSaldoQueryHookResult = ReturnType<typeof useGetWebTransaccionTransferenciaSaldoQuery>;
export type GetWebTransaccionTransferenciaSaldoLazyQueryHookResult = ReturnType<typeof useGetWebTransaccionTransferenciaSaldoLazyQuery>;
export type GetWebTransaccionTransferenciaSaldoQueryResult = Apollo.QueryResult<GetWebTransaccionTransferenciaSaldoQuery, GetWebTransaccionTransferenciaSaldoQueryVariables>;
export const GetWebTransaccionTransferenciaSaldoByUserDocument = gql`
    query getWebTransaccionTransferenciaSaldoByUser($desde: Date, $hasta: Date, $usuario_id: Int) {
  getWebTransaccionTransferenciaSaldoByUser(
    desde: $desde
    hasta: $hasta
    usuario_id: $usuario_id
  )
}
    `;

/**
 * __useGetWebTransaccionTransferenciaSaldoByUserQuery__
 *
 * To run a query within a React component, call `useGetWebTransaccionTransferenciaSaldoByUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWebTransaccionTransferenciaSaldoByUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWebTransaccionTransferenciaSaldoByUserQuery({
 *   variables: {
 *      desde: // value for 'desde'
 *      hasta: // value for 'hasta'
 *      usuario_id: // value for 'usuario_id'
 *   },
 * });
 */
export function useGetWebTransaccionTransferenciaSaldoByUserQuery(baseOptions?: Apollo.QueryHookOptions<GetWebTransaccionTransferenciaSaldoByUserQuery, GetWebTransaccionTransferenciaSaldoByUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetWebTransaccionTransferenciaSaldoByUserQuery, GetWebTransaccionTransferenciaSaldoByUserQueryVariables>(GetWebTransaccionTransferenciaSaldoByUserDocument, options);
      }
export function useGetWebTransaccionTransferenciaSaldoByUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetWebTransaccionTransferenciaSaldoByUserQuery, GetWebTransaccionTransferenciaSaldoByUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetWebTransaccionTransferenciaSaldoByUserQuery, GetWebTransaccionTransferenciaSaldoByUserQueryVariables>(GetWebTransaccionTransferenciaSaldoByUserDocument, options);
        }
export type GetWebTransaccionTransferenciaSaldoByUserQueryHookResult = ReturnType<typeof useGetWebTransaccionTransferenciaSaldoByUserQuery>;
export type GetWebTransaccionTransferenciaSaldoByUserLazyQueryHookResult = ReturnType<typeof useGetWebTransaccionTransferenciaSaldoByUserLazyQuery>;
export type GetWebTransaccionTransferenciaSaldoByUserQueryResult = Apollo.QueryResult<GetWebTransaccionTransferenciaSaldoByUserQuery, GetWebTransaccionTransferenciaSaldoByUserQueryVariables>;
export const GetJugadasDocument = gql`
    query getJugadas($tipo: Int, $desde: Date!, $hasta: Date, $umn_id: [ID!]) {
  jugadaQuiniela(tipo: $tipo, desde: $desde, hasta: $hasta, umn_id: $umn_id)
}
    `;

/**
 * __useGetJugadasQuery__
 *
 * To run a query within a React component, call `useGetJugadasQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetJugadasQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetJugadasQuery({
 *   variables: {
 *      tipo: // value for 'tipo'
 *      desde: // value for 'desde'
 *      hasta: // value for 'hasta'
 *      umn_id: // value for 'umn_id'
 *   },
 * });
 */
export function useGetJugadasQuery(baseOptions: Apollo.QueryHookOptions<GetJugadasQuery, GetJugadasQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetJugadasQuery, GetJugadasQueryVariables>(GetJugadasDocument, options);
      }
export function useGetJugadasLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetJugadasQuery, GetJugadasQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetJugadasQuery, GetJugadasQueryVariables>(GetJugadasDocument, options);
        }
export type GetJugadasQueryHookResult = ReturnType<typeof useGetJugadasQuery>;
export type GetJugadasLazyQueryHookResult = ReturnType<typeof useGetJugadasLazyQuery>;
export type GetJugadasQueryResult = Apollo.QueryResult<GetJugadasQuery, GetJugadasQueryVariables>;
export const GetResultsDocument = gql`
    query getResults($desde: Date, $hasta: Date, $sorteo_base_id: ID) {
  getSorteoDelDia(desde: $desde, hasta: $hasta, sorteo_base_id: $sorteo_base_id)
}
    `;

/**
 * __useGetResultsQuery__
 *
 * To run a query within a React component, call `useGetResultsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetResultsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetResultsQuery({
 *   variables: {
 *      desde: // value for 'desde'
 *      hasta: // value for 'hasta'
 *      sorteo_base_id: // value for 'sorteo_base_id'
 *   },
 * });
 */
export function useGetResultsQuery(baseOptions?: Apollo.QueryHookOptions<GetResultsQuery, GetResultsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetResultsQuery, GetResultsQueryVariables>(GetResultsDocument, options);
      }
export function useGetResultsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetResultsQuery, GetResultsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetResultsQuery, GetResultsQueryVariables>(GetResultsDocument, options);
        }
export type GetResultsQueryHookResult = ReturnType<typeof useGetResultsQuery>;
export type GetResultsLazyQueryHookResult = ReturnType<typeof useGetResultsLazyQuery>;
export type GetResultsQueryResult = Apollo.QueryResult<GetResultsQuery, GetResultsQueryVariables>;
export const GetSolicitudesDepositoPorAgenciaDocument = gql`
    query getSolicitudesDepositoPorAgencia($umn_id: [ID]) {
  getSolicitudesDepositoPorAgencia(umn_id: $umn_id)
}
    `;

/**
 * __useGetSolicitudesDepositoPorAgenciaQuery__
 *
 * To run a query within a React component, call `useGetSolicitudesDepositoPorAgenciaQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSolicitudesDepositoPorAgenciaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSolicitudesDepositoPorAgenciaQuery({
 *   variables: {
 *      umn_id: // value for 'umn_id'
 *   },
 * });
 */
export function useGetSolicitudesDepositoPorAgenciaQuery(baseOptions?: Apollo.QueryHookOptions<GetSolicitudesDepositoPorAgenciaQuery, GetSolicitudesDepositoPorAgenciaQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSolicitudesDepositoPorAgenciaQuery, GetSolicitudesDepositoPorAgenciaQueryVariables>(GetSolicitudesDepositoPorAgenciaDocument, options);
      }
export function useGetSolicitudesDepositoPorAgenciaLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSolicitudesDepositoPorAgenciaQuery, GetSolicitudesDepositoPorAgenciaQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSolicitudesDepositoPorAgenciaQuery, GetSolicitudesDepositoPorAgenciaQueryVariables>(GetSolicitudesDepositoPorAgenciaDocument, options);
        }
export type GetSolicitudesDepositoPorAgenciaQueryHookResult = ReturnType<typeof useGetSolicitudesDepositoPorAgenciaQuery>;
export type GetSolicitudesDepositoPorAgenciaLazyQueryHookResult = ReturnType<typeof useGetSolicitudesDepositoPorAgenciaLazyQuery>;
export type GetSolicitudesDepositoPorAgenciaQueryResult = Apollo.QueryResult<GetSolicitudesDepositoPorAgenciaQuery, GetSolicitudesDepositoPorAgenciaQueryVariables>;
export const DetallesPremiacionTipoDocument = gql`
    query detallesPremiacionTipo($agencia: [Int], $desde: Date!, $hasta: Date, $sorteo: [Int]) {
  detallesPremiacionTipo(
    agencia: $agencia
    desde: $desde
    hasta: $hasta
    sorteo: $sorteo
  )
}
    `;

/**
 * __useDetallesPremiacionTipoQuery__
 *
 * To run a query within a React component, call `useDetallesPremiacionTipoQuery` and pass it any options that fit your needs.
 * When your component renders, `useDetallesPremiacionTipoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDetallesPremiacionTipoQuery({
 *   variables: {
 *      agencia: // value for 'agencia'
 *      desde: // value for 'desde'
 *      hasta: // value for 'hasta'
 *      sorteo: // value for 'sorteo'
 *   },
 * });
 */
export function useDetallesPremiacionTipoQuery(baseOptions: Apollo.QueryHookOptions<DetallesPremiacionTipoQuery, DetallesPremiacionTipoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DetallesPremiacionTipoQuery, DetallesPremiacionTipoQueryVariables>(DetallesPremiacionTipoDocument, options);
      }
export function useDetallesPremiacionTipoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DetallesPremiacionTipoQuery, DetallesPremiacionTipoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DetallesPremiacionTipoQuery, DetallesPremiacionTipoQueryVariables>(DetallesPremiacionTipoDocument, options);
        }
export type DetallesPremiacionTipoQueryHookResult = ReturnType<typeof useDetallesPremiacionTipoQuery>;
export type DetallesPremiacionTipoLazyQueryHookResult = ReturnType<typeof useDetallesPremiacionTipoLazyQuery>;
export type DetallesPremiacionTipoQueryResult = Apollo.QueryResult<DetallesPremiacionTipoQuery, DetallesPremiacionTipoQueryVariables>;
export const GetSwitchEntregasDocument = gql`
    query getSwitchEntregas($umn_id: [ID!]) {
  switchEntregas(umn_id: $umn_id)
}
    `;

/**
 * __useGetSwitchEntregasQuery__
 *
 * To run a query within a React component, call `useGetSwitchEntregasQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSwitchEntregasQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSwitchEntregasQuery({
 *   variables: {
 *      umn_id: // value for 'umn_id'
 *   },
 * });
 */
export function useGetSwitchEntregasQuery(baseOptions?: Apollo.QueryHookOptions<GetSwitchEntregasQuery, GetSwitchEntregasQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSwitchEntregasQuery, GetSwitchEntregasQueryVariables>(GetSwitchEntregasDocument, options);
      }
export function useGetSwitchEntregasLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSwitchEntregasQuery, GetSwitchEntregasQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSwitchEntregasQuery, GetSwitchEntregasQueryVariables>(GetSwitchEntregasDocument, options);
        }
export type GetSwitchEntregasQueryHookResult = ReturnType<typeof useGetSwitchEntregasQuery>;
export type GetSwitchEntregasLazyQueryHookResult = ReturnType<typeof useGetSwitchEntregasLazyQuery>;
export type GetSwitchEntregasQueryResult = Apollo.QueryResult<GetSwitchEntregasQuery, GetSwitchEntregasQueryVariables>;
export const GetTerminalByCorredorDocument = gql`
    query getTerminalByCorredor($corredor_id: ID, $estado: String) {
  getCajas(corredor_id: $corredor_id, estado: $estado) {
    id
    Terminal {
      id
      serial
    }
  }
}
    `;

/**
 * __useGetTerminalByCorredorQuery__
 *
 * To run a query within a React component, call `useGetTerminalByCorredorQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTerminalByCorredorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTerminalByCorredorQuery({
 *   variables: {
 *      corredor_id: // value for 'corredor_id'
 *      estado: // value for 'estado'
 *   },
 * });
 */
export function useGetTerminalByCorredorQuery(baseOptions?: Apollo.QueryHookOptions<GetTerminalByCorredorQuery, GetTerminalByCorredorQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTerminalByCorredorQuery, GetTerminalByCorredorQueryVariables>(GetTerminalByCorredorDocument, options);
      }
export function useGetTerminalByCorredorLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTerminalByCorredorQuery, GetTerminalByCorredorQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTerminalByCorredorQuery, GetTerminalByCorredorQueryVariables>(GetTerminalByCorredorDocument, options);
        }
export type GetTerminalByCorredorQueryHookResult = ReturnType<typeof useGetTerminalByCorredorQuery>;
export type GetTerminalByCorredorLazyQueryHookResult = ReturnType<typeof useGetTerminalByCorredorLazyQuery>;
export type GetTerminalByCorredorQueryResult = Apollo.QueryResult<GetTerminalByCorredorQuery, GetTerminalByCorredorQueryVariables>;
export const TicketsResultDocument = gql`
    query ticketsResult($premiados: Boolean, $serie: String, $ticket: Float, $monto: Int, $nro_corredor: Int, $anuladas: Boolean, $umn_id: [ID!], $desde: Date!, $hasta: Date, $sorteo_id: [Int], $retrasadas: Boolean) {
  ticketResult(
    premiados: $premiados
    serie: $serie
    ticket: $ticket
    monto: $monto
    nro_corredor: $nro_corredor
    anuladas: $anuladas
    umn_id: $umn_id
    desde: $desde
    hasta: $hasta
    sorteo_id: $sorteo_id
    retrasadas: $retrasadas
  ) {
    id
    serie
    ticket
    monto_ganado
    monto_apostado
    created_at
    generado
    Transaccion {
      anulado
      Caja {
        Corredor {
          numero
          Umn {
            numero
            agencia_numero
          }
          Usuario {
            Person {
              nombre
              apellido
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useTicketsResultQuery__
 *
 * To run a query within a React component, call `useTicketsResultQuery` and pass it any options that fit your needs.
 * When your component renders, `useTicketsResultQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTicketsResultQuery({
 *   variables: {
 *      premiados: // value for 'premiados'
 *      serie: // value for 'serie'
 *      ticket: // value for 'ticket'
 *      monto: // value for 'monto'
 *      nro_corredor: // value for 'nro_corredor'
 *      anuladas: // value for 'anuladas'
 *      umn_id: // value for 'umn_id'
 *      desde: // value for 'desde'
 *      hasta: // value for 'hasta'
 *      sorteo_id: // value for 'sorteo_id'
 *      retrasadas: // value for 'retrasadas'
 *   },
 * });
 */
export function useTicketsResultQuery(baseOptions: Apollo.QueryHookOptions<TicketsResultQuery, TicketsResultQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TicketsResultQuery, TicketsResultQueryVariables>(TicketsResultDocument, options);
      }
export function useTicketsResultLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TicketsResultQuery, TicketsResultQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TicketsResultQuery, TicketsResultQueryVariables>(TicketsResultDocument, options);
        }
export type TicketsResultQueryHookResult = ReturnType<typeof useTicketsResultQuery>;
export type TicketsResultLazyQueryHookResult = ReturnType<typeof useTicketsResultLazyQuery>;
export type TicketsResultQueryResult = Apollo.QueryResult<TicketsResultQuery, TicketsResultQueryVariables>;
export const GetTotalTerminalesDocument = gql`
    query getTotalTerminales($umn_id: [ID], $colas: Boolean, $estado: String) {
  cajasSegunEstado(umn_id: $umn_id, colas: $colas, estado: $estado)
}
    `;

/**
 * __useGetTotalTerminalesQuery__
 *
 * To run a query within a React component, call `useGetTotalTerminalesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTotalTerminalesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTotalTerminalesQuery({
 *   variables: {
 *      umn_id: // value for 'umn_id'
 *      colas: // value for 'colas'
 *      estado: // value for 'estado'
 *   },
 * });
 */
export function useGetTotalTerminalesQuery(baseOptions?: Apollo.QueryHookOptions<GetTotalTerminalesQuery, GetTotalTerminalesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTotalTerminalesQuery, GetTotalTerminalesQueryVariables>(GetTotalTerminalesDocument, options);
      }
export function useGetTotalTerminalesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTotalTerminalesQuery, GetTotalTerminalesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTotalTerminalesQuery, GetTotalTerminalesQueryVariables>(GetTotalTerminalesDocument, options);
        }
export type GetTotalTerminalesQueryHookResult = ReturnType<typeof useGetTotalTerminalesQuery>;
export type GetTotalTerminalesLazyQueryHookResult = ReturnType<typeof useGetTotalTerminalesLazyQuery>;
export type GetTotalTerminalesQueryResult = Apollo.QueryResult<GetTotalTerminalesQuery, GetTotalTerminalesQueryVariables>;
export const GetTransaccionesCorredorTerminalDocument = gql`
    query getTransaccionesCorredorTerminal($corredor_id: ID, $terminal_id: ID) {
  getTransaccionByCorredorTerminal(
    corredor_id: $corredor_id
    terminal_id: $terminal_id
  )
}
    `;

/**
 * __useGetTransaccionesCorredorTerminalQuery__
 *
 * To run a query within a React component, call `useGetTransaccionesCorredorTerminalQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTransaccionesCorredorTerminalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTransaccionesCorredorTerminalQuery({
 *   variables: {
 *      corredor_id: // value for 'corredor_id'
 *      terminal_id: // value for 'terminal_id'
 *   },
 * });
 */
export function useGetTransaccionesCorredorTerminalQuery(baseOptions?: Apollo.QueryHookOptions<GetTransaccionesCorredorTerminalQuery, GetTransaccionesCorredorTerminalQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTransaccionesCorredorTerminalQuery, GetTransaccionesCorredorTerminalQueryVariables>(GetTransaccionesCorredorTerminalDocument, options);
      }
export function useGetTransaccionesCorredorTerminalLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTransaccionesCorredorTerminalQuery, GetTransaccionesCorredorTerminalQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTransaccionesCorredorTerminalQuery, GetTransaccionesCorredorTerminalQueryVariables>(GetTransaccionesCorredorTerminalDocument, options);
        }
export type GetTransaccionesCorredorTerminalQueryHookResult = ReturnType<typeof useGetTransaccionesCorredorTerminalQuery>;
export type GetTransaccionesCorredorTerminalLazyQueryHookResult = ReturnType<typeof useGetTransaccionesCorredorTerminalLazyQuery>;
export type GetTransaccionesCorredorTerminalQueryResult = Apollo.QueryResult<GetTransaccionesCorredorTerminalQuery, GetTransaccionesCorredorTerminalQueryVariables>;
export const CorredorProductosDocument = gql`
    query corredorProductos($umn_id: [ID], $corredor_id: [ID], $desde: Date, $hasta: Date, $producto_id: [Int], $sorteo_id: [Int], $juego_id: [Int]) {
  corredorProductos(
    umn_id: $umn_id
    corredor_id: $corredor_id
    desde: $desde
    hasta: $hasta
    producto_id: $producto_id
    sorteo_id: $sorteo_id
    juego_id: $juego_id
  )
}
    `;

/**
 * __useCorredorProductosQuery__
 *
 * To run a query within a React component, call `useCorredorProductosQuery` and pass it any options that fit your needs.
 * When your component renders, `useCorredorProductosQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCorredorProductosQuery({
 *   variables: {
 *      umn_id: // value for 'umn_id'
 *      corredor_id: // value for 'corredor_id'
 *      desde: // value for 'desde'
 *      hasta: // value for 'hasta'
 *      producto_id: // value for 'producto_id'
 *      sorteo_id: // value for 'sorteo_id'
 *      juego_id: // value for 'juego_id'
 *   },
 * });
 */
export function useCorredorProductosQuery(baseOptions?: Apollo.QueryHookOptions<CorredorProductosQuery, CorredorProductosQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CorredorProductosQuery, CorredorProductosQueryVariables>(CorredorProductosDocument, options);
      }
export function useCorredorProductosLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CorredorProductosQuery, CorredorProductosQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CorredorProductosQuery, CorredorProductosQueryVariables>(CorredorProductosDocument, options);
        }
export type CorredorProductosQueryHookResult = ReturnType<typeof useCorredorProductosQuery>;
export type CorredorProductosLazyQueryHookResult = ReturnType<typeof useCorredorProductosLazyQuery>;
export type CorredorProductosQueryResult = Apollo.QueryResult<CorredorProductosQuery, CorredorProductosQueryVariables>;
export const GetTransactionsWebBySorteoDocument = gql`
    query getTransactionsWebBySorteo($desde: Date, $hasta: Date, $sorteo: [Int]) {
  getTransactionsWebBySorteo(desde: $desde, hasta: $hasta, sorteo: $sorteo)
}
    `;

/**
 * __useGetTransactionsWebBySorteoQuery__
 *
 * To run a query within a React component, call `useGetTransactionsWebBySorteoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTransactionsWebBySorteoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTransactionsWebBySorteoQuery({
 *   variables: {
 *      desde: // value for 'desde'
 *      hasta: // value for 'hasta'
 *      sorteo: // value for 'sorteo'
 *   },
 * });
 */
export function useGetTransactionsWebBySorteoQuery(baseOptions?: Apollo.QueryHookOptions<GetTransactionsWebBySorteoQuery, GetTransactionsWebBySorteoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTransactionsWebBySorteoQuery, GetTransactionsWebBySorteoQueryVariables>(GetTransactionsWebBySorteoDocument, options);
      }
export function useGetTransactionsWebBySorteoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTransactionsWebBySorteoQuery, GetTransactionsWebBySorteoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTransactionsWebBySorteoQuery, GetTransactionsWebBySorteoQueryVariables>(GetTransactionsWebBySorteoDocument, options);
        }
export type GetTransactionsWebBySorteoQueryHookResult = ReturnType<typeof useGetTransactionsWebBySorteoQuery>;
export type GetTransactionsWebBySorteoLazyQueryHookResult = ReturnType<typeof useGetTransactionsWebBySorteoLazyQuery>;
export type GetTransactionsWebBySorteoQueryResult = Apollo.QueryResult<GetTransactionsWebBySorteoQuery, GetTransactionsWebBySorteoQueryVariables>;
export const GetWebUserTicketsBySorteoDocument = gql`
    query getWebUserTicketsBySorteo($desde: Date, $hasta: Date, $sorteo: Int) {
  getWebUserTicketsBySorteo(desde: $desde, hasta: $hasta, sorteo: $sorteo)
}
    `;

/**
 * __useGetWebUserTicketsBySorteoQuery__
 *
 * To run a query within a React component, call `useGetWebUserTicketsBySorteoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWebUserTicketsBySorteoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWebUserTicketsBySorteoQuery({
 *   variables: {
 *      desde: // value for 'desde'
 *      hasta: // value for 'hasta'
 *      sorteo: // value for 'sorteo'
 *   },
 * });
 */
export function useGetWebUserTicketsBySorteoQuery(baseOptions?: Apollo.QueryHookOptions<GetWebUserTicketsBySorteoQuery, GetWebUserTicketsBySorteoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetWebUserTicketsBySorteoQuery, GetWebUserTicketsBySorteoQueryVariables>(GetWebUserTicketsBySorteoDocument, options);
      }
export function useGetWebUserTicketsBySorteoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetWebUserTicketsBySorteoQuery, GetWebUserTicketsBySorteoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetWebUserTicketsBySorteoQuery, GetWebUserTicketsBySorteoQueryVariables>(GetWebUserTicketsBySorteoDocument, options);
        }
export type GetWebUserTicketsBySorteoQueryHookResult = ReturnType<typeof useGetWebUserTicketsBySorteoQuery>;
export type GetWebUserTicketsBySorteoLazyQueryHookResult = ReturnType<typeof useGetWebUserTicketsBySorteoLazyQuery>;
export type GetWebUserTicketsBySorteoQueryResult = Apollo.QueryResult<GetWebUserTicketsBySorteoQuery, GetWebUserTicketsBySorteoQueryVariables>;
export const UmnInfoDocument = gql`
    query UmnInfo($id: [ID]) {
  umn(id: $id) {
    numero
    agencia_numero
    zona
    nombre
  }
}
    `;

/**
 * __useUmnInfoQuery__
 *
 * To run a query within a React component, call `useUmnInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useUmnInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUmnInfoQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUmnInfoQuery(baseOptions?: Apollo.QueryHookOptions<UmnInfoQuery, UmnInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UmnInfoQuery, UmnInfoQueryVariables>(UmnInfoDocument, options);
      }
export function useUmnInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UmnInfoQuery, UmnInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UmnInfoQuery, UmnInfoQueryVariables>(UmnInfoDocument, options);
        }
export type UmnInfoQueryHookResult = ReturnType<typeof useUmnInfoQuery>;
export type UmnInfoLazyQueryHookResult = ReturnType<typeof useUmnInfoLazyQuery>;
export type UmnInfoQueryResult = Apollo.QueryResult<UmnInfoQuery, UmnInfoQueryVariables>;
export const GetUmnsDocument = gql`
    query getUmns($agencia: [Int], $zona: [Int]) {
  allUmns(agencia: $agencia, zona: $zona) {
    id
    nombre
    numero
    agencia_numero
  }
}
    `;

/**
 * __useGetUmnsQuery__
 *
 * To run a query within a React component, call `useGetUmnsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUmnsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUmnsQuery({
 *   variables: {
 *      agencia: // value for 'agencia'
 *      zona: // value for 'zona'
 *   },
 * });
 */
export function useGetUmnsQuery(baseOptions?: Apollo.QueryHookOptions<GetUmnsQuery, GetUmnsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUmnsQuery, GetUmnsQueryVariables>(GetUmnsDocument, options);
      }
export function useGetUmnsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUmnsQuery, GetUmnsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUmnsQuery, GetUmnsQueryVariables>(GetUmnsDocument, options);
        }
export type GetUmnsQueryHookResult = ReturnType<typeof useGetUmnsQuery>;
export type GetUmnsLazyQueryHookResult = ReturnType<typeof useGetUmnsLazyQuery>;
export type GetUmnsQueryResult = Apollo.QueryResult<GetUmnsQuery, GetUmnsQueryVariables>;
export const GetWebUserSaldoApuestaDocument = gql`
    query getWebUserSaldoApuesta {
  getWebUserSaldoApuesta
}
    `;

/**
 * __useGetWebUserSaldoApuestaQuery__
 *
 * To run a query within a React component, call `useGetWebUserSaldoApuestaQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWebUserSaldoApuestaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWebUserSaldoApuestaQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetWebUserSaldoApuestaQuery(baseOptions?: Apollo.QueryHookOptions<GetWebUserSaldoApuestaQuery, GetWebUserSaldoApuestaQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetWebUserSaldoApuestaQuery, GetWebUserSaldoApuestaQueryVariables>(GetWebUserSaldoApuestaDocument, options);
      }
export function useGetWebUserSaldoApuestaLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetWebUserSaldoApuestaQuery, GetWebUserSaldoApuestaQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetWebUserSaldoApuestaQuery, GetWebUserSaldoApuestaQueryVariables>(GetWebUserSaldoApuestaDocument, options);
        }
export type GetWebUserSaldoApuestaQueryHookResult = ReturnType<typeof useGetWebUserSaldoApuestaQuery>;
export type GetWebUserSaldoApuestaLazyQueryHookResult = ReturnType<typeof useGetWebUserSaldoApuestaLazyQuery>;
export type GetWebUserSaldoApuestaQueryResult = Apollo.QueryResult<GetWebUserSaldoApuestaQuery, GetWebUserSaldoApuestaQueryVariables>;
export const GetWebUserSaldoGanadoDocument = gql`
    query getWebUserSaldoGanado {
  getWebUserSaldoGanado
}
    `;

/**
 * __useGetWebUserSaldoGanadoQuery__
 *
 * To run a query within a React component, call `useGetWebUserSaldoGanadoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWebUserSaldoGanadoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWebUserSaldoGanadoQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetWebUserSaldoGanadoQuery(baseOptions?: Apollo.QueryHookOptions<GetWebUserSaldoGanadoQuery, GetWebUserSaldoGanadoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetWebUserSaldoGanadoQuery, GetWebUserSaldoGanadoQueryVariables>(GetWebUserSaldoGanadoDocument, options);
      }
export function useGetWebUserSaldoGanadoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetWebUserSaldoGanadoQuery, GetWebUserSaldoGanadoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetWebUserSaldoGanadoQuery, GetWebUserSaldoGanadoQueryVariables>(GetWebUserSaldoGanadoDocument, options);
        }
export type GetWebUserSaldoGanadoQueryHookResult = ReturnType<typeof useGetWebUserSaldoGanadoQuery>;
export type GetWebUserSaldoGanadoLazyQueryHookResult = ReturnType<typeof useGetWebUserSaldoGanadoLazyQuery>;
export type GetWebUserSaldoGanadoQueryResult = Apollo.QueryResult<GetWebUserSaldoGanadoQuery, GetWebUserSaldoGanadoQueryVariables>;
export const GetWebTotalApuestasDocument = gql`
    query getWebTotalApuestas {
  getWebTotalApuestas
}
    `;

/**
 * __useGetWebTotalApuestasQuery__
 *
 * To run a query within a React component, call `useGetWebTotalApuestasQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWebTotalApuestasQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWebTotalApuestasQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetWebTotalApuestasQuery(baseOptions?: Apollo.QueryHookOptions<GetWebTotalApuestasQuery, GetWebTotalApuestasQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetWebTotalApuestasQuery, GetWebTotalApuestasQueryVariables>(GetWebTotalApuestasDocument, options);
      }
export function useGetWebTotalApuestasLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetWebTotalApuestasQuery, GetWebTotalApuestasQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetWebTotalApuestasQuery, GetWebTotalApuestasQueryVariables>(GetWebTotalApuestasDocument, options);
        }
export type GetWebTotalApuestasQueryHookResult = ReturnType<typeof useGetWebTotalApuestasQuery>;
export type GetWebTotalApuestasLazyQueryHookResult = ReturnType<typeof useGetWebTotalApuestasLazyQuery>;
export type GetWebTotalApuestasQueryResult = Apollo.QueryResult<GetWebTotalApuestasQuery, GetWebTotalApuestasQueryVariables>;
export const GetWebTransactionsSaldosDocument = gql`
    query getWebTransactionsSaldos {
  getWebTransactionsSaldos
}
    `;

/**
 * __useGetWebTransactionsSaldosQuery__
 *
 * To run a query within a React component, call `useGetWebTransactionsSaldosQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWebTransactionsSaldosQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWebTransactionsSaldosQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetWebTransactionsSaldosQuery(baseOptions?: Apollo.QueryHookOptions<GetWebTransactionsSaldosQuery, GetWebTransactionsSaldosQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetWebTransactionsSaldosQuery, GetWebTransactionsSaldosQueryVariables>(GetWebTransactionsSaldosDocument, options);
      }
export function useGetWebTransactionsSaldosLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetWebTransactionsSaldosQuery, GetWebTransactionsSaldosQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetWebTransactionsSaldosQuery, GetWebTransactionsSaldosQueryVariables>(GetWebTransactionsSaldosDocument, options);
        }
export type GetWebTransactionsSaldosQueryHookResult = ReturnType<typeof useGetWebTransactionsSaldosQuery>;
export type GetWebTransactionsSaldosLazyQueryHookResult = ReturnType<typeof useGetWebTransactionsSaldosLazyQuery>;
export type GetWebTransactionsSaldosQueryResult = Apollo.QueryResult<GetWebTransactionsSaldosQuery, GetWebTransactionsSaldosQueryVariables>;
export const GetWebTicketsByUserDocument = gql`
    query getWebTicketsByUser($desde: Date, $hasta: Date, $sorteo: Int, $corredor_id: Int) {
  getWebTicketsByUser(
    desde: $desde
    hasta: $hasta
    sorteo: $sorteo
    corredor_id: $corredor_id
  )
}
    `;

/**
 * __useGetWebTicketsByUserQuery__
 *
 * To run a query within a React component, call `useGetWebTicketsByUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWebTicketsByUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWebTicketsByUserQuery({
 *   variables: {
 *      desde: // value for 'desde'
 *      hasta: // value for 'hasta'
 *      sorteo: // value for 'sorteo'
 *      corredor_id: // value for 'corredor_id'
 *   },
 * });
 */
export function useGetWebTicketsByUserQuery(baseOptions?: Apollo.QueryHookOptions<GetWebTicketsByUserQuery, GetWebTicketsByUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetWebTicketsByUserQuery, GetWebTicketsByUserQueryVariables>(GetWebTicketsByUserDocument, options);
      }
export function useGetWebTicketsByUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetWebTicketsByUserQuery, GetWebTicketsByUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetWebTicketsByUserQuery, GetWebTicketsByUserQueryVariables>(GetWebTicketsByUserDocument, options);
        }
export type GetWebTicketsByUserQueryHookResult = ReturnType<typeof useGetWebTicketsByUserQuery>;
export type GetWebTicketsByUserLazyQueryHookResult = ReturnType<typeof useGetWebTicketsByUserLazyQuery>;
export type GetWebTicketsByUserQueryResult = Apollo.QueryResult<GetWebTicketsByUserQuery, GetWebTicketsByUserQueryVariables>;
export const GetWebUserInformationDocument = gql`
    query getWebUserInformation($corredor_id: Int) {
  getWebUserInformation(corredor_id: $corredor_id)
}
    `;

/**
 * __useGetWebUserInformationQuery__
 *
 * To run a query within a React component, call `useGetWebUserInformationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWebUserInformationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWebUserInformationQuery({
 *   variables: {
 *      corredor_id: // value for 'corredor_id'
 *   },
 * });
 */
export function useGetWebUserInformationQuery(baseOptions?: Apollo.QueryHookOptions<GetWebUserInformationQuery, GetWebUserInformationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetWebUserInformationQuery, GetWebUserInformationQueryVariables>(GetWebUserInformationDocument, options);
      }
export function useGetWebUserInformationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetWebUserInformationQuery, GetWebUserInformationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetWebUserInformationQuery, GetWebUserInformationQueryVariables>(GetWebUserInformationDocument, options);
        }
export type GetWebUserInformationQueryHookResult = ReturnType<typeof useGetWebUserInformationQuery>;
export type GetWebUserInformationLazyQueryHookResult = ReturnType<typeof useGetWebUserInformationLazyQuery>;
export type GetWebUserInformationQueryResult = Apollo.QueryResult<GetWebUserInformationQuery, GetWebUserInformationQueryVariables>;
export const GetWebUserInformationUserDocument = gql`
    query getWebUserInformationUser($usuario_id: Int) {
  getWebUserInformationUser(usuario_id: $usuario_id)
}
    `;

/**
 * __useGetWebUserInformationUserQuery__
 *
 * To run a query within a React component, call `useGetWebUserInformationUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWebUserInformationUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWebUserInformationUserQuery({
 *   variables: {
 *      usuario_id: // value for 'usuario_id'
 *   },
 * });
 */
export function useGetWebUserInformationUserQuery(baseOptions?: Apollo.QueryHookOptions<GetWebUserInformationUserQuery, GetWebUserInformationUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetWebUserInformationUserQuery, GetWebUserInformationUserQueryVariables>(GetWebUserInformationUserDocument, options);
      }
export function useGetWebUserInformationUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetWebUserInformationUserQuery, GetWebUserInformationUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetWebUserInformationUserQuery, GetWebUserInformationUserQueryVariables>(GetWebUserInformationUserDocument, options);
        }
export type GetWebUserInformationUserQueryHookResult = ReturnType<typeof useGetWebUserInformationUserQuery>;
export type GetWebUserInformationUserLazyQueryHookResult = ReturnType<typeof useGetWebUserInformationUserLazyQuery>;
export type GetWebUserInformationUserQueryResult = Apollo.QueryResult<GetWebUserInformationUserQuery, GetWebUserInformationUserQueryVariables>;
export const HistorialDeSorteosDocument = gql`
    query historialDeSorteos($desde: Date!, $hasta: Date, $sorteo: [ID]) {
  historialDeSorteos(desde: $desde, hasta: $hasta, sorteo: $sorteo)
}
    `;

/**
 * __useHistorialDeSorteosQuery__
 *
 * To run a query within a React component, call `useHistorialDeSorteosQuery` and pass it any options that fit your needs.
 * When your component renders, `useHistorialDeSorteosQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHistorialDeSorteosQuery({
 *   variables: {
 *      desde: // value for 'desde'
 *      hasta: // value for 'hasta'
 *      sorteo: // value for 'sorteo'
 *   },
 * });
 */
export function useHistorialDeSorteosQuery(baseOptions: Apollo.QueryHookOptions<HistorialDeSorteosQuery, HistorialDeSorteosQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HistorialDeSorteosQuery, HistorialDeSorteosQueryVariables>(HistorialDeSorteosDocument, options);
      }
export function useHistorialDeSorteosLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HistorialDeSorteosQuery, HistorialDeSorteosQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HistorialDeSorteosQuery, HistorialDeSorteosQueryVariables>(HistorialDeSorteosDocument, options);
        }
export type HistorialDeSorteosQueryHookResult = ReturnType<typeof useHistorialDeSorteosQuery>;
export type HistorialDeSorteosLazyQueryHookResult = ReturnType<typeof useHistorialDeSorteosLazyQuery>;
export type HistorialDeSorteosQueryResult = Apollo.QueryResult<HistorialDeSorteosQuery, HistorialDeSorteosQueryVariables>;
export const HistorialTerminalDocument = gql`
    query HistorialTerminal($terminalId: ID) {
  historialTerminal(terminalId: $terminalId)
}
    `;

/**
 * __useHistorialTerminalQuery__
 *
 * To run a query within a React component, call `useHistorialTerminalQuery` and pass it any options that fit your needs.
 * When your component renders, `useHistorialTerminalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHistorialTerminalQuery({
 *   variables: {
 *      terminalId: // value for 'terminalId'
 *   },
 * });
 */
export function useHistorialTerminalQuery(baseOptions?: Apollo.QueryHookOptions<HistorialTerminalQuery, HistorialTerminalQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HistorialTerminalQuery, HistorialTerminalQueryVariables>(HistorialTerminalDocument, options);
      }
export function useHistorialTerminalLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HistorialTerminalQuery, HistorialTerminalQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HistorialTerminalQuery, HistorialTerminalQueryVariables>(HistorialTerminalDocument, options);
        }
export type HistorialTerminalQueryHookResult = ReturnType<typeof useHistorialTerminalQuery>;
export type HistorialTerminalLazyQueryHookResult = ReturnType<typeof useHistorialTerminalLazyQuery>;
export type HistorialTerminalQueryResult = Apollo.QueryResult<HistorialTerminalQuery, HistorialTerminalQueryVariables>;
export const HistorialTerminal2Document = gql`
    query HistorialTerminal2($terminalId: ID) {
  historialTerminal2(terminalId: $terminalId)
}
    `;

/**
 * __useHistorialTerminal2Query__
 *
 * To run a query within a React component, call `useHistorialTerminal2Query` and pass it any options that fit your needs.
 * When your component renders, `useHistorialTerminal2Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHistorialTerminal2Query({
 *   variables: {
 *      terminalId: // value for 'terminalId'
 *   },
 * });
 */
export function useHistorialTerminal2Query(baseOptions?: Apollo.QueryHookOptions<HistorialTerminal2Query, HistorialTerminal2QueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HistorialTerminal2Query, HistorialTerminal2QueryVariables>(HistorialTerminal2Document, options);
      }
export function useHistorialTerminal2LazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HistorialTerminal2Query, HistorialTerminal2QueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HistorialTerminal2Query, HistorialTerminal2QueryVariables>(HistorialTerminal2Document, options);
        }
export type HistorialTerminal2QueryHookResult = ReturnType<typeof useHistorialTerminal2Query>;
export type HistorialTerminal2LazyQueryHookResult = ReturnType<typeof useHistorialTerminal2LazyQuery>;
export type HistorialTerminal2QueryResult = Apollo.QueryResult<HistorialTerminal2Query, HistorialTerminal2QueryVariables>;
export const HistoryCorredorDocument = gql`
    query HistoryCorredor($desde: Date!, $hasta: Date, $corredor_id: ID!) {
  corredorHistorico(desde: $desde, hasta: $hasta, corredor_id: $corredor_id)
}
    `;

/**
 * __useHistoryCorredorQuery__
 *
 * To run a query within a React component, call `useHistoryCorredorQuery` and pass it any options that fit your needs.
 * When your component renders, `useHistoryCorredorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHistoryCorredorQuery({
 *   variables: {
 *      desde: // value for 'desde'
 *      hasta: // value for 'hasta'
 *      corredor_id: // value for 'corredor_id'
 *   },
 * });
 */
export function useHistoryCorredorQuery(baseOptions: Apollo.QueryHookOptions<HistoryCorredorQuery, HistoryCorredorQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HistoryCorredorQuery, HistoryCorredorQueryVariables>(HistoryCorredorDocument, options);
      }
export function useHistoryCorredorLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HistoryCorredorQuery, HistoryCorredorQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HistoryCorredorQuery, HistoryCorredorQueryVariables>(HistoryCorredorDocument, options);
        }
export type HistoryCorredorQueryHookResult = ReturnType<typeof useHistoryCorredorQuery>;
export type HistoryCorredorLazyQueryHookResult = ReturnType<typeof useHistoryCorredorLazyQuery>;
export type HistoryCorredorQueryResult = Apollo.QueryResult<HistoryCorredorQuery, HistoryCorredorQueryVariables>;
export const HistoryTerminalDocument = gql`
    query HistoryTerminal($desde: Date!, $hasta: Date, $terminal_id: ID!) {
  terminalHistorico(desde: $desde, hasta: $hasta, terminal_id: $terminal_id)
}
    `;

/**
 * __useHistoryTerminalQuery__
 *
 * To run a query within a React component, call `useHistoryTerminalQuery` and pass it any options that fit your needs.
 * When your component renders, `useHistoryTerminalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHistoryTerminalQuery({
 *   variables: {
 *      desde: // value for 'desde'
 *      hasta: // value for 'hasta'
 *      terminal_id: // value for 'terminal_id'
 *   },
 * });
 */
export function useHistoryTerminalQuery(baseOptions: Apollo.QueryHookOptions<HistoryTerminalQuery, HistoryTerminalQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HistoryTerminalQuery, HistoryTerminalQueryVariables>(HistoryTerminalDocument, options);
      }
export function useHistoryTerminalLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HistoryTerminalQuery, HistoryTerminalQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HistoryTerminalQuery, HistoryTerminalQueryVariables>(HistoryTerminalDocument, options);
        }
export type HistoryTerminalQueryHookResult = ReturnType<typeof useHistoryTerminalQuery>;
export type HistoryTerminalLazyQueryHookResult = ReturnType<typeof useHistoryTerminalLazyQuery>;
export type HistoryTerminalQueryResult = Apollo.QueryResult<HistoryTerminalQuery, HistoryTerminalQueryVariables>;
export const LimitedNumbersDocument = gql`
    query limitedNumbers($sorteo_id: [String]) {
  limitedNumbers(sorteo_id: $sorteo_id)
}
    `;

/**
 * __useLimitedNumbersQuery__
 *
 * To run a query within a React component, call `useLimitedNumbersQuery` and pass it any options that fit your needs.
 * When your component renders, `useLimitedNumbersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLimitedNumbersQuery({
 *   variables: {
 *      sorteo_id: // value for 'sorteo_id'
 *   },
 * });
 */
export function useLimitedNumbersQuery(baseOptions?: Apollo.QueryHookOptions<LimitedNumbersQuery, LimitedNumbersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LimitedNumbersQuery, LimitedNumbersQueryVariables>(LimitedNumbersDocument, options);
      }
export function useLimitedNumbersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LimitedNumbersQuery, LimitedNumbersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LimitedNumbersQuery, LimitedNumbersQueryVariables>(LimitedNumbersDocument, options);
        }
export type LimitedNumbersQueryHookResult = ReturnType<typeof useLimitedNumbersQuery>;
export type LimitedNumbersLazyQueryHookResult = ReturnType<typeof useLimitedNumbersLazyQuery>;
export type LimitedNumbersQueryResult = Apollo.QueryResult<LimitedNumbersQuery, LimitedNumbersQueryVariables>;
export const ListAgenciasForFilterDocument = gql`
    query listAgenciasForFilter {
  listAgenciasForFilter
}
    `;

/**
 * __useListAgenciasForFilterQuery__
 *
 * To run a query within a React component, call `useListAgenciasForFilterQuery` and pass it any options that fit your needs.
 * When your component renders, `useListAgenciasForFilterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListAgenciasForFilterQuery({
 *   variables: {
 *   },
 * });
 */
export function useListAgenciasForFilterQuery(baseOptions?: Apollo.QueryHookOptions<ListAgenciasForFilterQuery, ListAgenciasForFilterQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListAgenciasForFilterQuery, ListAgenciasForFilterQueryVariables>(ListAgenciasForFilterDocument, options);
      }
export function useListAgenciasForFilterLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListAgenciasForFilterQuery, ListAgenciasForFilterQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListAgenciasForFilterQuery, ListAgenciasForFilterQueryVariables>(ListAgenciasForFilterDocument, options);
        }
export type ListAgenciasForFilterQueryHookResult = ReturnType<typeof useListAgenciasForFilterQuery>;
export type ListAgenciasForFilterLazyQueryHookResult = ReturnType<typeof useListAgenciasForFilterLazyQuery>;
export type ListAgenciasForFilterQueryResult = Apollo.QueryResult<ListAgenciasForFilterQuery, ListAgenciasForFilterQueryVariables>;
export const ListAnulacionesMegalotoDocument = gql`
    query listAnulacionesMegaloto($desde: Date!, $hasta: Date!, $agencia: ID!, $corredor: ID, $ticket: String) {
  listAnulacionesMegaloto(
    desde: $desde
    hasta: $hasta
    agencia: $agencia
    corredor: $corredor
    ticket: $ticket
  )
}
    `;

/**
 * __useListAnulacionesMegalotoQuery__
 *
 * To run a query within a React component, call `useListAnulacionesMegalotoQuery` and pass it any options that fit your needs.
 * When your component renders, `useListAnulacionesMegalotoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListAnulacionesMegalotoQuery({
 *   variables: {
 *      desde: // value for 'desde'
 *      hasta: // value for 'hasta'
 *      agencia: // value for 'agencia'
 *      corredor: // value for 'corredor'
 *      ticket: // value for 'ticket'
 *   },
 * });
 */
export function useListAnulacionesMegalotoQuery(baseOptions: Apollo.QueryHookOptions<ListAnulacionesMegalotoQuery, ListAnulacionesMegalotoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListAnulacionesMegalotoQuery, ListAnulacionesMegalotoQueryVariables>(ListAnulacionesMegalotoDocument, options);
      }
export function useListAnulacionesMegalotoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListAnulacionesMegalotoQuery, ListAnulacionesMegalotoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListAnulacionesMegalotoQuery, ListAnulacionesMegalotoQueryVariables>(ListAnulacionesMegalotoDocument, options);
        }
export type ListAnulacionesMegalotoQueryHookResult = ReturnType<typeof useListAnulacionesMegalotoQuery>;
export type ListAnulacionesMegalotoLazyQueryHookResult = ReturnType<typeof useListAnulacionesMegalotoLazyQuery>;
export type ListAnulacionesMegalotoQueryResult = Apollo.QueryResult<ListAnulacionesMegalotoQuery, ListAnulacionesMegalotoQueryVariables>;
export const ListAnulacionPremioPagadoDocument = gql`
    query listAnulacionPremioPagado($desde: Date!, $hasta: Date!, $umn_id: [ID!]) {
  listAnulacionPremioPagado(desde: $desde, hasta: $hasta, umn_id: $umn_id)
}
    `;

/**
 * __useListAnulacionPremioPagadoQuery__
 *
 * To run a query within a React component, call `useListAnulacionPremioPagadoQuery` and pass it any options that fit your needs.
 * When your component renders, `useListAnulacionPremioPagadoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListAnulacionPremioPagadoQuery({
 *   variables: {
 *      desde: // value for 'desde'
 *      hasta: // value for 'hasta'
 *      umn_id: // value for 'umn_id'
 *   },
 * });
 */
export function useListAnulacionPremioPagadoQuery(baseOptions: Apollo.QueryHookOptions<ListAnulacionPremioPagadoQuery, ListAnulacionPremioPagadoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListAnulacionPremioPagadoQuery, ListAnulacionPremioPagadoQueryVariables>(ListAnulacionPremioPagadoDocument, options);
      }
export function useListAnulacionPremioPagadoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListAnulacionPremioPagadoQuery, ListAnulacionPremioPagadoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListAnulacionPremioPagadoQuery, ListAnulacionPremioPagadoQueryVariables>(ListAnulacionPremioPagadoDocument, options);
        }
export type ListAnulacionPremioPagadoQueryHookResult = ReturnType<typeof useListAnulacionPremioPagadoQuery>;
export type ListAnulacionPremioPagadoLazyQueryHookResult = ReturnType<typeof useListAnulacionPremioPagadoLazyQuery>;
export type ListAnulacionPremioPagadoQueryResult = Apollo.QueryResult<ListAnulacionPremioPagadoQuery, ListAnulacionPremioPagadoQueryVariables>;
export const ListaDeEstadosTerminalesDocument = gql`
    query listaDeEstadosTerminales {
  listaDeEstadosTerminales
}
    `;

/**
 * __useListaDeEstadosTerminalesQuery__
 *
 * To run a query within a React component, call `useListaDeEstadosTerminalesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListaDeEstadosTerminalesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListaDeEstadosTerminalesQuery({
 *   variables: {
 *   },
 * });
 */
export function useListaDeEstadosTerminalesQuery(baseOptions?: Apollo.QueryHookOptions<ListaDeEstadosTerminalesQuery, ListaDeEstadosTerminalesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListaDeEstadosTerminalesQuery, ListaDeEstadosTerminalesQueryVariables>(ListaDeEstadosTerminalesDocument, options);
      }
export function useListaDeEstadosTerminalesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListaDeEstadosTerminalesQuery, ListaDeEstadosTerminalesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListaDeEstadosTerminalesQuery, ListaDeEstadosTerminalesQueryVariables>(ListaDeEstadosTerminalesDocument, options);
        }
export type ListaDeEstadosTerminalesQueryHookResult = ReturnType<typeof useListaDeEstadosTerminalesQuery>;
export type ListaDeEstadosTerminalesLazyQueryHookResult = ReturnType<typeof useListaDeEstadosTerminalesLazyQuery>;
export type ListaDeEstadosTerminalesQueryResult = Apollo.QueryResult<ListaDeEstadosTerminalesQuery, ListaDeEstadosTerminalesQueryVariables>;
export const ListadoTerminalesDocument = gql`
    query listadoTerminales {
  terminales {
    id
    serial
    version_asignada
    Heartbeats {
      updated_at
      data
    }
  }
}
    `;

/**
 * __useListadoTerminalesQuery__
 *
 * To run a query within a React component, call `useListadoTerminalesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListadoTerminalesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListadoTerminalesQuery({
 *   variables: {
 *   },
 * });
 */
export function useListadoTerminalesQuery(baseOptions?: Apollo.QueryHookOptions<ListadoTerminalesQuery, ListadoTerminalesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListadoTerminalesQuery, ListadoTerminalesQueryVariables>(ListadoTerminalesDocument, options);
      }
export function useListadoTerminalesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListadoTerminalesQuery, ListadoTerminalesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListadoTerminalesQuery, ListadoTerminalesQueryVariables>(ListadoTerminalesDocument, options);
        }
export type ListadoTerminalesQueryHookResult = ReturnType<typeof useListadoTerminalesQuery>;
export type ListadoTerminalesLazyQueryHookResult = ReturnType<typeof useListadoTerminalesLazyQuery>;
export type ListadoTerminalesQueryResult = Apollo.QueryResult<ListadoTerminalesQuery, ListadoTerminalesQueryVariables>;
export const NumerosLimitadosDocument = gql`
    query numerosLimitados {
  numerosLimitados {
    id
    agencia
    fecha
    numeros
  }
}
    `;

/**
 * __useNumerosLimitadosQuery__
 *
 * To run a query within a React component, call `useNumerosLimitadosQuery` and pass it any options that fit your needs.
 * When your component renders, `useNumerosLimitadosQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNumerosLimitadosQuery({
 *   variables: {
 *   },
 * });
 */
export function useNumerosLimitadosQuery(baseOptions?: Apollo.QueryHookOptions<NumerosLimitadosQuery, NumerosLimitadosQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<NumerosLimitadosQuery, NumerosLimitadosQueryVariables>(NumerosLimitadosDocument, options);
      }
export function useNumerosLimitadosLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NumerosLimitadosQuery, NumerosLimitadosQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<NumerosLimitadosQuery, NumerosLimitadosQueryVariables>(NumerosLimitadosDocument, options);
        }
export type NumerosLimitadosQueryHookResult = ReturnType<typeof useNumerosLimitadosQuery>;
export type NumerosLimitadosLazyQueryHookResult = ReturnType<typeof useNumerosLimitadosLazyQuery>;
export type NumerosLimitadosQueryResult = Apollo.QueryResult<NumerosLimitadosQuery, NumerosLimitadosQueryVariables>;
export const OcurrenciasDocument = gql`
    query ocurrencias($desde: Date!, $hasta: Date, $sorteo: [ID], $postura: Int) {
  ocurrencias(desde: $desde, hasta: $hasta, sorteo: $sorteo, postura: $postura)
}
    `;

/**
 * __useOcurrenciasQuery__
 *
 * To run a query within a React component, call `useOcurrenciasQuery` and pass it any options that fit your needs.
 * When your component renders, `useOcurrenciasQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOcurrenciasQuery({
 *   variables: {
 *      desde: // value for 'desde'
 *      hasta: // value for 'hasta'
 *      sorteo: // value for 'sorteo'
 *      postura: // value for 'postura'
 *   },
 * });
 */
export function useOcurrenciasQuery(baseOptions: Apollo.QueryHookOptions<OcurrenciasQuery, OcurrenciasQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OcurrenciasQuery, OcurrenciasQueryVariables>(OcurrenciasDocument, options);
      }
export function useOcurrenciasLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OcurrenciasQuery, OcurrenciasQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OcurrenciasQuery, OcurrenciasQueryVariables>(OcurrenciasDocument, options);
        }
export type OcurrenciasQueryHookResult = ReturnType<typeof useOcurrenciasQuery>;
export type OcurrenciasLazyQueryHookResult = ReturnType<typeof useOcurrenciasLazyQuery>;
export type OcurrenciasQueryResult = Apollo.QueryResult<OcurrenciasQuery, OcurrenciasQueryVariables>;
export const OcurrenciasDosNumerosDocument = gql`
    query ocurrenciasDosNumeros($desde: Date!, $hasta: Date, $sorteo: [ID], $postura: Int) {
  ocurrenciasDosNumeros(
    desde: $desde
    hasta: $hasta
    sorteo: $sorteo
    postura: $postura
  )
}
    `;

/**
 * __useOcurrenciasDosNumerosQuery__
 *
 * To run a query within a React component, call `useOcurrenciasDosNumerosQuery` and pass it any options that fit your needs.
 * When your component renders, `useOcurrenciasDosNumerosQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOcurrenciasDosNumerosQuery({
 *   variables: {
 *      desde: // value for 'desde'
 *      hasta: // value for 'hasta'
 *      sorteo: // value for 'sorteo'
 *      postura: // value for 'postura'
 *   },
 * });
 */
export function useOcurrenciasDosNumerosQuery(baseOptions: Apollo.QueryHookOptions<OcurrenciasDosNumerosQuery, OcurrenciasDosNumerosQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OcurrenciasDosNumerosQuery, OcurrenciasDosNumerosQueryVariables>(OcurrenciasDosNumerosDocument, options);
      }
export function useOcurrenciasDosNumerosLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OcurrenciasDosNumerosQuery, OcurrenciasDosNumerosQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OcurrenciasDosNumerosQuery, OcurrenciasDosNumerosQueryVariables>(OcurrenciasDosNumerosDocument, options);
        }
export type OcurrenciasDosNumerosQueryHookResult = ReturnType<typeof useOcurrenciasDosNumerosQuery>;
export type OcurrenciasDosNumerosLazyQueryHookResult = ReturnType<typeof useOcurrenciasDosNumerosLazyQuery>;
export type OcurrenciasDosNumerosQueryResult = Apollo.QueryResult<OcurrenciasDosNumerosQuery, OcurrenciasDosNumerosQueryVariables>;
export const GetOcurrenciasAndChartDocument = gql`
    query getOcurrenciasAndChart($desde: Date!, $hasta: Date!, $flag: Boolean) {
  getOcurrenciasAndChart(desde: $desde, hasta: $hasta, flag: $flag)
}
    `;

/**
 * __useGetOcurrenciasAndChartQuery__
 *
 * To run a query within a React component, call `useGetOcurrenciasAndChartQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOcurrenciasAndChartQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOcurrenciasAndChartQuery({
 *   variables: {
 *      desde: // value for 'desde'
 *      hasta: // value for 'hasta'
 *      flag: // value for 'flag'
 *   },
 * });
 */
export function useGetOcurrenciasAndChartQuery(baseOptions: Apollo.QueryHookOptions<GetOcurrenciasAndChartQuery, GetOcurrenciasAndChartQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOcurrenciasAndChartQuery, GetOcurrenciasAndChartQueryVariables>(GetOcurrenciasAndChartDocument, options);
      }
export function useGetOcurrenciasAndChartLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOcurrenciasAndChartQuery, GetOcurrenciasAndChartQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOcurrenciasAndChartQuery, GetOcurrenciasAndChartQueryVariables>(GetOcurrenciasAndChartDocument, options);
        }
export type GetOcurrenciasAndChartQueryHookResult = ReturnType<typeof useGetOcurrenciasAndChartQuery>;
export type GetOcurrenciasAndChartLazyQueryHookResult = ReturnType<typeof useGetOcurrenciasAndChartLazyQuery>;
export type GetOcurrenciasAndChartQueryResult = Apollo.QueryResult<GetOcurrenciasAndChartQuery, GetOcurrenciasAndChartQueryVariables>;
export const SearchPersonaDocument = gql`
    query searchPersona($nrodoc: String) {
  pn_persona(nrodoc: $nrodoc) {
    nombre
    apellido
    fechanac
    nrodoc
  }
}
    `;

/**
 * __useSearchPersonaQuery__
 *
 * To run a query within a React component, call `useSearchPersonaQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchPersonaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchPersonaQuery({
 *   variables: {
 *      nrodoc: // value for 'nrodoc'
 *   },
 * });
 */
export function useSearchPersonaQuery(baseOptions?: Apollo.QueryHookOptions<SearchPersonaQuery, SearchPersonaQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchPersonaQuery, SearchPersonaQueryVariables>(SearchPersonaDocument, options);
      }
export function useSearchPersonaLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchPersonaQuery, SearchPersonaQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchPersonaQuery, SearchPersonaQueryVariables>(SearchPersonaDocument, options);
        }
export type SearchPersonaQueryHookResult = ReturnType<typeof useSearchPersonaQuery>;
export type SearchPersonaLazyQueryHookResult = ReturnType<typeof useSearchPersonaLazyQuery>;
export type SearchPersonaQueryResult = Apollo.QueryResult<SearchPersonaQuery, SearchPersonaQueryVariables>;
export const PlanillaAgenciaDocument = gql`
    query planillaAgencia($desde: Date!, $hasta: Date, $umn: [Int]) {
  quinielaFechaPorCorredor(desde: $desde, hasta: $hasta, umn: $umn)
}
    `;

/**
 * __usePlanillaAgenciaQuery__
 *
 * To run a query within a React component, call `usePlanillaAgenciaQuery` and pass it any options that fit your needs.
 * When your component renders, `usePlanillaAgenciaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePlanillaAgenciaQuery({
 *   variables: {
 *      desde: // value for 'desde'
 *      hasta: // value for 'hasta'
 *      umn: // value for 'umn'
 *   },
 * });
 */
export function usePlanillaAgenciaQuery(baseOptions: Apollo.QueryHookOptions<PlanillaAgenciaQuery, PlanillaAgenciaQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PlanillaAgenciaQuery, PlanillaAgenciaQueryVariables>(PlanillaAgenciaDocument, options);
      }
export function usePlanillaAgenciaLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PlanillaAgenciaQuery, PlanillaAgenciaQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PlanillaAgenciaQuery, PlanillaAgenciaQueryVariables>(PlanillaAgenciaDocument, options);
        }
export type PlanillaAgenciaQueryHookResult = ReturnType<typeof usePlanillaAgenciaQuery>;
export type PlanillaAgenciaLazyQueryHookResult = ReturnType<typeof usePlanillaAgenciaLazyQuery>;
export type PlanillaAgenciaQueryResult = Apollo.QueryResult<PlanillaAgenciaQuery, PlanillaAgenciaQueryVariables>;
export const PlanillaGeneralDocument = gql`
    query planillaGeneral($umn_id: [ID]) {
  umn(id: $umn_id) {
    now
    numero
    nombre
    agencia_numero
    totalCorredores
    totalLinea
    totalDeuda
    totalVentasConCierre
    totalVentasSinCierre
    totalCajasAbiertas
    Cobradores {
      numero
      id
      cuenta
      linea
      Usuario {
        username
        Person {
          nombre
          apellido
          nrodoc
        }
      }
    }
    Corredores {
      numero
      id
      cuenta
      linea
      Cajas {
        id
        apertura
        cierre
        estado
        Transacciones {
          monto
        }
      }
      Usuario {
        username
        Person {
          nombre
          apellido
          nrodoc
        }
      }
    }
  }
}
    `;

/**
 * __usePlanillaGeneralQuery__
 *
 * To run a query within a React component, call `usePlanillaGeneralQuery` and pass it any options that fit your needs.
 * When your component renders, `usePlanillaGeneralQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePlanillaGeneralQuery({
 *   variables: {
 *      umn_id: // value for 'umn_id'
 *   },
 * });
 */
export function usePlanillaGeneralQuery(baseOptions?: Apollo.QueryHookOptions<PlanillaGeneralQuery, PlanillaGeneralQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PlanillaGeneralQuery, PlanillaGeneralQueryVariables>(PlanillaGeneralDocument, options);
      }
export function usePlanillaGeneralLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PlanillaGeneralQuery, PlanillaGeneralQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PlanillaGeneralQuery, PlanillaGeneralQueryVariables>(PlanillaGeneralDocument, options);
        }
export type PlanillaGeneralQueryHookResult = ReturnType<typeof usePlanillaGeneralQuery>;
export type PlanillaGeneralLazyQueryHookResult = ReturnType<typeof usePlanillaGeneralLazyQuery>;
export type PlanillaGeneralQueryResult = Apollo.QueryResult<PlanillaGeneralQuery, PlanillaGeneralQueryVariables>;
export const PremiadosDocument = gql`
    query premiados($serie: String, $ticket: Float, $desde: Date!, $hasta: Date, $numero_corredor: Int, $sorteo_id: [Int], $umn_id: [ID!], $retrasadas: Boolean, $agencia: [ID], $premiados: Boolean) {
  ticketPremiados(
    serie: $serie
    ticket: $ticket
    desde: $desde
    hasta: $hasta
    numero_corredor: $numero_corredor
    sorteo_id: $sorteo_id
    umn_id: $umn_id
    retrasadas: $retrasadas
    agencia: $agencia
    premiados: $premiados
  ) {
    id
    nombre
    apellido
    serie
    ticket
    monto_ganado
    monto_apostado
    generado
    created_at
    Sorteo {
      SorteoBase {
        tipo
        nombre
        id
      }
    }
    Transaccion {
      Caja {
        Corredor {
          numero
          id
          Umn {
            numero
            agencia_numero
          }
          Usuario {
            Person {
              nombre
              apellido
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __usePremiadosQuery__
 *
 * To run a query within a React component, call `usePremiadosQuery` and pass it any options that fit your needs.
 * When your component renders, `usePremiadosQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePremiadosQuery({
 *   variables: {
 *      serie: // value for 'serie'
 *      ticket: // value for 'ticket'
 *      desde: // value for 'desde'
 *      hasta: // value for 'hasta'
 *      numero_corredor: // value for 'numero_corredor'
 *      sorteo_id: // value for 'sorteo_id'
 *      umn_id: // value for 'umn_id'
 *      retrasadas: // value for 'retrasadas'
 *      agencia: // value for 'agencia'
 *      premiados: // value for 'premiados'
 *   },
 * });
 */
export function usePremiadosQuery(baseOptions: Apollo.QueryHookOptions<PremiadosQuery, PremiadosQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PremiadosQuery, PremiadosQueryVariables>(PremiadosDocument, options);
      }
export function usePremiadosLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PremiadosQuery, PremiadosQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PremiadosQuery, PremiadosQueryVariables>(PremiadosDocument, options);
        }
export type PremiadosQueryHookResult = ReturnType<typeof usePremiadosQuery>;
export type PremiadosLazyQueryHookResult = ReturnType<typeof usePremiadosLazyQuery>;
export type PremiadosQueryResult = Apollo.QueryResult<PremiadosQuery, PremiadosQueryVariables>;
export const PremiadosAgrupadosDocument = gql`
    query premiadosAgrupados($serie: String, $ticket: Float, $desde: Date!, $hasta: Date, $numero_corredor: Int, $sorteo_id: [Int], $umn_id: [ID!], $premiado: Boolean, $agencia: [ID], $sorteoNoAgrupado: Boolean, $fechaNoAgrupado: Boolean) {
  premiadosAgrupados(
    serie: $serie
    ticket: $ticket
    desde: $desde
    hasta: $hasta
    numero_corredor: $numero_corredor
    sorteo_id: $sorteo_id
    umn_id: $umn_id
    premiado: $premiado
    agencia: $agencia
    sorteoNoAgrupado: $sorteoNoAgrupado
    fechaNoAgrupado: $fechaNoAgrupado
  )
}
    `;

/**
 * __usePremiadosAgrupadosQuery__
 *
 * To run a query within a React component, call `usePremiadosAgrupadosQuery` and pass it any options that fit your needs.
 * When your component renders, `usePremiadosAgrupadosQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePremiadosAgrupadosQuery({
 *   variables: {
 *      serie: // value for 'serie'
 *      ticket: // value for 'ticket'
 *      desde: // value for 'desde'
 *      hasta: // value for 'hasta'
 *      numero_corredor: // value for 'numero_corredor'
 *      sorteo_id: // value for 'sorteo_id'
 *      umn_id: // value for 'umn_id'
 *      premiado: // value for 'premiado'
 *      agencia: // value for 'agencia'
 *      sorteoNoAgrupado: // value for 'sorteoNoAgrupado'
 *      fechaNoAgrupado: // value for 'fechaNoAgrupado'
 *   },
 * });
 */
export function usePremiadosAgrupadosQuery(baseOptions: Apollo.QueryHookOptions<PremiadosAgrupadosQuery, PremiadosAgrupadosQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PremiadosAgrupadosQuery, PremiadosAgrupadosQueryVariables>(PremiadosAgrupadosDocument, options);
      }
export function usePremiadosAgrupadosLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PremiadosAgrupadosQuery, PremiadosAgrupadosQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PremiadosAgrupadosQuery, PremiadosAgrupadosQueryVariables>(PremiadosAgrupadosDocument, options);
        }
export type PremiadosAgrupadosQueryHookResult = ReturnType<typeof usePremiadosAgrupadosQuery>;
export type PremiadosAgrupadosLazyQueryHookResult = ReturnType<typeof usePremiadosAgrupadosLazyQuery>;
export type PremiadosAgrupadosQueryResult = Apollo.QueryResult<PremiadosAgrupadosQuery, PremiadosAgrupadosQueryVariables>;
export const ProyeccionesDocument = gql`
    query proyecciones($fecha: Date!, $sorteo_id: [Int], $agencia: [Int], $cifras: Int) {
  proyeccionPremiacion(
    fecha: $fecha
    sorteo_id: $sorteo_id
    agencia: $agencia
    cifras: $cifras
  )
}
    `;

/**
 * __useProyeccionesQuery__
 *
 * To run a query within a React component, call `useProyeccionesQuery` and pass it any options that fit your needs.
 * When your component renders, `useProyeccionesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProyeccionesQuery({
 *   variables: {
 *      fecha: // value for 'fecha'
 *      sorteo_id: // value for 'sorteo_id'
 *      agencia: // value for 'agencia'
 *      cifras: // value for 'cifras'
 *   },
 * });
 */
export function useProyeccionesQuery(baseOptions: Apollo.QueryHookOptions<ProyeccionesQuery, ProyeccionesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProyeccionesQuery, ProyeccionesQueryVariables>(ProyeccionesDocument, options);
      }
export function useProyeccionesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProyeccionesQuery, ProyeccionesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProyeccionesQuery, ProyeccionesQueryVariables>(ProyeccionesDocument, options);
        }
export type ProyeccionesQueryHookResult = ReturnType<typeof useProyeccionesQuery>;
export type ProyeccionesLazyQueryHookResult = ReturnType<typeof useProyeccionesLazyQuery>;
export type ProyeccionesQueryResult = Apollo.QueryResult<ProyeccionesQuery, ProyeccionesQueryVariables>;
export const QWebCantidadesDocument = gql`
    query qWebCantidades($desde: Date, $hasta: Date) {
  qWebCantidades(desde: $desde, hasta: $hasta)
}
    `;

/**
 * __useQWebCantidadesQuery__
 *
 * To run a query within a React component, call `useQWebCantidadesQuery` and pass it any options that fit your needs.
 * When your component renders, `useQWebCantidadesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQWebCantidadesQuery({
 *   variables: {
 *      desde: // value for 'desde'
 *      hasta: // value for 'hasta'
 *   },
 * });
 */
export function useQWebCantidadesQuery(baseOptions?: Apollo.QueryHookOptions<QWebCantidadesQuery, QWebCantidadesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<QWebCantidadesQuery, QWebCantidadesQueryVariables>(QWebCantidadesDocument, options);
      }
export function useQWebCantidadesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<QWebCantidadesQuery, QWebCantidadesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<QWebCantidadesQuery, QWebCantidadesQueryVariables>(QWebCantidadesDocument, options);
        }
export type QWebCantidadesQueryHookResult = ReturnType<typeof useQWebCantidadesQuery>;
export type QWebCantidadesLazyQueryHookResult = ReturnType<typeof useQWebCantidadesLazyQuery>;
export type QWebCantidadesQueryResult = Apollo.QueryResult<QWebCantidadesQuery, QWebCantidadesQueryVariables>;
export const TransaccionesRetirosByCorredorDocument = gql`
    query transaccionesRetirosByCorredor($desde: Date!, $hasta: Date!, $corredor_id: Int!, $agencia_numero: [Int], $producto_id: ID) {
  transaccionesRetirosByCorredor(
    desde: $desde
    hasta: $hasta
    corredor_id: $corredor_id
    agencia: $agencia_numero
    producto_id: $producto_id
  )
}
    `;

/**
 * __useTransaccionesRetirosByCorredorQuery__
 *
 * To run a query within a React component, call `useTransaccionesRetirosByCorredorQuery` and pass it any options that fit your needs.
 * When your component renders, `useTransaccionesRetirosByCorredorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTransaccionesRetirosByCorredorQuery({
 *   variables: {
 *      desde: // value for 'desde'
 *      hasta: // value for 'hasta'
 *      corredor_id: // value for 'corredor_id'
 *      agencia_numero: // value for 'agencia_numero'
 *      producto_id: // value for 'producto_id'
 *   },
 * });
 */
export function useTransaccionesRetirosByCorredorQuery(baseOptions: Apollo.QueryHookOptions<TransaccionesRetirosByCorredorQuery, TransaccionesRetirosByCorredorQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TransaccionesRetirosByCorredorQuery, TransaccionesRetirosByCorredorQueryVariables>(TransaccionesRetirosByCorredorDocument, options);
      }
export function useTransaccionesRetirosByCorredorLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TransaccionesRetirosByCorredorQuery, TransaccionesRetirosByCorredorQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TransaccionesRetirosByCorredorQuery, TransaccionesRetirosByCorredorQueryVariables>(TransaccionesRetirosByCorredorDocument, options);
        }
export type TransaccionesRetirosByCorredorQueryHookResult = ReturnType<typeof useTransaccionesRetirosByCorredorQuery>;
export type TransaccionesRetirosByCorredorLazyQueryHookResult = ReturnType<typeof useTransaccionesRetirosByCorredorLazyQuery>;
export type TransaccionesRetirosByCorredorQueryResult = Apollo.QueryResult<TransaccionesRetirosByCorredorQuery, TransaccionesRetirosByCorredorQueryVariables>;
export const ReporteFacturacionDiariaDocument = gql`
    query reporteFacturacionDiaria($desde: Date!, $hasta: Date, $sorteo_id: [ID], $agencia: [ID]) {
  reporteFacturacionDiaria(
    desde: $desde
    hasta: $hasta
    sorteo_id: $sorteo_id
    agencia: $agencia
  )
}
    `;

/**
 * __useReporteFacturacionDiariaQuery__
 *
 * To run a query within a React component, call `useReporteFacturacionDiariaQuery` and pass it any options that fit your needs.
 * When your component renders, `useReporteFacturacionDiariaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReporteFacturacionDiariaQuery({
 *   variables: {
 *      desde: // value for 'desde'
 *      hasta: // value for 'hasta'
 *      sorteo_id: // value for 'sorteo_id'
 *      agencia: // value for 'agencia'
 *   },
 * });
 */
export function useReporteFacturacionDiariaQuery(baseOptions: Apollo.QueryHookOptions<ReporteFacturacionDiariaQuery, ReporteFacturacionDiariaQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReporteFacturacionDiariaQuery, ReporteFacturacionDiariaQueryVariables>(ReporteFacturacionDiariaDocument, options);
      }
export function useReporteFacturacionDiariaLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReporteFacturacionDiariaQuery, ReporteFacturacionDiariaQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReporteFacturacionDiariaQuery, ReporteFacturacionDiariaQueryVariables>(ReporteFacturacionDiariaDocument, options);
        }
export type ReporteFacturacionDiariaQueryHookResult = ReturnType<typeof useReporteFacturacionDiariaQuery>;
export type ReporteFacturacionDiariaLazyQueryHookResult = ReturnType<typeof useReporteFacturacionDiariaLazyQuery>;
export type ReporteFacturacionDiariaQueryResult = Apollo.QueryResult<ReporteFacturacionDiariaQuery, ReporteFacturacionDiariaQueryVariables>;
export const ResumenAgenciaUmnProductosDocument = gql`
    query resumenAgenciaUmnProductos($umn: [ID], $desde: Date!, $hasta: Date) {
  resumenAgenciaUmnProductos(umn: $umn, desde: $desde, hasta: $hasta)
}
    `;

/**
 * __useResumenAgenciaUmnProductosQuery__
 *
 * To run a query within a React component, call `useResumenAgenciaUmnProductosQuery` and pass it any options that fit your needs.
 * When your component renders, `useResumenAgenciaUmnProductosQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useResumenAgenciaUmnProductosQuery({
 *   variables: {
 *      umn: // value for 'umn'
 *      desde: // value for 'desde'
 *      hasta: // value for 'hasta'
 *   },
 * });
 */
export function useResumenAgenciaUmnProductosQuery(baseOptions: Apollo.QueryHookOptions<ResumenAgenciaUmnProductosQuery, ResumenAgenciaUmnProductosQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ResumenAgenciaUmnProductosQuery, ResumenAgenciaUmnProductosQueryVariables>(ResumenAgenciaUmnProductosDocument, options);
      }
export function useResumenAgenciaUmnProductosLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ResumenAgenciaUmnProductosQuery, ResumenAgenciaUmnProductosQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ResumenAgenciaUmnProductosQuery, ResumenAgenciaUmnProductosQueryVariables>(ResumenAgenciaUmnProductosDocument, options);
        }
export type ResumenAgenciaUmnProductosQueryHookResult = ReturnType<typeof useResumenAgenciaUmnProductosQuery>;
export type ResumenAgenciaUmnProductosLazyQueryHookResult = ReturnType<typeof useResumenAgenciaUmnProductosLazyQuery>;
export type ResumenAgenciaUmnProductosQueryResult = Apollo.QueryResult<ResumenAgenciaUmnProductosQuery, ResumenAgenciaUmnProductosQueryVariables>;
export const GetResumenFacturacionTelefoniaDocument = gql`
    query getResumenFacturacionTelefonia($desde: Date!, $hasta: Date, $agencia: [Int]) {
  getResumenFacturacionTelefonia(desde: $desde, hasta: $hasta, agencia: $agencia)
}
    `;

/**
 * __useGetResumenFacturacionTelefoniaQuery__
 *
 * To run a query within a React component, call `useGetResumenFacturacionTelefoniaQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetResumenFacturacionTelefoniaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetResumenFacturacionTelefoniaQuery({
 *   variables: {
 *      desde: // value for 'desde'
 *      hasta: // value for 'hasta'
 *      agencia: // value for 'agencia'
 *   },
 * });
 */
export function useGetResumenFacturacionTelefoniaQuery(baseOptions: Apollo.QueryHookOptions<GetResumenFacturacionTelefoniaQuery, GetResumenFacturacionTelefoniaQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetResumenFacturacionTelefoniaQuery, GetResumenFacturacionTelefoniaQueryVariables>(GetResumenFacturacionTelefoniaDocument, options);
      }
export function useGetResumenFacturacionTelefoniaLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetResumenFacturacionTelefoniaQuery, GetResumenFacturacionTelefoniaQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetResumenFacturacionTelefoniaQuery, GetResumenFacturacionTelefoniaQueryVariables>(GetResumenFacturacionTelefoniaDocument, options);
        }
export type GetResumenFacturacionTelefoniaQueryHookResult = ReturnType<typeof useGetResumenFacturacionTelefoniaQuery>;
export type GetResumenFacturacionTelefoniaLazyQueryHookResult = ReturnType<typeof useGetResumenFacturacionTelefoniaLazyQuery>;
export type GetResumenFacturacionTelefoniaQueryResult = Apollo.QueryResult<GetResumenFacturacionTelefoniaQuery, GetResumenFacturacionTelefoniaQueryVariables>;
export const GetDetallesFacturaTelefoniaDocument = gql`
    query getDetallesFacturaTelefonia($desde: Date!, $hasta: Date, $agencia: Int!) {
  getDetallesFacturaTelefonia(desde: $desde, hasta: $hasta, agencia: $agencia)
}
    `;

/**
 * __useGetDetallesFacturaTelefoniaQuery__
 *
 * To run a query within a React component, call `useGetDetallesFacturaTelefoniaQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDetallesFacturaTelefoniaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDetallesFacturaTelefoniaQuery({
 *   variables: {
 *      desde: // value for 'desde'
 *      hasta: // value for 'hasta'
 *      agencia: // value for 'agencia'
 *   },
 * });
 */
export function useGetDetallesFacturaTelefoniaQuery(baseOptions: Apollo.QueryHookOptions<GetDetallesFacturaTelefoniaQuery, GetDetallesFacturaTelefoniaQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDetallesFacturaTelefoniaQuery, GetDetallesFacturaTelefoniaQueryVariables>(GetDetallesFacturaTelefoniaDocument, options);
      }
export function useGetDetallesFacturaTelefoniaLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDetallesFacturaTelefoniaQuery, GetDetallesFacturaTelefoniaQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDetallesFacturaTelefoniaQuery, GetDetallesFacturaTelefoniaQueryVariables>(GetDetallesFacturaTelefoniaDocument, options);
        }
export type GetDetallesFacturaTelefoniaQueryHookResult = ReturnType<typeof useGetDetallesFacturaTelefoniaQuery>;
export type GetDetallesFacturaTelefoniaLazyQueryHookResult = ReturnType<typeof useGetDetallesFacturaTelefoniaLazyQuery>;
export type GetDetallesFacturaTelefoniaQueryResult = Apollo.QueryResult<GetDetallesFacturaTelefoniaQuery, GetDetallesFacturaTelefoniaQueryVariables>;
export const ResumenTerminalesDocument = gql`
    query resumenTerminales($umn_id: [Int]) {
  resumenTerminales(umn_id: $umn_id)
}
    `;

/**
 * __useResumenTerminalesQuery__
 *
 * To run a query within a React component, call `useResumenTerminalesQuery` and pass it any options that fit your needs.
 * When your component renders, `useResumenTerminalesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useResumenTerminalesQuery({
 *   variables: {
 *      umn_id: // value for 'umn_id'
 *   },
 * });
 */
export function useResumenTerminalesQuery(baseOptions?: Apollo.QueryHookOptions<ResumenTerminalesQuery, ResumenTerminalesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ResumenTerminalesQuery, ResumenTerminalesQueryVariables>(ResumenTerminalesDocument, options);
      }
export function useResumenTerminalesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ResumenTerminalesQuery, ResumenTerminalesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ResumenTerminalesQuery, ResumenTerminalesQueryVariables>(ResumenTerminalesDocument, options);
        }
export type ResumenTerminalesQueryHookResult = ReturnType<typeof useResumenTerminalesQuery>;
export type ResumenTerminalesLazyQueryHookResult = ReturnType<typeof useResumenTerminalesLazyQuery>;
export type ResumenTerminalesQueryResult = Apollo.QueryResult<ResumenTerminalesQuery, ResumenTerminalesQueryVariables>;
export const ResumenTerminales2Document = gql`
    query resumenTerminales2($umn_id: [Int]) {
  resumenTerminales2(umn_id: $umn_id)
}
    `;

/**
 * __useResumenTerminales2Query__
 *
 * To run a query within a React component, call `useResumenTerminales2Query` and pass it any options that fit your needs.
 * When your component renders, `useResumenTerminales2Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useResumenTerminales2Query({
 *   variables: {
 *      umn_id: // value for 'umn_id'
 *   },
 * });
 */
export function useResumenTerminales2Query(baseOptions?: Apollo.QueryHookOptions<ResumenTerminales2Query, ResumenTerminales2QueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ResumenTerminales2Query, ResumenTerminales2QueryVariables>(ResumenTerminales2Document, options);
      }
export function useResumenTerminales2LazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ResumenTerminales2Query, ResumenTerminales2QueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ResumenTerminales2Query, ResumenTerminales2QueryVariables>(ResumenTerminales2Document, options);
        }
export type ResumenTerminales2QueryHookResult = ReturnType<typeof useResumenTerminales2Query>;
export type ResumenTerminales2LazyQueryHookResult = ReturnType<typeof useResumenTerminales2LazyQuery>;
export type ResumenTerminales2QueryResult = Apollo.QueryResult<ResumenTerminales2Query, ResumenTerminales2QueryVariables>;
export const ProductoGestionarRapasapditaDocument = gql`
    query productoGestionarRapasapdita($desde: Date, $hasta: Date) {
  resumenTicketsRaspadita(desde: $desde, hasta: $hasta)
}
    `;

/**
 * __useProductoGestionarRapasapditaQuery__
 *
 * To run a query within a React component, call `useProductoGestionarRapasapditaQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductoGestionarRapasapditaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductoGestionarRapasapditaQuery({
 *   variables: {
 *      desde: // value for 'desde'
 *      hasta: // value for 'hasta'
 *   },
 * });
 */
export function useProductoGestionarRapasapditaQuery(baseOptions?: Apollo.QueryHookOptions<ProductoGestionarRapasapditaQuery, ProductoGestionarRapasapditaQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProductoGestionarRapasapditaQuery, ProductoGestionarRapasapditaQueryVariables>(ProductoGestionarRapasapditaDocument, options);
      }
export function useProductoGestionarRapasapditaLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProductoGestionarRapasapditaQuery, ProductoGestionarRapasapditaQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProductoGestionarRapasapditaQuery, ProductoGestionarRapasapditaQueryVariables>(ProductoGestionarRapasapditaDocument, options);
        }
export type ProductoGestionarRapasapditaQueryHookResult = ReturnType<typeof useProductoGestionarRapasapditaQuery>;
export type ProductoGestionarRapasapditaLazyQueryHookResult = ReturnType<typeof useProductoGestionarRapasapditaLazyQuery>;
export type ProductoGestionarRapasapditaQueryResult = Apollo.QueryResult<ProductoGestionarRapasapditaQuery, ProductoGestionarRapasapditaQueryVariables>;
export const ResumenUmnAgenciaDocument = gql`
    query resumenUmnAgencia($umn_id: [ID], $desde: Date!, $hasta: Date!) {
  resumenUmnAgencia(umn_id: $umn_id, desde: $desde, hasta: $hasta)
}
    `;

/**
 * __useResumenUmnAgenciaQuery__
 *
 * To run a query within a React component, call `useResumenUmnAgenciaQuery` and pass it any options that fit your needs.
 * When your component renders, `useResumenUmnAgenciaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useResumenUmnAgenciaQuery({
 *   variables: {
 *      umn_id: // value for 'umn_id'
 *      desde: // value for 'desde'
 *      hasta: // value for 'hasta'
 *   },
 * });
 */
export function useResumenUmnAgenciaQuery(baseOptions: Apollo.QueryHookOptions<ResumenUmnAgenciaQuery, ResumenUmnAgenciaQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ResumenUmnAgenciaQuery, ResumenUmnAgenciaQueryVariables>(ResumenUmnAgenciaDocument, options);
      }
export function useResumenUmnAgenciaLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ResumenUmnAgenciaQuery, ResumenUmnAgenciaQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ResumenUmnAgenciaQuery, ResumenUmnAgenciaQueryVariables>(ResumenUmnAgenciaDocument, options);
        }
export type ResumenUmnAgenciaQueryHookResult = ReturnType<typeof useResumenUmnAgenciaQuery>;
export type ResumenUmnAgenciaLazyQueryHookResult = ReturnType<typeof useResumenUmnAgenciaLazyQuery>;
export type ResumenUmnAgenciaQueryResult = Apollo.QueryResult<ResumenUmnAgenciaQuery, ResumenUmnAgenciaQueryVariables>;
export const AgenciaProductoGestionarDocument = gql`
    query agenciaProductoGestionar($producto_id: ID!, $desde: Date, $hasta: Date, $umn_id: [Int], $agrupar: Boolean) {
  resumenUmnProductoGestionar(
    producto_id: $producto_id
    desde: $desde
    hasta: $hasta
    umn_id: $umn_id
    agrupar: $agrupar
  )
}
    `;

/**
 * __useAgenciaProductoGestionarQuery__
 *
 * To run a query within a React component, call `useAgenciaProductoGestionarQuery` and pass it any options that fit your needs.
 * When your component renders, `useAgenciaProductoGestionarQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAgenciaProductoGestionarQuery({
 *   variables: {
 *      producto_id: // value for 'producto_id'
 *      desde: // value for 'desde'
 *      hasta: // value for 'hasta'
 *      umn_id: // value for 'umn_id'
 *      agrupar: // value for 'agrupar'
 *   },
 * });
 */
export function useAgenciaProductoGestionarQuery(baseOptions: Apollo.QueryHookOptions<AgenciaProductoGestionarQuery, AgenciaProductoGestionarQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AgenciaProductoGestionarQuery, AgenciaProductoGestionarQueryVariables>(AgenciaProductoGestionarDocument, options);
      }
export function useAgenciaProductoGestionarLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AgenciaProductoGestionarQuery, AgenciaProductoGestionarQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AgenciaProductoGestionarQuery, AgenciaProductoGestionarQueryVariables>(AgenciaProductoGestionarDocument, options);
        }
export type AgenciaProductoGestionarQueryHookResult = ReturnType<typeof useAgenciaProductoGestionarQuery>;
export type AgenciaProductoGestionarLazyQueryHookResult = ReturnType<typeof useAgenciaProductoGestionarLazyQuery>;
export type AgenciaProductoGestionarQueryResult = Apollo.QueryResult<AgenciaProductoGestionarQuery, AgenciaProductoGestionarQueryVariables>;
export const AgenciaProductoGestionarGerenciaDocument = gql`
    query agenciaProductoGestionarGerencia($producto_id: ID!, $desde: Date, $hasta: Date, $agencia_numero: [Int], $agrupar: Boolean, $juego_id: [Int]) {
  resumenUmnProductoGestionarGerencia(
    producto_id: $producto_id
    desde: $desde
    hasta: $hasta
    agencia_numero: $agencia_numero
    agrupar: $agrupar
    juego_id: $juego_id
  )
}
    `;

/**
 * __useAgenciaProductoGestionarGerenciaQuery__
 *
 * To run a query within a React component, call `useAgenciaProductoGestionarGerenciaQuery` and pass it any options that fit your needs.
 * When your component renders, `useAgenciaProductoGestionarGerenciaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAgenciaProductoGestionarGerenciaQuery({
 *   variables: {
 *      producto_id: // value for 'producto_id'
 *      desde: // value for 'desde'
 *      hasta: // value for 'hasta'
 *      agencia_numero: // value for 'agencia_numero'
 *      agrupar: // value for 'agrupar'
 *      juego_id: // value for 'juego_id'
 *   },
 * });
 */
export function useAgenciaProductoGestionarGerenciaQuery(baseOptions: Apollo.QueryHookOptions<AgenciaProductoGestionarGerenciaQuery, AgenciaProductoGestionarGerenciaQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AgenciaProductoGestionarGerenciaQuery, AgenciaProductoGestionarGerenciaQueryVariables>(AgenciaProductoGestionarGerenciaDocument, options);
      }
export function useAgenciaProductoGestionarGerenciaLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AgenciaProductoGestionarGerenciaQuery, AgenciaProductoGestionarGerenciaQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AgenciaProductoGestionarGerenciaQuery, AgenciaProductoGestionarGerenciaQueryVariables>(AgenciaProductoGestionarGerenciaDocument, options);
        }
export type AgenciaProductoGestionarGerenciaQueryHookResult = ReturnType<typeof useAgenciaProductoGestionarGerenciaQuery>;
export type AgenciaProductoGestionarGerenciaLazyQueryHookResult = ReturnType<typeof useAgenciaProductoGestionarGerenciaLazyQuery>;
export type AgenciaProductoGestionarGerenciaQueryResult = Apollo.QueryResult<AgenciaProductoGestionarGerenciaQuery, AgenciaProductoGestionarGerenciaQueryVariables>;
export const RtmCorredorDataDocument = gql`
    query rtmCorredorData($corredor_id: ID!) {
  corredor(id: $corredor_id) {
    id
    numero
    cuenta
    linea
    entrega_corredor
    activo
    Umn {
      numero
      agencia_numero
    }
    Cajas {
      linea
      utilizado
      estado
    }
    Usuario {
      Person {
        nombre
        apellido
        nrodoc
        telefono
      }
    }
  }
}
    `;

/**
 * __useRtmCorredorDataQuery__
 *
 * To run a query within a React component, call `useRtmCorredorDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useRtmCorredorDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRtmCorredorDataQuery({
 *   variables: {
 *      corredor_id: // value for 'corredor_id'
 *   },
 * });
 */
export function useRtmCorredorDataQuery(baseOptions: Apollo.QueryHookOptions<RtmCorredorDataQuery, RtmCorredorDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RtmCorredorDataQuery, RtmCorredorDataQueryVariables>(RtmCorredorDataDocument, options);
      }
export function useRtmCorredorDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RtmCorredorDataQuery, RtmCorredorDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RtmCorredorDataQuery, RtmCorredorDataQueryVariables>(RtmCorredorDataDocument, options);
        }
export type RtmCorredorDataQueryHookResult = ReturnType<typeof useRtmCorredorDataQuery>;
export type RtmCorredorDataLazyQueryHookResult = ReturnType<typeof useRtmCorredorDataLazyQuery>;
export type RtmCorredorDataQueryResult = Apollo.QueryResult<RtmCorredorDataQuery, RtmCorredorDataQueryVariables>;
export const RtmExtractoCorredorDocument = gql`
    query rtmExtractoCorredor($corredor_id: ID!) {
  corredorExtracto(corredor_id: $corredor_id) {
    id
    created_at
    detalle
    monto
    cuenta
    cuenta_anterior
    fecha_referencia
    annexable_id
    serial
  }
}
    `;

/**
 * __useRtmExtractoCorredorQuery__
 *
 * To run a query within a React component, call `useRtmExtractoCorredorQuery` and pass it any options that fit your needs.
 * When your component renders, `useRtmExtractoCorredorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRtmExtractoCorredorQuery({
 *   variables: {
 *      corredor_id: // value for 'corredor_id'
 *   },
 * });
 */
export function useRtmExtractoCorredorQuery(baseOptions: Apollo.QueryHookOptions<RtmExtractoCorredorQuery, RtmExtractoCorredorQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RtmExtractoCorredorQuery, RtmExtractoCorredorQueryVariables>(RtmExtractoCorredorDocument, options);
      }
export function useRtmExtractoCorredorLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RtmExtractoCorredorQuery, RtmExtractoCorredorQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RtmExtractoCorredorQuery, RtmExtractoCorredorQueryVariables>(RtmExtractoCorredorDocument, options);
        }
export type RtmExtractoCorredorQueryHookResult = ReturnType<typeof useRtmExtractoCorredorQuery>;
export type RtmExtractoCorredorLazyQueryHookResult = ReturnType<typeof useRtmExtractoCorredorLazyQuery>;
export type RtmExtractoCorredorQueryResult = Apollo.QueryResult<RtmExtractoCorredorQuery, RtmExtractoCorredorQueryVariables>;
export const SherlockModeDocument = gql`
    query sherlockMode($desde: Date!, $hasta: Date, $corredor_numero: ID, $agencia: ID, $sorteo_numero: ID, $order: [ID]) {
  sherlockMode(
    desde: $desde
    hasta: $hasta
    corredor_numero: $corredor_numero
    agencia: $agencia
    sorteo_numero: $sorteo_numero
    order: $order
  )
}
    `;

/**
 * __useSherlockModeQuery__
 *
 * To run a query within a React component, call `useSherlockModeQuery` and pass it any options that fit your needs.
 * When your component renders, `useSherlockModeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSherlockModeQuery({
 *   variables: {
 *      desde: // value for 'desde'
 *      hasta: // value for 'hasta'
 *      corredor_numero: // value for 'corredor_numero'
 *      agencia: // value for 'agencia'
 *      sorteo_numero: // value for 'sorteo_numero'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useSherlockModeQuery(baseOptions: Apollo.QueryHookOptions<SherlockModeQuery, SherlockModeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SherlockModeQuery, SherlockModeQueryVariables>(SherlockModeDocument, options);
      }
export function useSherlockModeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SherlockModeQuery, SherlockModeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SherlockModeQuery, SherlockModeQueryVariables>(SherlockModeDocument, options);
        }
export type SherlockModeQueryHookResult = ReturnType<typeof useSherlockModeQuery>;
export type SherlockModeLazyQueryHookResult = ReturnType<typeof useSherlockModeLazyQuery>;
export type SherlockModeQueryResult = Apollo.QueryResult<SherlockModeQuery, SherlockModeQueryVariables>;
export const TicketsSorteoGerenciaDocument = gql`
    query ticketsSorteoGerencia($matutino: ID!, $vespertino: ID!, $nocturno: ID!, $purete: ID!, $premiados: Boolean) {
  sorteo1: ticketsSorteo(sorteo_id: $matutino, premiados: $premiados) {
    id
    serie
    ticket
    monto_ganado
    monto_apostado
    generado
    Anulacion {
      estado
    }
    Transaccion {
      Caja {
        id
        Corredor {
          numero
          Umn {
            numero
            agencia_numero
          }
          Usuario {
            Person {
              nombre
              apellido
            }
          }
        }
      }
    }
  }
  sorteo2: ticketsSorteo(sorteo_id: $vespertino, premiados: $premiados) {
    id
    serie
    ticket
    monto_ganado
    monto_apostado
    generado
    Anulacion {
      estado
    }
    Transaccion {
      Caja {
        id
        Corredor {
          numero
          Umn {
            numero
            agencia_numero
          }
          Usuario {
            Person {
              nombre
              apellido
            }
          }
        }
      }
    }
  }
  sorteo3: ticketsSorteo(sorteo_id: $nocturno, premiados: $premiados) {
    id
    serie
    ticket
    monto_ganado
    monto_apostado
    generado
    Anulacion {
      estado
    }
    Transaccion {
      Caja {
        id
        Corredor {
          numero
          Umn {
            numero
            agencia_numero
          }
          Usuario {
            Person {
              nombre
              apellido
            }
          }
        }
      }
    }
  }
  sorteo4: ticketsSorteo(sorteo_id: $purete, premiados: $premiados) {
    id
    serie
    ticket
    monto_ganado
    monto_apostado
    generado
    Anulacion {
      estado
    }
    Transaccion {
      Caja {
        id
        Corredor {
          numero
          Umn {
            numero
            agencia_numero
          }
          Usuario {
            Person {
              nombre
              apellido
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useTicketsSorteoGerenciaQuery__
 *
 * To run a query within a React component, call `useTicketsSorteoGerenciaQuery` and pass it any options that fit your needs.
 * When your component renders, `useTicketsSorteoGerenciaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTicketsSorteoGerenciaQuery({
 *   variables: {
 *      matutino: // value for 'matutino'
 *      vespertino: // value for 'vespertino'
 *      nocturno: // value for 'nocturno'
 *      purete: // value for 'purete'
 *      premiados: // value for 'premiados'
 *   },
 * });
 */
export function useTicketsSorteoGerenciaQuery(baseOptions: Apollo.QueryHookOptions<TicketsSorteoGerenciaQuery, TicketsSorteoGerenciaQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TicketsSorteoGerenciaQuery, TicketsSorteoGerenciaQueryVariables>(TicketsSorteoGerenciaDocument, options);
      }
export function useTicketsSorteoGerenciaLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TicketsSorteoGerenciaQuery, TicketsSorteoGerenciaQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TicketsSorteoGerenciaQuery, TicketsSorteoGerenciaQueryVariables>(TicketsSorteoGerenciaDocument, options);
        }
export type TicketsSorteoGerenciaQueryHookResult = ReturnType<typeof useTicketsSorteoGerenciaQuery>;
export type TicketsSorteoGerenciaLazyQueryHookResult = ReturnType<typeof useTicketsSorteoGerenciaLazyQuery>;
export type TicketsSorteoGerenciaQueryResult = Apollo.QueryResult<TicketsSorteoGerenciaQuery, TicketsSorteoGerenciaQueryVariables>;
export const SorteoResultsDocument = gql`
    query sorteoResults($sorteo_id: ID!) {
  sorteoResults(sorteo_id: $sorteo_id) {
    SorteoBase {
      nombre
      numero
    }
    fecha
    apertura
    cierre
    transmision
    resultados
  }
}
    `;

/**
 * __useSorteoResultsQuery__
 *
 * To run a query within a React component, call `useSorteoResultsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSorteoResultsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSorteoResultsQuery({
 *   variables: {
 *      sorteo_id: // value for 'sorteo_id'
 *   },
 * });
 */
export function useSorteoResultsQuery(baseOptions: Apollo.QueryHookOptions<SorteoResultsQuery, SorteoResultsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SorteoResultsQuery, SorteoResultsQueryVariables>(SorteoResultsDocument, options);
      }
export function useSorteoResultsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SorteoResultsQuery, SorteoResultsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SorteoResultsQuery, SorteoResultsQueryVariables>(SorteoResultsDocument, options);
        }
export type SorteoResultsQueryHookResult = ReturnType<typeof useSorteoResultsQuery>;
export type SorteoResultsLazyQueryHookResult = ReturnType<typeof useSorteoResultsLazyQuery>;
export type SorteoResultsQueryResult = Apollo.QueryResult<SorteoResultsQuery, SorteoResultsQueryVariables>;
export const SorteoPremiadosDocument = gql`
    query sorteoPremiados($sorteo_id: ID!, $umn_id: [ID]) {
  ticketsSorteo(sorteo_id: $sorteo_id, premiados: true, umn_id: $umn_id) {
    id
    serie
    ticket
    monto_ganado
    monto_apostado
    generado
    Transaccion {
      Caja {
        Corredor {
          numero
          Umn {
            numero
            agencia_numero
          }
          Usuario {
            Person {
              nombre
              apellido
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useSorteoPremiadosQuery__
 *
 * To run a query within a React component, call `useSorteoPremiadosQuery` and pass it any options that fit your needs.
 * When your component renders, `useSorteoPremiadosQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSorteoPremiadosQuery({
 *   variables: {
 *      sorteo_id: // value for 'sorteo_id'
 *      umn_id: // value for 'umn_id'
 *   },
 * });
 */
export function useSorteoPremiadosQuery(baseOptions: Apollo.QueryHookOptions<SorteoPremiadosQuery, SorteoPremiadosQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SorteoPremiadosQuery, SorteoPremiadosQueryVariables>(SorteoPremiadosDocument, options);
      }
export function useSorteoPremiadosLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SorteoPremiadosQuery, SorteoPremiadosQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SorteoPremiadosQuery, SorteoPremiadosQueryVariables>(SorteoPremiadosDocument, options);
        }
export type SorteoPremiadosQueryHookResult = ReturnType<typeof useSorteoPremiadosQuery>;
export type SorteoPremiadosLazyQueryHookResult = ReturnType<typeof useSorteoPremiadosLazyQuery>;
export type SorteoPremiadosQueryResult = Apollo.QueryResult<SorteoPremiadosQuery, SorteoPremiadosQueryVariables>;
export const SorteosDocument = gql`
    query sorteos {
  sorteos {
    fecha
    id
    SorteoBase {
      nombre
      numero
    }
    apertura
    cierre
    transmision
    resultados
    liquidado
    pendientes
  }
}
    `;

/**
 * __useSorteosQuery__
 *
 * To run a query within a React component, call `useSorteosQuery` and pass it any options that fit your needs.
 * When your component renders, `useSorteosQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSorteosQuery({
 *   variables: {
 *   },
 * });
 */
export function useSorteosQuery(baseOptions?: Apollo.QueryHookOptions<SorteosQuery, SorteosQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SorteosQuery, SorteosQueryVariables>(SorteosDocument, options);
      }
export function useSorteosLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SorteosQuery, SorteosQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SorteosQuery, SorteosQueryVariables>(SorteosDocument, options);
        }
export type SorteosQueryHookResult = ReturnType<typeof useSorteosQuery>;
export type SorteosLazyQueryHookResult = ReturnType<typeof useSorteosLazyQuery>;
export type SorteosQueryResult = Apollo.QueryResult<SorteosQuery, SorteosQueryVariables>;
export const SorteosProgramaDocument = gql`
    query sorteosPrograma {
  sorteosPrograma {
    id
    dia
    apertura
    cierre
    transmision
    status
    SorteoBase {
      nombre
      numero
    }
  }
}
    `;

/**
 * __useSorteosProgramaQuery__
 *
 * To run a query within a React component, call `useSorteosProgramaQuery` and pass it any options that fit your needs.
 * When your component renders, `useSorteosProgramaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSorteosProgramaQuery({
 *   variables: {
 *   },
 * });
 */
export function useSorteosProgramaQuery(baseOptions?: Apollo.QueryHookOptions<SorteosProgramaQuery, SorteosProgramaQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SorteosProgramaQuery, SorteosProgramaQueryVariables>(SorteosProgramaDocument, options);
      }
export function useSorteosProgramaLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SorteosProgramaQuery, SorteosProgramaQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SorteosProgramaQuery, SorteosProgramaQueryVariables>(SorteosProgramaDocument, options);
        }
export type SorteosProgramaQueryHookResult = ReturnType<typeof useSorteosProgramaQuery>;
export type SorteosProgramaLazyQueryHookResult = ReturnType<typeof useSorteosProgramaLazyQuery>;
export type SorteosProgramaQueryResult = Apollo.QueryResult<SorteosProgramaQuery, SorteosProgramaQueryVariables>;
export const SorteosfechaDocument = gql`
    query sorteosfecha($desde: Date!, $hasta: Date) {
  currentSorteos(desde: $desde, hasta: $hasta) {
    SorteoBase {
      nombre
      numero
    }
    resultados
    id
  }
}
    `;

/**
 * __useSorteosfechaQuery__
 *
 * To run a query within a React component, call `useSorteosfechaQuery` and pass it any options that fit your needs.
 * When your component renders, `useSorteosfechaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSorteosfechaQuery({
 *   variables: {
 *      desde: // value for 'desde'
 *      hasta: // value for 'hasta'
 *   },
 * });
 */
export function useSorteosfechaQuery(baseOptions: Apollo.QueryHookOptions<SorteosfechaQuery, SorteosfechaQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SorteosfechaQuery, SorteosfechaQueryVariables>(SorteosfechaDocument, options);
      }
export function useSorteosfechaLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SorteosfechaQuery, SorteosfechaQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SorteosfechaQuery, SorteosfechaQueryVariables>(SorteosfechaDocument, options);
        }
export type SorteosfechaQueryHookResult = ReturnType<typeof useSorteosfechaQuery>;
export type SorteosfechaLazyQueryHookResult = ReturnType<typeof useSorteosfechaLazyQuery>;
export type SorteosfechaQueryResult = Apollo.QueryResult<SorteosfechaQuery, SorteosfechaQueryVariables>;
export const TerminalesDocument = gql`
    query terminales($umn_id: [ID]) {
  terminales(umn_id: $umn_id) {
    id
    serial
  }
}
    `;

/**
 * __useTerminalesQuery__
 *
 * To run a query within a React component, call `useTerminalesQuery` and pass it any options that fit your needs.
 * When your component renders, `useTerminalesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTerminalesQuery({
 *   variables: {
 *      umn_id: // value for 'umn_id'
 *   },
 * });
 */
export function useTerminalesQuery(baseOptions?: Apollo.QueryHookOptions<TerminalesQuery, TerminalesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TerminalesQuery, TerminalesQueryVariables>(TerminalesDocument, options);
      }
export function useTerminalesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TerminalesQuery, TerminalesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TerminalesQuery, TerminalesQueryVariables>(TerminalesDocument, options);
        }
export type TerminalesQueryHookResult = ReturnType<typeof useTerminalesQuery>;
export type TerminalesLazyQueryHookResult = ReturnType<typeof useTerminalesLazyQuery>;
export type TerminalesQueryResult = Apollo.QueryResult<TerminalesQuery, TerminalesQueryVariables>;
export const TerminalesUmnDocument = gql`
    query terminalesUmn($umn_id: [ID!], $nro_corredor: Int, $serial: String) {
  terminalesUmn(umn_id: $umn_id, nro_corredor: $nro_corredor, serial: $serial)
}
    `;

/**
 * __useTerminalesUmnQuery__
 *
 * To run a query within a React component, call `useTerminalesUmnQuery` and pass it any options that fit your needs.
 * When your component renders, `useTerminalesUmnQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTerminalesUmnQuery({
 *   variables: {
 *      umn_id: // value for 'umn_id'
 *      nro_corredor: // value for 'nro_corredor'
 *      serial: // value for 'serial'
 *   },
 * });
 */
export function useTerminalesUmnQuery(baseOptions?: Apollo.QueryHookOptions<TerminalesUmnQuery, TerminalesUmnQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TerminalesUmnQuery, TerminalesUmnQueryVariables>(TerminalesUmnDocument, options);
      }
export function useTerminalesUmnLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TerminalesUmnQuery, TerminalesUmnQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TerminalesUmnQuery, TerminalesUmnQueryVariables>(TerminalesUmnDocument, options);
        }
export type TerminalesUmnQueryHookResult = ReturnType<typeof useTerminalesUmnQuery>;
export type TerminalesUmnLazyQueryHookResult = ReturnType<typeof useTerminalesUmnLazyQuery>;
export type TerminalesUmnQueryResult = Apollo.QueryResult<TerminalesUmnQuery, TerminalesUmnQueryVariables>;
export const TicketsByCorredorDocument = gql`
    query ticketsByCorredor($desde: Date, $hasta: Date, $corredor_id: Int, $serie: String, $sorteo_id: [Int], $ticket: Int, $all: Boolean) {
  ticketCorredor(
    desde: $desde
    hasta: $hasta
    corredor_id: $corredor_id
    serie: $serie
    sorteo_id: $sorteo_id
    ticket: $ticket
    all: $all
  )
}
    `;

/**
 * __useTicketsByCorredorQuery__
 *
 * To run a query within a React component, call `useTicketsByCorredorQuery` and pass it any options that fit your needs.
 * When your component renders, `useTicketsByCorredorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTicketsByCorredorQuery({
 *   variables: {
 *      desde: // value for 'desde'
 *      hasta: // value for 'hasta'
 *      corredor_id: // value for 'corredor_id'
 *      serie: // value for 'serie'
 *      sorteo_id: // value for 'sorteo_id'
 *      ticket: // value for 'ticket'
 *      all: // value for 'all'
 *   },
 * });
 */
export function useTicketsByCorredorQuery(baseOptions?: Apollo.QueryHookOptions<TicketsByCorredorQuery, TicketsByCorredorQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TicketsByCorredorQuery, TicketsByCorredorQueryVariables>(TicketsByCorredorDocument, options);
      }
export function useTicketsByCorredorLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TicketsByCorredorQuery, TicketsByCorredorQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TicketsByCorredorQuery, TicketsByCorredorQueryVariables>(TicketsByCorredorDocument, options);
        }
export type TicketsByCorredorQueryHookResult = ReturnType<typeof useTicketsByCorredorQuery>;
export type TicketsByCorredorLazyQueryHookResult = ReturnType<typeof useTicketsByCorredorLazyQuery>;
export type TicketsByCorredorQueryResult = Apollo.QueryResult<TicketsByCorredorQuery, TicketsByCorredorQueryVariables>;
export const TicketQuinielaDocument = gql`
    query ticketQuiniela($juego_id: ID!) {
  ticketQuiniela(juego_id: $juego_id) {
    id
    serie
    ticket
    monto_ganado
    monto_apostado
    generado
    numero_sorteado
    Transaccion {
      Caja {
        id
        Corredor {
          numero
          Umn {
            numero
            agencia_numero
          }
          Usuario {
            Person {
              nombre
              apellido
            }
          }
        }
      }
    }
    Sorteo {
      resultados
      id
      SorteoBase {
        nombre
        numero
      }
    }
    jugadas {
      id
      tipo
      numero
      postura
      monto_apostado
      monto_ganado
    }
  }
}
    `;

/**
 * __useTicketQuinielaQuery__
 *
 * To run a query within a React component, call `useTicketQuinielaQuery` and pass it any options that fit your needs.
 * When your component renders, `useTicketQuinielaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTicketQuinielaQuery({
 *   variables: {
 *      juego_id: // value for 'juego_id'
 *   },
 * });
 */
export function useTicketQuinielaQuery(baseOptions: Apollo.QueryHookOptions<TicketQuinielaQuery, TicketQuinielaQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TicketQuinielaQuery, TicketQuinielaQueryVariables>(TicketQuinielaDocument, options);
      }
export function useTicketQuinielaLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TicketQuinielaQuery, TicketQuinielaQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TicketQuinielaQuery, TicketQuinielaQueryVariables>(TicketQuinielaDocument, options);
        }
export type TicketQuinielaQueryHookResult = ReturnType<typeof useTicketQuinielaQuery>;
export type TicketQuinielaLazyQueryHookResult = ReturnType<typeof useTicketQuinielaLazyQuery>;
export type TicketQuinielaQueryResult = Apollo.QueryResult<TicketQuinielaQuery, TicketQuinielaQueryVariables>;
export const TicketsSorteoDocument = gql`
    query ticketsSorteo($sorteo_id: ID!, $premiados: Boolean, $serie: String, $ticket: Float, $monto: Int, $caja_id: Int, $umn_id: [ID], $pendientes: Boolean) {
  ticketsSorteo(
    sorteo_id: $sorteo_id
    premiados: $premiados
    serie: $serie
    ticket: $ticket
    monto: $monto
    caja_id: $caja_id
    umn_id: $umn_id
    pendientes: $pendientes
  ) {
    id
    serie
    ticket
    monto_ganado
    monto_apostado
    generado
    created_at
    Anulacion {
      estado
    }
    Transaccion {
      Caja {
        id
        Corredor {
          numero
          Umn {
            numero
            agencia_numero
          }
          Usuario {
            Person {
              nombre
              apellido
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useTicketsSorteoQuery__
 *
 * To run a query within a React component, call `useTicketsSorteoQuery` and pass it any options that fit your needs.
 * When your component renders, `useTicketsSorteoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTicketsSorteoQuery({
 *   variables: {
 *      sorteo_id: // value for 'sorteo_id'
 *      premiados: // value for 'premiados'
 *      serie: // value for 'serie'
 *      ticket: // value for 'ticket'
 *      monto: // value for 'monto'
 *      caja_id: // value for 'caja_id'
 *      umn_id: // value for 'umn_id'
 *      pendientes: // value for 'pendientes'
 *   },
 * });
 */
export function useTicketsSorteoQuery(baseOptions: Apollo.QueryHookOptions<TicketsSorteoQuery, TicketsSorteoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TicketsSorteoQuery, TicketsSorteoQueryVariables>(TicketsSorteoDocument, options);
      }
export function useTicketsSorteoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TicketsSorteoQuery, TicketsSorteoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TicketsSorteoQuery, TicketsSorteoQueryVariables>(TicketsSorteoDocument, options);
        }
export type TicketsSorteoQueryHookResult = ReturnType<typeof useTicketsSorteoQuery>;
export type TicketsSorteoLazyQueryHookResult = ReturnType<typeof useTicketsSorteoLazyQuery>;
export type TicketsSorteoQueryResult = Apollo.QueryResult<TicketsSorteoQuery, TicketsSorteoQueryVariables>;
export const AgenciaProductoDocument = gql`
    query agenciaProducto($producto_id: ID!, $desde: Date, $hasta: Date) {
  resumenUmnProducto(producto_id: $producto_id, desde: $desde, hasta: $hasta)
}
    `;

/**
 * __useAgenciaProductoQuery__
 *
 * To run a query within a React component, call `useAgenciaProductoQuery` and pass it any options that fit your needs.
 * When your component renders, `useAgenciaProductoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAgenciaProductoQuery({
 *   variables: {
 *      producto_id: // value for 'producto_id'
 *      desde: // value for 'desde'
 *      hasta: // value for 'hasta'
 *   },
 * });
 */
export function useAgenciaProductoQuery(baseOptions: Apollo.QueryHookOptions<AgenciaProductoQuery, AgenciaProductoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AgenciaProductoQuery, AgenciaProductoQueryVariables>(AgenciaProductoDocument, options);
      }
export function useAgenciaProductoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AgenciaProductoQuery, AgenciaProductoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AgenciaProductoQuery, AgenciaProductoQueryVariables>(AgenciaProductoDocument, options);
        }
export type AgenciaProductoQueryHookResult = ReturnType<typeof useAgenciaProductoQuery>;
export type AgenciaProductoLazyQueryHookResult = ReturnType<typeof useAgenciaProductoLazyQuery>;
export type AgenciaProductoQueryResult = Apollo.QueryResult<AgenciaProductoQuery, AgenciaProductoQueryVariables>;
export const ResumenUmnGerenciaUmnDocument = gql`
    query resumenUmnGerenciaUmn($agencia: [Int], $desde: Date!, $hasta: Date!, $corredor_numero: Int) {
  resumenUmnGerenciaUmn(
    agencia: $agencia
    desde: $desde
    hasta: $hasta
    corredor_numero: $corredor_numero
  )
}
    `;

/**
 * __useResumenUmnGerenciaUmnQuery__
 *
 * To run a query within a React component, call `useResumenUmnGerenciaUmnQuery` and pass it any options that fit your needs.
 * When your component renders, `useResumenUmnGerenciaUmnQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useResumenUmnGerenciaUmnQuery({
 *   variables: {
 *      agencia: // value for 'agencia'
 *      desde: // value for 'desde'
 *      hasta: // value for 'hasta'
 *      corredor_numero: // value for 'corredor_numero'
 *   },
 * });
 */
export function useResumenUmnGerenciaUmnQuery(baseOptions: Apollo.QueryHookOptions<ResumenUmnGerenciaUmnQuery, ResumenUmnGerenciaUmnQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ResumenUmnGerenciaUmnQuery, ResumenUmnGerenciaUmnQueryVariables>(ResumenUmnGerenciaUmnDocument, options);
      }
export function useResumenUmnGerenciaUmnLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ResumenUmnGerenciaUmnQuery, ResumenUmnGerenciaUmnQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ResumenUmnGerenciaUmnQuery, ResumenUmnGerenciaUmnQueryVariables>(ResumenUmnGerenciaUmnDocument, options);
        }
export type ResumenUmnGerenciaUmnQueryHookResult = ReturnType<typeof useResumenUmnGerenciaUmnQuery>;
export type ResumenUmnGerenciaUmnLazyQueryHookResult = ReturnType<typeof useResumenUmnGerenciaUmnLazyQuery>;
export type ResumenUmnGerenciaUmnQueryResult = Apollo.QueryResult<ResumenUmnGerenciaUmnQuery, ResumenUmnGerenciaUmnQueryVariables>;
export const ResumenUmnGerenciaProductoDocument = gql`
    query resumenUmnGerenciaProducto($agencia: [Int], $desde: Date!, $hasta: Date!, $corredor_numero: Int, $sorteo: [Int], $generado: Boolean) {
  resumenUmnGerenciaProducto(
    agencia: $agencia
    desde: $desde
    hasta: $hasta
    corredor_numero: $corredor_numero
    sorteo: $sorteo
    generado: $generado
  )
}
    `;

/**
 * __useResumenUmnGerenciaProductoQuery__
 *
 * To run a query within a React component, call `useResumenUmnGerenciaProductoQuery` and pass it any options that fit your needs.
 * When your component renders, `useResumenUmnGerenciaProductoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useResumenUmnGerenciaProductoQuery({
 *   variables: {
 *      agencia: // value for 'agencia'
 *      desde: // value for 'desde'
 *      hasta: // value for 'hasta'
 *      corredor_numero: // value for 'corredor_numero'
 *      sorteo: // value for 'sorteo'
 *      generado: // value for 'generado'
 *   },
 * });
 */
export function useResumenUmnGerenciaProductoQuery(baseOptions: Apollo.QueryHookOptions<ResumenUmnGerenciaProductoQuery, ResumenUmnGerenciaProductoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ResumenUmnGerenciaProductoQuery, ResumenUmnGerenciaProductoQueryVariables>(ResumenUmnGerenciaProductoDocument, options);
      }
export function useResumenUmnGerenciaProductoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ResumenUmnGerenciaProductoQuery, ResumenUmnGerenciaProductoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ResumenUmnGerenciaProductoQuery, ResumenUmnGerenciaProductoQueryVariables>(ResumenUmnGerenciaProductoDocument, options);
        }
export type ResumenUmnGerenciaProductoQueryHookResult = ReturnType<typeof useResumenUmnGerenciaProductoQuery>;
export type ResumenUmnGerenciaProductoLazyQueryHookResult = ReturnType<typeof useResumenUmnGerenciaProductoLazyQuery>;
export type ResumenUmnGerenciaProductoQueryResult = Apollo.QueryResult<ResumenUmnGerenciaProductoQuery, ResumenUmnGerenciaProductoQueryVariables>;
export const GetAgencyByUmnDocument = gql`
    query getAgencyByUmn($id: [ID]) {
  umn(id: $id) {
    agencia_numero
    numero
    nombre
    zona
  }
}
    `;

/**
 * __useGetAgencyByUmnQuery__
 *
 * To run a query within a React component, call `useGetAgencyByUmnQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAgencyByUmnQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAgencyByUmnQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetAgencyByUmnQuery(baseOptions?: Apollo.QueryHookOptions<GetAgencyByUmnQuery, GetAgencyByUmnQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAgencyByUmnQuery, GetAgencyByUmnQueryVariables>(GetAgencyByUmnDocument, options);
      }
export function useGetAgencyByUmnLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAgencyByUmnQuery, GetAgencyByUmnQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAgencyByUmnQuery, GetAgencyByUmnQueryVariables>(GetAgencyByUmnDocument, options);
        }
export type GetAgencyByUmnQueryHookResult = ReturnType<typeof useGetAgencyByUmnQuery>;
export type GetAgencyByUmnLazyQueryHookResult = ReturnType<typeof useGetAgencyByUmnLazyQuery>;
export type GetAgencyByUmnQueryResult = Apollo.QueryResult<GetAgencyByUmnQuery, GetAgencyByUmnQueryVariables>;
export const UsuarioListDocument = gql`
    query usuarioList($limit: Int, $offset: Int, $nrodoc: String, $role: String, $umn_id: [ID], $nro_corredor: Int) {
  usuarios(
    offset: $offset
    limit: $limit
    nrodoc: $nrodoc
    role: $role
    umn_id: $umn_id
    nro_corredor: $nro_corredor
  ) {
    id
    username
    password
    pin
    Permisos {
      Role {
        id
        name
      }
    }
    Person {
      nombre
      apellido
      nrodoc
      telefono
    }
    Corredores {
      numero
      id
    }
    Umns {
      id
      numero
      nombre
      agencia_numero
    }
    Cobradores {
      numero
    }
  }
}
    `;

/**
 * __useUsuarioListQuery__
 *
 * To run a query within a React component, call `useUsuarioListQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsuarioListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsuarioListQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      nrodoc: // value for 'nrodoc'
 *      role: // value for 'role'
 *      umn_id: // value for 'umn_id'
 *      nro_corredor: // value for 'nro_corredor'
 *   },
 * });
 */
export function useUsuarioListQuery(baseOptions?: Apollo.QueryHookOptions<UsuarioListQuery, UsuarioListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UsuarioListQuery, UsuarioListQueryVariables>(UsuarioListDocument, options);
      }
export function useUsuarioListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UsuarioListQuery, UsuarioListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UsuarioListQuery, UsuarioListQueryVariables>(UsuarioListDocument, options);
        }
export type UsuarioListQueryHookResult = ReturnType<typeof useUsuarioListQuery>;
export type UsuarioListLazyQueryHookResult = ReturnType<typeof useUsuarioListLazyQuery>;
export type UsuarioListQueryResult = Apollo.QueryResult<UsuarioListQuery, UsuarioListQueryVariables>;
export const UsoTerminalDocument = gql`
    query usoTerminal($terminal_id: ID!) {
  terminal(terminal_id: $terminal_id) {
    id
    serial
  }
  usoTerminal(terminal_id: $terminal_id) {
    id
    apertura
    cierre
    Corredor {
      numero
      Umn {
        numero
        agencia_numero
      }
      Usuario {
        Person {
          nombre
          apellido
        }
      }
    }
  }
}
    `;

/**
 * __useUsoTerminalQuery__
 *
 * To run a query within a React component, call `useUsoTerminalQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsoTerminalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsoTerminalQuery({
 *   variables: {
 *      terminal_id: // value for 'terminal_id'
 *   },
 * });
 */
export function useUsoTerminalQuery(baseOptions: Apollo.QueryHookOptions<UsoTerminalQuery, UsoTerminalQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UsoTerminalQuery, UsoTerminalQueryVariables>(UsoTerminalDocument, options);
      }
export function useUsoTerminalLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UsoTerminalQuery, UsoTerminalQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UsoTerminalQuery, UsoTerminalQueryVariables>(UsoTerminalDocument, options);
        }
export type UsoTerminalQueryHookResult = ReturnType<typeof useUsoTerminalQuery>;
export type UsoTerminalLazyQueryHookResult = ReturnType<typeof useUsoTerminalLazyQuery>;
export type UsoTerminalQueryResult = Apollo.QueryResult<UsoTerminalQuery, UsoTerminalQueryVariables>;
export const RaspaditaDetallesCorredorPorAgenciaDocument = gql`
    query raspaditaDetallesCorredorPorAgencia($desde: Date, $hasta: Date, $agencia: Int) {
  raspaditaDetallesCorredorPorAgencia(
    desde: $desde
    hasta: $hasta
    agencia: $agencia
  )
}
    `;

/**
 * __useRaspaditaDetallesCorredorPorAgenciaQuery__
 *
 * To run a query within a React component, call `useRaspaditaDetallesCorredorPorAgenciaQuery` and pass it any options that fit your needs.
 * When your component renders, `useRaspaditaDetallesCorredorPorAgenciaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRaspaditaDetallesCorredorPorAgenciaQuery({
 *   variables: {
 *      desde: // value for 'desde'
 *      hasta: // value for 'hasta'
 *      agencia: // value for 'agencia'
 *   },
 * });
 */
export function useRaspaditaDetallesCorredorPorAgenciaQuery(baseOptions?: Apollo.QueryHookOptions<RaspaditaDetallesCorredorPorAgenciaQuery, RaspaditaDetallesCorredorPorAgenciaQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RaspaditaDetallesCorredorPorAgenciaQuery, RaspaditaDetallesCorredorPorAgenciaQueryVariables>(RaspaditaDetallesCorredorPorAgenciaDocument, options);
      }
export function useRaspaditaDetallesCorredorPorAgenciaLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RaspaditaDetallesCorredorPorAgenciaQuery, RaspaditaDetallesCorredorPorAgenciaQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RaspaditaDetallesCorredorPorAgenciaQuery, RaspaditaDetallesCorredorPorAgenciaQueryVariables>(RaspaditaDetallesCorredorPorAgenciaDocument, options);
        }
export type RaspaditaDetallesCorredorPorAgenciaQueryHookResult = ReturnType<typeof useRaspaditaDetallesCorredorPorAgenciaQuery>;
export type RaspaditaDetallesCorredorPorAgenciaLazyQueryHookResult = ReturnType<typeof useRaspaditaDetallesCorredorPorAgenciaLazyQuery>;
export type RaspaditaDetallesCorredorPorAgenciaQueryResult = Apollo.QueryResult<RaspaditaDetallesCorredorPorAgenciaQuery, RaspaditaDetallesCorredorPorAgenciaQueryVariables>;
export const AjusteUmnFinanzasDocument = gql`
    query ajusteUmnFinanzas($desde: Date!, $hasta: Date!, $umn_id: [ID]) {
  verAjustesUmnFinanzas(desde: $desde, hasta: $hasta, umn_id: $umn_id)
}
    `;

/**
 * __useAjusteUmnFinanzasQuery__
 *
 * To run a query within a React component, call `useAjusteUmnFinanzasQuery` and pass it any options that fit your needs.
 * When your component renders, `useAjusteUmnFinanzasQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAjusteUmnFinanzasQuery({
 *   variables: {
 *      desde: // value for 'desde'
 *      hasta: // value for 'hasta'
 *      umn_id: // value for 'umn_id'
 *   },
 * });
 */
export function useAjusteUmnFinanzasQuery(baseOptions: Apollo.QueryHookOptions<AjusteUmnFinanzasQuery, AjusteUmnFinanzasQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AjusteUmnFinanzasQuery, AjusteUmnFinanzasQueryVariables>(AjusteUmnFinanzasDocument, options);
      }
export function useAjusteUmnFinanzasLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AjusteUmnFinanzasQuery, AjusteUmnFinanzasQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AjusteUmnFinanzasQuery, AjusteUmnFinanzasQueryVariables>(AjusteUmnFinanzasDocument, options);
        }
export type AjusteUmnFinanzasQueryHookResult = ReturnType<typeof useAjusteUmnFinanzasQuery>;
export type AjusteUmnFinanzasLazyQueryHookResult = ReturnType<typeof useAjusteUmnFinanzasLazyQuery>;
export type AjusteUmnFinanzasQueryResult = Apollo.QueryResult<AjusteUmnFinanzasQuery, AjusteUmnFinanzasQueryVariables>;
export const VerConsolidacionesDocument = gql`
    query verConsolidaciones($umn_id: [ID], $desde: Date!, $hasta: Date) {
  verConsolidaciones(umn_id: $umn_id, desde: $desde, hasta: $hasta) {
    id
    agencia_id
    umn_id
    monto
    comision
    premio
    reclamos
    recuperacion
    ocasional
    multas
    monto_retrasadas
    comision_retrasadas
    premios_retrasadas
    cantidad_boletas
    cantidad_corredores
    saldo_anterior
    depositos
    neto
    umn
    fecha_movimiento
    saldo_a_depositar
  }
}
    `;

/**
 * __useVerConsolidacionesQuery__
 *
 * To run a query within a React component, call `useVerConsolidacionesQuery` and pass it any options that fit your needs.
 * When your component renders, `useVerConsolidacionesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVerConsolidacionesQuery({
 *   variables: {
 *      umn_id: // value for 'umn_id'
 *      desde: // value for 'desde'
 *      hasta: // value for 'hasta'
 *   },
 * });
 */
export function useVerConsolidacionesQuery(baseOptions: Apollo.QueryHookOptions<VerConsolidacionesQuery, VerConsolidacionesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<VerConsolidacionesQuery, VerConsolidacionesQueryVariables>(VerConsolidacionesDocument, options);
      }
export function useVerConsolidacionesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<VerConsolidacionesQuery, VerConsolidacionesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<VerConsolidacionesQuery, VerConsolidacionesQueryVariables>(VerConsolidacionesDocument, options);
        }
export type VerConsolidacionesQueryHookResult = ReturnType<typeof useVerConsolidacionesQuery>;
export type VerConsolidacionesLazyQueryHookResult = ReturnType<typeof useVerConsolidacionesLazyQuery>;
export type VerConsolidacionesQueryResult = Apollo.QueryResult<VerConsolidacionesQuery, VerConsolidacionesQueryVariables>;
export const VerConsolidacionesAgenciaDocument = gql`
    query verConsolidacionesAgencia($agencia: [Int], $desde: Date!, $hasta: Date) {
  verConsolidacionesAgencia(agencia: $agencia, desde: $desde, hasta: $hasta)
}
    `;

/**
 * __useVerConsolidacionesAgenciaQuery__
 *
 * To run a query within a React component, call `useVerConsolidacionesAgenciaQuery` and pass it any options that fit your needs.
 * When your component renders, `useVerConsolidacionesAgenciaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVerConsolidacionesAgenciaQuery({
 *   variables: {
 *      agencia: // value for 'agencia'
 *      desde: // value for 'desde'
 *      hasta: // value for 'hasta'
 *   },
 * });
 */
export function useVerConsolidacionesAgenciaQuery(baseOptions: Apollo.QueryHookOptions<VerConsolidacionesAgenciaQuery, VerConsolidacionesAgenciaQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<VerConsolidacionesAgenciaQuery, VerConsolidacionesAgenciaQueryVariables>(VerConsolidacionesAgenciaDocument, options);
      }
export function useVerConsolidacionesAgenciaLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<VerConsolidacionesAgenciaQuery, VerConsolidacionesAgenciaQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<VerConsolidacionesAgenciaQuery, VerConsolidacionesAgenciaQueryVariables>(VerConsolidacionesAgenciaDocument, options);
        }
export type VerConsolidacionesAgenciaQueryHookResult = ReturnType<typeof useVerConsolidacionesAgenciaQuery>;
export type VerConsolidacionesAgenciaLazyQueryHookResult = ReturnType<typeof useVerConsolidacionesAgenciaLazyQuery>;
export type VerConsolidacionesAgenciaQueryResult = Apollo.QueryResult<VerConsolidacionesAgenciaQuery, VerConsolidacionesAgenciaQueryVariables>;
export const VerFinanzasGerenciaDocument = gql`
    query verFinanzasGerencia($agencia: [Int], $desde: Date!, $hasta: Date) {
  verConsolidacionesGerencia(agencia: $agencia, desde: $desde, hasta: $hasta)
}
    `;

/**
 * __useVerFinanzasGerenciaQuery__
 *
 * To run a query within a React component, call `useVerFinanzasGerenciaQuery` and pass it any options that fit your needs.
 * When your component renders, `useVerFinanzasGerenciaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVerFinanzasGerenciaQuery({
 *   variables: {
 *      agencia: // value for 'agencia'
 *      desde: // value for 'desde'
 *      hasta: // value for 'hasta'
 *   },
 * });
 */
export function useVerFinanzasGerenciaQuery(baseOptions: Apollo.QueryHookOptions<VerFinanzasGerenciaQuery, VerFinanzasGerenciaQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<VerFinanzasGerenciaQuery, VerFinanzasGerenciaQueryVariables>(VerFinanzasGerenciaDocument, options);
      }
export function useVerFinanzasGerenciaLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<VerFinanzasGerenciaQuery, VerFinanzasGerenciaQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<VerFinanzasGerenciaQuery, VerFinanzasGerenciaQueryVariables>(VerFinanzasGerenciaDocument, options);
        }
export type VerFinanzasGerenciaQueryHookResult = ReturnType<typeof useVerFinanzasGerenciaQuery>;
export type VerFinanzasGerenciaLazyQueryHookResult = ReturnType<typeof useVerFinanzasGerenciaLazyQuery>;
export type VerFinanzasGerenciaQueryResult = Apollo.QueryResult<VerFinanzasGerenciaQuery, VerFinanzasGerenciaQueryVariables>;
export const VerFinanzasGerenciaDetallesDocument = gql`
    query verFinanzasGerenciaDetalles($agencia: [Int], $desde: Date!, $hasta: Date) {
  verConsolidacionesAgenciaGerencia(
    desde: $desde
    hasta: $hasta
    agencia: $agencia
  )
}
    `;

/**
 * __useVerFinanzasGerenciaDetallesQuery__
 *
 * To run a query within a React component, call `useVerFinanzasGerenciaDetallesQuery` and pass it any options that fit your needs.
 * When your component renders, `useVerFinanzasGerenciaDetallesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVerFinanzasGerenciaDetallesQuery({
 *   variables: {
 *      agencia: // value for 'agencia'
 *      desde: // value for 'desde'
 *      hasta: // value for 'hasta'
 *   },
 * });
 */
export function useVerFinanzasGerenciaDetallesQuery(baseOptions: Apollo.QueryHookOptions<VerFinanzasGerenciaDetallesQuery, VerFinanzasGerenciaDetallesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<VerFinanzasGerenciaDetallesQuery, VerFinanzasGerenciaDetallesQueryVariables>(VerFinanzasGerenciaDetallesDocument, options);
      }
export function useVerFinanzasGerenciaDetallesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<VerFinanzasGerenciaDetallesQuery, VerFinanzasGerenciaDetallesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<VerFinanzasGerenciaDetallesQuery, VerFinanzasGerenciaDetallesQueryVariables>(VerFinanzasGerenciaDetallesDocument, options);
        }
export type VerFinanzasGerenciaDetallesQueryHookResult = ReturnType<typeof useVerFinanzasGerenciaDetallesQuery>;
export type VerFinanzasGerenciaDetallesLazyQueryHookResult = ReturnType<typeof useVerFinanzasGerenciaDetallesLazyQuery>;
export type VerFinanzasGerenciaDetallesQueryResult = Apollo.QueryResult<VerFinanzasGerenciaDetallesQuery, VerFinanzasGerenciaDetallesQueryVariables>;
export const DepositosUmnFinanzasDocument = gql`
    query depositosUmnFinanzas($desde: Date!, $hasta: Date!, $umn_id: [ID]) {
  verDepositosUmnFinanzas(desde: $desde, hasta: $hasta, umn_id: $umn_id)
}
    `;

/**
 * __useDepositosUmnFinanzasQuery__
 *
 * To run a query within a React component, call `useDepositosUmnFinanzasQuery` and pass it any options that fit your needs.
 * When your component renders, `useDepositosUmnFinanzasQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDepositosUmnFinanzasQuery({
 *   variables: {
 *      desde: // value for 'desde'
 *      hasta: // value for 'hasta'
 *      umn_id: // value for 'umn_id'
 *   },
 * });
 */
export function useDepositosUmnFinanzasQuery(baseOptions: Apollo.QueryHookOptions<DepositosUmnFinanzasQuery, DepositosUmnFinanzasQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DepositosUmnFinanzasQuery, DepositosUmnFinanzasQueryVariables>(DepositosUmnFinanzasDocument, options);
      }
export function useDepositosUmnFinanzasLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DepositosUmnFinanzasQuery, DepositosUmnFinanzasQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DepositosUmnFinanzasQuery, DepositosUmnFinanzasQueryVariables>(DepositosUmnFinanzasDocument, options);
        }
export type DepositosUmnFinanzasQueryHookResult = ReturnType<typeof useDepositosUmnFinanzasQuery>;
export type DepositosUmnFinanzasLazyQueryHookResult = ReturnType<typeof useDepositosUmnFinanzasLazyQuery>;
export type DepositosUmnFinanzasQueryResult = Apollo.QueryResult<DepositosUmnFinanzasQuery, DepositosUmnFinanzasQueryVariables>;
export const UmnResumenFinanzasDocument = gql`
    query umnResumenFinanzas($desde: Date!, $hasta: Date!, $umn_id: [ID]) {
  verUmnFinanzas(desde: $desde, hasta: $hasta, umn_id: $umn_id)
}
    `;

/**
 * __useUmnResumenFinanzasQuery__
 *
 * To run a query within a React component, call `useUmnResumenFinanzasQuery` and pass it any options that fit your needs.
 * When your component renders, `useUmnResumenFinanzasQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUmnResumenFinanzasQuery({
 *   variables: {
 *      desde: // value for 'desde'
 *      hasta: // value for 'hasta'
 *      umn_id: // value for 'umn_id'
 *   },
 * });
 */
export function useUmnResumenFinanzasQuery(baseOptions: Apollo.QueryHookOptions<UmnResumenFinanzasQuery, UmnResumenFinanzasQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UmnResumenFinanzasQuery, UmnResumenFinanzasQueryVariables>(UmnResumenFinanzasDocument, options);
      }
export function useUmnResumenFinanzasLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UmnResumenFinanzasQuery, UmnResumenFinanzasQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UmnResumenFinanzasQuery, UmnResumenFinanzasQueryVariables>(UmnResumenFinanzasDocument, options);
        }
export type UmnResumenFinanzasQueryHookResult = ReturnType<typeof useUmnResumenFinanzasQuery>;
export type UmnResumenFinanzasLazyQueryHookResult = ReturnType<typeof useUmnResumenFinanzasLazyQuery>;
export type UmnResumenFinanzasQueryResult = Apollo.QueryResult<UmnResumenFinanzasQuery, UmnResumenFinanzasQueryVariables>;
export const ZonaAgenciaDocument = gql`
    query zonaAgencia($agencia: Int) {
  getZonasByAgencia(agencia: $agencia)
}
    `;

/**
 * __useZonaAgenciaQuery__
 *
 * To run a query within a React component, call `useZonaAgenciaQuery` and pass it any options that fit your needs.
 * When your component renders, `useZonaAgenciaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useZonaAgenciaQuery({
 *   variables: {
 *      agencia: // value for 'agencia'
 *   },
 * });
 */
export function useZonaAgenciaQuery(baseOptions?: Apollo.QueryHookOptions<ZonaAgenciaQuery, ZonaAgenciaQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ZonaAgenciaQuery, ZonaAgenciaQueryVariables>(ZonaAgenciaDocument, options);
      }
export function useZonaAgenciaLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ZonaAgenciaQuery, ZonaAgenciaQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ZonaAgenciaQuery, ZonaAgenciaQueryVariables>(ZonaAgenciaDocument, options);
        }
export type ZonaAgenciaQueryHookResult = ReturnType<typeof useZonaAgenciaQuery>;
export type ZonaAgenciaLazyQueryHookResult = ReturnType<typeof useZonaAgenciaLazyQuery>;
export type ZonaAgenciaQueryResult = Apollo.QueryResult<ZonaAgenciaQuery, ZonaAgenciaQueryVariables>;